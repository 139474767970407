import {changeNumber,addEntity,getEntityList} from "./reducer";
import { combineReducers } from "redux";


const rootReducer = combineReducers({
    changeNumber,
    addEntity,
    getEntityList
    
})



export default rootReducer