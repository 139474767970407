import React, { Component, Fragment } from 'react';
import Carousel from 'react-bootstrap/Carousel'
import './EventCarousel.css'

class EventCarousel extends Component {

    render() {
        return (

            <Carousel>
            {this.props.list.map((value) => {
                return (
                    <Carousel.Item>
                    <img
                        className="d-block w-100 image"
                        src={value.original}
                        alt="First slide"
                    />
                </Carousel.Item>
                );
            })}
                
            </Carousel>

        );
    }
}

export default EventCarousel;