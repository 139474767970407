import React,{Component} from 'react';
import './EmailVerification.css';
import {Container} from 'react-bootstrap';
import {emailVerification} from '../../util/APIUtils';
import Alert from 'react-s-alert';
import { Link } from 'react-router-dom';


class EmailVerification extends Component{
    constructor() {
        super()
        let url_string = window.location.href
        let url = new URL(url_string)

        this.state = {
            id: url.searchParams.get("id"),
            msgDisplay : false,
            msg: "Oops! Something went wrong. Please try again later."
        }
    }

        componentDidMount(){
            emailVerification(this.state.id)
            .then(response => {
                Alert.success("Your registered email has been successfully verified. You can now login!");
                this.props.history.push("/login")
            }).catch(error => {
                Alert.error((error && error.message) || 'Oops! Something went wrong!')
                this.setState({
                    msgDisplay:true,
                    msg:error.message
                })
            })
        }

    render(){
        return(
            <Container>
                <div className="email-verification-container">
                    <h2> Email Verification</h2>
                    <div>
                            {this.state.msgDisplay ?
                                <h3>{this.state.msg}<Link to="/login">Login</Link></h3>
                            :''}
                    </div>
                </div>
            </Container>
        )
    }
}

export default EmailVerification