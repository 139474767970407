import React, { Component } from 'react'
import { Container, Row, Col, Button, Form } from 'react-bootstrap'
import { getCurrentUserId } from '../util/functionUtils';
import { postComment } from '../util/healthAPIUtils';
import Alert from 'react-s-alert'

export default class FeedbackForm extends Component {

    constructor() {
        super()
        this.state = {
            comment: '',
            entityId: 1,
            entity: 'SHARED_REC_CONFIG',
            commenterId: getCurrentUserId(),
            commentList: []
        }
    }

    handleChageInput = (event) => {
        this.setState({
            comment: event.target.value
        })
    }
    handleSubmit = () => {
        const commentRequest = Object.assign({}, this.state)

        postComment(commentRequest)
            .then(reponse => {
                Alert.success("comment saved successfully")
            })
            .catch(error => {
                Alert.error((error && error.message) || 'Something went wrong, please try again later!');
            })
    }

    render() {
        return (
            <Container>
                <div>
                    <br /> <br /> <br /> <br /> <br />
                    {this.state.commentList.map(value => {
                        return (
                            <>
                                <Form.Control size="lg" type="text" value={value} name="comment" /> <br />
                            </>
                        )
                    })}
                    <Form>
                        <Row>
                            <Col md={4}>
                            </Col>
                            <Col md={8}>
                                <Form.Group>
                                    <Form.Control as="textarea" rows="3" name="comment" value={this.state.comment} placeholder="Please Provide Feedback" onChange={this.handleChageInput} maxLength="1000" />
                                </Form.Group>
                                <Button variant="primary " className="float-right" type="button" onClick={this.handleSubmit}>Submit</Button>
                            </Col>
                        </Row>

                    </Form>
                </div>
                <br />
                <br />
            </Container>
        )
    }
}
