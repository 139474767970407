import React, { Component } from 'react'

import { Container, Row, Media, Col, Form, Image, Button, CardDeck, Card } from 'react-bootstrap';
import EmptyJumbotron from '../common/EmptyJumbotron';
import { loadPharmacyList, searchPharmacyBy, getPharmacyListByStatus } from '../util/pharmacyUtil'
import Alert from 'react-s-alert';
import LoadingIndicator from '../common/LoadingIndicator';
import AddSharpIcon from '@material-ui/icons/AddSharp';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { getRole } from '../util/functionUtils';
import Select from 'react-select';
import Referential from '../referential/Referential';
import SearchIcon from '@material-ui/icons/Search';
import { Link } from 'react-router-dom';
import RefreshTwoToneIcon from '@material-ui/icons/RefreshTwoTone';
import {addressCountryStateCityMap,convertIntoCamelCase } from '../util/functionUtils'
import { getAddress		} from '../util/ReferentialAPIUtils'



export default class PharmacyList extends Component {

	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			role: getRole(),
			PharmacyList: [],
			selectedCountry: null,
			selectedState: null,
			selectedCity: null,
			name: '',
			type: '',
			countryCode: '',
			stateCode: '',
			cityCode: '',
			pageNumber: 0,
			pageSize: 20,
			totalElements: 0,
			status: [{ label: "APPROVED", value: "APPROVED" }, { label: "PENDING", value: "PENDING" }, { label: "REJECTED", value: "REJECTED" }, { label: "DEACTIVATED", value: "DEACTIVATED" }],
			selectedStatus: '',
			types: [{ label: "ALLOPATHY", value: "ALLOPATHY" }, { label: "HOMEOPATH", value: "HOMEOPATH" }, { label: "AYURVEDA", value: "AYURVEDA" }],
			selectedTypes: '',
			selectPharmacy: this.props.history.location.state ? this.props.history.location.state.selectPharmacy ? this.props.history.location.state.selectPharmacy : false : false,
		}
		this.handleSearch = this.handleSearch.bind(this);
		this.loadMore = this.loadMore.bind(this);
	}


	componentDidMount() {
		this.loadPharmacy()
		window.scrollTo(0, 0)
	}

	loadPharmacy = () => {
		let addressList=[]
		searchPharmacyBy("", "", "", "", "", this.state.pageNumber, this.state.pageSize)
			.then(response => {
				if(response.totalElements >0 ){
					//adding address into list
					response.content.map(ad=>{
					return addressList.push(ad.address);
					})
					// converting country,state,and city
					let requestMap = addressCountryStateCityMap(addressList)
					getAddress(requestMap)
					.then(res => {
						response.content.map(add => {
						if (res[add.address.countryCode]) {
							add.address.country = res[add.address.countryCode]["ccDisplayStr"]
							add.address.state = res[ add.address.countryCode]["stateMap"][ add.address.stateCode]["scDisplayStr"]
							add.address.city = res[ add.address.countryCode]["stateMap"][ add.address.stateCode]["cityMap"][ add.address.cityCode]["ccDisplayStr"]
						}
						this.setState({
							PharmacyList: response.content,
							totalElements: response.totalElements,
							loading: false
						})
						}) 
					}).catch(error => {
					// Alert.error((error && error.message) || 'Something went wrong, please try again later!');
					this.setState({
						loading: false
					})
					})
					} else{
						this.setState({
							loading: false
						})
					}
				}
				
				).catch(error => {
					Alert.error((error && error.message) || 'Something went wrong, please try again later!');
					this.setState({
						loading: false
					})
				}
			)
	}

	loadMore() {
		this.setState({
			pageNumber: this.state.pageNumber + 1,
			loading: true
		})
		searchPharmacyBy("", "", "", "", "", this.state.pageNumber, this.state.pageSize)
			.then(response => {
				this.setState({
					PharmacyList: [...this.state.PharmacyList, ...response.content],
					loading: false
				});
			}).catch(error => {
				this.setState({
					loading: false
				});
				Alert.error((error && error.message) || 'Something went wrong, please try again later!');
			});
	}



	async handleSearch() {
		await this.setState({
			pageNumber: 0,
		})
		searchPharmacyBy(this.state.name, this.state.selectedTypes ? this.state.selectedTypes.value : '',this.state.countryCode, this.state.stateCode, this.state.cityCode, this.state.pageNumber, this.state.pageSize)
			.then(response => {
				this.setState({
					PharmacyList: response.content,
					totalElements: response.totalElements,
					loading: false
				});
			}).catch(error => {
				Alert.error((error && error.message) || 'Something went wrong, please try again later!');
			});
	}


	clearSearch = () => {
		this.setState({
			name: '',
			type: '',
			selectedCountry: null,
			selectedState: null,
			selectedCity: null,
			selectedTypes: null,
			countryCode: '',
			stateCode: '',
			cityCode: '',
		})
	}

	handleNameChange = (event) => {
		this.setState({
			name: event.target.value
		})
	}

	handleTypeChange = (selectedOption) => {
		this.setState({
			selectedTypes: selectedOption
		})
	}

	// searching pharmacy
	async searchPharmacy(setValue, setName, selectedOption, selected) {
		await this.setState({
			[setName]: setValue,
			[selected]: selectedOption
		})
	}

	// get pharmacy by status
	searchPharmacyByStatus = (selectedOption) => {
		const status = selectedOption.value
		this.setState({
			selectedStatus: selectedOption,
			loading: true
		})
		getPharmacyListByStatus(status)
			.then(response => {
				this.setState({
					PharmacyList: response.content,
					loading: false
				});
			}).catch(error => {
				this.setState({
					loading: false
				});
				Alert.error((error && error.message) || 'Something went wrong, please try again later!');
			});

	}

	handleBack = () => {
		this.props.history.goBack()
	}

	render() {
		if (this.state.loading) {
			return <LoadingIndicator />
		}
		return (
			<Container>
				<div className="doctor-container container-fluid">
					<Row className="welcome text-center mb-5">
						<Col sm={12}>
							<h3>Pharmacies</h3>
						</Col>
						<hr />
					</Row>
					<div className="heading">
						<Button className="float-right" varient="primary" onClick={this.handleBack}><ArrowBackIcon /></Button>
					</div>
					<Row className="justify-content-center mb-2">
						<Col md={7}>
							<Form.Control data-browse="Button text" type="text" name="name" value={this.state.name} placeholder="Search by pharmacy name" onChange={this.handleNameChange} />
						</Col>
					</Row>
					<Row>
						<Col md={2} className="mb-2">
						</Col>
						<Col md={2} className="mb-2">
							<Select
								options={this.state.types}
								onChange={this.handleTypeChange}
								placeholder="Types"
								value={this.state.selectedTypes}
							/>
						</Col>
						<Referential
							doctorFilterBy={(setValue, setName, selectedOption, selected) => this.searchPharmacy(setValue, setName, selectedOption, selected)}
							selectedCity={this.state.selectedCity}
							selectedState={this.state.selectedState}
							selectedCountry={this.state.selectedCountry}
						/>
						<Col md={2}>
							{
								this.state.role.roleAdmin === true ?
									<Select
										options={this.state.status}
										onChange={this.searchPharmacyByStatus}
										placeholder="Status"
										value={this.state.selectedStatus}
									/> : null
							}
						</Col>
					</Row>
					<Row className="justify-content-end mt-2">
						<Col xs={3} sm={2} md={1}>
							<Button onClick={this.handleSearch}> <SearchIcon /></Button>
						</Col>
						<Col xs={3} sm={2} md={1}>
							<Button onClick={this.clearSearch}><RefreshTwoToneIcon /></Button>
						</Col>
					</Row>


					<hr />
					{this.state.doctorPartReload ? <LoadingIndicator /> :
						<>


							<CardDeck>
								{
									this.state.PharmacyList.map(function (ph) {
										// let userId = dc.userId
										return (
											<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-4" key={ph.id}>
												<Media >
													<Image
														width={150}
														height={150}
														className="mr-3"
														src={ph.photoLink ? ph.photoLink : "../img/mac.png"}
														alt="Generic placeholder"
														roundedCircle
													/>
													<Media.Body>
														<h6>{ph.name}</h6>
														{/* <p className="crdfont mb-2">{dc.experienceInYears + " years | " + this.getSps(dc.specialisations)}</p> */}
														<p className="crdfont mb-2">{ph.about.substring(0, 100) + "..."}</p>
														<p className="crdfont mb-2"><h6>Address:{ph.address.house} {ph.address.street1 ? (", " + convertIntoCamelCase(ph.address.street1)) : null}{ph.address.street2 ? (", " + convertIntoCamelCase(ph.address.street2)) : null}
                                                          {ph.address.street3 ? (", " + convertIntoCamelCase(ph.address.street3)) : null} {ph.address.city?(", "+ convertIntoCamelCase(ph.address.city)):null}{ph.address.state?(", "+ convertIntoCamelCase(ph.address.state)):null}
                                                          {ph.address.country?(","+convertIntoCamelCase(ph.address.country)):null}-{ph.address.pincode}  </h6></p>

														<Link to={{ pathname: "/pd/" + ph.id }} style={{ color: 'white', textDecoration: 'none' }}><Button className="align-center" variant="primary" size="sm"> Know more...</Button></Link>
														{/* {ph.serviceAvailable ? <Link style={{ color: 'white', textDecoration: 'none' }}><Button className="align-center btn btn-success float-right" size="sm"> Order Medicine</Button></Link> : null} */}
														{/* {this.state.selectPharmacy ? <Link to={{pathname:"/ad"}} style={{ color: 'white', textDecoration: 'none' }}><Button className="align-center btn btn-success float-right" size="sm"> Order Medicine</Button></Link> : null} */}
													</Media.Body>
												</Media>
											</div>
										)
									}, this)
								}
							</CardDeck>


							{this.state.PharmacyList.length >= 20 ?
								this.state.PharmacyList.length < this.state.totalElements ?
									<Button color="primary" className=" loadMore " size="sm" onClick={this.loadMore}>Load more...</Button> :
									<Button color="primary" className=" loadMore" size="sm" href="#top">Back to top</Button> : null
							}
							{this.state.PharmacyList.length === 0 ? <h3 className="text-center"> No pharmacy found !</h3> : null}
						</>
					}
				</div>
				<br />	<br />
			</Container>
		)
	}
}