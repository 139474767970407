import React, { Component } from 'react';
import { Jumbotron, Media, Table } from 'react-bootstrap';
import './PrivacyPolicy.css';

export default class PrivacyPolicy extends Component {

	componentDidMount(){
		window.scrollTo(0, 0)
	}
	
	render() {
		return (
			<div className="container-fluid">
				<Jumbotron className="row ppjumbotron justify-content-center">
					<div>
						<h1 className="justify-content-center text-black">Privacy Policy</h1>
					</div>
				</Jumbotron>

				<div className="container mb-2">
					<Table className="shadow" striped bordered hover variant="light">
						<div className="justify-content-center para">
							<p>BI Health is determined to following highest standards for secure transactions and customer information privacy. Below is the details about our information gathering and dissemination practices.</p>
							<p>By using the BI Health website or mobile application, you expressly consent to our use and disclosure of your personal information in accordance with this Privacy Policy.</p>
							<p>We collect two kinds of information from you when you visit the Website.</p>
							<p>Firstly, we collect any information you voluntarily provide to us during registration or details provided on the ‘Contact Us’ page. Any information that you post or otherwise provide to BI Health by means of website or mobile application shall be true and accurate, not misleading, and offered in good faith.</p>
							<p>Whenever you request for a medical consultation, online or offline, we keep record of your identity and contact details and of any medical conditions discussed, and guidance provided during your medical consultation, medical prescriptions, allowing for ease of use during future engagement, diagnostic reports and other health records provided to us. Our unique Patient Management System guarantees privacy and confidential record keeping. All medical consultations including audio and video consultations are recorded and treated as your Electronic Health Records.</p>
							<p>Secondly, we automatically collect some information from you that’s related to how you’re accessing the Website. For example, we collect the Internet Protocol (IP) address of the domain you use to access the internet, the date and time you access our site, the pages you visit, and the Internet address of the website from which you linked directly to our site, as well as other similar features that are useful to us in providing the best possible content to you. This information is not shared with anyone beyond the support staff for this website, except when required by law or other law enforcement authorities and is used only as a source of anonymous statistical information.</p>
							<p>We will occasionally ask you to complete your personal profile page and update your health record for your own usage. These information might be used as contact information and demographic information (like pin code, location, age, or income level). We use this data to tailor your experience, providing you with content or services that we think you might be interested in and to display content according to your preferences.</p>
							<p>You must promptly notify us along with your bank/credit card provider if your credit card, user name, or password is lost, stolen, or used without permission. In such an event, we will remove that credit card number, user name, or password from your account and update our records accordingly.</p>
							<br></br>
							<h4>Cookies</h4>
							<p>Cookies are useful for enabling the browser to remember information specific to a given User. We place both permanent and temporary cookies in your computer’s hard drive. The cookies do not contain any of your personally identifiable information.</p>
							<p>We use data collection devices such as “cookies” on certain pages of the Website to help analyse our web page flow, measure promotional effectiveness, and promote trust and safety</p>
							<p>We also use cookies to allow you to enter your password less frequently during a session. Cookies can also help us provide information that is targeted to your interests. You are always free to decline our cookies if your browser permits, although in that case you may not be able to use certain features on the Website and you may be required to re-enter your password more frequently during a session. Additionally, you may encounter “cookies” or other similar devices on certain pages of the Website that are placed by third parties. We do not control use of cookies by third parties.</p>
							<br></br>
							<h4>Sharing of personal information</h4>
							<p>We may share personal information with our other corporate entities and affiliates to provide services to you, to help detect and prevent identity theft, fraud and other potentially illegal acts; correlate related or multiple accounts to prevent abuse of our services; and to facilitate to provide their services or joint or co-branded services that you request where such services are provided by more than one corporate entity.</p>
							<br></br>
							<h4>Advertisements on Website</h4>
							<p>We allow third-party advertising companies to display ads when you visit our Website. These companies may use information (not including username, address, email address, or telephone number) about your visits to this and other websites in order to provide advertisements about goods and services of interest to you.</p>
							<br></br>
							<h4>Your Consent</h4>
							<p>By using the Website, availing our services and/ or by providing your information, you give your express consent to the collection and use of the information you disclose on the Website or while availing our services in accordance with this Privacy Policy, including but not limited to your consent for sharing your information as per this privacy policy.</p>
							<p>If we decide to change our privacy policy, we will post those changes on this page so that you are always aware of what information we collect, how we use it, and under what circumstances we disclose it</p>
							<br></br>
							<h4>Third Party Website</h4>
							<p>The Site contains links to other websites. We are not responsible for the privacy practices or the content of such websites. We also make chat rooms, forums, message boards, and news groups available to you. Please understand that any information that is disclosed in these areas becomes public information. We have no control over its use and you should exercise caution when deciding to disclose your Personal Information.</p>
							<br></br>
							<h4>Communications</h4>
							<p>You consent to receive/ communicate data, information, updates from BI Health regarding the Services, through valid modes of communication which includes but not limited to emails, SMS, MMS, and other messages etc. We provide you with the opportunity to opt-out of receiving non-essential (promotional, marketing-related) communications from us after setting up an account with us.</p>
							<p>If you wish to remove your contact information from all BI Health lists and newsletters, please write to contact@binaryintuition.com</p>
						</div>
					</Table>
				</div>
			</div >
		)
	}
}