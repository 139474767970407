import { FilterDramaTwoTone } from '@material-ui/icons';
import { HEALTH_RECORD_API_BASE_URL ,COMMENTS_API_BASE_URL} from '../constants';
import { request } from './APIUtils';


export function createPrescription(prescriptionFormRequest , method) {

    return request({
        url: HEALTH_RECORD_API_BASE_URL + "/prescription",
        method: method,
        body: JSON.stringify(prescriptionFormRequest)

    });
}

export function getPrescriptionById(prescriptionId,appointmentId) {
    return request({
        url: HEALTH_RECORD_API_BASE_URL + "/prescription?id=" + prescriptionId + "&aid=" + appointmentId,
        method: 'GET'
    });
}

export function getPrescriptionList (consumerId , pageNumber , pageSize){
    return request({
        url : HEALTH_RECORD_API_BASE_URL + "/prescription?cid=" + consumerId + "&page=" + pageNumber + "&size=" + pageSize,
        method : 'GET'
    })
}

export function addCategory(addCategoryRequest) {
    return request({
        url: HEALTH_RECORD_API_BASE_URL + "/hrecord/cat",
        method: 'POST',
        body: JSON.stringify(addCategoryRequest)
    })
}


export function loadCategoryList(ownerId) {
    return request({
        url: HEALTH_RECORD_API_BASE_URL + "/hrecord/cat?oid=" + ownerId,
        method: 'GET',
    })
}

export function uploadMedicalRecord(medicalReportRequest) {
    return request({
        url: HEALTH_RECORD_API_BASE_URL + "/hrecord",
        method: 'POST',
        body: JSON.stringify(medicalReportRequest)
    })
}
export function getMedicalReport(ownerId, categoryId) {
    return request({
        url: HEALTH_RECORD_API_BASE_URL + "/hrecord?oid=" + ownerId + "&catid=" + categoryId,
        method: 'GET'
    })
}

export function DeleteRecordById(recordId) {
    return request({
        url: HEALTH_RECORD_API_BASE_URL + "/hrecord?id=" + recordId,
        method: 'DELETE'
    })
}
export function DeleteCategoryById(categoryId) {
    return request({
        url: HEALTH_RECORD_API_BASE_URL + "/hrecord/cat?id=" + categoryId,
        method: 'DELETE'
    })
}

export function getShareByList(accessId, withOrBy, pageNumber, pageSize) {
    return request({
        url: HEALTH_RECORD_API_BASE_URL + "/hrecord/config?auid=" + accessId + "&bow=" + withOrBy + "&page=" + pageNumber + "&size=" + pageSize,
        method: 'GET'
    })
}

export function getShareWithList(accessId, withOrBy, pageNumber, pageSize) {
    return request({
        url: HEALTH_RECORD_API_BASE_URL + "/hrecord/config?auid=" + accessId + "&bow=" + withOrBy + "&page=" + pageNumber + "&size=" + pageSize,
        method: 'GET'
    })
}

export function sharedByRequest(shareByRequest) {
    return request({
        url: HEALTH_RECORD_API_BASE_URL + "/hrecord/config",
        method: 'POST',
        body: JSON.stringify(shareByRequest)
    })
}


export function getSharedDetails(recordId) {
    return request({
        url: HEALTH_RECORD_API_BASE_URL + "/hrecord/config?id=" + recordId,
        method: 'GET'
    })
}

export function unShareRecord(recordId) {
    return request({
        url: HEALTH_RECORD_API_BASE_URL + "/hrecord/config?id=" + recordId,
        method: 'DELETE'
    })
}

export function unShareSingleRecord(recordId) {
    return request({
        url: HEALTH_RECORD_API_BASE_URL + "/hrecord/share?id=" + recordId,
        method: 'DELETE'
    })
}

export function downloadHealthRecord(recordId, download) {
    return request({
        url: HEALTH_RECORD_API_BASE_URL + "/hrecord/url?hid=" + recordId+"&d="+download,
        method: 'GET'
    })
}
export function downloadPrescriptionRecord(presId,download) {
    return request({
        url: HEALTH_RECORD_API_BASE_URL + "/prescription/url?id=" + presId + "&d="+ download,
        method: 'GET'
    })
}

export function postComment(commentRequest){
    console.log(" in health record" ,commentRequest);
    return request({
        url:COMMENTS_API_BASE_URL + "/comment",
        method:'POST',
        body:JSON.stringify(commentRequest)
    })
}

export function getCommentList(entityId,entity){
    return request({
        url:COMMENTS_API_BASE_URL + "/comment?eid=" + entityId + "&e=" + entity,
        method:'GET'
    })
}

export function trackHabit(habitRequest) {
    return request({
        url: HEALTH_RECORD_API_BASE_URL + "/habit",
        method: 'POST',
        body: JSON.stringify(habitRequest)
    })
}

export function updatedHabit(habitRequest) {
    return request({
        url: HEALTH_RECORD_API_BASE_URL + "/habit",
        method: 'PUT',
        body: JSON.stringify(habitRequest)
    })
}

export function deleteHabitById(id) {
    return request({
        url: HEALTH_RECORD_API_BASE_URL + "/habit?id=" + id,
        method: 'DELETE'
    })
}

export function getHabitById(id) {
    return request({
        url: HEALTH_RECORD_API_BASE_URL + "/habit?id=" + id,
        method: 'GET',
    })
}

export function loadTrackedHabits(ownerId, habitDate, tz) {
    const dateStr = habitDate.getFullYear()+"-" + (habitDate.getMonth() + 1) +"-"+habitDate.getDate();
    return request({
        url: HEALTH_RECORD_API_BASE_URL + "/habit/?cid=" + ownerId+"&date="+dateStr+"&tz="+tz,
        method: 'GET',
    })
}