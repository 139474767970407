import React, {Component} from 'react';
import {Jumbotron, Container} from 'react-bootstrap';

import './EmptyJumbotron.css';

export default class EmptyJumbotron extends Component{
	render(){
		return(
				<Jumbotron fluid>
					<Container>

					</Container>  
				</Jumbotron>				
		)
	}
}