// To Show the event details of Individual one
import React, { Component } from "react";
import './Event.css'
// import '../../node_modules/bootstrap/dist/css/bootstrap.min.css'

import CustomFooter from '../common/CustomFooter';
import EmptyJumbotron from '../common/EmptyJumbotron';
import { Link } from 'react-router-dom';

import ImageGallery from 'react-image-gallery';
import { fetchGalleryParts } from '../util/APIUtils';
import Alert from 'react-s-alert';
import LoadingIndicator from '../common/LoadingIndicator';
import "react-image-gallery/styles/css/image-gallery.css";
import EventCarousel from './EventCarousel';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ShareIcon from '@material-ui/icons/Share';


import { Container, Row, Col, Card, Image, Button } from 'react-bootstrap';

export default class EventGallery extends Component {

//Event Id needs to be passed as props

  constructor(props) {
    super(props);
    this.state = {
      eId: this.props.location.id,
      banners: this.props.location.banners,
      eventDetails: this.props.location.eventDetails,
      images:[],
      loading: true
    };
    this.loadGalleryParts = this.loadGalleryParts.bind(this);
  }

  loadGalleryParts() {
    fetchGalleryParts(this.state.eId,"PHOTO")
      .then(response => {
        this.setState({
          images: response.content,
          loading: false
        });

      }).catch(error => {
        this.setState({
          loading: false
        });
        Alert.error((error && error.message) || 'Something went wrong, please try again later!');
      });
  }

  componentDidMount() {
    this.loadGalleryParts();
  }


  render() {
    if (this.state.loading) {
      return <LoadingIndicator />
    }
    return (
      <Container>
        {this.state.banners ? <EventCarousel list={this.state.banners} />:null}
        <Card style={{ width: "100%", backgroundColor: 'rgb(222, 247, 247)', marginTop: '4px' }}>
                                <Card.Body>
                                    <Row>
                                        <Col sm={6} lg={9} className="mb-2">
                                            <Card.Text>{new Date(this.state.eventDetails.dateStart).toLocaleDateString([], { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })} at {new Date(this.state.eventDetails.dateStart).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} <LocationOnIcon /> </Card.Text>
                                        </Col>
                                        <Col sm={6} lg={3} className="mb-2">
                                        

                                            <Card.Link><Link to={{ pathname: "/er",banners: this.state.banners  }}> <Button>Save my Spot</Button></Link></Card.Link>
                                            <Card.Link><Button><ShareIcon /></Button></Card.Link>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
        <ImageGallery items={this.state.images} />
        <EmptyJumbotron />
        <CustomFooter />
      </Container>
    )
  }
}
