import React, { Component } from 'react';
import EmptyJumbotron from '../common/EmptyJumbotron';
import Alert from 'react-s-alert';
import { getSpecializationList, getQualificationList, createDoctor, fetchDoctorDetails } from '../util/APIUtils';
import { getCityList, getCountryList, getStateList, getPincodeList } from '../util/ReferentialAPIUtils';
// import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './DoctorForm.css'
import Select from 'react-select'
import { Link } from 'react-router-dom';
import { Col, Button, Row, Table } from 'react-bootstrap'
import ProfExps from './ProfExps';
import AddSharpIcon from '@material-ui/icons/AddSharp';
import SocialProfile from './SocialProfile';
import LoadingIndicator from '../common/LoadingIndicator';
import { getCurrentUserId, getRole } from '../util/functionUtils';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';


class DoctorForm extends Component {

    constructor(props) {
        super(props)
        this.state = {
            spList: [],
            qlList: [],
            countryList: [],
            states: [],
            cities: [],
            selectedCountry: '',
            selectedState: '',
            selectedCity: '',
            selectedPincode: '',
            pincodes: [],
            registration: "",
            name: this.props.history.location.state ? this.props.history.location.state.name : '',
            email: this.props.history.location.state ? this.props.history.location.state.email : '',
            phone: this.props.history.location.state ? this.props.history.location.state.phone : '',
            checkForPhoneField: this.props.history.location.state ? this.props.history.location.state.phone : null,
            experienceInYears: '',
            cityCode: "",
            countryCode: "",
            house: "",
            pincode: "",
            stateCode: "",
            street1: "",
            street2: "",
            street3: "",
            specialisations: [],
            degrees: [],
            about: "",
            socialProfiles: [{
                index: Math.random(), type: '', profileUrl: ''
            }],
            profExps: [{
                index: Math.random(), designation: '', organisation: '', fromDate: '', endDate: ''
            }],
            userId: getRole().roleAdmin ? null : getCurrentUserId(),
            doctorId: this.props.history.location.state ? this.props.history.location.state.doctorId : '',
            dd: '',
            loading: this.props.history.location.state ? this.props.history.location.state.loading : false,
            back: this.props.history.location.state ? this.props.history.location.state.back : '',
            validateAddress: ['cityCode', 'countryCode', 'stateCode', 'pincode'],
            validateOtherField: ["specialisations", 'degrees'],
        }
    }

    loadSp() {

        getSpecializationList(0, 200)
            .then(response => {
                this.setState({
                    spList: response.content.map(data => {
                        return { label: data.dislayStr, value: data }
                    })
                });
            }).catch(error => {
                console.log('No Data available');
            });

    }
    loadQl() {
        getQualificationList(0, 200)
            .then(response => {
                this.setState({
                    qlList: response.content.map(data => {
                        return { label: data.dislayStr, value: data }
                    })
                });
            }).catch(error => {
                console.log('No Data available');
            });

    }

    addIndex = (objectList) => {
        objectList.map(value => {
            const randomNumber = Math.random()
            value.index = randomNumber
            return null
        })
        return objectList

    }

    loadDoctor = () => {
        fetchDoctorDetails(this.state.doctorId)
            .then(response => {
                if (response.address && response.address[0]) {
                    this.populateReferential(response.address[0].countryCode, response.address[0].stateCode, response.address[0].cityCode, response.address[0].pincode);
                }

                this.setState({
                    dd: response,
                    loading: false,
                    name: response.name,
                    email: response.email,
                    phone: response.phone,
                    cityCode: response.address[0] ? response.address[0].cityCode : '',
                    countryCode: response.address[0] ? response.address[0].countryCode : '',
                    house: response.address[0] ? response.address[0].house : '',
                    pincode: response.address[0] ? response.address[0].pincode : '',
                    stateCode: response.address[0] ? response.address[0].stateCode : '',
                    street1: response.address[0] ? response.address[0].street1 : '',
                    street2: response.address[0] ? response.address[0].street2 : '',
                    street3: response.address[0] ? response.address[0].street3 : '',
                    designation: response.designation,
                    registration: response.registration,
                    experienceInYears: response.experienceInYears,
                    specialisations: response.specialisations ? response.specialisations.map(value => {
                        return { label: value.dislayStr, value: value }
                    }) : [],
                    degrees: response.degrees ? response.degrees.map(value => {
                        return { label: value.dislayStr, value: value }
                    }) : [],
                    about: response.about,
                    socialProfiles: response.socialProfiles ? this.addIndex(response.socialProfiles) : [],
                    profExps: response.profExps ? this.addIndex(response.profExps) : [],
                    userId: response.userId

                });

            }).catch(error => {
                this.setState({
                    loading: false
                });
                Alert.error((error && error.message) || 'Something went wrong, please try again later!');
            });
    }

    populateReferential = (countryCode, stateCode, cityCode, pincode) => {
        let setCountryName = ''
        let setStateName = ''
        let setCityName = ''
        let setPincode = ''
        let states = ''
        let cities = ''
        let pincodes = ''
        this.state.countryList.map(value => {
            if (countryCode === value.value.code) {
                setCountryName = value
                this.setState({
                    selectedCountry: setCountryName
                })
            }
        })


        getStateList(countryCode)
            .then(response => {
                states = response.map(state => {
                    if (state.code === stateCode) {
                        setStateName = { label: state.dislayStr, value: state }
                        this.setState({
                            selectedState: setStateName,
                        })
                    }
                    return { label: state.dislayStr, value: state }
                })
                this.setState({
                    states: states
                })
            })
            .then(() => {
                getCityList(countryCode, stateCode)
                    .then(response => {
                        cities = response.map(city => {
                            if (city.code === cityCode) {
                                setCityName = { label: city.dislayStr, value: city }
                                this.setState({
                                    selectedCity: setCityName,
                                })
                            }
                            return { label: city.dislayStr, value: city }
                        })
                        this.setState({
                            cities: cities
                        })
                    })

            })
            .then(() => {
                getPincodeList(countryCode, stateCode, cityCode)
                    .then(response => {
                        pincodes = response.map(pcode => {
                            if (pcode.code === pincode) {
                                setPincode = { label: pcode.code, value: pcode }
                                this.setState({
                                    selectedPincode: setPincode,
                                })
                            }
                            return { label: pcode.code, value: pcode }
                        })
                        this.setState({
                            pincodes: pincodes
                        })
                    })

            })
    }
    
    componentDidMount() {
        this.loadSp();
        this.loadQl();
        this.loadCountry()
            .then(() => {
                if (this.state.doctorId)
                    this.loadDoctor()
            })
        window.scrollTo(0, 0)

    }


    handleInputChange = (event) => {
        const target = event.target
        const inputName = target.name
        const inputValue = target.value

        this.setState({
            [inputName]: inputValue
        });
    }

    handleAddressChange = (event) => {
        const target = event.target
        const inputName = target.name
        const inputValue = target.value
        this.setState({
            [inputName]: inputValue
        })

    }

    handleSpAndQl = (selectedList, setName) => {

        if (selectedList === null) {
            this.setState({
                [setName]: []
            })
        }
        else {
            let jsonObject = selectedList.map(JSON.stringify);
            let uniqueSet = new Set(jsonObject);
            let uniqueselectedList = Array.from(uniqueSet).map(JSON.parse);
            this.setState({
                [setName]: uniqueselectedList
            })
        }

    }

    addNewProfExps = (setValue) => {
        this.setState((prevState) => ({
            [setValue]: [...prevState[setValue],
            {
                index: Math.random(), designation: '', organisation: '', fromDate: '', endDate: ''
            }
            ]
        }));
    }

    deleteProfExps = (index, setValue) => {
        this.setState({
            [setValue]: this.state[setValue].filter((value, currIndex) => currIndex !== index)
        });
    }

    profExpsInputChange = (inputName, inputValue, index, setValue) => {
        this.state[setValue][index][inputName] = inputValue
    }

    addNewSocialProfile = (setValue) => {
        this.setState((prevState) => ({
            [setValue]: [...prevState[setValue],
            {
                index: Math.random(), type: '', profileUrl: ''
            }
            ]
        }));
    }

    deleteSocialProfile = (index, setValue) => {
        this.setState({
            [setValue]: this.state[setValue].filter((value, currIndex) => currIndex !== index)
        });
    }

    socialProfilesInputChange = (inputName, inputValue, index, setValue) => {
        this.state[setValue][index][inputName] = inputValue
    }


    handleSubmit = (event) => {
        event.preventDefault()

        const address = [
            {
                cityCode: this.state.cityCode,
                countryCode: this.state.countryCode,
                house: this.state.house,
                pincode: this.state.pincode,
                stateCode: this.state.stateCode,
                street1: this.state.street1,
                street2: this.state.street2,
                street3: this.state.street3
            }
        ]

        const { name, email, phone, registration, experienceInYears, specialisations, degrees, about, socialProfiles, profExps, userId } = this.state
        let doctorFormRequest = { name, email, phone, address, registration, experienceInYears, specialisations, degrees, about, socialProfiles, profExps, userId, address }
        let method = 'POST'
        if (this.state.doctorId) {
            method = 'PUT'
            doctorFormRequest = Object.assign(this.state.dd, doctorFormRequest)
        }
        else {
            doctorFormRequest.isActive = true
        }
        doctorFormRequest.socialProfiles = doctorFormRequest.socialProfiles.filter(value => (value.profileUrl !== '' && value.type !== ''))

        if (this.validate(doctorFormRequest)) {

            if (this.state.specialisations.length !== 0 || this.state.specialisations[0] !== null || this.state.specialisations[0] !== undefined) {
                doctorFormRequest["specialisations"] = doctorFormRequest["specialisations"].map(selectedData => {
                    return selectedData.value
                })
            }

            if (this.state.degrees.length !== 0 || this.state.degrees[0] !== null || this.state.degrees[0] !== undefined) {
                doctorFormRequest["degrees"] = doctorFormRequest["degrees"].map(selectedData => {
                    return selectedData.value
                })
            }
            this.setState({
                loading: true
            })

            createDoctor(doctorFormRequest, method, this.state.userId)
                .then(response => {
                    if (this.state.doctorId) {
                        Alert.success("Doctor Updated successfully!");
                        this.props.history.push({ pathname: "/dp/" + this.state.doctorId })
                    } else {
                        Alert.success("Doctor Created successfully! System Admin will review and approve your profile. Once approved, please configure your apppintment schedule.");
                        this.props.history.push({ pathname: "/dp/" + response.id })
                    }
                }).catch(error => {
                    this.setState({
                        loading: false
                    })
                    Alert.error('Something went wrong, please try again later!');
                });
        }
        else {
            this.setState({
                loading: false
            })
            Alert.error('Please provide all required fields!');
            window.scrollTo(0, 0)
        }
    }

    validate = (doctorFormRequest) => {
        let pEle = document.getElementById('validate')

        for (let i = 0; i < this.state.validateOtherField.length; i++) {
            if (doctorFormRequest[this.state.validateOtherField[i]].length === 0 || doctorFormRequest[this.state.validateOtherField[i]][0] === null || doctorFormRequest[this.state.validateOtherField[i]][0] === undefined) {
                this.setState({
                    [this.state.validateOtherField[i]]: [],
                })
                pEle.innerHTML = "*Please provide specialization and qualification."
                return false
            }
        }

        for (let i = 0; i < this.state.validateAddress.length; i++) {
            if (doctorFormRequest['address'][0][this.state.validateAddress[i]] === '') {
                pEle.innerHTML = "*Please provide country, state, city and pincode."
                return false
            }
        }

        let profExps = doctorFormRequest.profExps
        for(let i = 0; i < profExps.length; i++){
            if(profExps[i].organisation !== ''){
                if (profExps[i].fromDate === '' || profExps[i].fromDate === null){
                    pEle.innerHTML = "*Please provide start date of all provided organisation under professional experiance section."
                    return false
                }
            }
        }

        pEle.innerHTML = ''
        return true
    }

    async loadCountry() {
        await getCountryList()
            .then(response => {
                this.setState({
                    countryList: response.map((data) => {
                        return { label: data.dislayStr, value: data }
                    })
                })
            }).catch(error => {
                console.log('No Data available');
            });

    }

    handleCountryChange = (selectedOption) => {
        const countryCode = selectedOption.value.code
        this.setState({
            selectedCity: '',
            selectedPincode: '',
            selectedState: '',
            cityCode: '',
            pincode: '',
            stateCode: '',
            countryCode: countryCode,
            selectedCountry: selectedOption,
        })
        getStateList(countryCode)
            .then(response => {
                this.setState({
                    states: response.map(data => {
                        return { label: data.dislayStr, value: data }

                    })
                })
            }).catch(error => {
                Alert.error('Something went wrong, please try again later!');
            })
    }

    handleStateChange = (selectedOption) => {
        const stateCode = selectedOption.value.code
        this.setState({
            selectedCity: '',
            selectedPincode: '',
            cityCode: '',
            pincode: '',
            stateCode: stateCode,
            selectedState: selectedOption
        })

        getCityList(this.state.countryCode, stateCode)
            .then(response => {
                this.setState({
                    cities: response.map(data => {
                        return { label: data.dislayStr, value: data }

                    })
                })
            })
            .catch(error => {
                Alert.error('Something went wrong, please try again later!');
            })
    }

    handleCityChange = (selectedOption) => {
        const CityCode = selectedOption.value.code
        this.setState({
            selectedCity: '',
            selectedPincode: '',
            pincode: '',
            cityCode: CityCode,
            selectedCity: selectedOption
        })

        getPincodeList(this.state.countryCode, this.state.stateCode, CityCode)
            .then(response => {
                this.setState({
                    pincodes: response.map(data => {
                        return { label: data.code, value: data }

                    })
                })
            })
            .catch(error => {
                Alert.error('Something went wrong, please try again later!');
            })
    }

    handlePincodeChange = (selectedOption) => {
        const pincode = selectedOption.value.code
        this.setState({
            pincode: pincode,
            selectedPincode: selectedOption
        })
    }


    render() {
        if (this.state.loading)
            return <LoadingIndicator />

        return (
            <div>
                <div className="doctor-form-container container-fluid">

                    <Row className="welcome text-center mb-5">
                        <div className="feature-title container-fluid">
                            {this.props.history.location.state ? this.props.history.location.state.edit ? <h3>Edit Doctor Profile</h3> : <h3>Doctor Profile</h3> : <h3>Doctor Profile</h3>}
                        </div>
                        <hr />
                    </Row>
                    <Row>

                        <Col md={12}>
                            {this.state.back === "profile" ?
                                <Link to={{ pathname: "/profile", state: { userProfile: true } }}> <Button type="button" className="btn btn-primary mb-3" ><ArrowBackIcon /></Button></Link> :
                                this.state.back === 'doctorProfile' ? <Link to={{ pathname: `/dp/${this.state.doctorId}` }}> <Button type="button" className="btn btn-primary mb-3"><ArrowBackIcon /></Button></Link> :
                                    <Link to="/dc"> <Button type="button" className="btn btn-primary mb-3" href="/dc"><ArrowBackIcon /></Button></Link>}

                            <div>
                                <p id="validate" style={{ color: 'red' }}></p>
                            </div>

                            <form onSubmit={this.handleSubmit}>
                                <Table bordered className="shadow">
                                    <thead className="thead-color">
                                        <th>Personal Details</th>
                                    </thead>
                                    <tbody>
                                        <td>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label for="inputName">Name</label>
                                                    {this.props.history.location.state ?
                                                        <input type="text" value={this.state.name} name="name" className="form-control" id="inputName" placeholder="Name" onChange={this.handleInputChange} required readOnly />
                                                        :
                                                        <input type="text" value={this.state.name} name="name" className="form-control" id="inputName" placeholder="Name" onChange={this.handleInputChange} required />
                                                    }
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label for="inputEmail">Email</label>
                                                    {this.props.history.location.state ?
                                                        <input type="email" value={this.state.email} name="email" className="form-control" id="inputEmail" placeholder="Email" onChange={this.handleInputChange} required readOnly />
                                                        : <input type="email" value={this.state.email} name="email" className="form-control" id="inputEmail" placeholder="Email" onChange={this.handleInputChange} required />}
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label for="inputPhone">Phone</label>
                                                    {
                                                        this.state.checkForPhoneField ?
                                                            <input type="text" value={this.state.phone} name="phone" className="form-control" id="inputPhone" placeholder="Phone" onChange={this.handleInputChange} maxlength="10" required readOnly />
                                                            : this.state.checkForPhoneField === null ?
                                                                <input type="text" value={this.state.phone} name="phone" className="form-control" id="inputPhone" placeholder="Phone" onChange={this.handleInputChange} maxlength="10" required />
                                                                : <input type="text" value={this.state.phone} name="phone" className="form-control" id="inputPhone" placeholder="Phone" onChange={this.handleInputChange} maxlength="10" required />}
                                                </div>

                                            </div>
                                        </td>
                                    </tbody>
                                </Table>

                                <Table bordered className="shadow">
                                    <thead className="thead-color">
                                        <th>Professional Details</th>
                                    </thead>
                                    <tbody>
                                        <td>
                                            <div className="form-row">

                                                <div className="form-group col-md-6">
                                                    <label for="inputState">Qualifications</label>
                                                    <Select
                                                        options={this.state.qlList}
                                                        value={this.state.degrees}
                                                        onChange={(value) => this.handleSpAndQl(value, "degrees")}
                                                        isMulti={true}
                                                    />
                                                </div>

                                                <div className="form-group col-md-6">
                                                    <label for="inputState">Specialisations</label>

                                                    <Select
                                                        options={this.state.spList}
                                                        value={this.state.specialisations}
                                                        onChange={(value) => this.handleSpAndQl(value, "specialisations")}
                                                        isMulti={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                {/* <div className="form-group col-md-6">
                                                    <label for="inputDesignation">Designation</label>
                                                    <input type="text" value={this.state.designation} name="designation" className="form-control" id="inputDesignation" placeholder="Designation" onChange={this.handleInputChange} />
                                                </div> */}
                                                <div className="form-group col-md-6">
                                                    <label for="inputRegistration">Registration</label>
                                                    <input type="text" value={this.state.registration} name="registration" className="form-control" id="inputRegistration" placeholder="Registration" onChange={this.handleInputChange} required />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label for="inputExperience">Year of Experience</label>
                                                    <input type="number" min="0" value={this.state.experienceInYears} name="experienceInYears" className="form-control" id="inputExperience" placeholder="Year of Experience" onChange={this.handleInputChange} required />
                                                </div>
                                            </div>
                                            <hr />

                                        </td>
                                    </tbody>
                                </Table>

                                <Table bordered className="shadow">
                                    <thead className="thead-color">
                                        <th>Professional Experience</th>
                                    </thead>
                                    <tbody>
                                        <td>
                                            <div className="form-group">
                                                {/* <label for="inputRegistration">Professional Experience</label>{this.state.profExps.length === 0 ? <Button style={{ float: "right" }} onClick={() => this.addNewProfExps("profExps")}><AddSharpIcon /></Button> : null}<br /><hr /> */}

                                                {this.state.profExps.map((value, index) => {
                                                    return (
                                                        <ProfExps
                                                            key={value.index}
                                                            index={index}
                                                            data={{ ...value }}
                                                            deleteProfExps={(returnIndex) => this.deleteProfExps(returnIndex, "profExps")}
                                                            addNewProfExps={() => this.addNewProfExps("profExps")}
                                                            inputChange={(inputName, inputValue, index) => this.profExpsInputChange(inputName, inputValue, index, "profExps",)}
                                                        />
                                                    )
                                                })}
                                                <Button style={{ float: "right" }} onClick={() => this.addNewProfExps("profExps")}><AddSharpIcon /></Button>

                                            </div>
                                        </td>
                                    </tbody>
                                </Table>

                                <Table bordered className="shadow">
                                    <thead className="thead-color">
                                        <th>Address</th>
                                    </thead>
                                    <tbody>
                                        <td>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label for="inputAddress">House </label>
                                                    <input type="text" value={this.state.house} name="house" className="form-control" id="inputAddress" placeholder="1234 Main St" onChange={this.handleAddressChange} required />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label for="inputAddress">Street1 </label>
                                                    <input type="text" value={this.state.street1} name="street1" className="form-control" id="inputAddress" placeholder="1234 Main St, Apartment, studio, or floor" onChange={this.handleAddressChange} />
                                                </div>
                                            </div>

                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label for="inputAddress">Street2 </label>
                                                    <input type="text" value={this.state.street2} name="street2" className="form-control" id="inputAddress" placeholder="1234 Main St, Apartment, studio, or floor" onChange={this.handleAddressChange} />
                                                </div>

                                                <div className="form-group col-md-6">
                                                    <label for="inputAddress">Street3 </label>
                                                    <input type="text" value={this.state.street3} name="street3" className="form-control" id="inputAddress" placeholder="1234 Main St, Apartment, studio, or floor" onChange={this.handleAddressChange} />
                                                </div>
                                            </div>

                                            <div className="form-row">
                                                <div className="form-group col-md-3">
                                                    <label>Country</label>
                                                    <Select
                                                        options={this.state.countryList}
                                                        value={this.state.selectedCountry}
                                                        onChange={this.handleCountryChange}
                                                    />


                                                </div>
                                                <div className="form-group col-md-3">
                                                    <label>State</label>
                                                    <Select
                                                        options={this.state.states}
                                                        value={this.state.selectedState}
                                                        onChange={this.handleStateChange}
                                                    />
                                                </div>


                                                <div className="form-group col-md-3">
                                                    <label >City</label>
                                                    <Select
                                                        options={this.state.cities}
                                                        value={this.state.selectedCity}
                                                        onChange={this.handleCityChange}
                                                    />
                                                </div>

                                                <div className="form-group col-md-3">
                                                    <label >Pincode</label>
                                                    <Select
                                                        options={this.state.pincodes}
                                                        value={this.state.selectedPincode}
                                                        onChange={this.handlePincodeChange}
                                                    />
                                                </div>

                                            </div>
                                        </td>
                                    </tbody>
                                </Table>


                                <Table bordered className="shadow">
                                    <thead className="thead-color">
                                        <th>Social Profiles</th>
                                        {/* <th><Button style={{ float: "right" }} onClick={() => this.addNewSocialProfile("socialProfiles")}><AddSharpIcon /></Button></th> */}
                                    </thead>
                                    <tbody>
                                        <td>
                                            <div className="form-group">
                                                {this.state.socialProfiles.map((value, index) => {
                                                    return (
                                                        <SocialProfile
                                                            key={value.index}
                                                            index={index}
                                                            data={{ ...value }}
                                                            deleteSocialProfile={(returnIndex) => this.deleteSocialProfile(returnIndex, "socialProfiles")}
                                                            addNewSocialProfile={() => this.addNewSocialProfile("socialProfiles")}
                                                            inputChange={(inputName, inputValue, index) => this.socialProfilesInputChange(inputName, inputValue, index, "socialProfiles",)}
                                                        />
                                                    )
                                                })}
                                                <hr /><Button style={{ float: "right" }} onClick={() => this.addNewSocialProfile("socialProfiles")}><AddSharpIcon /></Button><br />


                                            </div>
                                        </td>
                                    </tbody>
                                </Table>



                                <Table bordered className="shadow">
                                    <thead className="thead-color">
                                        <th>About</th>
                                    </thead>
                                    <tbody>
                                        <td>
                                            <div className="form-group">
                                                <textarea name="about" rows="6" value={this.state.about} className="form-control" id="about" maxLength="1000" placeholder="maximum 1000 characters..." onChange={this.handleInputChange} required />
                                            </div>
                                        </td>
                                    </tbody>
                                </Table>
                                {this.state.back === "profile" ?
                                    <Link to={{ pathname: "/profile", state: { userProfile: true } }}> <Button type="button" className="btn btn-primary mb-3" >Back</Button></Link> :
                                    this.state.back === 'doctorProfile' ? <Link to={{ pathname: `/dp/${this.state.doctorId}` }}> <Button type="button" className="btn btn-primary mb-3">Back</Button></Link> :
                                        <Link to="/dc"> <Button type="button" className="btn btn-primary mb-3" href="/dc">Back</Button></Link>}

                                <Button type="submit" className="btn btn-primary float-right">Submit</Button>

                            </form>
                        </Col>
                    </Row>
                </div><br />
                <EmptyJumbotron />
            </div>
        );
    }
}

export default DoctorForm;