import React, { Component } from 'react';
import "./PaymentForm.css";
import { Row, Col, Button, Container, Form, Card, Media, CardDeck } from "react-bootstrap";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { Link } from 'react-router-dom';
import Edit from '@material-ui/icons/Edit';
import Alert from 'react-s-alert';
import DeleteOutlineSharpIcon from '@material-ui/icons/DeleteOutlineSharp';
import { deleteAddressById } from '../util/paymentUtils'

class AddressList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            billingAddressList: this.props.billingAddress
        }
    }


    editAddressHandler = (address) => {
        this.props.addressCallback(address)
    }

    deleteAddressHandler = (addressId) => {
        deleteAddressById(addressId)
            .then(response => {
                Alert.error("Address deleted successfully ");
            }).catch(error => {
                Alert.error((error && error.message) || 'Something went wrong, please try again later!');
            })
    }

    render() {
        return (
            <Container>
                <Row className="welcome text-center mb-5">
                    <Col sm={12}>
                        <h6>Select Address</h6>
                    </Col>
                    <hr />
                </Row>
                <Row>
                    <Col sm={6}>
                        <CardDeck>
                            {
                                this.props.billingAddress.map(ad => {
                                    return (
                                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-4" >
                                            <Media >
                                                <Media.Body>
                                                    <Form.Check type="radio" className="float-right" label="" value="DOCTOR" name="sharedWithType" onChange={this.handleChange}/>
                                                    <h6>{ad.type.toLowerCase()} address</h6>
                                                    <p className="crdfont mb-2"> House no:{ad.house}</p>
                                                    <p className="crdfont mb-2"> Street1:{ad.street1}</p>
                                                    <p className="crdfont mb-2">Street2:{ad.street2}</p>
                                                    <p className="crdfont mb-2">Street3:{ad.street3}</p>
                                                    <Button className="align-center" variant="secondary" size="sm" onClick={() => this.editAddressHandler(ad)}> <Edit /></Button>
                                                    <Button className="align-center ml-2" variant="secondary" size="sm" data-toggle="modal" data-target="#staticBackdrop"> <DeleteOutlineSharpIcon />
                                                    <div className="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                    <div className="modal-dialog">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title textColor" id="staticBackdropLabel">Are you sure you want to delete ?</h5>
                                                                <Button type="Button" className="close" data-dismiss="modal" aria-label="Close">
                                                                    <span aria-hidden="true">&times;</span>
                                                                </Button>
                                                            </div>
                                                            <div className="modal-footer">
                                                                <Button type="Button" className="btn btn-secondary" data-dismiss="modal">Cancel</Button>
                                                                <Button type="Button" className="btn btn-primary"  data-dismiss="modal" onClick={() => this.deleteAddressHandler(ad.id)}>Delete</Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                    </Button>
                                                </Media.Body>

                                            </Media>
                                        </div>
                                    )
                                }, this)
                            }
                        </CardDeck>
                     
                    </Col>
                    <Col sm={6}>
                        <CardDeck>
                            {
                                this.props.shippingAddress.map(ad => {
                                    return (
                                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-4" >
                                            <Media >
                                                <Media.Body>
                                                <Form.Check type="radio" className="float-right" label="" value="DOCTOR" name="sharedWithType" onChange={this.handleChange}/>
                                                    <h6>{ad.type.toLowerCase()} address</h6>
                                                    <p className="crdfont mb-2"> House no:{ad.house}</p>
                                                    <p className="crdfont mb-2"> Street1:{ad.street1}</p>
                                                    <p className="crdfont mb-2">Street2:{ad.street2}</p>
                                                    <p className="crdfont mb-2">Street3:{ad.street3}</p>
                                                    <Button className="align-center" variant="secondary" size="sm" onClick={this.editAddressHandler}> <Edit /></Button>
                                                    <Button className="align-center ml-2" variant="secondary" size="sm" onClick={() => this.deleteAddressHandler(ad.id)}> <DeleteOutlineSharpIcon /></Button>
                                                </Media.Body>

                                            </Media>
                                        </div>
                                    )
                                }, this)
                            }
                        </CardDeck>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default AddressList;