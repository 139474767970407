import React, { Component } from 'react';
import { Card } from 'react-bootstrap'
import './Slider.css'
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";



class Slider extends Component {
    handleSocialProfile = (value) => {
        if (value.type === 'FACEBOOK') {
            return (
                <a target="_blank" href={value.profileUrl}><FacebookIcon color="primary" style={{ fontSize: "18px" }} /></a>
            )
        }
        else if (value.type === 'INSTAGRAM') {
            return (
                <a target="_blank" href={value.profileUrl}><InstagramIcon color="secondary" style={{ fontSize: "18px" }} /></a>
            )
        }
        else if (value.type === 'LINKEDIN') {
            return (
                <a target="_blank" href={value.profileUrl}><LinkedInIcon style={{ fontSize: "18px" }} /></a>
            )
        }
        else if (value.type === 'TWITTER') {
            return (
                <a target="_blank" href={value.profileUrl}><TwitterIcon color="primary" style={{ fontSize: "18px" }} /></a>
            )
        }
        else if (value.type === 'GOOGLE') {
            return (
                <a target="_blank" href={value.profileUrl}><i class="fa fa-google" aria-hidden="true"></i></a>
            )
        }
    }
    render() {
        const responsive = {
            superLargeDesktop: {
                breakpoint: { max: 4000, min: 3000 },
                items: 5
            },
            desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 3
            },
            tablet: {
                breakpoint: { max: 1024, min: 464 },
                items: 2
            },
            mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 1
            }
        };
        return (



            <Carousel responsive={responsive}>
                {this.props.list.map((value) => {
                    return (
                        <Card
                            style={{ width: '17rem', borderRadius: '10px' }}
                            className="mb-2"
                        >
                            <Card.Img className="slider-img" variant="top" src={value.profileLink ? value.profileLink : 'img/mac.png'}></Card.Img>
                            <Card.Body>
                                <Card.Title>Name : {value.name}</Card.Title>
                                <Card.Text>Designation :{value.designation}</Card.Text>
                                <Card.Text>Organization :{value.organisation}</Card.Text>
                                <Card.Text>Follow Me : {value.socialProfile? (value.socialProfile.map((value) => this.handleSocialProfile(value))) : ""} </Card.Text>

                            </Card.Body>
                        </Card>
                    )
                })
                }
            </Carousel >
        )
    }
}

export default Slider;
