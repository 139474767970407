import React, { Component } from 'react'
import './AppointmentForm.css';
import { Row, Col, Button, Container, Form ,Table} from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { addAppointmentMember } from '../util/APIUtils';
import { changeTime, getCurrentUserId, getDateStrFromObj ,getCurrentUser} from '../util/functionUtils';
import { getAvailableAppointment, createAppointment, getMenberOfUser } from '../util/OrdersAPIUtils';
import Alert from 'react-s-alert';
import Select from 'react-select';
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import DoctorProfileHeader from '../doctors/DoctorProfileHeader'
import RefreshTwoToneIcon from '@material-ui/icons/RefreshTwoTone';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import LoadingIndicator from '../common/LoadingIndicator';

export default class AppointmentForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            member: 'myself',
            myselfChecked: true,
            dob: null,
            name: '',
            gender: '',
            relation: '',
            memberId: null,
            providerId: this.props.history.location.state.doctorId,
            consumerId: getCurrentUserId(),
            providerType: "ONLINE_DOC",
            serviceType: "VIDEO_CONS",
            scheduleList: [],
            currentDaySchedule: [],
            bookedSchedule: null,
            date: new Date(),
            memberList: null,
            reason: '',
            loading: true,
            selectedMember: '',
            typesOption: [{ label: "VIDEO", value: "VIDEO_CONS" }, { label: "PHYSICAL", value: "PHYSICAL_CONS" }],
            types: { label: "VIDEO", value: "VIDEO_CONS" },
            showCharge:''
           
        }
        this.handleDateChange = this.handleDateChange.bind(this)
        this.handleDropdownInput = this.handleDropdownInput.bind(this)
    }

    loadDoctorSchedule = (providerId, providerType, serviceType) => {
        getAvailableAppointment(providerId, providerType, serviceType)
            .then(response => {
                this.setState({
                    scheduleList: response,
                    loading: false
                })
                if (response.length !== 0) {
                    var firstAvailableScheduleIndex = -1
                    for (var i = 0; i < response.length; i++) {
                        if (response[i].status === 'AVAILABLE') {
                            firstAvailableScheduleIndex = i
                            break
                        }
                    }
                    if (firstAvailableScheduleIndex !== -1) {
                        const startDay = new Date(response[firstAvailableScheduleIndex].startTimeMs).getDay()
                        const startDate = new Date(response[firstAvailableScheduleIndex].startTimeMs).getDate()
                        this.setState({
                            date: new Date(response[firstAvailableScheduleIndex].startTimeMs)
                        })
                        response.map(value => {
                            const selectedDay = new Date(value.startTimeMs).getDay()
                            const selectedDate = new Date(value.startTimeMs).getDate()
                            if ((startDay - selectedDay) === 0 && (selectedDate - startDate) === 0) {
                                this.setState(prevState => ({
                                    currentDaySchedule: [...prevState.currentDaySchedule, value]
                                }))
                            }
                        })
                    }
                }


            }).catch(error => {
                //    Alert.error((error && error.message) || 'Something went wrong, please try again later!');
            })
    }

    loadMemberOfUser = () => {
        getMenberOfUser(this.state.consumerId)
            .then(response => {
                this.setState({
                    memberList: response.content.map(value => {
                        return { label: value.name, value: value }
                    })
                })
            })
    }

    componentDidMount() {
        this.loadDoctorSchedule(this.state.providerId, this.state.providerType, this.state.serviceType)
        window.scrollTo(0, 0)
    }

    handleDOBChange = (dob) => {
        this.setState({
            dob: dob
        })
    }

   
    handleInputChange = (event) => {
        const inputName = event.target.name
        const inputValue = event.target.value
        if (inputName.toLowerCase() === 'member' && inputValue.toLowerCase() === 'others' && this.state.memberList === null)
            this.loadMemberOfUser()
        if (inputName.toLowerCase() === 'member' && inputValue.toLowerCase() === 'myself') {
            this.setState({
                gender: '',
                name: '',
                dob: null,
                relation: '',
                member: inputValue,
                myselfChecked: true
            })
            let pEle = document.getElementById('validate')
            pEle.innerHTML = ''

        } else if (inputValue.toLowerCase() === 'others') {
            this.setState({
                [inputName]: inputValue,
                myselfChecked: false
            })
        }
        else {
            this.setState({
                [inputName]: inputValue,
            })
        }

    }

    inputClearHandler = () => {
        this.setState({
            gender: '',
            name: '',
            dob: '',
            relation: '',
            selectedMember: ''
        })
    }
    async handleDateChange(date) {
        await this.setState({
            date: date
        })
        this.populateSchedule()
    }

    populateSchedule = () => {
        this.setState({
            currentDaySchedule: []
        })
        this.state.scheduleList.map(value => {
            const startDay = new Date(value.startTimeMs).getDay()
            const startDate = new Date(value.startTimeMs).getDate()
            const selectedDay = new Date(this.state.date).getDay()
            const selectedDate = new Date(this.state.date).getDate()
            if ((startDay - selectedDay) === 0 && (selectedDate - startDate) === 0) {
                this.setState(prevState => ({
                    currentDaySchedule: [...prevState.currentDaySchedule, value]
                }))
            }
        })
    }

    handleBookSlot = (index, value) => {
     
        for (let i = 0; i < this.state.currentDaySchedule.length; i++) {
            let button = document.getElementById('slotButton' + i)
            if (i === index) {
                button.classList.toggle('btn-outline-primary')
                button.classList.toggle('btn-primary')
                if (button.classList.value.includes("btn-primary"))
                    this.setState({
                        bookedSchedule: value
                    })
                else
                    this.setState({
                        bookedSchedule: null
                    })
            }
            else {
                button.classList.remove('btn-primary')
                button.classList.add('btn-outline-primary')
            }

        }

    }

    handleMemberDropdown = (selectedOption) => {
        this.setState({
            name: selectedOption.value.name,
            relation: selectedOption.value.relation,
            gender: selectedOption.value.gender,
            dob: new Date(selectedOption.value.dob),
            selectedMember: selectedOption

        })
    }

    validateMember = (appointmentMemberRequest) => {
        let pEle = document.getElementById('validate')
        for (let key in appointmentMemberRequest) {
            if (appointmentMemberRequest[key] === '' || appointmentMemberRequest[key] === null) {
                pEle.innerHTML = "Please provide all details of your member, for whom you are booking the appointment!"
                return false
            }
        }
        pEle.innerHTML = ''
        return true
    }

    validateAppointment = () => {
        let pEle = document.getElementById('validate')
        if (this.state.reason === '') {
            pEle.innerHTML = "Please provide a reason to see the doctor!"
            return false
        }
        pEle.innerHTML = ''
        return true
    }

    handleSubmit = (event) => {
        event.preventDefault();
        delete this.state.bookedSchedule.order
        delete this.state.bookedSchedule.orderId
        if (this.state.member.toLowerCase() === 'others') {
            const appointmentMemberRequest = {
                name: this.state.name,
                dob: this.state.dob,
                gender: this.state.gender,
                relation: this.state.relation
            }
            if (this.validateMember(appointmentMemberRequest) && this.validateAppointment()) {
                addAppointmentMember(appointmentMemberRequest)
                    .then(response => {
                        this.state.bookedSchedule.memberId = response.id
                        this.state.bookedSchedule.consumerId = this.state.consumerId
                        this.state.bookedSchedule.reason = this.state.reason
                        this.state.bookedSchedule.concentAccepted= false
                        const appointmentFormRequest = Object.assign({}, this.state.bookedSchedule);
                        // createAppointment(appointmentFormRequest)
                        //     .then(response => {
                        //         Alert.success("Appointment booked succcessfully. Please confirm consultation charges!")
                        //         this.props.history.push({
                        //             pathname: '/appt-consent',
                        //             state: { appointmentId: response.id, consumerId: response.consumerId, patientName: response.member.name, doctorId: this.state.providerId }
                        //         })
                        //     }).catch(error => {
                        //         Alert.error((error && error.message) || 'Something went wrong, please try again later!')
                        //     })

                        this.props.history.push({
                            pathname: '/appt-consent',
                            state: { appointmentRequest:appointmentFormRequest,doctorId: this.state.providerId,patientName:this.state.name,}
                        })
                    })
                    .catch(error => {
                        Alert.error((error && error.message) || 'Something went wrong, please try again later!');
                    })
            }
            else {
                Alert.error('Please fill in all fields!');
                window.scrollTo(0, 0)
            }

        }

        else {
            this.state.bookedSchedule.consumerId = this.state.consumerId
            this.state.bookedSchedule.reason = this.state.reason
            this.state.bookedSchedule.concentAccepted= false
            const appointmentFormRequest = Object.assign({}, this.state.bookedSchedule);
            if (this.validateAppointment()) {
                // createAppointment(appointmentFormRequest)
                //     .then(response => {
                //         Alert.success("Appointment created succcessfully")
                //         this.props.history.push({
                //             pathname: '/appt-consent',
                //             state: { appointmentId: response.id, consumerId: response.consumerId, patientName: response.consumer.name, doctorId: this.state.providerId }
                //         })
                //     }).catch(error => {
                //         Alert.error((error && error.message) || 'Something went wrong, please try again later!')
                //     })

                this.props.history.push({
                                pathname: '/appt-consent',
                                state: { appointmentRequest:appointmentFormRequest,doctorId: this.state.providerId,patientName:getCurrentUser().name }
                            })
            }
            else {
                Alert.error('Please provide all the required details!');
                window.scrollTo(0, 0)
            }

        }
         
    }

    async handleDropdownInput(selectedOption) {
        await this.setState({
            serviceType: selectedOption.value,
        })
        if (selectedOption.value == "VIDEO_CONS") {
            await this.setState({
                providerType: "ONLINE_DOC",
                types: { label: "VIDEO", value: "VIDEO_CONS" },
                currentDaySchedule: ''
            })
        } else {
            await this.setState({
                providerType: "PHYSICAL_DOC",
                types: { label: "PHYSICAL", value: "PHYSICAL_CONS" },
                currentDaySchedule: ''
            })
        }
        this.loadDoctorSchedule(this.state.providerId, this.state.providerType, this.state.serviceType)
    }

    render() {
        if (this.state.loading) {
            return <LoadingIndicator />
        }
        return (
            <Container>
                <div className="appointment-form-container">
                    <Row className="welcome text-center mb-5">
                        <Col sm={12}>
                            <h3>Book Your Appointment</h3>
                        </Col>
                        <hr />
                    </Row>
                    <Row className="mb-5">
                        <Col md={12}>
                            <DoctorProfileHeader dd={this.props.history.location.state.dd} />
                        </Col>
                    </Row>
                    <Row>
                        <Link to={{ pathname: `/dp/${this.state.providerId}` }}><Button className="btn btn-primary"><ArrowBackIcon /></Button></Link>

                    </Row>
                    <div><p style={{ color: 'red' }} id="validate"></p></div>
                    <Form className="mb-5">
                        <hr />
                        <Row>
                            <Col md={4}>
                                <Row>
                                    <Col md={4}>
                                        Patient is:
                                    </Col>
                                    <Col md={4}>
                                        <Row>
                                            <Form.Check type="radio" label="Myself" value="myself" name="member" onChange={this.handleInputChange} required checked={this.state.myselfChecked} />
                                        </Row>
                                    </Col>
                                    <Col md={4}>
                                        <Row>
                                            <Form.Check type="radio" label="Others" value="Others" name="member" onChange={this.handleInputChange} required />
                                        </Row>
                                    </Col>
                                    <Col className="shift-text">AppointmentType :</Col>
                                    <Col md={11} className=" mb-2">
                                        <Select
                                            options={this.state.typesOption}
                                            value={this.state.types}
                                            onChange={(selectedOption) => this.handleDropdownInput(selectedOption)}
                                            placeholder="AppointmentType"
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            {this.state.member === 'Others' ?
                                <Col md={8}>
                                    <Row>
                                        <Col md={4} className="mb-2">
                                            <Select
                                                options={this.state.memberList}
                                                value={this.state.selectedMember}
                                                placeholder="Members"
                                                onChange={this.handleMemberDropdown}
                                            />
                                        </Col>
                                        <Col md={4} className="mb-2">
                                            <Form.Control type="text" value={this.state.name} name="name" placeholder="His/her Name" onChange={this.handleInputChange} required />
                                        </Col>
                                        <Col md={4} className="mb-2">
                                            <Form.Control type="text" value={this.state.relation} name="relation" placeholder="His/her Relation" onChange={this.handleInputChange} required />
                                        </Col>
                                        <Col md={6} className="mb-2">
                                            Date of Birth : <DatePicker name="dob" value={this.state.dob} selected={this.state.dob} placeholderText="Date of Birth" onChange={this.handleDOBChange} showMonthDropdown showYearDropdown required />
                                        </Col>

                                        <Col md={6}>
                                            <Form.Group as={Row}>
                                                <Col md={12} className="mt-2">
                                                    Gender :{' '}
                                                    <Form.Check inline label="Female" type="radio" name="gender" checked={this.state.gender.toLowerCase() === 'female' ? 'checked' : ''} value="Female" onChange={this.handleInputChange} required />
                                                    <Form.Check inline label="Male" type="radio" name="gender" checked={this.state.gender.toLowerCase() === 'male' ? 'checked' : ''} value="Male" onChange={this.handleInputChange} required />
                                                    <Form.Check inline label="Others" type="radio" name="gender" checked={this.state.gender.toLowerCase() === 'others' ? 'checked' : ''} value="Others" onChange={this.handleInputChange} required />
                                                    <Button onClick={this.inputClearHandler}><RefreshTwoToneIcon /></Button>
                                                </Col>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Col>
                                : null
                            }
                        </Row>
                        <hr />
                        <br />
                        <Row>
                            <Col md={12}>
                                <Form.Control as="textarea" rows="3" name="reason" value={this.state.reason} placeholder="Reason to meet" onChange={this.handleInputChange} required />
                            </Col>
                        </Row>
                        <br />
                        <hr />
                        <p>Select Best Time To See The Doctor</p>
                        <Row>
                            <Col md={2} className="mr-3 mb-2">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        margin="normal"
                                        id="date-picker-dialog"
                                        format="dd/MM/yyyy"
                                        value={this.state.date}
                                        onChange={this.handleDateChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        minDate={new Date()}
                                        maxDate={new Date(new Date().getTime() + (13 * (1000 * 60 * 60 * 24)))}
                                    />
                                </MuiPickersUtilsProvider>
                            </Col>
                            <Col md={9} className="ml-2 mb-2">
                                <Row>
                                    {this.state.currentDaySchedule.length !== 0 ?
                                        this.state.currentDaySchedule.map((value, index) => {
                                            if (value.status === 'AVAILABLE')
                                                return (
                                                    <Col xl={3} lg={4} md={6} sm={6} className="mb-2 text-center">
                                                        <Button style={{height:'60px'}} id={`slotButton${index}`} variant="outline-primary" onClick={() => this.handleBookSlot(index, value)}>{new Date(value.startTimeMs).toLocaleTimeString([], { hour: "numeric", minute: "numeric", hour12: "true" })} - {new Date(value.endTimeMs).toLocaleTimeString([], { hour: "numeric", minute: "numeric", hour12: "true" })} <p>{value.currencyPerScheduleConfig } { value.chargePerScheduleConfig}</p></Button>
                                                    </Col>

                                                )
                                            else
                                                return (
                                                    <Col xl={3} lg={4} md={6} sm={6} className="mb-2 text-center">
                                                        <Button style={{height:'60px'}} id={`slotButton${index}`} variant="outline-secondary" disabled>{changeTime(value.startTimeMs)} - {changeTime(value.endTimeMs)}<p>{value.currencyPerScheduleConfig } { value.chargePerScheduleConfig}</p></Button>
                                                    </Col>
                                                )
                                        })
                                        : <Col className="text-center"><p><strong>No slots available on {getDateStrFromObj(this.state.date)}</strong></p></Col>}
                                </Row>
                            </Col>
                        </Row>
                        
                        <hr />
                        {this.state.bookedSchedule ?
                            <>
                                <Button type="button" className="btn btn-primary float-right" data-toggle="modal" data-target="#staticBackdrop">Next</Button>
                                <div className="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                    <div className="modal-dialog">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title textColor" id="staticBackdropLabel">Please make sure all details and schedule are correct as you will not able to edit it later </h5>
                                                <Button type="Button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </Button>
                                            </div>
                                            <div className="modal-footer">
                                                <Button type="Button" className="btn btn-secondary" data-dismiss="modal">Cancel</Button>
                                                <Button type="Button" className="btn btn-primary" data-dismiss="modal" onClick={this.handleSubmit}>Submit</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                            : <Button type="button" className="btn btn-primary float-right" disabled>Next</Button>
                        }
                        <Link to={{ pathname: `/dp/${this.state.providerId}` }}><Button className="btn btn-primary">Back</Button></Link>
                    </Form>
                </div>
            </Container>
        )
    }
}
