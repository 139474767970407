import React, { Component } from 'react';
import Col from 'react-bootstrap/Col';
import Select from 'react-select';
import { getCountryList, getStateList, getCityList } from '../util/ReferentialAPIUtils';



class Referential extends Component {
    constructor(props) {
        super(props)
        this.state = {
            countryList: [],
            states: [],
            cities: [],
            selectedCountry: this.props.selectedCountry,
            selectedState: this.props.selectedState,
            selectedCity: this.props.selectedCity,
            countryCode: null,
            stateCode: null,
            cityCode: null
        }
    }

    loadCountry() {
        getCountryList()
            .then(response => {
                this.setState({
                    countryList: response.map((data) => {
                        return { label: data.dislayStr, value: data }
                    })
                })
            })

    }
    componentDidMount() {
        this.loadCountry();

    }

    static getDerivedStateFromProps(props, state) {
        if (props.selectedCountry !== state.selectedCountry) {
            return ({
                selectedCountry: props.selectedCountry,
                selectedCity: props.selectedCity,
                selectedState: props.selectedState,
                states: [],
                cities: [],
            })
        }
        return null;
    }

    handleCountryChange = (selectedOption) => {
        const countryCode = selectedOption.value.code
        this.setState({
            countryCode: countryCode,
            selectedCountry: selectedOption,
            selectedState: null,
            selectedCity: null,
            cities: []
        })

        getStateList(countryCode)
            .then(response => {
                this.setState({
                    states: response.map(data => {
                        return { label: data.dislayStr, value: data }
                    })
                })
            })

        this.props.doctorFilterBy(countryCode, 'countryCode', selectedOption, "selectedCountry")
        this.props.doctorFilterBy('', 'stateCode')
        this.props.doctorFilterBy('', 'cityCode')

    }


    handleStateChange = (selectedOption) => {
        const stateCode = selectedOption.value.code
        this.setState({
            selectedState: selectedOption,
            selectedCity: null
        })
        this.props.doctorFilterBy(stateCode, "stateCode")
        this.props.doctorFilterBy('', 'cityCode')

        getCityList(this.state.countryCode, stateCode)
            .then(response => {
                this.setState({
                    cities: response.map(data => {
                        return { label: data.dislayStr, value: data }

                    })
                })
            })
    }

    handleCityChange = (selectedOption) => {
        const cityCode = selectedOption.value.code
        this.setState({
            selectedCity: selectedOption
        })
        this.props.doctorFilterBy(cityCode, "cityCode")

    }

    render() {
        return (
            <>
                <Col md={2} className="mb-2">
                    <Select
                        options={this.state.countryList}
                        value={this.state.selectedCountry}
                        onChange={this.handleCountryChange}
                        placeholder="Country"
                    />
                </Col>
                <Col md={2} className="mb-2">
                    <Select
                        options={this.state.states}
                        value={this.state.selectedState}
                        onChange={this.handleStateChange}
                        placeholder="State"
                    />
                </Col>
                <Col md={2} className="mb-2">
                    <Select
                        options={this.state.cities}
                        value={this.state.selectedCity}
                        onChange={this.handleCityChange}
                        placeholder="City"
                    />
                </Col>
            </>
        );
    }
}

export default Referential;