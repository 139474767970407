import React, { Component } from 'react';
import {Container} from 'react-bootstrap';

import Carousel from '../common/CustomCarousel';
import Jumbotron from '../common/CustomJumbotron';
import CustomFooter from '../common/CustomFooter';

export default class RunTrack extends Component{
	render(){
		return(
			<Container>
				<Carousel/>
				<Jumbotron/>
				<CustomFooter/>
			</Container>
		)
	}
}