import React, { Component } from 'react'
import Select from 'react-select';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import RemoveSharpIcon from '@material-ui/icons/RemoveSharp';
import DateFnsUtils from '@date-io/date-fns';
import './PharmacyConfigSlot.css'

export default class PharmacyConfigSlot extends Component {

    constructor(props) {
        super(props)
        this.state = {
            types: [{ label: "ONLINE", value: "ONLINE" },{ label: "PHYSICAL", value: "PHYSICAL" }],
            slots: [{ label: "5", value: "5" }, { label: "10", value: "10" }, { label: "15", value: "15" }, { label: "30", value: "30" }, { label: "45", value: "45" }, { label: "60", value: "60" }],
            type: this.props.slot.type,
            durationInMins: this.props.slot.durationInMins,
            startTimeStr: this.props.slot.startTimeStr ? this.convertStringToTime(this.props.slot.startTimeStr) : null,
            endTimeStr: this.props.slot.endTimeStr ? this.convertStringToTime(this.props.slot.endTimeStr) : null
        }
    }

    convertStringToTime = (str) => {
        let hour = parseInt(str.slice(0, 2))
        let min = parseInt(str.slice(3, 5))
        let format = str.slice(6, 8)
        if (format.toUpperCase() === "PM")
            hour += 12
        else if (format.toUpperCase() === "AM" && hour === 12)
            hour = 0
        let date = new Date(((hour * 3600 + min * 60) - (5 * 3600 + 30 * 60)) * 1000)
        return date
    }

    select = (options, inputName, placeholder) => {
        if (this.state[inputName])
            return (
                <Select
                    options={this.state[options]}
                    value={{ label: this.state[inputName], value: this.state[inputName] }}
                    onChange={(selectedOptions) => this.handleDropdownInput(selectedOptions, inputName)}
                    placeholder={placeholder}
                />
            )
        else
            return (
                <Select
                    options={this.state[options]}
                    onChange={(selectedOptions) => this.handleDropdownInput(selectedOptions, inputName)}
                    placeholder={placeholder}
                />
            )
    }

    handleDropdownInput = (selectedOptions, inputName) => {
        const inputValue = selectedOptions.value
        this.setState({
            [inputName]: inputValue
        })
        this.props.handleInputChange(inputName, inputValue)

    }

    handleTimeChange = (time, inputName) => {
        console.log(time, inputName);
        this.setState({
            [inputName]: time
        })

        let date = new Date(time)
        let hour = date.getHours()
        let min = date.getMinutes()
        let format = 'AM'
        if (hour > 12) {
            hour -= 12
            format = 'PM'
        }
        else if (hour === 0)
            hour = 12
        else if (hour === 12)
            format = 'PM'

        if (hour < 10)
            hour = "0" + hour
        if (min < 10)
            min = "0" + min

        const inputValue = hour + ":" + min + " " + format
        this.props.handleInputChange(inputName, inputValue)
    }

    handleInputChange = (event) => {
        const setInput = event.target.name
        const setValue = event.target.value
        this.setState({
            [setInput]: setValue
        })
        this.props.handleInputChange(setInput, setValue)

    }




    render() {
        return (
            <>
                <Row>
                    <Col md={3}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <TimePicker
                                label="Start time"
                                 value={this.state.startTimeStr}
                                onChange={(time) => this.handleTimeChange(time, "startTimeStr")}
                            />
                        </MuiPickersUtilsProvider>

                    </Col>
                    <Col md={3}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <TimePicker
                                label="End time"
                                 value={this.state.endTimeStr}
                                onChange={(time) => this.handleTimeChange(time, "endTimeStr")}
                            />
                        </MuiPickersUtilsProvider>

                    </Col>
                    <Col md={2} className="mt-3">
                        {this.select("types", "type", "Type")}
                    </Col>
                    <Col md={2} className="mt-3">
                        {this.select("slots", "durationInMins", "Duration")}
                    </Col>

                    <Col md={2} className="mt-3">
                     <Button className="btn btn-primary schedule-plus-btn" onClick={this.props.removeSlot} className="btn-danger"><RemoveSharpIcon /></Button>
                    </Col>
                </Row>
            </>
        )
    }
}

