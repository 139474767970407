import React, { Component } from 'react';
import LoadingIndicator from '../common/LoadingIndicator';
import { Container, Table, Button, Row, Col } from 'react-bootstrap';
import EmptyJumbotron from '../common/EmptyJumbotron';
import './EntityList.css';
import { getQualificationList, deleteQualification } from '../util/APIUtils';
import Alert from 'react-s-alert';
import { Link } from 'react-router-dom';
import Edit from '@material-ui/icons/Edit';
import DeleteOutlineSharpIcon from '@material-ui/icons/DeleteOutlineSharp';
import AddSharpIcon from '@material-ui/icons/AddSharp';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { getEntityList, deleteEntity } from '../util/EntityUtils';
import { getRole } from '../util/functionUtils';



class EntityList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            entityList: [],
            loading: true,
            pageNumber: 0,
            totalElements: 0,
            pageSize: 20,
            role: getRole()
        }
        this.handleDelete = this.handleDelete.bind(this);
        this.loadMore = this.loadMore.bind(this);

    }

    loadEntityList() {
        getEntityList(this.state.pageNumber, this.state.pageSize)
            .then(response => {
                this.setState({
                    entityList: response.content,
                    totalElements: response.totalElements,
                    loading: false,
                });
            }).catch(error => {
                this.setState({
                    loading: false
                })
                Alert.error((error && error.message) || 'Something went wrong, please try again later!');
            })
    }
    componentDidMount() {
        this.loadEntityList();
        window.scrollTo(0, 0)
    }

    async loadMore() {
        await this.setState({
            pageNumber: this.state.pageNumber + 1
        })
        getEntityList(this.state.pageNumber, this.state.pageSize)
            .then(response => {
                this.setState({
                    entityList: [...this.state.entityList, ...response.content],
                    totalElements: response.totalElements,
                    loading: false,
                });
            }).catch(error => {
                Alert.error((error && error.message) || 'Something went wrong, please try again later!');
            });
    }

    handleDelete(event, value) {
        this.setState({
            loading: true
        })
        const deleteEntityRequest = value.id
        console.log("value" ,value)
        deleteEntity(deleteEntityRequest)
            .then(response => {
                Alert.success("Entity deleted successfully.");
                this.loadEntityList();
            }).catch(error => {
                Alert.error((error && error.message) || 'Something went wrong, please try again later!');
            })
    }
    render() {
        if (this.state.loading) {
            return <LoadingIndicator> loading ...</LoadingIndicator>
        }
        return (
            <Container id="top">
                <div className="entityList-container container-fluid">
                    <Row className="welcome text-center mb-5">
                        <Col sm={12}>
                            <h3>Entities</h3>
                        </Col>
                        <hr />
                    </Row>
                    <div className="heading">
                        <Link to="/ap" className="float-right btn btn-primary"><ArrowBackIcon /></Link>
                        <h2></h2>
                        {this.state.role.roleAdmin ? <Link className="btn btn-primary" to="/en"><AddSharpIcon /></Link> : null}
                    </div>
                    <Table id="entityListTable">
                        {this.state.entityList.length === 0 ? null :
                            <thead  className="thead-color">
                                <tr id ="entityListTableHead">
                                    <td className="text-center">Code</td>
                                    <td className="text-center">Description</td>
                                    <td className="text-center"> Edit</td>
                                    {this.state.role.roleAdmin ? <td className="text-center">Delete</td> : null}
                                </tr>
                            </thead>
                        }
                        <tbody>
                            {this.state.entityList.map((entity) => {
                                if (entity.isActive)
                                    return (
                                        <tr>
                                            <td className="text-center" data-label="Code"> <Link to={{ pathname: this.state.role.roleAdmin?"/en-dl":"/ent", state: { entityId: entity.id, entityName: entity.name } }}>{entity.name}</Link></td>
                                            <td className="text-center" data-label="Description"> {entity.description}</td>
                                            <td className="text-center" data-label="Edit"><Button variant="outlined"><Link to={{ pathname: "/en", editEntity: entity }} style={{ textDecoration: 'none' }}><Edit color="action" /></Link></Button></td>
                                            {this.state.role.roleAdmin ? <td className="text-center" data-label="Delete"> <Button data-toggle="modal" data-target="#staticBackdrop" variant="outlined"><DeleteOutlineSharpIcon color="secondary" /></Button>
                                                <div className="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                    <div className="modal-dialog">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title textColor" id="staticBackdropLabel">Are you sure you want to delete ?</h5>
                                                                <Button type="Button" className="close" data-dismiss="modal" aria-label="Close">
                                                                    <span aria-hidden="true">&times;</span>
                                                                </Button>
                                                            </div>
                                                            <div className="modal-body textColor">
                                                                There may be doctors associated with this Entity !
                                                            </div>
                                                            <div className="modal-footer">
                                                                <Button type="Button" className="btn btn-secondary" data-dismiss="modal">Cancel</Button>
                                                                <Button type="Button" className="btn btn-primary" data-dismiss="modal" onClick={(event) => this.handleDelete(event, entity)}>Delete</Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div></td> : null}
                                        </tr>
                                    )
                            })}
                        </tbody>
                    </Table>
                    {this.state.entityList.length >= 20 ? this.state.entityList.length < this.state.totalElements ?
                        <Button color="primary" className="loadMore" onClick={this.loadMore}>Load more...</Button> :
                        <Button color="primary" className="loadMore" href="#top">Back to top</Button> : null
                    }
                    {this.state.entityList.length === 0 ? <h4 className="text-center mb -5"> No Entity is added</h4> : null}
                    <EmptyJumbotron />
                </div>
            </Container>
        );
    }
}

export default EntityList;