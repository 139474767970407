import React, { Component } from 'react'
import { Container, Row, Col, Button, Table, Form } from "react-bootstrap";
import LoadingIndicator  from '../common/LoadingIndicator';
import Alert from 'react-s-alert';
import { getPrescriptionById } from '../util/healthAPIUtils';
import {Link } from 'react-router-dom';


export default class PatientMedicineSelect extends Component {

    constructor(props){
        super(props)
        this.state = {
            loading:false,
            appointmentId: this.props.history.location.state ? this.props.history.location.state.appointmentId ? this.props.history.location.state.appointmentId : '' : '',
            prescriptionId: this.props.history.location.state ? this.props.history.location.state.prescriptionId ? this.props.history.location.state.prescriptionId : '' : '',
            prescription: '',
            medicineList: [],
            medicineSelected:true,
        }
    }

    loadPrescription() {
        getPrescriptionById(this.state.prescriptionId, this.state.appointmentId)
            .then(response => {
                this.setState({
                    prescription: response,
                    loading: false
                })

                response.prescribedItems.map(value => {
                    if (value.type === 'MEDICINE') {
                        value.checked = true
                        this.setState(prevState => ({
                            medicineList: [...prevState.medicineList, value]
                        }))
                    }
                })
            })
            .catch(error => {
                Alert.error('Something went wrong, please try again later!')
            })
    }

    componentDidMount() {
        if (this.state.prescriptionId || this.state.appointmentId)
            this.loadPrescription()
        window.scrollTo(0, 0)
    }

    handleCheck = (event,medicine) =>{
        this.setState({
            medicineList: this.state.medicineList.map(value => (medicine.id === value.id ? medicine.checked ? Object.assign(value, { checked:false }) : Object.assign(value, { checked:true }): value)),
          });
    }



    render(){
        if (this.state.loading)
            return <LoadingIndicator />
        return (
            <Container>
                <div className='appointment-details-container'>

                    <Row className="welcome text-center mb-5">
                        <Col sm={12}>
                            <h4>Select Medicine</h4>
                        </Col>
                        <hr/>
                    </Row>

                    <Row>
                            <Col md={12}>
                                <Table responsive bordered className="shadow" id="prescriptionDetails">
                                    <thead className="thead-color" >
                                        <tr id="prescriptionDetailsHead">
                                            <th className="text-center">Medicine Name</th>
                                            <th className="text-center">Dosage</th>
                                            <th className="text-center">Frequency</th>
                                            <th className="text-center">Duration</th>
                                            <th className="text-center">Instruction</th>
                                            <th className="text-center">Select</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.medicineList.map(value => {
                                                return (
                                                    <tr>
                                                        <td className="text-center" data-label="Medicine Name">{value.name}</td>
                                                        <td className="text-center" data-label="Dosage">{value.dosage}</td>
                                                        <td className="text-center" data-label="Frequency">{value.frequency}</td>
                                                        <td className="text-center" data-label="Duration">{value.duration}</td>
                                                        <td className="text-center" data-label="Instruction">{value.instruction}</td>
                                                        <td className="text-center" data-label="Select"><Form.Check  checked={value.checked} onChange={(event) => this.handleCheck(event,value)}/></td>
                                                    </tr>
                                                )
                                        })}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col>
                                <Link to={{ pathname: "/pmacy", state:{selectPharmacy:true}}}><Button varient="primary" className="float-right">Next</Button></Link>
                            </Col>
                        </Row>

                </div>
            </Container>
        );
    }
}