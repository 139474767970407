import React, { Component } from 'react'
import { Container, Row, Col, Button, Table } from "react-bootstrap";


export default class MyOrder extends Component {

    render() {
        return (
            <Container>
                <div>
                    <br/><br/>
               <Row className="welcome text-center mb-5">
						<Col sm={12}>
							<h3>My Order</h3>
						</Col>
						<hr />
					</Row>
                <Table responsive hover className="shadow mb-5">
                    <thead className="thead-color">
                        <tr>
                            <th>Id</th>
                            <th>User</th>
                            <th>Time</th>
                            <th>Status</th>
                            <th>Amout</th>
                            <th>View</th>
                        </tr>
                    </thead>
                    <tbody>
                    </tbody>
                </Table>
                </div>
            </Container>
        )
    }
}