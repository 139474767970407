import React, { Component } from 'react'
import './HabitTracker.css'
import AddSharpIcon from '@material-ui/icons/AddSharp';
import { Button, Container, Table } from 'react-bootstrap';
import { deleteHabitById, getHabitById, loadTrackedHabits } from '../util/healthAPIUtils'
import CancelIcon from '@material-ui/icons/Cancel';
import { Link } from 'react-router-dom';
import Alert from 'react-s-alert';
import { getCurrentUserId, getDateSafariHack } from '../util/functionUtils'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import LoadingIndicator from '../common/LoadingIndicator';
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';


export default class HabitTracker extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ownerId: getCurrentUserId(),
            // categoryLists: ['Food','Exercise','Medicines','Meditation','Yoga','Walk','Run'],
            habits: [],
            habitDate: new Date(),
            tz: new Date().toString().match(/\(([^\)]+)\)$/)[1].match(/\b(\w)/g).join(''),       
            // const dateAsString = new Date().toString();
            // const timezone = dateAsString.match(/\(([^\)]+)\)$/)[1];
            // console.log("timezone", timezone);
            // var matches = timezone.match(/\b(\w)/g);
            // var abbreviations = matches.join('');
            // console.log("abbreviations", abbreviations);
            loading: true
        }
        this.handleDateChange = this.handleDateChange.bind(this)
    }


    loadHabits = () => {
        loadTrackedHabits(this.state.ownerId, this.state.habitDate, this.state.tz)
            .then(response => {
                this.setState({
                    habits: response,
                    loading: false
                })
            })
            .catch(error => {
                Alert.error((error && error.message) || 'Oops! Something went wrong. Please try again!');
            })
    }

    handleDeleteHabit = (habitId) => {
        // Alert.success("Tracked Habit deleted " + habitId " + habitId");
        deleteHabitById(habitId)
            .then(response => {
                Alert.success("Tracked Habit deleted successfully")
               this.loadHabits()
            })
            .catch(error => {
                Alert.error((error && error.message) || 'Oops! Something went wrong. Please try again!');
            })
    }

    async handleDateChange(date) {
        await this.setState({
            habitDate: date
        })
        this.loadHabits()
    }

    componentDidMount() {
        this.loadHabits()
        window.scrollTo(0, 0)
    }
    render() {
        
        if (this.state.loading) {
            return <LoadingIndicator />
        }
        return (
            <Container>
                <div className="add-health-record-container">
                    <div className="feature-title container-fluid">
                        <h3>Daily Habit Tracking</h3><hr /><br />
                        <Link to="/health-records" className="custom-button float-left btn btn-primary"><ArrowBackIcon /></Link>
                        <Link className="float-right btn btn-primary" to="/track-habit"><AddSharpIcon /></Link>
                        <br />
                        
                        
                        <Table  id="habitTrackerTable">
                            <tbody>
                                <tr>
                                    <td md={2} className="mr-3 mb-2">
                                    <b>Change Date to see previous day's habits tracking</b>
                                    </td>
                                    <td md={2} className="mr-3 mb-2">
                                    
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                margin="normal"
                                                id="date-picker-dialog"
                                                format="dd/MM/yyyy"
                                                value={this.state.habitDate}
                                                onChange={this.handleDateChange}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                minDate={new Date(new Date().getTime() - (60 * (1000 * 60 * 60 * 24)))}
                                                maxDate={new Date().getTime() + (1 * (1000 * 60 * 60 * 24))}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </td>  
                                </tr>
                            </tbody> 
                        </Table>
                       
                        <Table className="shadow" id="habitTrackerTable">
                            <thead className="thead-color">
                                {this.state.habits.length === 0 ? null :
                                    <tr id="habitTrackerTableHead">
                                        <th>Type</th>
                                        <th>Name</th>
                                        <th>Detail</th>
                                        <th>Tracked At</th>
                                        <th>Delete</th>
                                    </tr>
                                }
                            </thead>
                            <tbody>
                                                         
                                {this.state.habits.map((value, index) => {
                                    return (
                                        <tr key={index}>
                                            <td data-label="Type">{value.type}</td>
                                            <td data-label="Name">{value.name}</td>
                                            <td data-label="Detail">{value.detail}</td>
                                            <td data-label="Tracked At">{value.startDateStr}</td>
                                            <td data-label="Delete"><CancelIcon data-toggle="modal" data-target="#staticBackdrop" color="secondary" />
                                                <div className="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                    <div className="modal-dialog">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title textColor" id="staticBackdropLabel">Are you sure you want to delete ?{value.id}</h5>
                                                                <Button type="Button" className="close" data-dismiss="modal" aria-label="Close">
                                                                    <span aria-hidden="true">&times;</span>
                                                                </Button>
                                                            </div>
                                                            <div className="modal-footer">
                                                                <Button type="Button" className="btn btn-secondary" data-dismiss="modal">Cancel</Button>
                                                                <Button type="Button" className="btn btn-primary"  data-dismiss="modal" onClick={() => this.handleDeleteHabit(value.id)}>Delete</Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}

                            </tbody>
                        </Table>
                        {this.state.habits.length === 0 ? <h5 className="text-center">No Habit Tracking done for {' '} 
                        {this.state.habitDate.toLocaleDateString([], { year: 'numeric', month: 'long', day: 'numeric' })}
                        . Please click add(+) to track your daily habit.</h5> : null}
                    </div>
                </div>
            </Container>
        )
    }
}
