import React, { Component } from "react";
import "./Consent.css";
import { Row, Col, Container, Form, Button } from "react-bootstrap";
import Alert from "react-s-alert";
import { addConsent } from "../util/OrdersAPIUtils";
import { fetchDoctorDetails } from "../util/APIUtils";
import { Link, Redirect } from "react-router-dom";
import { createAppointment } from "../util/OrdersAPIUtils";
import Appointment from "./Appointment";
import LoadingIndicator from '../common/LoadingIndicator';


class Consent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appointmentId: this.props.history.location.state ? this.props.history.location.state.appointmentId : null,
      consumerId: this.props.history.location.state ? this.props.history.location.state.consumerId : null,
      patientName: this.props.history.location.state ? this.props.history.location.state.patientName : null,
      doctorId: this.props.history.location.state ? this.props.history.location.state.doctorId : null,
      disabled: true,
      isHomeopath: false,
      doctorsDeatils: "",
      specilizations: [],
      AppointmentRequest: this.props.history.location.state? this.props.history.location.state.appointmentRequest: null,
      loading: false
    };
  }
  handleAction(event) {
    const appointmentRequest = this.state.AppointmentRequest
    if (event.target.checked) {
      this.setState({
        disabled: false,
        AppointmentRequest: { ...appointmentRequest, concentAccepted: true }
      });
    } else {
      this.setState({
        disabled: true,
        AppointmentRequest: { ...appointmentRequest, concentAccepted: false }
      });
    }
  }

  handleSubmit = (event) => {
    this.setState({
      loading: true
    })
    event.preventDefault();
    createAppointment(this.state.AppointmentRequest)
      .then((response) => {
        Alert.success("Appointment created succcessfully");
        this.setState({
          loading: false
        });
        this.props.history.push({
          pathname: "/appointment-details",
          state: {
            appointmentId: response.id, consumerId: response.consumerId,
          },
        });

        // addConsent(response.id, this.state.consent)
        //     .then(res=> {
        //         Alert.success("Apppintment concent updated successfully")
        //         this.props.history.push({
        //             pathname: '/appointment-details',
        //             state: { appointmentId: response.id, consumerId: response.consumerId }
        //         })
        //     }).catch(error => {
        //         Alert.error((error && error.message) || 'Something went wrong, please try again later!')
        //     })
      })
      .catch((error) => {
        this.setState({
          loading: false
        })
        Alert.error(
          (error && error.message) ||
          "Something went wrong, please try again later!"
        );
      });
  };

  componentDidMount() {
    this.loadDc();
  }
  handleBack = () => {
    this.props.history.goBack();
  };

  loadDc = () => {
    fetchDoctorDetails(this.state.doctorId)
      .then((response) => {
        let ar = [];
        response.specialisations.map((s) => {
          ar.push(s.dislayStr);
        });
        this.setState({
          specilizations: ar,
        });
        if (this.state.specilizations.includes("Homeopath")) {
          this.setState({
            isHomeopath: true,
          });
        }
      })
      .catch((error) => {
        Alert.error(
          (error && error.message) ||
          "Something went wrong, please try again later!"
        );
      });
  };
  render() {
    // if (!this.state.consumerId && !this.state.appointmentId) {
    //     return <Redirect
    //         to={{
    //             pathname: "/",
    //             state: { from: this.props.location }
    //         }} />;
    // }

    if (this.state.loading) {
      return <LoadingIndicator />
    }
    return (
      <Container>
        <div className="page-size appointment-container">
          <Row className="welcome text-center mb-5">
            <Col sm={12}>
              <h3>Consent</h3>
            </Col>
            <hr />
          </Row>
          <p className="font-size">I <b>{this.state.patientName}</b> wish to undergo {this.state.isHomeopath ? " Homeopathy" : ""} treatment for my ailment in BI Health – A brand of BS Unprecedented Intuition Tech Services LLP, a Company registered under The Companies Act 1956 of India, hereinafter referred to as “BI Health”.</p>

          <p className="font-size">The {this.state.isHomeopath ? "homeopathic" : "doctors"} treatment, per se, is essentially harmless and safe. But it is advised to be aware of the following:-</p>
          <p className="font-size">
            At BI Health{this.state.isHomeopath ? " our homoeopaths" : " doctors"}  are trained and well experienced to select the one remedy from thousands available that most closely matches the total picture of your symptoms for treating the ailment. Therefore, you are expected to provide full and complete information pertaining to your ailment, symptoms, history etc and the clinic shall be entitled to presume that the said information provided by you is true and correct. Our {this.state.isHomeopath ? " Homoeopaths" : " Doctors"} shall give the treatment based on the information as aforesaid provided by you to the best of their judgement.
          </p>
          <p className="font-size">The doctors at BI Health have valid license from state council or from center council of India to practice in the state or in India.
            We do not guarantee or claim to cure each and every case and all adverse medical conditions.
          </p>
          <p className="font-size">
            Our doctors will make their best possible endeavour to heal you, however, the outcome and duration of {this.state.isHomeopath ? " homoeopathic" : ""}  treatment may vary by constitution on individual’s body, nature of ailment, past history of illness and other line of treatment followed in past and present.
          </p>
          <p className="font-size">
            In any unforeseen circumstances our consultants offering the treatment or the organization, will not be liable to any legal proceedings whatsoever or will not be responsible for the course of events during the period of treatment. The patient bears no right to ask for any damages with respect to the same.
          </p>
          <p className="font-size">
            The clinic will not share patient’s case records or video recordings of the consultation with the patient or their relatives.
          </p>
          <p className="font-size">
            The clinic or the staff thereof is not responsible / liable for any unforeseen event / adverse effect on the patient on account of the treatment given in-house or for medication.
            In case of terminal diseases it is recommended that you inform your medical doctor that you are receiving homoeopathic treatment. In case any symptoms persist or worsen, on treatment you should seek professional medical attention.
            {this.state.isHomeopath ? "Like all other branches of medicine, homoeopathy too has its limitations." : null}

          </p>
          <p className="font-size">
            It is advised to approach a nearby hospital in any acute flare up or life threatening situation.
          </p>
          <p className="font-size">
            The treatment at BI Health by its Doctors is based on all above terms and conditions.
          </p>

          <Form.Group controlId="formBasicCheckbox" >
                        <Form.Check type="checkbox" label="I agree to the Terms and Conditions and provide my consent for online consultation" onChange={(event) => this.handleAction(event)} />
                    </Form.Group>
                    <Button type="button" className="btn btn-primary float-right" data-toggle="modal" data-target="#staticBackdrop" disabled={this.state.disabled}>Submit</Button>
                    <div className="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title textColor" id="staticBackdropLabel">
                                        Please make sure you have read and understood Terms and Condition. By accepting Terms and Condition you provide your consent for consulatation.
                                    </h5>
                                    <Button type="Button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </Button>
                                </div>
                                <div className="modal-footer">
                                    <Button type="Button" className="btn btn-secondary" data-dismiss="modal">Cancel</Button>
                                    <Button type="Button" className="btn btn-primary" data-dismiss="modal" onClick={this.handleSubmit} >Confirm</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Link><Button className="btn btn-primary" onClick={this.handleBack}>Back</Button></Link>
                </div>
                <br></br>
            </Container>
        )


    }
}
export default Consent;
