import React, { Component } from 'react'
import Slot from './Slot'
import './Schedule.css'
import { Button, Row, Col, Table } from 'react-bootstrap';
import { doctorSchedule, doctorScheduleUpdate } from '../../util/APIUtils';
import Alert from 'react-s-alert';
import AddSharpIcon from '@material-ui/icons/AddSharp';
import { Link } from 'react-router-dom';
import DoctorProfileHeader from '../../doctors/DoctorProfileHeader';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import LoadingIndicator from '../../common/LoadingIndicator';




export default class Schedule extends Component {

    constructor(props) {
        super(props)
        
        this.state = {
            day: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
            Sunday: this.props.history.location.state.Sunday.length !== 0 ? this.props.history.location.state.Sunday : [this.returnSlotObject('sunday')],
            Monday: this.props.history.location.state.Monday.length !== 0 ? this.props.history.location.state.Monday : [this.returnSlotObject('monday')],
            Tuesday: this.props.history.location.state.Tuesday.length !== 0 ? this.props.history.location.state.Tuesday : [this.returnSlotObject('tuesday')],
            Wednesday: this.props.history.location.state.Wednesday.length !== 0 ? this.props.history.location.state.Wednesday : [this.returnSlotObject('wednesday')],
            Thursday: this.props.history.location.state.Thursday.length !== 0 ? this.props.history.location.state.Thursday : [this.returnSlotObject('thursday')],
            Friday: this.props.history.location.state.Friday.length !== 0 ? this.props.history.location.state.Friday : [this.returnSlotObject('friday')],
            Saturday: this.props.history.location.state.Saturday.length !== 0 ? this.props.history.location.state.Saturday : [this.returnSlotObject('saturday')],
            doctorId: this.props.history.location.state.doctorId,
            loading:false

        }

        this.handleInputChange = this.handleInputChange.bind(this)
        this.submitHandler = this.submitHandler.bind(this)
    }

    returnSlotObject = (day) => {
        let index = ''
        if (day.toLowerCase() === 'sunday')
            index = 1
        else if (day.toLowerCase() === 'monday')
            index = 2
        else if (day.toLowerCase() === 'tuesday')
            index = 3
        else if (day.toLowerCase() === 'wednesday')
            index = 4
        else if (day.toLowerCase() === 'thursday')
            index = 5
        else if (day.toLowerCase() === 'friday')
            index = 6
        else if (day.toLowerCase() === 'saturday')
            index = 7

        return (
            {
                index: Math.random(),
                dayOfTheweek: index,
                startTimeStr: '',
                endTimeStr: '',
                type: '',
                durationInMins: '',
                currency: '',
                charge: '',
                doctorId: this.props.history.location.state.doctorId,
                isActive: 1
            }
        )
    }


    addSlot = (dayName, index) => {

        this.setState((prevState) => ({
            [dayName]: [...prevState[dayName],
            {
                index: Math.random(),
                dayOfTheweek: index,
                startTimeStr: '',
                endTimeStr: '',
                type: '',
                durationInMins: '',
                currency: '',
                charge: '',
                doctorId: this.props.history.location.state.doctorId,
                isActive: 1
            }
            ]
        }));
    }

    removeSlot = (day, slotIndex) => {

        this.setState({
            [day]: this.state[day].filter((slot, index) => index !== slotIndex)
        })

    }

    handleInputChange(day, index, setInput, setValue) {
        let daySlot = this.state[day].slice()
        daySlot[index][setInput] = setValue
        this.setState({
            [day]: daySlot
        })
    }


    submitHandler(event) {
        event.preventDefault();
        this.setState({
            loading:true
        })

        let scheduleRequest = [...this.state.Sunday, ...this.state.Monday, ...this.state.Tuesday,
        ...this.state.Wednesday, ...this.state.Thursday, ...this.state.Friday, ...this.state.Saturday
        ]

        scheduleRequest = scheduleRequest.filter(value => (value.startTimeStr !== '' && value.endTimeStr !== '' && value.type !== '' && value.durationInMins !== '' && value.currency !== '' && value.charge !== ''))
        if (this.props.location.edit) {
            doctorScheduleUpdate(scheduleRequest, this.state.doctorId)
                .then(response => {
                    this.setState({
                        loading:false
                    })
                    Alert.success("schedule updated successully!");
                    this.props.history.push({
                        pathname: '/dconfigdetails',
                        state: { doctorId: this.state.doctorId, dd: this.props.history.location.state.dd }
                    })

                }).catch(error => {
                    this.setState({
                        loading:false
                    })
                    Alert.error((error && error.message) || 'Oops! Something went wrong. Please try again!');
                });
        }


        else {
            doctorSchedule(scheduleRequest, this.state.doctorId)
                .then(response => {
                    this.setState({
                        loading:false
                    })
                    Alert.success("Schedule added successully!");
                    this.props.history.push({
                        pathname: '/dconfigdetails',
                        state: { doctorId: this.state.doctorId, dd: this.props.history.location.state.dd }
                    })

                }).catch(error => {
                    this.setState({
                        loading:false
                    })
                    Alert.error((error && error.message) || 'Oops! Something went wrong. Please try again!');
                });
        }
    }

    componentDidMount(){
        window.scrollTo(0, 0)
    }

    render() {
        if(this.state.loading){
            return <LoadingIndicator/>
        }
        return (
            <div className="schedule-container container">
                <Row className="welcome text-center mb-5">
                    <Col sm={12}>
                        <h3>Consultation Schedule</h3>
                    </Col>
                    <hr />
                </Row>
                <Row className="mb-5">
                    <Col md={12}>
                        <DoctorProfileHeader dd={this.props.history.location.state.dd} />
                    </Col>
                </Row>
                <Row>
                   <Link to={{pathname:"/dconfigdetails" ,state: { doctorId: this.state.doctorId, dd: this.props.history.location.state.dd }}}><Button type="button" className="btn btn-primary" ><ArrowBackIcon /></Button></Link>
                </Row>
                {this.state.day.map((day, dayIndex) => {
                    return (
                        <Row>
                            <Table className="shadow">
                                <thead className="thead-color">
                                    <th>{day.toUpperCase()} </th>
                                </thead>
                                <tbody>
                                    <td>
                                        {this.state[day].map((slot, slotIndex) => {
                                            return (
                                                <>
                                                    <Slot
                                                        key={slot.index}
                                                        slot={{ ...slot }}
                                                        index={slotIndex}
                                                        day={day}
                                                        handleInputChange={(setInput, setValue) => { this.handleInputChange(day, slotIndex, setInput, setValue) }}
                                                        removeSlot={() => this.removeSlot(day, slotIndex)}
                                                    />
                                                    <hr />
                                                </>
                                            )
                                        })}
                                    </td>
                                    <tr> <td><Button className="btn btn-primary float-right schedule-plus-btn" onClick={() => this.addSlot(day, dayIndex + 1)}> <AddSharpIcon /></Button></td></tr>
                                </tbody>
                            </Table>
                            {/*sunday is starting from 1 but arrayIndex starts from 0 thats why we are adding 1 to dayIndex */}
                            {/* <p> <strong>{day}</strong> <Button className="btn btn-primary float-right schedule-plus-btn" onClick={() => this.addSlot(day, dayIndex + 1)}> <AddSharpIcon /></Button></p><br />
                            {this.state[day].map((slot, slotIndex) => {
                                return (
                                    <Slot
                                        key={slot.index}
                                        slot={{ ...slot }}
                                        index={slotIndex}
                                        day={day}
                                        handleInputChange={(setInput, setValue) => { this.handleInputChange(day, slotIndex, setInput, setValue) }}
                                        removeSlot={() => this.removeSlot(day, slotIndex)}
                                    />
                                )
                            })}
                            <hr /><br /> */}
                        </Row>
                    )
                })
                }

                <Button type="submit" className="btn btn-primary float-right" onClick={this.submitHandler} >Submit</Button>
                <Link to={{pathname:"/dconfigdetails" ,state: { doctorId: this.state.doctorId, dd: this.props.history.location.state.dd }}}><Button type="button" className="btn btn-primary" >Back</Button></Link><br /><br /><br />
            </div>
        )
    }
}
