import { COMMENTS_API_BASE_URL } from '../constants';
import { request } from './APIUtils';

export function loadFeedBackKeys() {
    return request({
        url: COMMENTS_API_BASE_URL + "/feedback/item/key",
        method: 'GET'
    });
}
export function createFeedBack(feedBackRequest) {
    return request({
        url: COMMENTS_API_BASE_URL + "/feedback/item",
        method: 'POST',
        body: JSON.stringify(feedBackRequest)
    });
}

export function getfeedback(entity, entityId, fpId, by) {
    return request({
        url: COMMENTS_API_BASE_URL + "/feedback/item?et=" + entity + "&eid=" + entityId + "&fpid=" + fpId + "&b=" + by,
        method: 'GET'
    });
}

export function createRating(ratingRequest) {
    return request({
        url: COMMENTS_API_BASE_URL + "/rating",
        method: 'POST',
        body: JSON.stringify(ratingRequest)
    });
}

export function getRatingByProviderId(ids) {
    return request({
        url: COMMENTS_API_BASE_URL + "/rating/info",
        method: 'POST',
        body: JSON.stringify(ids)
    });
}