import React, { Component } from 'react';
import { Container, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getUserPresignedUrl, getCurrentUser ,getCurrentUserDoctorId } from '../../util/APIUtils';
import { CURRENT_USER, PROVIDER } from '../../constants';
import './Profile.css';
import EmptyJumbotron from '../../common/EmptyJumbotron';
import Alert from 'react-s-alert';
import { uploadFile, validateImageSize, validateImageType } from '../../util/functionUtils'
import LoadingIndicator from '../../common/LoadingIndicator';
import { getDateSafariHack } from '../../util/functionUtils';
import {Row,Col,Button} from 'react-bootstrap';


class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: null,
      id:null,
      photoFile: null,
      role: null,
      loaded: false,
      notValid: false,
      valid: validateImageType(),
      notValidSize: false,
      doctorId:'',
      reload: false
    };

    this.handleUploadPhoto = this.handleUploadPhoto.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.loadCurrentlyLoggedInUser = this.loadCurrentlyLoggedInUser.bind(this);
    this.refreshPage = this.refreshPage.bind(this);
  }


  refreshPage() {
    window.location.reload(true);
  }

  componentDidMount() {
    this.loadCurrentlyLoggedInUser();
    window.scrollTo(0, 0)
  }

  loadCurrentlyLoggedInUser() {
    if (!localStorage.getItem(CURRENT_USER)) {
      getCurrentUser()
        .then(response => {
          localStorage.setItem(CURRENT_USER, JSON.stringify(response));
          this.setState({
            currentUser: response,
            id:response.id
          });
          getCurrentUserDoctorId(response.id)
          .then(res => {
            if(res){
              this.setState({
                doctorId : res.id,
                loaded: true
              })
            }else{
              this.setState({
                loaded: true
              })
            }
           
          })
        }).catch(error => {
          this.setState({
            loaded: true
          });
        });
    } else {
      this.setState({
        currentUser: JSON.parse(localStorage.getItem(CURRENT_USER))
      });
      getCurrentUserDoctorId(JSON.parse(localStorage.getItem(CURRENT_USER)).id)
          .then(res => {
            if(res)
            {
              this.setState({
                doctorId : res.id,
                loaded: true
              })
            }else{
              this.setState({
                loaded: true
              })
            }
          })
    }
  }

  handleInputChange(event) {
    event.preventDefault();
    const file = event.target.files[0]
    this.setState({
      photoFile: file,
      notValid: false,
      notValidSize: false
    })
  };

  handleUploadPhoto(event) {
    event.preventDefault();
    this.setState({
      loaded:false
    })
    const imageType = this.state.photoFile.name.split('.').pop().toLowerCase()
    const size = this.state.photoFile.size;
    if (validateImageType(imageType)) {
      if (validateImageSize(size)) {
        getUserPresignedUrl(this.state.photoFile.name, this.state.currentUser.id, this.state.photoFile.type)
          .then(response => {
            Alert.success('Profile photo upload is in progress');
            uploadFile(response, this.state.photoFile)
              .then(response => {
                Alert.success("Profile photo uploaded Successfully")
                this.setState({
                  loaded:true
                })
                window.location.reload()
              })
              .catch(error => {
                this.setState({
                  loaded:true
                })
                Alert.error((error && error.message) || 'Error uploading file');
              })
            getCurrentUser()
              .then(response => {
                localStorage.removeItem(CURRENT_USER);
                localStorage.setItem(CURRENT_USER, JSON.stringify(response));
              }).catch(error => {
                Alert.error((error && error.message) || 'Oops! Your session is not refreshed with updated profile. Please sign out and sign in once!');
              });
          }).catch(error => {
            this.setState({
              loaded:true
            })
            Alert.error(error && error.message);
          })
      } else {
        this.setState({
          loaded:true,
          notValidSize: true
        })
        Alert.error('File Size is bigger than 250 KB! Please upload smaller size photo.');
      }
    } else {
      this.setState({
        loaded:true,
        notValid: true
      })
      Alert.error('File Type is not supported. Please try JPG, JPEG, PNG.');
    }
  }

  render() {
    if (!this.state.loaded) {
      return <LoadingIndicator />
    }

    return (
      <Container>

        <div className="acct-profile-container">
          <div className="container">
            <div className="acct-profile-info">
              <h2>Your Profile</h2>
              <Table responsive striped bordered hover>
                <tbody>
                  <tr>
                    <td>PHOTO</td>
                    <td>
                         { this.state.currentUser.imageUrl ? (
                            <div className="acct-profile-avatar">
                              <img src={this.state.currentUser.imageUrl} alt={this.state.currentUser.name} />
                              <i className="fa fa-camera" aria-hidden="true" data-toggle="modal" data-target="#staticBackdrop"> </i>
                              <div className="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                <div className="modal-dialog">
                                  <div className="modal-content">

                                    <div className="modal-body">
                                      <h5 className="modal-title" id="staticBackdropLabel">Upload Photo</h5><br />
                                      <div className="upload-photo">
                                        <i className="fa fa-camera" aria-hidden="true"></i>
                                      </div>
                                      <input type="file" name="file" onChange={this.handleInputChange} />
                                      {this.state.notValid ? <p className="text-danger">Image type not supported</p> : null}
                                      {this.state.notValidSize ? <p className=" text-danger">Image should be smaller than 250 KB</p> : null}
                                    </div>
                                    <div className="modal-footer">
                                      <button type="button" className="btn btn-outline-danger" onClick={this.handleUploadPhotoBack} data-dismiss="modal">Back</button>
                                      {this.state.notValid ?
                                        <button type="button" className="btn btn-outline-success" onClick={this.handleUploadPhoto}>Upload</button> :
                                        <button type="button" className="btn btn-outline-success" onClick={this.handleUploadPhoto}>Upload</button>}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) :
                            (
                              <div className="acct-text-avatar">
                                <span >{this.state.currentUser.name && this.state.currentUser.name[0]}</span>
                                <i className="fa fa-camera" aria-hidden="true" data-toggle="modal" data-target="#staticBackdrop"> </i>
                                <div className="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                  <div className="modal-dialog">
                                    <div className="modal-content">
                                      <div className="modal-body">
                                        <h5 className="modal-title" id="staticBackdropLabel">Upload Photo</h5><br />
                                        <div className="upload-photo">
                                          <i className="fa fa-camera" aria-hidden="true"></i>
                                        </div>
                                        <input type="file" name="file" onChange={this.handleInputChange} />
                                      </div>
                                      <div className="modal-footer">
                                        <button type="button" className="btn btn-outline-danger" data-dismiss="modal">Back</button>
                                        <button type="button" className="btn btn-outline-success" data-dismiss="modal" onClick={this.handleUploadPhoto}>Upload</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                      }
                    </td>
                  </tr>
                  <tr>
                    <td>Name</td>
                    <td>{this.state.currentUser.name}</td>
                  </tr>
                  <tr>
                    <td>Date of Birth </td>
                    <td>
                      {this.state.currentUser.dob ? getDateSafariHack(this.state.currentUser.dob).toLocaleDateString([], { year: 'numeric', month: 'long', day: 'numeric' }):''}
                    </td>
                  </tr>
                  <tr>
                    <td>Gender </td>
                    <td>{this.state.currentUser.gender}</td>
                  </tr>
                  <tr>
                    <td>Email </td>
                    <td>{this.state.currentUser.email}</td>
                  </tr>
                  <tr>
                    <td>Phone</td>
                    <td>{this.state.currentUser.phone}</td>
                  </tr>
                  <tr>{this.state.currentUser.provider === PROVIDER ? null :
                    <>
                      <td>Password</td>
                      <td>********  <span className="change-password"><Link to={{ pathname: "/changepassword" }}><i className="fa fa-pencil" aria-hidden="true"></i></Link></span></td></>
                  }
                  </tr>
                </tbody>
              </Table>
              <Row>
              <Col sm={6} className="mb-2">
              <Button variant="outline-primary"><Link to={{ pathname: "/profileedit", props: this.props, handleRefresh: this.refreshPage }} style={{ textDecoration: 'none' }}>Edit profile <i className="fa fa-pencil" aria-hidden="true"></i></Link> </Button>
              </Col>
              <Col sm={6}>
              {this.state.doctorId ? <Button variant="outline-primary"><Link to={{ pathname: `/dp/${this.state.doctorId}` , state:{fromProfile:true} }} style={{ textDecoration: 'none' }}>View your doctor profile</Link></Button>:<Button variant="outline-primary"> <Link to={{ pathname: "/add-doctor" ,state:{name:this.state.currentUser.name ,email:this.state.currentUser.email ,phone:this.state.currentUser.phone,loading:false,back:"profile"}}} style={{ textDecoration: 'none' }}>If you are a health practitioner register to our platform</Link></Button>}
              </Col>
              </Row>
            </div>
          </div>
        </div>
        <EmptyJumbotron />
      </Container>
    );
  }
}
export default Profile