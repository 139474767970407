import  {REFERENTIAL_API_BASE_URL} from '../constants';
import {request} from './APIUtils';

export function getCountryList(){
    return request({
        url: REFERENTIAL_API_BASE_URL + "/country",
        method: 'GET'
    });
}

export function getStateList(countryCode){
    return request({
        url: REFERENTIAL_API_BASE_URL + "/country/state?cc=" + countryCode,
        method: 'GET'
    });
}

export function getCityList(countryCode, stateCode){
    return request({
        url: REFERENTIAL_API_BASE_URL + "/country/state/city?cc=" + countryCode + "&st=" + stateCode,
        method: 'GET'
    });
}

export function getPincodeList(countryCode, stateCode, cityCode){
    return request({
        url: REFERENTIAL_API_BASE_URL + "/country/state/city/pincode?cc=" + countryCode + "&st=" + stateCode + "&ct=" + cityCode,
        method: 'GET'
    });
}

export function getAddressInfo(countryCode, stateCode, cityCode){
    return request({
        url: REFERENTIAL_API_BASE_URL + "/country/state/city/info?cc=" + countryCode + "&st=" + stateCode + "&ct=" + cityCode,
        method: 'GET'
    });
}

export function getAddress(addressRequest){
    return request({
        url: REFERENTIAL_API_BASE_URL + "/country/state/city/info",
        method: 'POST',
        body:JSON.stringify(addressRequest)
    });
}
