import React, { Component } from 'react';
import './Signup.css';
import { Link, Redirect } from 'react-router-dom'
import { GOOGLE_AUTH_URL} from '../../constants';
import { signup } from '../../util/APIUtils';
import googleLogo from '../../img/google-logo.png';
import Alert from 'react-s-alert';
import LoadingIndicator from '../../common/LoadingIndicator';

import { Container} from 'react-bootstrap';
import EmptyJumbotron from '../../common/EmptyJumbotron';


class Signup extends Component {
    render() {
        if (this.props.authenticated) {
            return <Redirect
                to={{
                    pathname: "/",
                    state: { from: this.props.location }
                }} />;
        }

        return (
            <Container>
                <div className="signup-container">
                    <div className="signup-content">
                        <h1 className="signup-title">Signup with BI Health</h1>
                        {/* <SocialSignup />
                        <div className="or-separator">
                            <span className="or-text">OR</span>
                        </div> */}
                        <SignupForm {...this.props} />
                        <span className="login-link">Already have an account? <Link to="/login">Login!</Link></span>
                    </div>
                </div>
                <EmptyJumbotron />
            </Container>
        );
    }
}


class SocialSignup extends Component {
    render() {
        return (
            <div className="social-signup">
                <a className="btn btn-block social-btn google" href={GOOGLE_AUTH_URL}>
                    <img src={googleLogo} alt="Google" /> Sign up with Google</a>
            </div>
        );
    }
}

class SignupForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            password: '',
            confirmedPassword: '',
            loading:false
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;
        const inputValue = target.value;

        this.setState({
            [inputName]: inputValue
        });
    }

    validatePassword() {
        const password = this.state.password;
        const confirmedPassword = this.state.confirmedPassword;
        var reg = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");

        if (!reg.test(password)) {
            Alert.error("Password should contain at least 8 characters, one uppercase,one lowercase,one digit and one special character");
            this.setState({
                loading:false
            })
            return false;
        }
        if (password !== confirmedPassword) {
            Alert.error('Password did not match confirm password!');
            this.setState({
                loading:false
            })
            return false;
        }
        return true;
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({
            loading:true
        })

        const signUpRequest = Object.assign({}, this.state);
        console.log("signUpRequest==",signUpRequest);
        if (this.validatePassword()) {
            signup(signUpRequest)
                .then(response => {
                    this.setState({
                        loading:false
                    })
                    Alert.success("You're successfully registered. A verification email has been sent to you registered email id. Please activate your account by clicking the link!");
                    this.props.history.push("/login");
                }).catch(error => {
                    this.setState({
                        loading:false
                    })
                    Alert.error((error && error.message) || 'Oops! Something went wrong. Please try again!');
                });
        }
    }

    render() {
        if(this.state.loading){
           return <LoadingIndicator/>
        }
        return (
            <form onSubmit={this.handleSubmit}>
                <div className="form-item">
                    <input type="text" name="name"
                        className="form-control" placeholder="Name"
                        value={this.state.name} onChange={this.handleInputChange} required />
                </div>
                <br />
                <div className="form-item">
                    <input type="email" name="email"
                        className="form-control" placeholder="Email"
                        value={this.state.email} onChange={this.handleInputChange} required />
                </div>
                <br />
                <div className="form-item">
                    <input type="password" name="password"
                        className="form-control" placeholder="Password"
                        value={this.state.password} onChange={this.handleInputChange} required />
                    <p className="login-link">Password should contain at least 8 characters, 1 Uppercase,1 lowercase,1 digit and 1 special character</p>
                </div>
                <div className="form-item">
                    <input type="password" name="confirmedPassword"
                        className="form-control" placeholder="Confirm Password"
                        value={this.state.confirmedPassword} onChange={this.handleInputChange} required />
                </div>
                <br />
                <div className="form-item">
                    <button type="submit" className="btn btn-block btn-primary" >Sign Up</button>
                </div>
            </form>

        );
    }
}

export default Signup