import React, { Component } from 'react';
import { Container, Row, Col, Form, Table, Button } from 'react-bootstrap';
import './EntityForm';
import './EntityAssociation.css'
import Alert from 'react-s-alert';
import { getDoctorList, loadUsers } from '../util/APIUtils';
import { createAssociation, getEntityDoctorList, getUserForentity } from '../util/EntityUtils'
import { Link } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import RefreshTwoToneIcon from '@material-ui/icons/RefreshTwoTone';
import { searchDoctorBy } from '../util/searchAPIUtils'

class EntityAssociation extends Component {

    constructor(props) {
        super(props)
        this.state = {
            doctorList: [],
            sharedWithIds: [],
            entityName: this.props.history.location.state ? this.props.history.location.state.entityName : null,
            entityId: this.props.history.location.state ? this.props.history.location.state.entityId : null,
            spType: "DOC",
            spIdsToAdd: [],
            spIdsToRemove: [],
            adminToAdd: [],
            adminToRemove: [],
            pageNumber: 0,
            pageSize: 20,
            search: '',
            isEdit: this.props.history.location.state ? this.props.history.location.state.isEdit : false,
            doctorUserIds: [],
            doctorAssociationByEntityId: [],
            object: {}
        }
        this.handleSearchForDoctors = this.handleSearchForDoctors.bind(this);
    }



    loadDc() {
        getDoctorList(this.state.pageNumber, this.state.pageSize)
            .then(response => {
                this.setState({
                    doctorList: response.content,
                    sharedWithType: 'DOCTOR',
                    totalElements: response.totalElements,
                    loading: false
                });
            }).catch(error => {
                this.setState({
                    loading: false
                });
                Alert.error((error && error.message) || 'Something went wrong, please try again later!');
            });

    }


    checkInputHandlerForEntity = (event, userId, isEdit) => {

        if (event.target.checked) {
            if (isEdit) {
                const index = this.state.spIdsToRemove.indexOf(userId)
                this.state.spIdsToRemove.splice(index, 1)
                this.setState({
                    spIdsToRemove: this.state.spIdsToRemove
                })
            }
            if (!isEdit) {
                this.setState({
                    spIdsToAdd: [...this.state.spIdsToAdd, userId],

                })
            }
        }
        else {
            this.setState({
                spIdsToAdd: this.state.spIdsToAdd.filter(curIndex => curIndex !== userId)
            })
        }

        if (isEdit & !event.target.checked) {
            this.setState({
                spIdsToRemove: [...this.state.spIdsToRemove, userId]
            })
        }


    }

    checkInputHandlerForAdmin = (event, userId, isEdit) => {
        if (event.target.checked) {
            if (isEdit) {
                const index = this.state.adminToRemove.indexOf(userId)
                this.state.adminToRemove.splice(index, 1)
                this.setState({
                    adminToRemove: this.state.adminToRemove
                })
            }
            if (!isEdit)
                this.setState({
                    adminToAdd: [...this.state.adminToAdd, userId]
                })

        }
        else {
            this.setState({
                adminToAdd: this.state.adminToAdd.filter((currIndex) => currIndex !== userId)
            })
        }

        if (isEdit && !event.target.checked) {
            this.setState({
                adminToRemove: [...this.state.adminToRemove, userId]
            })
        }
    }

    componentDidMount() {
        this.loadDc()
        if (this.state.isEdit)
            this.loadDoctorByEntity()
        window.scrollTo(0, 0)
    }

    handleChange = () => {
        this.setState({
            spType: "DOC"
        })
    }

    submitHandler = (event) => {
        event.preventDefault();
        const entityAssociationRequest = {
            spType: this.state.spType,
            spIdsToAdd: this.state.spIdsToAdd,
            spIdsToRemove: this.state.spIdsToRemove,
            adminToAdd: this.state.adminToAdd,
            adminToRemove: this.state.adminToRemove,
            entityId: this.state.entityId
        }

        createAssociation(entityAssociationRequest)
            .then(response => {
                Alert.success("Entity association created successully!");
            }).catch(error => {
                Alert.error((error && error.message) || 'Oops! Something went wrong. Please try again!');
            })
    }

    handleSearchChange = (event) => {
        const inputValue = event.target.value;
        const inputName = event.target.name
        this.setState({
            search: event.target.value
        })
    }


    clearSearch = () => {
        this.setState({
            search: ''
        })
        this.loadDc()
    }

    async handleSearchForDoctors() {
        await this.setState({
            pageNumber: 0

        })
        searchDoctorBy(this.state.search, "", "", "", "", "", "", "")
            .then(response => {
                this.setState({
                    doctorList: response.content,
                });
            }).catch(error => {
                Alert.error((error && error.message) || 'Something went wrong, please try again later!');
            });
    }

    handleBack = () => {
        this.props.history.goBack()
    }

    loadDoctorByEntity() {
        getEntityDoctorList(this.state.entityId, this.state.spType, this.state.pageNumber, this.state.pageSize)
            .then(response => {
                this.setState({
                    doctorAssociationByEntityId: response.content,
                    totalElements: response.totalElements,
                    loading: false,
                });
                this.state.doctorAssociationByEntityId.map(value => {
                    const id = value.spId;
                    const values = value.isEntityAdmin
                    this.state.object[id] = values
                    this.state.doctorUserIds.push(value.spId)

                })
            })
    }

    render() {
        return (
            <Container>
                <div className="entity-container">
                    <Row className="welcome text-center mb-5">
                        <Col sm={12}>
                            <h3>{this.state.isEdit ? "Update doctors to " : "Add doctors to "}{this.state.entityName}</h3>
                        </Col>
                        <hr />
                    </Row>
                    <Row>
                        <Col md={2}>
                            {this.state.isEdit ? "Update doctors to " : "Add doctors to "} {this.state.entityName}
                        </Col>
                        <Col md={3}>
                            <Row>
                                <Form.Check type="radio" label="Doctor's on this platform" value="DOCTOR" name="sharedWithType" onChange={this.handleChange} defaultChecked />
                            </Row>
                        </Col>
                        <Col md={3}>
                            <Form.Check type="radio" label="User's on this platform" value="USER" name="sharedWithType" onChange={this.handleChange} className="radio" />
                        </Col>
                        <Col md={3}>
                            <Form.Check type="radio" label="Others" value="EMAIL" name="sharedWithType" onChange={this.handleChange} className="radio" />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={1}>
                        </Col>
                        <Col md={7} className="mb-2">
                            <Form.Control data-browse="Button text" type="text" name="search" value={this.state.search} placeholder="Search doctor by name" onChange={(event) => this.handleSearchChange(event)} />
                        </Col>
                        <Col xs={3} sm={2} md={2}>
                            <Button onClick={this.handleSearchForDoctors}> <SearchIcon /> </Button>
                        </Col>
                        <Col xs={3} sm={2} md={1}>
                            <Button onClick={this.clearSearch}><RefreshTwoToneIcon /></Button>
                        </Col>
                    </Row>
                    <br />
                    <Table className="shadow" id="entity-association-table">

                        <thead className="thead-color">
                            <tr id="entity-association-table-Head">
                                <th>#</th>
                                <th className="text-left">Name</th>
                                <th className="text-center">Select</th>
                                <th className="text-center">Add as admin</th>
                            </tr>
                        </thead>

                        <tbody>
                            {
                                (this.state.doctorList.map((value) => {
                                    return (
                                        <tr>
                                            <td data-label="#">{value.id}</td>
                                            <td className="text-left" data-label="Name">{value.profileLink ? (
                                                <span className="profile-avatar">
                                                    <img src={value.profileLink} alt={value.name} width="25px" height="25px" />
                                                </span>
                                            ) : (
                                                <span className="profile">{value.name[0]}</span>
                                            )
                                            }
                                                {" "}  {value.name}</td>
                                            {
                                                this.state.isEdit ? this.state.doctorUserIds.includes(value.userId) ?
                                                    <td className="text-md-center" data-label="Select"><Form.Check type="checkbox" onChange={(event) => this.checkInputHandlerForEntity(event, value.userId, this.state.isEdit)} defaultChecked></Form.Check></td> :
                                                    <td className="text-md-center" data-label="Select"><Form.Check type="checkbox" onChange={(event) => this.checkInputHandlerForEntity(event, value.userId)}></Form.Check></td> :
                                                    <td className="text-md-center" data-label="Select"><Form.Check type="checkbox" onChange={(event) => this.checkInputHandlerForEntity(event, value.userId)}></Form.Check></td>
                                            }
                                            {
                                                this.state.isEdit ? this.state.object[value.userId] ?
                                                    <td className="text-md-center" data-label="Add as admin"><Form.Check type="checkbox" onChange={(event) => this.checkInputHandlerForAdmin(event, value.userId, this.state.isEdit)} defaultChecked></Form.Check></td> :
                                                    <td className="text-md-center" data-label="Add as admin"><Form.Check type="checkbox" onChange={(event) => this.checkInputHandlerForAdmin(event, value.userId)}></Form.Check></td> :
                                                    <td className="text-md-center" data-label="Add as admin"><Form.Check type="checkbox" onChange={(event) => this.checkInputHandlerForAdmin(event, value.userId)}></Form.Check></td>
                                            }
                                        </tr>
                                    )
                                }))
                            }
                        </tbody>

                    </Table>

                    <Row>
                        <Col>
                            <Link className="float-left btn btn-primary mb-3" onClick={this.handleBack}>Back</Link>
                        </Col>
                        <Col>
                            <Button type="submit" className="float-right mb-3" onClick={(event) => this.submitHandler(event)}>Submit</Button>
                        </Col>
                    </Row>

                </div>
            </Container>
        );
    }
}

export default EntityAssociation;