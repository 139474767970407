import React, { Component } from 'react';
import { Container, Form, Row, Col, Button } from 'react-bootstrap';
import './EmailFormToResetPassword.css'
import EmptyJumbotron from '../../common/EmptyJumbotron';
import { forgotPasswordSendMail } from '../../util/APIUtils';
import Alert from 'react-s-alert';

class EmailFormToResetPassword extends Component {
  constructor() {
    super();
    this.state = {
      email: ''
    }
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    this.setState({
      email: event.target.value
    })
  }

  componentDidMount(){
    window.scrollTo(0, 0)
  }

  validateEmail() {
    const email = this.state.email;
    var reg = /\S+@\S+\.\S+/
    if (!reg.test(email)) {
      alert("email is not valid")
      return false;
    }
    return true;
  }

  handleSubmit(event) {
    event.preventDefault();
    const forgotPasswordRequest = Object.assign({}, this.state);
    if (this.validateEmail()) {
      forgotPasswordSendMail(forgotPasswordRequest)
        .then(response => {
          Alert.success('An email with password reset link has been sent to your email id. Please check.')
        }).catch(error => {
          Alert.error((error && error.message) || 'Oops! Something went wrong. Please try again!')
        });
    }

  }

  render() {
    return (
      <Container>
        <div className="forgot-password-container">
          <h2> Forgot password</h2>
          <p>Please enter your Email and a link will be sent to your email to reset your password. </p>
          <Form onSubmit={this.handleSubmit}>
            <Form.Group as={Row} controlId="email">
              <Form.Label column sm={2}>
                Email
							    </Form.Label>
              <Col sm={3}>
                <Form.Control type="email" value={this.state.email} placeholder="email" onChange={this.handleInputChange} />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Col sm={{ span: 2, offset: 2 }}>
                <Button type="button" href="/login">Back to Login</Button>
              </Col>
              <Col >
                <Button type="submit">Send</Button>
              </Col>
            </Form.Group>
          </Form>
        </div>
        <EmptyJumbotron />
      </Container>
    );
  }
}

export default EmailFormToResetPassword;