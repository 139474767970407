export const API_BASE_URL = 'https://binaryintuition.com/i';
export const ACCESS_TOKEN = 'accessToken';
export const AUTHENTICATED = 'authenticated';
export const CURRENT_USER = 'currentUser';
export const SP_LIST = 'spList';
export const QL_LIST = 'qlList';

export const DOCTORS_API_BASE_URL = 'https://binaryintuition.com/d';
export const EVENTS_API_BASE_URL = 'https://binaryintuition.com/w';
export const GALLERY_API_BASE_URL = 'https://binaryintuition.com:8083';
export const REFERENTIAL_API_BASE_URL = "https://binaryintuition.com/r/ref";
export const HEALTH_RECORD_API_BASE_URL = "https://binaryintuition.com/h";
export const ORDERS_API_BASE_URL = "https://binaryintuition.com/o";
export const COMMENTS_API_BASE_URL = "https://binaryintuition.com/c";
export const EMAIL_API_API_BASE_URL = "https://binaryintuition.com/e";
export const PAYMENT_API_BASE_URL = "https://binaryintuition.com/y";
export const PHARMACY_API_BASE_URL = "https://binaryintuition.com/m";




export const OAUTH2_REDIRECT_URI = 'https://health.binaryintuition.com/oauth2/redirect'

export const GOOGLE_AUTH_URL = API_BASE_URL + '/oauth2/authorize/google?redirect_uri=' + OAUTH2_REDIRECT_URI;
export const FACEBOOK_AUTH_URL = API_BASE_URL + '/oauth2/authorize/facebook?redirect_uri=' + OAUTH2_REDIRECT_URI;
export const GITHUB_AUTH_URL = API_BASE_URL + '/oauth2/authorize/github?redirect_uri=' + OAUTH2_REDIRECT_URI;

export const status = {
                        PENDING : 'PENDING',
                        AWAITING_PAYMENT : 'AWAITING PAYMENT',
                        PAYMENT_DONE : 'PAYMENT DONE',
                        CONFIRMED : 'CONFIRMED',
                        FULFILLED : 'FULFILLED',
                        CANCELLED : 'CANCELLED',
                        RESCHEDULED : 'RESCHEDULED',
                        CLOSED : 'CLOSED'
                    }

export const PROVIDER = 'google'