import React, { Component } from 'react'
import './TrackHabit.css'
import { Row, Col, Button, Container, Form } from 'react-bootstrap';
import { trackHabit } from '../util/healthAPIUtils'
import Select from 'react-select'
import Alert from 'react-s-alert';
import { Link } from 'react-router-dom'
import { getCurrentUserId } from '../util/functionUtils';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker, } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

export default class TrackHabit extends Component {

    constructor() {
        super()
        this.state = {
            habitTypes: [{ label: "Food", value: "Food" },
                { label: "Walk", value: "Walk" },
                { label: "Run", value: "Run" },
                { label: "Exercise", value: "Exercise" },
                { label: "Yoga", value: "Yoga" },
                { label: "Meditation", value: "Meditation" }, 
                { label: "Medicines", value: "Medicines" },
                { label: "Reading", value: "Reading" }],
            consumerId: getCurrentUserId(),
            type: '',
            name: '',
            detail: '',
            startDate: '',
            startTime: new Date(),
            startDateStr: new Date().toUTCString(),
            endDate: '',
            endTime: '',
            trackedHabit: '' 
        }

        this.handleDateChange = this.handleDateChange.bind(this)
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    changeDropdownHandler = (setValue, setName) => {
        this.setState({
            [setName]: setValue
        })
    }
    handleNameChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    async handleDateChange(date) {

        var now_utc = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(),
                date.getUTCDate(), date.getUTCHours(),
                date.getUTCMinutes(), date.getUTCSeconds());  
        
        console.log("date selected " + date);
        console.log("date formatted " + new Date(now_utc));
        console.log("ISO format str " + date.toUTCString());
        console.log("Back to date obj " + new Date(date.toUTCString()));

        await this.setState({
            // startDate: new Date(date.toUTCString()),
            startTime: date,
            startDateStr: date.toUTCString()
        })
    }

    submitHandler = () => {
        // console.log("date " + this.state.startDate);
        // console.log("time " + this.state.startTime);
        // console.log("timezone " + this.state.startTime.toString().match(/\(([^\)]+)\)$/)[1]);
        const trackHabitRequest = this.state
        trackHabit(trackHabitRequest)
            .then(response => {
                Alert.success("Habit Tracked successfully")
                this.setState({
                    trackedHabit: response
                })
                this.props.history.push({
                    pathname: 'habit-tracker'//,
                    // state: { id: this.state.addedCategory.id, type: this.state.addedCategory.type, name: this.state.addedCategory.name }
                })
            }).catch(error => {
                Alert.error((error && error.message) || 'Oops! Something went wrong. Please try again!');
            })
    }

    render() {
        return (
            <Container>
                <div className="trackhabit-records-container">
                    <h2>Track Habit</h2>
                    <br />
                    <Form onSubmit={this.handleSubmit}>
                        <Form.Group as={Row} controlId="type">
                            <Form.Label column sm={3}>
                                Habit Type :
                            </Form.Label>
                            <Col sm={3}>
                                <Select
                                    options={this.state.habitTypes}
                                    onChange={(selectedOption) => this.changeDropdownHandler(selectedOption.value, "type")}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="name">
                            <Form.Label column sm={3}>
                                Habit :
                            </Form.Label>
                            <Col sm={3}>
                                <Form.Control type="text" value={this.state.name} name="name" placeholder="Habit or Activity" onChange={this.handleNameChange} maxLength="40" required />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="details">
                            <Form.Label column sm={3}>
                                Detail :
                            </Form.Label>
                            <Col sm={3}>
                                <Form.Control type="textarea" value={this.state.detail} name="detail" placeholder="Provide detail of the Habit or Activity" onChange={this.handleNameChange} maxLength="60" required />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="startTime">
                            <Form.Label column sm={3}>
                                Start Time :
                            </Form.Label>
                            <Col sm={3}>
                                {/* <Form.Control type="textarea" value={this.state.startTime} name="startTime" placeholder="Provide detail of the Habit or Activity" onChange={this.handleNameChange} maxLength="60" required /> */}
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDateTimePicker
                                        margin="normal"
                                        id="date-picker-dialog"
                                        format="yyyy-MM-dd'T'HH:mm:ss"
                                        value={this.state.startTime}
                                        onChange={this.handleDateChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        minDate={new Date(new Date().getTime() - (30 * (1000 * 60 * 60 * 24)))}
                                        maxDate={new Date()}
                                    />
                                </MuiPickersUtilsProvider>
                            </Col>
                        </Form.Group>
                        <Row>
                            <Link className=" btn btn-primary" to="/habit-tracker">Back</Link>
                            <Button variant="primary" className="left-margin-adcat" onClick={this.submitHandler}>Submit</Button>
                        </Row>
                    </Form>
                    <br />
                </div>
            </Container>
        )
    }
}
