import React, { Component } from 'react';
import { Jumbotron, Button, Container, Form, Row, Col } from 'react-bootstrap';
import { GoogleMap, withScriptjs, withGoogleMap } from "react-google-maps";
import Alert from 'react-s-alert';
import LoadingIndicator from '../common/LoadingIndicator';

import './Contact.css';
import {contactFormRequest} from '../util/APIUtils';

function Map() {
	return (
		<GoogleMap defaultZoom={10}
			defaultCenter={{ lat: 45.42153, lng: -75.697189 }} />

	);
}

const WrappedMap = withScriptjs(withGoogleMap(Map));

export default class Contact extends Component {

	constructor() {
		super()
		this.state = {
			name:'',
			email:'',
			subject:'',
			comment:'',
			type: 'CUS',
			loading:false
		}
	}
	changeInputHandler = (event) =>{
		const name= event.target.name
		const value = event.target.value
		this.setState({
			[name]:value
		})
	}

	componentDidMount(){
		window.scrollTo(0, 0)
	}


	submitHandler = (event) => {
		this.setState({
			loading:true
		})
		event.preventDefault();
	   const request = this.state
	   contactFormRequest(request)
	   .then(response =>{
			Alert.success(response.message);
			this.setState({
				loading:false
			})
	   })
	   .catch(error => {
		Alert.error((error && error.message) || 'Oops! Something went wrong. Please try again!');
		this.setState({
			loading:false
		})
	})
	}
	render() {
		if(this.state.loading){
			return <LoadingIndicator/>
		}
		return (
			<div className="mb-2">
				<Jumbotron className="contjumbotron">
					<h1 className="white-text text-center">Contact Us</h1>
					<h4 className="white-text text-center text-muted">We are here to help and answer any question you might have. We look forward to hearing from you.</h4>
				</Jumbotron>
				<Container>
					{/* <div className="row"> */}

						{/* <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"> */}

						<Row>
							<Col sm={12} md={6}>
							{/* <Media>
								<Media.Body> */}
									<Form onSubmit={this.submitHandler}>
										<div className="colorme white-text padding"><h3><i className="fa fa-envelope"></i> Write to us:</h3></div>
										<Form.Group controlId="frmName">
											<i className="fa fa-user prefix grey-text"></i>
											<Form.Control type="text" placeholder="Your name" name ="name" value = {this.state.name} onChange ={this.changeInputHandler} required/>
										</Form.Group>
										<Form.Group controlId="frmEmail">
											<i className="fa fa-envelope prefix grey-text"></i>
											<Form.Control type="email" placeholder="Your email" name ="email" value = {this.state.email} onChange ={this.changeInputHandler} required/>
										</Form.Group>
										<Form.Group controlId="frmSubject">
											<i className="fa fa-tag prefix grey-text"></i>
											<Form.Control type="text" placeholder="Subject" name="subject" value = {this.state.subject} onChange ={this.changeInputHandler} required/>
										</Form.Group>
										<Form.Group controlId="frm.textarea">
											<i className="fa fa-pencil prefix grey-text"></i>
											<Form.Control as="textarea" rows="3" placeholder="Comments / Feedback / Request" name="comment" value = {this.state.comment} onChange ={this.changeInputHandler} required/>
										</Form.Group>
										<Button type="submit" variant="dark">Submit</Button>
									</Form>
								{/* </Media.Body>
							</Media> */}
							</Col>
						{/* </div> */}

						{/* <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"> */}
						<Col sm={12} md={6}>
							{/* <Media>
								<Media.Body> */}
									<div className="mapdiv">
										<WrappedMap
											googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyCmITuQKdcHfcH-nx0uBHELre_oPzf2Xk4&v=3.exp&libraries=geometry,drawing,places`}
											loadingElement={<div style={{ height: `100%` }} />}
											containerElement={<div style={{ height: `100%` }} />}
											mapElement={<div style={{ height: `100%` }} />}
										/>
									</div>
									<br />
									<div className="row text-center">
										<div className="col-md-6">
											<a className="btn-floating"><i className="fa fa-map-marker"></i></a>
											<p><small>Bangalore, KA 560037, INDIA</small></p>
										</div>
										<div className="col-md-6">
											<a className="btn-floating"><i className="fa fa-envelope fblack"></i></a>
											<p><small>contact@binaryintuition.com</small></p>
										</div>
									</div>
								{/* </Media.Body>
							</Media> */}
						</Col>
						{/* </div> */}
						</Row>
						{/* <Card bg="light" text="dark">
								<Card.Header><h3><i className="fa fa-envelope"></i> Write to us:</h3></Card.Header>
								<Card.Body>
									<Form>
										<Form.Group controlId="frmName">
											<i className="fa fa-user prefix grey-text"></i>
											<Form.Control type="text" placeholder="Your name" />
										</Form.Group>
										<Form.Group controlId="frmEmail">
											<i className="fa fa-envelope prefix grey-text"></i>
											<Form.Control type="email" placeholder="Your email" />
										</Form.Group>
										<Form.Group controlId="frmSubject">
											<i className="fa fa-tag prefix grey-text"></i>
											<Form.Control type="text" placeholder="Subject" />
										</Form.Group>
										<Form.Group controlId="frm.textarea">
											<i className="fa fa-pencil prefix grey-text"></i>
											<Form.Control as="textarea" rows="3" placeholder="Comments / Feedback / Request" />
										</Form.Group>
										<Button type="submit" variant="dark">Submit</Button>
									</Form>
								</Card.Body>
							</Card>

							<Card>
								<Card.Body>
									<div className="mapdiv">
										<WrappedMap
											googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places`}
											loadingElement={<div style={{ height: `100%` }} />}
											containerElement={<div style={{ height: `100%` }} />}
											mapElement={<div style={{ height: `100%` }} />}
										/>
									</div>
									<br />
									<div className="row text-center">
										<div className="col-md-6">
											<a className="btn-floating"><i className="fa fa-map-marker"></i></a>
											<p><small>Bangalore, KA 560037, INDIA</small></p>
										</div>
										<div className="col-md-6">
											<a className="btn-floating"><i className="fa fa-envelope fblack"></i></a>
											<p><small>contact@binaryintuition.com</small></p>
										</div>
									</div>
								</Card.Body>
							</Card> */}

					{/* </div> */}
				</Container>
			</div >
		);
	}
}