// To Show the event details of Individual one
import React, { Component } from "react";
import './EventCustomize.css';
// import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { Jumbotron, Table } from 'react-bootstrap';
import { updateGalleryPhoto, updateActorPhoto } from '../util/APIUtils';
import CustomFooter from '../common/CustomFooter';
import EmptyJumbotron from '../common/EmptyJumbotron';
import Alert from 'react-s-alert';
import "react-image-gallery/styles/css/image-gallery.css";
import { Container, Row, Col, Tab, ListGroup} from 'react-bootstrap';


class EventDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photoFile: '',
      eventId: this.props.location.eventId ,
      panelists: this.props.location.panelists? this.props.location.panelists : [],
      moderators: this.props.location.moderators ? this.props.location.moderators : [],
      sponsorers:this.props.location.sponsorers ? this.props.location.sponsorers : [],
      partners:this.props.location.partners? this.props.location.partners : []

    };

    this.handleUploadPhoto = this.handleUploadPhoto.bind(this);
    this.handleBannerUpload = this.handleBannerUpload.bind(this);
    this.handlePhotoGalleryUpload = this.handlePhotoGalleryUpload.bind(this);
    this.handleActorPhotoUpload = this.handleActorPhotoUpload.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    // this.handleUploadPhotoBack = this.handleUploadPhotoBack.bind(this);
  }
  // handleUploadPhotoBack(event) {
  //   event.preventDefault();
  //   window.location.reload(false);
  // }

  handleInputChange(event) {
    event.preventDefault();
    const file = event.target.files[0]
    this.setState({
      photoFile: file
    })


  };

  // handleUploadPhoto(event) {
  //   event.preventDefault();
  //   console.log("Upload======================= " + this.state.photoFile);
  //   updateProfilePhoto(this.state.photoFile)
  //     .then(response => {
  //       Alert.success('image uploaded successfully');
  //     }).catch(error => {
  //       Alert.error(error && error.message);
  //     })
  // }

  handleBannerUpload(event) {
    event.preventDefault();
    updateGalleryPhoto(this.state.photoFile,this.state.eventId,"BANNER")
      .then(response => {
        Alert.success('Banner uploaded successfully');
      }).catch(error => {
        Alert.error(error && error.message);
      })
  }

  handlePhotoGalleryUpload(event) {
    event.preventDefault();
    updateGalleryPhoto(this.state.photoFile,this.state.eventId,"PHOTO")
      .then(response => {
        Alert.success('Photo uploaded successfully');
      }).catch(error => {
        Alert.error(error && error.message);
      })
  }


  handleActorPhotoUpload(event, actId) {
    event.preventDefault();
    console.log("handleActorPhotoUpload " + actId);
    updateActorPhoto(this.state.photoFile,this.state.eventId,actId)
      .then(response => {
        Alert.success('Photo uploaded successfully');
      }).catch(error => {
        Alert.error(error && error.message);
      })
  }

  render() {
    return (
      <Container id="top">
        <div className="event-details-container container-fluid">
          <Jumbotron>
            <h2>Customize Event</h2>
          </Jumbotron>
          <Tab.Container id="list-group-tabs-example" defaultActiveKey="#link1">
            <Row>
              <Col sm={12}>
                <ListGroup horizontal>
                  <ListGroup.Item action href="#link1">
                    Banner Upload
                  </ListGroup.Item>
                  <ListGroup.Item action href="#link2">
                    Photo Gallery
                  </ListGroup.Item>
                  <ListGroup.Item action href="#link3">
                    Video Gallery
                  </ListGroup.Item>
                  <ListGroup.Item action href="#link4">
                    Panelist Photo
                  </ListGroup.Item>
                  <ListGroup.Item action href="#link5">
                    Moderators' Photo
                  </ListGroup.Item>
                  <ListGroup.Item action href="#link6">
                    Sponsorers' Photo
                  </ListGroup.Item>
                  <ListGroup.Item action href="#link7">
                    Partners' Photo
                  </ListGroup.Item>
                </ListGroup>
              </Col>
              <Col sm={12}>
                <Tab.Content>
                  <Tab.Pane eventKey="#link1">
                    <div className="tab-container container-fluid">
                      <div className="upload-section">Please upload Banner image for your event!
                    <i className="fa fa-camera fa-2x" aria-hidden="true" data-toggle="modal" data-target="#staticBackdrop"> </i></div>
                      <div className="text-muted">*** File types allowed are .jpeg, .png, and max size allowed is 200KB.</div>
                      <div className="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-body">
                              <h5 className="modal-title" id="staticBackdropLabel">Upload Photo</h5><br />
                              <div className="upload-photo">
                                <i className="fa fa-camera" aria-hidden="true"></i>
                              </div>
                              <input type="file" name="file" onChange={this.handleInputChange} />
                            </div>
                            <div className="modal-footer">
                              <button type="button" className="btn btn-outline-danger" data-dismiss="modal">Back</button>
                              <button type="button" className="btn btn-outline-success" onClick={this.handleBannerUpload}>Upload</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </Tab.Pane>
                  <Tab.Pane eventKey="#link2">
                    <div className="tab-container container-fluid">
                      <div className="upload-section">Please upload photos of your event here!
                    <i className="fa fa-camera fa-2x" aria-hidden="true" data-toggle="modal" data-target="#staticBackdrop1"> </i></div>
                      <div className="text-muted">*** File types allowed are .zip, .jpeg, .png, and max size allowed is 500MB.</div>
                      <div className="modal fade" id="staticBackdrop1" data-backdrop="static" data-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-body">
                              <h5 className="modal-title" id="staticBackdropLabel">Upload Photo</h5><br />
                              <div className="upload-photo">
                                <i className="fa fa-camera" aria-hidden="true"></i>
                              </div>
                              <input type="file" name="file" onChange={this.handleInputChange} />
                            </div>
                            <div className="modal-footer">
                              <button type="button" className="btn btn-outline-danger" data-dismiss="modal">Back</button>
                              <button type="button" className="btn btn-outline-success" onClick={this.handlePhotoGalleryUpload}>Upload</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="#link3">
                    <div className="tab-container container-fluid">

                      <Tab.Container id="list-group-tabs-example" defaultActiveKey="#link1">
                        <Row>
                          <Col sm={3}>
                            <ListGroup>
                              <ListGroup.Item action href="#link1">
                                YouTube Link
                            </ListGroup.Item>
                              <ListGroup.Item action href="#link2">
                                Video Upload
                            </ListGroup.Item>
                            </ListGroup>
                          </Col>
                          <Col sm={9}>
                            <Tab.Content>
                              <Tab.Pane eventKey="#link1">
                                <form onSubmit={this.handleSubmit}>
                                  <div className="form-row">
                                    <div className="form-group col-md-10">
                                      <input type="text" value={this.state.link} name="link" className="form-control" id="inputlink" placeholder="Link" onChange={this.handleInputChange} />
                                    </div>
                                    <div className="form-group col-md-2">
                                      <button type="submit" className="btn btn-outline-primary">Submit</button>
                                    </div>
                                  </div>
                                </form>
                              </Tab.Pane>
                              <Tab.Pane eventKey="#link2">
                                <div className="upload-section">Please upload videos of your event here!</div>
                                <div className="text-muted">*** File types allowed are .mp4 and .wmv, and max size allowed is 500MB.</div>
                                <i className="fa fa-camera fa-2x" aria-hidden="true" data-toggle="modal" data-target="#staticBackdrop2"> </i>
                                <div className="modal fade" id="staticBackdrop2" data-backdrop="static" data-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                  <div className="modal-dialog">
                                    <div className="modal-content">
                                      <div className="modal-body">
                                        <h5 className="modal-title" id="staticBackdropLabel">Upload Photo</h5><br />
                                        <div className="upload-photo">
                                          <i className="fa fa-camera" aria-hidden="true"></i>
                                        </div>
                                        <input type="file" name="file" onChange={this.handleInputChange} />
                                      </div>
                                      <div className="modal-footer">
                                        <button type="button" className="btn btn-outline-danger" data-dismiss="modal">Back</button>
                                        <button type="button" className="btn btn-outline-success" onClick={this.handleUploadPhoto}>Upload</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Tab.Pane>
                            </Tab.Content>
                          </Col>
                        </Row>
                      </Tab.Container>
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="#link4">
                    <Table>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Upload Photo</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          this.state.panelists.map(panelist => {
                            return (
                              <tr>
                                <td>{panelist.name}</td>
                                <td>
                                  <i className="fa fa-camera fa-2x" aria-hidden="true" data-toggle="modal" data-target={"#staticBackdrop4"+panelist.id}> </i>
                                  <div className="modal fade" id={"staticBackdrop4"+panelist.id} data-backdrop="static" data-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                    <div className="modal-dialog">
                                      <div className="modal-content">
                                        <div className="modal-body">
                                          <h5 className="modal-title" id="staticBackdropLabel">Upload Photo</h5><br />
                                          <div className="upload-photo">
                                            <i className="fa fa-camera" aria-hidden="true"></i>
                                          </div>
                                          <input type="file" name="file" onChange={this.handleInputChange} />
                                        </div>
                                        <div className="modal-footer">
                                          <button type="button" className="btn btn-outline-danger" data-dismiss="modal">Back</button>
                                          <button type="button" className="btn btn-outline-success" onClick={(e) => this.handleActorPhotoUpload(e, panelist.id)}>Upload</button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </Table>

                  </Tab.Pane>
                  <Tab.Pane eventKey="#link5">
                    <Table>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Upload Photo</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          this.state.moderators.map(moderator => {
                            return (
                              <tr>
                                <td>{moderator.name}</td>
                                <td>
                                  <i className="fa fa-camera fa-2x" aria-hidden="true" data-toggle="modal" data-target={"#staticBackdrop5"+moderator.id}> </i>
                                  <div className="modal fade" id={"staticBackdrop5"+moderator.id} data-backdrop="static" data-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                    <div className="modal-dialog">
                                      <div className="modal-content">
                                        <div className="modal-body">
                                          <h5 className="modal-title" id="staticBackdropLabel">Upload Photo</h5><br />
                                          <div className="upload-photo">
                                            <i className="fa fa-camera" aria-hidden="true"></i>
                                          </div>
                                          <input type="file" name="file" onChange={this.handleInputChange} />
                                        </div>
                                        <div className="modal-footer">
                                          <button type="button" className="btn btn-outline-danger" data-dismiss="modal">Back</button>
                                          <button type="button" className="btn btn-outline-success" onClick={(e) => this.handleActorPhotoUpload(e, moderator.id)}>Upload</button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </Table>
                  </Tab.Pane>
                  <Tab.Pane eventKey="#link6">
                  <Table>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Upload Photo</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          this.state.sponsorers.map(sponsorer => {
                            return (
                              <tr>
                                <td>{sponsorer.name}</td>
                                <td>
                                <i className="fa fa-camera fa-2x" aria-hidden="true" data-toggle="modal" data-target={"#staticBackdrop6"+sponsorer.id}> </i>
                                <div className="modal fade" id={"staticBackdrop6"+sponsorer.id} data-backdrop="static" data-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                      <div className="modal-dialog">
                                        <div className="modal-content">
                                          <div className="modal-body">
                                            <h5 className="modal-title" id="staticBackdropLabel">Upload Photo</h5><br />
                                            <div className="upload-photo">
                                              <i className="fa fa-camera" aria-hidden="true"></i>
                                            </div>
                                            <input type="file" name="file" onChange={this.handleInputChange} />
                                          </div>
                                          <div className="modal-footer">
                                            <button type="button" className="btn btn-outline-danger" data-dismiss="modal">Back</button>
                                            <button type="button" className="btn btn-outline-success" onClick={(e) => this.handleActorPhotoUpload(e, sponsorer.id)}>Upload</button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </Table>
                  </Tab.Pane>
                  <Tab.Pane eventKey="#link7">
                  <Table>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Upload Photo</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          this.state.partners.map(partner => {
                            return (
                              <tr>
                                <td>{partner.name}</td>
                                <td>
                                <i className="fa fa-camera fa-2x" aria-hidden="true" data-toggle="modal" data-target={"#staticBackdrop7"+partner.id}> </i>
                                <div className="modal fade" id={"staticBackdrop7"+partner.id} data-backdrop="static" data-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                      <div className="modal-dialog">
                                        <div className="modal-content">
                                          <div className="modal-body">
                                            <h5 className="modal-title" id="staticBackdropLabel">Upload Photo</h5><br />
                                            <div className="upload-photo">
                                              <i className="fa fa-camera" aria-hidden="true"></i>
                                            </div>
                                            <input type="file" name="file" onChange={this.handleInputChange} />
                                          </div>
                                          <div className="modal-footer">
                                            <button type="button" className="btn btn-outline-danger" data-dismiss="modal">Back</button>
                                            <button type="button" className="btn btn-outline-success" onClick={(e) => this.handleActorPhotoUpload(e, partner.id)}>Upload</button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </Table>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
        <EmptyJumbotron />
        <CustomFooter />
      </Container>
    );
  }
}

export default EventDetails;
