import React, { Component, Fragment } from 'react'
import EventCarousel from './EventCarousel'
import CustomFooter from '../common/CustomFooter';
import { getEventDetails, getGalleryPhotos } from '../util/APIUtils'
import { userRegisteredToEvents } from '../util/eventsUtils';
import { registerEvents } from '../util/eventsUtils'
import { Link, Redirect } from 'react-router-dom';
import './EventDetails.css'
import { Jumbotron, Row, Col, Card, Button, Container, Table, Media } from 'react-bootstrap'
import Slider from './Slider';
import Alert from 'react-s-alert';
import LoadingIndicator from '../common/LoadingIndicator';
import { AUTHENTICATED } from '../constants';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { getRole, changeTime, eventsTypeMapping} from '../util/functionUtils';




class EventDetails extends Component {

    constructor(props) {
        super(props)
        this.state = {
            eventId: this.props.match.params.id,
            list: [1, 2, 4],
            eventDetails: [],
            panelist: [],
            moderators: [],
            sponsorers: [],
            partners: [],
            banners: [],
            images: [],
            pageSize: 6,
            pageNumber: 0,
            loading: true,
            authenticated: localStorage.getItem(AUTHENTICATED) ? localStorage.getItem(AUTHENTICATED) : false,
            RegisterUserEventId: [this.props.match.params.id],
            role:getRole(),
            isUserRegistered:false
        }
    }

    loadGallery = (type, setValue) => {
        getGalleryPhotos(this.state.pageNumber, this.state.pageSize, this.state.id, type)
            .then(response => {
                this.setState({
                    [setValue]: response.content
                })
            }).catch(error => {
                Alert.error('Oops! Something went wrong!')
            })
    }

    loadEventDetails = () => {
        getEventDetails(this.state.eventId)
            .then(response => {
                this.setState({
                    eventDetails: response,
                })
                response.actors.map((value) => {
                    if (value.type === 'PANELIST') {
                        this.setState({
                            panelist: [...this.state.panelist, value]
                        })
                    }
                    else if (value.type === 'MODERATOR') {
                        this.setState({
                            moderators: [...this.state.moderators, value]
                        })
                    }
                    else if (value.type === 'SPONSORS') {
                        this.setState({
                            sponsorers: [...this.state.sponsorers, value]
                        })
                    }
                    else if (value.type === 'PARTNERS') {
                        this.setState({
                            partners: [...this.state.partners, value]
                        })
                    }
                })
                // this.checkUserIsregistered();
                // this.setState({
                //     loading: false
                // })
            })
            .then(this.checkUserIsregistered())
            .catch(error => {
                Alert.error('Oops! Something went wrong! Please try again later!')
                this.setState({
                    loading: false
                })
            })

    }

    checkUserIsregistered() {
        if (this.state.authenticated){
        userRegisteredToEvents(this.state.RegisterUserEventId)
            .then(response => {
               let userRegistered = false;
                response.map(ep=>{
                    if(ep.eventId==this.state.eventId){
                        userRegistered=true
                    }
                })
                this.setState({
                    isUserRegistered:userRegistered,
                    loading: false
                })
            })
            .catch(error => {
                Alert.error('Oops! Something went wrong, Please try again later!')
                this.setState({
                    loading: false
                })
            })          
        }else{
            this.setState({
                loading: false
            });
        }
    }

    componentDidMount() {
        if (this.state.eventId) {
            this.loadEventDetails();
            //commneting now because not needed as of now ,can be used later
            // this.loadGallery("PHOTO", 'images');
            // this.loadGallery("BANNER", 'banners');
        } else {
            this.setState({
                loading: false
            });
        }
    }

    handleBack = () => {
        this.props.history.goBack()
    }

    registerToEvents = () => {
        if (this.state.authenticated) {
            registerEvents(this.state.eventId)
                .then(response => {
                    this.setState({
                        isUserRegistered:true
                    })
                    Alert.success("Successfully registered to the event.")
                })
                .catch(error => {
                    Alert.error('Oops! Something went wrong!')
                })
        } else {
            this.props.history.push("/login")
        }
    }

    render() {
        if (this.state.loading) {
            return <LoadingIndicator />
        }

        if (!this.state.eventId) {
            return <Redirect
                to={{
                    pathname: "/events",
                    state: { from: this.props.location }
                }} />;
        }
        // console.log("date diferencr",timeDifferenceInMinutes(this.state.eventDetails.dateStart,new Date()))

        return (
            <Container>
                <Fragment>
                    <div className="event-details-container container-fluid">

                        <Jumbotron fluid>
                            <Button className="btn btn-primary" onClick={this.handleBack}><ArrowBackIcon /></Button>
                            <Media>
                                <img
                                    width={220}
                                    height={220}
                                    className="mr-3 media-img"
                                    src={this.state.eventDetails.eventImgLink ? this.state.eventDetails.eventImgLink : "../img/mac.png"}
                                    alt="Event Image">
                                </img>
                                <Media.Body>
                                    <h4 className="mb-3">{this.state.eventDetails.title}</h4>
                                    <h6 className="mb-3">{this.state.eventDetails.subTitle}</h6>
                                    <p><h6>
                                        <b>Start Date & Time</b>: {new Date(this.state.eventDetails.dateStart).toLocaleDateString([], { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })} at {changeTime(this.state.eventDetails.dateStart)}
                                    </h6></p>
                                    <p><h6>
                                    <b>End Date & Time</b>: {new Date(this.state.eventDetails.dateEnd).toLocaleDateString([], { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })} at {changeTime(this.state.eventDetails.dateEnd)}
                                    </h6></p>
                                    <p><h6>
                                        Type:{eventsTypeMapping(this.state.eventDetails.type)}
                                    </h6></p>
                                </Media.Body>
                            </Media>
                            <hr />
                            <Col md={12}>
                                {this.state.authenticated && this.state.isUserRegistered ?<Button className="btn btn-primary" disabled>Registered</Button>:this.state.authenticated ? <Link className="btn btn-primary" data-toggle="modal" data-target="#staticBackdrop">Register</Link> :
                                    <Link className="btn btn-primary" onClick={this.registerToEvents}>Register</Link>}
                                <div className="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                    <div className="modal-dialog">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title textColor" id="staticBackdropLabel">Thank you for registering to the event. We will share the details to join/attend the event on your registered email id.</h5>
                                                <Button type="Button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </Button>
                                            </div>
                                            <div className="modal-footer">
                                                <Button type="Button" className="btn btn-secondary" data-dismiss="modal">Cancel</Button>
                                                <Button type="Button" className="btn btn-primary" data-dismiss="modal" onClick={this.registerToEvents} >Submit</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                               {this.state.authenticated && this.state.isUserRegistered && this.state.eventDetails.link ? <a href={this.state.eventDetails.link} target="_blank"> <Button className="btn btn-primary ml-2">Join</Button></a>:null}
                            </Col>

                            <Table bordered className="shadow mt-3">
                                <thead className="thead-color">
                                    <th><h5>About The Event</h5></th>
                                </thead>
                                <tbody>
                                    <td>
                                        <h6 className="space">{this.state.eventDetails.about}
                                        </h6>
                                    </td>
                                </tbody>
                            </Table>

                            <Table bordered className="shadow mt-3">
                                <thead className="thead-color">
                                    <th><h5>Terms & Condition</h5></th>
                                </thead>
                                <tbody>
                                    <td>
                                        <h6 className="space">{this.state.eventDetails.tnc}
                                        </h6>
                                    </td>
                                </tbody>
                            </Table>

                            <div className="container-fluid mt-4">
                                {this.state.panelist.length != 0 ?
                                    <Fragment>
                                        <Row className="heading">
                                            <Col sm={12} md={12} lg={12}>
                                                <div className="text-center">
                                                    <h3>{this.state.panelist.length != 0 ? "Panelist" : null}</h3>
                                                </div>
                                                <Slider list={this.state.panelist} />

                                            </Col>
                                        </Row>
                                        <hr />
                                    </Fragment>
                                    : null
                                }
                                {this.state.moderators.length != 0 ?
                                    <Fragment>
                                        <Row className="heading">
                                            <Col sm={12} md={12} lg={12}>
                                                <div className="text-center">
                                                    <h3>Moderators</h3>
                                                </div>
                                                <Slider list={this.state.moderators} />
                                            </Col>
                                        </Row>
                                        <hr />
                                    </Fragment>
                                    : null
                                }
                                {this.state.sponsorers.length != 0 ?
                                    <Fragment>
                                        <Row className="heading">
                                            <Col sm={12} md={12} lg={12}>
                                                <div className="text-center">
                                                    <h3>{this.state.sponsorers.length != 0 ? "Sponsorers" : null}</h3>
                                                </div>
                                                <Slider list={this.state.sponsorers} />
                                            </Col>
                                        </Row>
                                        <hr />
                                    </Fragment>
                                    : null
                                }

                                {this.state.partners.length != 0 ?
                                    <Fragment>
                                        <Row className="heading">
                                            <Col sm={12} md={12} lg={12}>
                                                <div className="text-center">
                                                    <h3>{this.state.partners.length != 0 ? "Partners" : null}</h3>
                                                </div>
                                                <Slider list={this.state.partners} />
                                            </Col>
                                        </Row>
                                        <hr className="my-5" />
                                    </Fragment>
                                    : null
                                }
                            </div>
                            {/* <Row> */}
                            {/* <Col lg={6} className="img-gallery">
                                    <h3 className='text-center'>Photo Gallery</h3>

                                    <Row>

                                        {this.state.images.map((image) => {
                                            return (
                                                <Col xs={6} sm={4} lg={4} className="mb-3">
                                                    <img width="150px" height="150px" src={image.original} />
                                                </Col>
                                            )
                                        })
                                        }
                                    </Row>

                                    <div className="float-right mb-4">
                                        <Link to={{ pathname: "/eg", id: this.state.id, banners: this.state.banners, eventDetails: this.state.eventDetails }}>  <Button className="primary">Explore...</Button></Link>
                                    </div>
                                </Col> */}
                            {/* <Col lg={6} className="vid-gallery mt-1"> */}
                            {/* <h3 className='text-center'>Video Gallery</h3> */}
                            {/* <div class="embed-responsive embed-responsive-16by9"> */}
                            {/* <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/WKupJuHYkmo&feature=youtu.be" allowfullscreen></iframe> */}
                            {/* <iframe width="853" height="480" src="https://www.youtube.com/embed/JzyrbnFOaZQ" frameborder="0" allowfullscreen ng-show="showvideo"></iframe> */}
                            {/* </div> */}
                            {/* <video width="540" height="360" controls>
                                        <source src="https://www.youtube.com/watch?v=WKupJuHYkmo&feature=youtu.be" type="video/mp4"></source>


                                    </video> */}

                            {/* <div className="float-right mb-4">
                                        <Link to={{ pathname: "/eg", id: this.state.id, banners: this.state.banners, eventDetails: this.state.eventDetails }}>  <Button className="primary">Explore...</Button></Link>
                                    </div> */}
                            {/* </Col> */}
                            {/* </Row> */}
                            <br></br>

                            <Row>
                                <Col xs={6} sm={10} lg={10}>

                                    <Button type="button" className="btn btn-primary float-left" onClick={this.handleBack}>Back</Button>

                                    {/* <Button type="button" className="btn btn-primary float-right">Participants</Button> */}
                                </Col>
                               {this.state.role.roleAdmin? <Col xs={6} sm={2} lg={2}><Link to={{ pathname: "/ep/"+ this.state.eventId }} ><Button type="button" className="btn btn-primary float-right">Participants</Button></Link></Col>:null}
                            </Row>
                        </Jumbotron>
                    </div>
                </Fragment>
            </Container>
        );
    }
}


export default EventDetails;