import React, { Component } from 'react';
// eslint-disable-next-line
import { Link } from 'react-router-dom';
// eslint-disable-next-line
import { Container, Row, Col, Button, Table } from 'react-bootstrap';
import EmptyJumbotron from '../common/EmptyJumbotron';
import './Specialization.css';
import { getSpecializationList, deleteSpecialization } from '../util/APIUtils';
import Alert from 'react-s-alert';
import Edit from '@material-ui/icons/Edit';
import LoadingIndicator from '../common/LoadingIndicator';
import DeleteOutlineSharpIcon from '@material-ui/icons/DeleteOutlineSharp';
import AddSharpIcon from '@material-ui/icons/AddSharp';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { getRole } from '../util/functionUtils';




export default class Specialization extends Component {

  constructor(props) {
    super(props);
    this.state = {
      spList: [],
      loading: true,
      totalElement: 0,
      pageNumber: 0,
      pageSize: 20,
      colorArray: ['dark', 'light'],
    };
    this.loadSp = this.loadSp.bind(this);
    this.loadMore = this.loadMore.bind(this);
    // this.loadImage = this.loadImage.bind(this)
  }

  handleDelete(event, value) {
    const deleteSpecializationRequest = Object.assign({}, value);
    deleteSpecialization(deleteSpecializationRequest)
      .then(response => {
        Alert.success("Specialisation deleted successfully.");
        this.loadSp();
      }).catch(error => {
        Alert.error((error && error.message) || 'Something went wrong, please try again later!');
      })
  }
  async loadMore() {
    await this.setState({
      pageNumber: this.state.pageNumber + 1
    })
    console.log(" in specilization", this.state.pageNumber)
    getSpecializationList(this.state.pageNumber, this.state.pageSize)
      .then(response => {
        this.setState({
          spList: [...this.state.spList, ...response.content],
          loading: false,
        });
      }).catch(error => {
        this.setState({
          loading: false
        });
        Alert.error((error && error.message) || 'Something went wrong, please try again later!');
      });
  }

  loadSp() {
    getSpecializationList(this.state.pageNumber, this.state.pageSize)
      .then(response => {
        this.setState({
          spList: response.content,
          loading: false,
          role: getRole(),
          totalElement: response.totalElements
        });
      }).catch(error => {
        this.setState({
          loading: false
        });
        Alert.error((error && error.message) || 'Something went wrong, please try again later!');
      });
  }
  componentDidMount() {
    this.loadSp();
    window.scrollTo(0, 0)

  }

  render() {
    if (this.state.loading) {
      return <LoadingIndicator />
    }
    return (
      <Container>
        <div className="specialization-container container-fluid" id="top">
          <Row className="welcome text-center mb-5">
            <Col sm={12}>
              <h3>Specialisation</h3>
            </Col>
            <hr />
          </Row>
          <div className="heading">
            <Link to="/admin" className="float-right btn btn-primary"><ArrowBackIcon /></Link>
            <h2 className="text-center"></h2>
            <Link className="btn btn-primary" to="/spform"><AddSharpIcon /></Link>
          </div>
          <Table id="specializationList">
            {this.state.spList.length === 0 ? null :
              <thead>
                <tr id="specializationListHead">
                  <th className="text-center">Code</th>
                  <th className="text-center">Description</th>
                  {this.state.role.roleAdmin ? <th className="text-center"> Edit</th> : null}
                  {this.state.role.roleAdmin ? <th className="text-center">Delete</th> : null}
                </tr>
              </thead>
            }
            <tbody>
              {this.state.spList.map((value) => {
                if (value.isActive)
                  return (
                    <tr>
                      <td className="text-center" data-label="Code"><Link to={{ pathname: "/dc", state: { spCode: value.code, selectedSp: { label: value.dislayStr, value: value } } }}>{value.code}</Link></td>
                      <td className="text-center" data-label="Description"> {value.dislayStr}</td>
                      {this.state.role.roleAdmin ? <td className="text-center" data-label="Edit"><Button variant="outlined"><Link to={{ pathname: "/spform", editspecialization: value }} style={{ textDecoration: 'none' }}><Edit color="action" /></Link></Button></td> : null}
                      {this.state.role.roleAdmin ? <td className="text-center" data-label="Delete"> <Button data-toggle="modal" data-target="#staticBackdrop" variant="outlined"><DeleteOutlineSharpIcon color="secondary" /></Button>
                        <div className="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                          <div className="modal-dialog">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5 className="modal-title textColor" id="staticBackdropLabel">Are you sure you want to delete ?</h5>
                                <Button type="Button" className="close" data-dismiss="modal" aria-label="Close">
                                  <span aria-hidden="true">&times;</span>
                                </Button>
                              </div>
                              <div className="modal-body textColor">
                                There may be doctors associated with this Qualification !
                              </div>
                              <div className="modal-footer">
                                <Button type="Button" className="btn btn-secondary" data-dismiss="modal">Cancel</Button>
                                <Button type="Button" className="btn btn-primary" data-dismiss="modal" onClick={(event) => this.handleDelete(event, value)}>Delete</Button>
                              </div>
                            </div>
                          </div>
                        </div></td> : null}
                    </tr>
                  )
              })}
            </tbody>
          </Table>

          {this.state.spList.length >= 20 ? this.state.spList.length < this.state.totalElement ?
            <Button className="loadMore" onClick={this.loadMore}>Load more...</Button> : <Button className="loadMore" href="#top">Back to top</Button> : null
          }
        </div>
        {this.state.spList.length === 0 ? <h4 className="text-center mb-5">No specialisation is added !</h4> : null}
        <EmptyJumbotron />
      </Container>
    )
  }
}
