import React, { Component } from "react";
import { Row, Col, Button, Container, Form } from "react-bootstrap";
import RemoveSharpIcon from '@material-ui/icons/RemoveSharp';
import Select from 'react-select';
import { getCountryList, getStateList, getCityList, getPincodeList } from '../util/ReferentialAPIUtils';


export default class AddressForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      countryList: [],
      states: [],
      cities: [],
      pincodes: [],
      house: this.props.editAddress ? this.props.editAddress.house : '',
      street1: this.props.editAddress ? this.props.editAddress.street1 : '',
      street2: this.props.editAddress ? this.props.editAddress.street2 : '',
      street3: this.props.editAddress ? this.props.editAddress.street3 : '',
      selectedCountry: null,
      selectedState: null,
      selectedCity: null,
      selectedPincode: null,
      countryCode: null,
      stateCode: null,
      cityCode: null,
    }
  }

  loadCountry() {
    getCountryList()
      .then(response => {
        this.setState({
          countryList: response.map((data) => {
            return { label: data.dislayStr, value: data }
          })
        })
      })

  }
  componentDidMount() {
    this.loadCountry();

  }

  handleCountryChange = (selectedOption) => {
    const countryCode = selectedOption.value.code
    this.setState({
      countryCode: countryCode,
      selectedCountry: selectedOption,
      selectedState: null,
      selectedCity: null,
      selectedPincode: null,
      cities: [],
      pincodes: []
    })

    this.props.parentCallback('countryCode', countryCode, this.props.type)
    this.props.parentCallback('stateCode', '', this.props.type)
    this.props.parentCallback('cityCode', '', this.props.type)
    this.props.parentCallback('pincode', '', this.props.type)

    getStateList(countryCode)
      .then(response => {
        this.setState({
          states: response.map(data => {
            return { label: data.dislayStr, value: data }
          })
        })
      })

  }

  handleStateChange = (selectedOption) => {
    const stateCode = selectedOption.value.code
    this.setState({
      stateCode: stateCode,
      selectedState: selectedOption,
      selectedCity: null,
      selectedPincode: null,
      pincodes: []
    })

    this.props.parentCallback('stateCode', stateCode, this.props.type)
    this.props.parentCallback('cityCode', '', this.props.type)
    this.props.parentCallback('pincode', '', this.props.type)

    getCityList(this.state.countryCode, stateCode)
      .then(response => {
        this.setState({
          cities: response.map(data => {
            return { label: data.dislayStr, value: data }

          })
        })
      })
  }

  handleCityChange = (selectedOption) => {
    const cityCode = selectedOption.value.code
    this.setState({
      cityCode: cityCode,
      selectedCity: selectedOption,
      selectedPincode: null
    })
    this.props.parentCallback('cityCode', cityCode, this.props.type)
    this.props.parentCallback("pincode", '', this.props.type)

    getPincodeList(this.state.countryCode, this.state.stateCode, cityCode)
      .then(response => {
        this.setState({
          pincodes: response.map(data => {
            return { label: data.code, value: data }

          })
        })
      })
  }

  handlePincodeChange = (selectedOption) => {
    const pincode = selectedOption.value.code
    this.setState({
      selectedPincode: selectedOption
    })
    this.props.parentCallback("pincode", pincode, this.props.type)
  }

  handleInputChange = (event) => {
    const inputName = event.target.name
    const inputValue = event.target.value

    this.setState({
      [inputName]: inputValue
    })
    this.props.parentCallback(inputName, inputValue, this.props.type)
  }

  render() {
    return (
      <>
        <Col lg={6} className="mb-2">
          <Form.Control name="house" value={this.state.house} placeholder="House number" onChange={this.handleInputChange} required />
        </Col>
        <Col lg={6} className="mb-2">
          <Form.Control name="street1" value={this.state.street1} placeholder="Street1" onChange={this.handleInputChange} required />
        </Col>
        <Col lg={6} className="mb-2">
          <Form.Control name="street2" value={this.state.street2} placeholder="Street2" onChange={this.handleInputChange} />
        </Col>
        <Col lg={6} className="mb-2">
          <Form.Control name="street3" value={this.state.street3} placeholder="Street3" onChange={this.handleInputChange} />
        </Col>
        <Col lg={3} className="mb-2">
          <Select
            options={this.state.countryList}
            value={this.state.selectedCountry}
            onChange={this.handleCountryChange}
            placeholder="Country"
            required={true}
          />
        </Col>
        <Col lg={3} className="mb-2">
          <Select
            options={this.state.states}
            value={this.state.selectedState}
            onChange={this.handleStateChange}
            placeholder="State"
          />
        </Col>
        <Col lg={3} className="mb-2">
          <Select
            options={this.state.cities}
            value={this.state.selectedCity}
            onChange={this.handleCityChange}
            placeholder="City"
          />
        </Col>
        <Col lg={3} className="mb-2">
          <Select
            options={this.state.pincodes}
            value={this.state.selectedPincode}
            onChange={this.handlePincodeChange}
            placeholder="Pincode"
          />
        </Col>
      </>
    );
  }
}
