import React, { Component } from 'react';
import './Users.css';
import { Container, Row, Col, Form, Button, Table, Popover, OverlayTrigger } from 'react-bootstrap';
import Alert from 'react-s-alert';
import { Link } from 'react-router-dom';
import EmptyJumbotron from '../common/EmptyJumbotron';
import { loadUsers, searchUserBy, updateUserStatus } from '../util/APIUtils'
import SearchIcon from '@material-ui/icons/Search';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import googleLogo from '../img/google-logo.png';
import Select from 'react-select';
import SettingsIcon from '@material-ui/icons/Settings';
import RefreshTwoToneIcon from '@material-ui/icons/RefreshTwoTone';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import LoadingIndicator from '../common/LoadingIndicator';



class Users extends Component {

    constructor() {
        super()
        this.state = {
            usersList: [],
            userIds: [],
            action: '',
            name: '',
            email: '',
            phone: '',
            pageNumber: 0,
            pageSize: 20,
            totalElements: 0,
            isLocked: '',
            status: '',
            emailVerified: '',
            statusList: [{ label: "Active", value: "ACT" }, { label: "Inactive", value: "DEACT" }],
            lockedList: [{ label: "Lock", value: true }, { label: "Unlock", value: false }],
            emailVerifiedList: [{ label: "Verified", value: true }, { label: "Not verified", value: false }],
            selectedEmail: null,
            selectedLock: null,
            selectedStatus: null,
            loading: true,

        }
        this.loadMore = this.loadMore.bind(this)
    }

    handleNameChange = (event) => {
        const inputName = event.target.name
        const inputValue = event.target.value

        this.setState({
            [inputName]: inputValue
        })
    }

    handleDropdownChange = (selectedOption, inputName, selected) => {
        const inputValue = selectedOption.value
        this.setState({
            [inputName]: inputValue,
            [selected]: selectedOption
        })

    }
    handleAction = (event, id) => {
        if (event.target.checked) {
            this.setState({
                userIds: [...this.state.userIds, id]
            })
        }
        else {
            this.setState({
                userIds: this.state.userIds.filter((currIndex) => currIndex !== id)
            })
        }
    }

    popover = () => {
        return (
            <Popover id="popover-basic" style={{ width: 140, height: 270 }}>
                <Popover.Title as="h3">Actions</Popover.Title>
                <Popover.Content>
                    <Row>
                        <Col md={12}>
                            <Button className="m-2 btn-danger" onClick={() => this.updateStatusHandler("Lock")}>Lock</Button>
                        </Col>
                        <Col md={12}>
                            <Button className="m-2 btn-outline" onClick={() => this.updateStatusHandler("UnLock")}>Unlock</Button>
                        </Col>
                        <Col md={12}>
                            <Button className="m-2 btn-outline" onClick={() => this.updateStatusHandler("Activate")}>Activate</Button>
                        </Col>

                        <Col md={12}>
                            <Button className="m-2 btn-danger" onClick={() => this.updateStatusHandler("DeActivate")}>Deactivate</Button>
                        </Col>
                    </Row>
                </Popover.Content>
            </Popover>
        );
    }

    updateStatusHandler = (actionType) => {
        if (this.state.userIds.length == 0) {
            Alert.error("Please select at least one user!")
        } else {
            const updateRequest = { "userIds": this.state.userIds, "action": actionType }
            updateUserStatus(updateRequest)
                .then(response => {
                    Alert.success("updated successfully")
                    // window.location.reload()
                })
                .catch(error => {
                    Alert.error((error && error.message) || 'Something went wrong, please try again later!');
                })
        }
    }
    handleSearch = () => {
        searchUserBy(this.state.name, this.state.email, this.state.phone, this.state.status, this.state.isLocked, this.state.emailVerified)
            .then(response => {
                this.setState({
                    usersList: response.content,
                    totalElements: response.totalElements
                });
            }).catch(error => {
                Alert.error((error && error.message) || 'Something went wrong, please try again later!');
            });
    }

    usersList() {
        loadUsers(this.state.pageNumber, this.state.pageSize)
            .then(response => {
                this.setState({
                    usersList: response.content,
                    totalElements: response.totalElements,
                    loading: false
                })
            })
    }

    async loadMore() {
        await this.setState({
            pageNumber: this.state.pageNumber + 1
        })
        loadUsers(this.state.pageNumber, this.state.pageSize)
            .then(response => {
                this.setState({
                    usersList: [...this.state.usersList, ...response.content],
                });
            }).catch(error => {
                Alert.error((error && error.message) || 'Something went wrong, please try again later!');
            });
    }

    clearSearch = () => {
        this.usersList()
        this.setState({
            name: '',
            email: '',
            phone: '',
            status: '',
            isLocked: '',
            emailVerified: '',
            selectedEmail: null,
            selectedStatus: null,
            selectedLock: null
        })
    }

    componentDidMount() {
        this.usersList()
        window.scrollTo(0, 0)
    }

    render() {

        if (this.state.loading) {
            return <LoadingIndicator />
        }

        return (
            <Container>
                <div className="user-container container-fluid">
                    <Row className="welcome text-center mb-5">
                        <Col sm={12}>
                            <h1 className="display-4">Users</h1>
                        </Col>
                        <hr />
                    </Row>
                    <Row>
                        <Col md={10}>
                            <Row>
                                <Col md={4} className="mb-2">
                                    <Form.Control data-browse="Button text" type="text" name="name" value={this.state.name} placeholder="Search by name" onChange={this.handleNameChange} />
                                </Col>
                                <Col md={4} className="mb-2">
                                    <Form.Control data-browse="Button text" type="email" name="email" value={this.state.email} placeholder="Search by email" onChange={this.handleNameChange} />
                                </Col>
                                <Col md={4} className="mb-2">
                                    <Form.Control data-browse="Button text" type="text" name="phone" value={this.state.phone} placeholder="Search by phone" onChange={this.handleNameChange} maxLength="10" />
                                </Col>
                                <Col md={4} className="mb-2">
                                    <Select
                                        placeholder="Status"
                                        value={this.state.selectedStatus}
                                        options={this.state.statusList}
                                        onChange={(selectedOption) => this.handleDropdownChange(selectedOption, 'status', 'selectedStatus')}
                                    />
                                </Col>
                                <Col md={4} className="mb-2">
                                    <Select
                                        placeholder="Locked"
                                        name="lock"
                                        value={this.state.selectedLock}
                                        options={this.state.lockedList}
                                        onChange={(selectedOption) => this.handleDropdownChange(selectedOption, 'isLocked', 'selectedLock')} /
                                    >
                                </Col>
                                <Col md={4} className="mb-2">
                                    <Select
                                        placeholder="Email verified"
                                        name="email"
                                        value={this.state.selectedEmail}
                                        options={this.state.emailVerifiedList}
                                        onChange={(selectedOption) => this.handleDropdownChange(selectedOption, 'emailVerified', 'selectedEmail')}
                                    />
                                </Col>
                            </Row>
                        </Col>

                        <Col md={2}>
                            <Row>
                                <Col xs={3} sm={2} md={12} lg={12} className="mb-2">
                                    <Button onClick={this.clearSearch}> <RefreshTwoToneIcon /></Button>
                                </Col>
                                <Col xs={3} sm={2} md={12} lg={12} className="mb-2">
                                    <Button onClick={this.handleSearch}> <SearchIcon /></Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <br /><br />
                    <Link to={{ pathname: "/admin" }}> <Button type="button" className="btn btn-primary mb-3" ><ArrowBackIcon /></Button></Link><br />

                    <Row className="hide-button">
                        <Col>
                            <Button className="m-2 btn-danger" onClick={() => this.updateStatusHandler("Lock")}>Lock</Button>
                        </Col>
                        <Col>
                            <Button className="m-2 btn-outline" onClick={() => this.updateStatusHandler("UnLock")}>Unlock</Button>
                        </Col>
                    </Row>
                    <Row className="hide-button">
                        <Col>
                            <Button className="m-2 btn-outline" onClick={() => this.updateStatusHandler("Activate")}>Activate</Button>
                        </Col>
                        <Col>
                            <Button className="m-2 btn-danger" onClick={() => this.updateStatusHandler("DeActivate")}>Deactivate</Button>
                        </Col>
                    </Row>
                    <b>Total number of user(s) :{this.state.totalElements}</b>
                    <Table hover className="table-font-size shadow" id="usersList">
                        <thead className="thead-color">
                            <tr id="usersListHead">
                                <th >Id</th>
                                <th>Photo</th>
                                <th>Name</th>
                                <th>Gender</th>
                                <th>Email</th>
                                <th>Email Verified</th>
                                <th>Phone</th>
                                <th>Provider</th>
                                <th>Created On</th>
                                <th>Role</th>
                                <th>Status</th>
                                <th>Locked</th>
                                <th>
                                    <OverlayTrigger trigger="click" placement="auto" overlay={this.popover()}>
                                        <SettingsIcon />
                                    </OverlayTrigger>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.usersList.map(value => {
                                return (
                                    <tr>
                                        <td data-label="Id">{value.id}</td>
                                        <td data-label="Photo">

                                            {
                                                value.imageUrl ? (
                                                    <div className="profile-avatar">
                                                        <img src={value.imageUrl} alt={value.name} />
                                                    </div>
                                                ) : (
                                                    <div className="text-avatar">
                                                        <span>{value.name && value.name[0]}</span>
                                                    </div>
                                                )
                                            }
                                        </td>
                                        <td data-label="Name">{value.name}</td>
                                        <td data-label="Gender">{value.gender}</td>
                                        <td data-label="Email">{value.email}</td>
                                        <td className="text-center" data-label="Email Verified">{value.emailVerified ? <CheckCircleIcon style={{ color: 'green' }} /> : <CancelIcon style={{ color: 'red' }} />}</td>
                                        <td data-label="Phone">{value.phone}</td>
                                        <td className="text-center" data-label="Provider">
                                            {
                                                value.provider === 'local' ? (
                                                    <div className="text-avatar">
                                                        <span>BI</span>
                                                    </div>
                                                ) : value.provider === 'google' ?
                                                    <img src={googleLogo} alt="Google" height="40px" width="40px" />
                                                    : null
                                            }



                                        </td>
                                        <td data-label="Created On">{value.createdOn.slice(0, 10)}</td>
                                        <td data-label="Role">
                                            {value.roles.map(role => {
                                                if (role === 'ROLE_USER')
                                                    return (<><i class="fa fa-user" aria-hidden="true"> </i> {" "}</>)
                                                else if (role === 'ROLE_DOCTOR')
                                                    return (<><i class="fa fa-user-md" aria-hidden="true"></i> {" "}</>)
                                                else if (role === 'ROLE_ADMIN')
                                                    return (<><i class="fa fa-user-plus" aria-hidden="true"></i> {" "}</>)
                                                else
                                                    return null
                                            })}
                                        </td>
                                        <td data-label="Status">{value.status === 'ACT' ? <CheckCircleIcon style={{ color: 'green' }} /> : <CancelIcon style={{ color: 'red' }} />}</td>
                                        <td data-label="Locked">{value.isLocked ? <CheckCircleIcon style={{ color: 'green' }} /> : <CancelIcon style={{ color: 'red' }} />}</td>
                                        <td data-label="Select"><Form.Check type="checkbox" onChange={(event) => this.handleAction(event, value.id)} /></td>
                                    </tr>
                                )
                            })}

                        </tbody>
                    </Table>
                    <Link to={{ pathname: "/admin" }}> <Button type="button" className="btn btn-primary float-left" >Back</Button></Link><br /><br />
                    {this.state.usersList.length >= 20 ?
                        this.state.usersList.length < this.state.totalElements ?
                            <Button variant="primary" className=" float-right" size="sm" onClick={this.loadMore}>Load more...</Button> :
                            <Button variant="primary" className=" loadMore" size="sm" href="#top">Back to top</Button> : null
                    }
                    {/* <EmptyJumbotron /> */}
                </div>
            </Container>
        )
    }
}

export default Users;


