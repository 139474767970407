import React, { Component } from 'react';
import { Jumbotron, Table } from 'react-bootstrap';
import './TermsConditions.css';

export default class TermsConditions extends Component {
	componentDidMount(){
		window.scrollTo(0, 0)
	}
	render() {
		return (
			<div className="container-fluid">
				<Jumbotron className="row tncjumbotron justify-content-center">
					<div>
						<div>
							<h1 className="justify-content-center text-white">Terms & Conditions</h1>

							<a href="https://www.binaryintuition.com" target="_blank"></a>
						</div>
					</div>
				</Jumbotron>

				<div className="container mb-2">
					<Table className="shadow" striped bordered hover variant="light">
						<div className="justify-content-center para">
							<p>The website / mobile application / computer application from BI Health (hereinafter referred to as “Application” or “App”), is owned by BSUnprecedented Intuition Tech Services Llp and it's registered address is Flat No. I 004, Bren Unity Apartment, Chinnappanahalli Main Road, Bangalore KA 560037 India.</p>
							<p>For the purpose of these Terms of Use, along with any amendments to the same, and wherever the context so requires “You” or “User” shall mean any natural or legal person who has agreed to become a user of the Application by registering on the App. The term “We”, “Us”, “Our” shall mean BI Health. “Agreement” shall mean and refer to this Terms of Service, including any amendments that may be incorporated into it and the Terms of Service, and other Policies available on our Website, <a href="http://binaryintuition.com" target="_blank">binaryintuition</a>. “Third Party” shall mean and refer to any individual(s), company or entity apart from the User and BI Health.</p>
							<p>The headings of each section in this Agreement are only for the purpose of organising the various provisions under this Agreement in an orderly manner. These headings shall not be used by either party to interpret the provisions contained with them in any manner. Further, the headings have no legal or contractual value. The terms used herein shall have the same meaning ascribed to them in the Terms of Service, Privacy Policy, and other policies available on the Website <a href="http://binaryintuition.com" target="_blank">binaryintuition</a> (“Website / Site”).</p>
							<p>By using the website / App, You accept and agree to be bound by this Agreement, the Privacy Policy, as well as rules, guidelines, policies, terms, and conditions applicable to any service that is provided by this Application, including the Terms and Polices on the Website that shall be deemed to be incorporated into this Terms of Use and shall be considered as part and parcel of this Terms of Use. Your use of Our Application is evidence that You have read and agreed to be contractually bound by these Terms of Service and our Privacy Policy. Please read the terms carefully. The use of this Application by You is governed by this policy and any policy so mentioned by terms of reference.</p>
							<p>If you do not agree with any of these terms, please discontinue using the Website / Application.</p>
							<p>We hold the sole right to modify the Terms of Service without prior permission from You or providing notice to You. The relationship creates on You a duty to periodically check the Terms of Service and stay updates on its requirements. If You continue to use the Application or avail of its services following such change, it is deemed as consent by You to the so amended policies. Your continued use of the Application is conditioned upon your compliance with the Terms of Service, including but not limited to compliance with the Terms of Service even after alterations, if any.</p>
							<br></br>
							<h4>Restrictions on Use</h4>
							<p>To fully avail the services of the Application and use it, you must login to the website and update your personal information in user profile. Without limitation to the foregoing, in the event you are barred from undertaking legally binding obligations under the Indian Contract Act, 1872, or are for any reason, unable to provide ‘Consent’ as per the Information Technology (Reasonable security practices and procedures and sensitive personal data or information) Rules, 2011, you are not eligible to register for, use or avail the services available on the Application.</p>
							<br></br>
							<h4>Fair usage policy</h4>
							<p>The Services of BI Health cannot be used for personal gains or in conjunction with any commercial activity including but not limited to using on the application. BI Health reserves the right to limit, suspend, disable or discontinue your Service in the event that it is discovered that the Service is being used in conjunction with any commercial activity including but not limited to using on the application or for commercial gains or misuse of service for not intended purpose.</p>
							<br></br>
							<h4>Terms of Use</h4>
							<p>The use of free consultations via our offer plan shall be restricted on a daily, monthly, weekly and yearly basis. Capping of which is subjected to change.
							The application is constantly reviewed by our internal teams to ensure we deliver the right benefits to our customers and avoid any mis-use of our services.
							To read more about the policy visit, Your Gold/Gold Store page in our applications.
							Without limiting any other provisions of these Terms, you may not use this Application for any purpose that is unlawful or prohibited by these Terms and/or any applicable additional terms. Your access of this Application may be terminated immediately, in our sole discretion, with or without notice, if you fail to comply with any provision of these Terms and/or additional terms, or for any other reason, or no reason.</p>
							<br></br>
							<h4>Intermediary Application</h4>
							<p>The Website is a platform that Patients and Practitioners utilize to meet and interact with one another for their transactions. We act as aggregators who display to the End User / Patients Practitioners best suited to your needs, based on the Content provided to us / updated on the Website / updated on the Mobile Application, or applications built on it, by Practitioners and End Users, including without limitation Content relating to area of Practitioner area of specialization, expertise, experience, availability, and geographic location.</p>
							<p>However, should a User choose to proceed with a Practitioner displayed / identified / listed by us, for any purpose whatsoever, including without limitation, posing a question, seeking medical advisory service, exchanging Patient information, charts, DICOM Images, seeking appointments, we are not a party to such interaction and take no liability arising from such communication.</p>
							<br></br>
							<h4>Communication</h4>
							<p>By using this Application, it is deemed that You have consented to receiving Services & marketing/promotional related telephonic calls, SMSs and/or emails from us and other Users. Such communications shall be sent to You on the telephone number and/or email id provided by You for the use of this Application which are subject to our Privacy Policy. Such communication by Us is for purposes that inter alia include clarification calls, marketing calls and promotional calls.</p>
							<p>In the event you use the Appication to book appointments with any Practitioner, We may send booking confirmation, cancellation, payment confirmation, refund status, schedule change or any such other information relevant for the transaction, via SMS, Chat, or by voice call on the contact number provided by You at the time of making the appointment. This includes contacting you through information received from other parties. This consent to be contacted is for purposes that include and are not limited to clarification calls and marketing and promotional calls.</p>
							<br></br>
							<h4>Practitioner Display</h4>
							<p>BI Health collects, directly or indirectly, and displays on the Application, relevant information regarding the profile and practice of the Practitioners listed on the Application, such as their specialization, qualification, area of practice, experience, fees, location, visiting hours, and similar details. Although we take reasonable efforts to ensure that such information is accurate and updated at frequent intervals, we cannot be held liable for any inaccuracies or incompleteness represented on the Application pertaining to Practitioner Content.
							It is hereby expressly clarified that, the Information that you obtain or receive from BI Health, is for informational and User’s personal purposes only. We make no guarantees, representations or warranties, whether expressed or implied, with respect to professional qualifications, quality of work, expertise or other information pertaining to the Practitioners provided on the Website.
							In no event shall we be liable to you or anyone else for any decision made or action taken by you in reliance on such information. BI Health is not meant to be a substitute for Emergency Medical Care.</p>
						</div>
					</Table>
				</div>
			</div >
		)
	}
}