import React, { Component } from 'react'
import './ShareByList.css'
import { Row, Col, Button, Container, Table } from 'react-bootstrap';
import Alert from 'react-s-alert';
import { getShareByList, unShareRecord } from '../util/healthAPIUtils'
import { Link } from 'react-router-dom'
import { getCurrentUserId, getRole, getDateSafariHack } from '../util/functionUtils';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import LoadingIndicator from '../common/LoadingIndicator';


export default class ShareByList extends Component {
    constructor() {
        super()
        this.state = {
            accessingUserId: getCurrentUserId(),
            byOrWith: 'by',
            shareByList: [],
            pageNumber: 0,
            pageSize: 20,
            totalElements: 0,
            roles: getRole(),
            sharedWithType: '',
            loading: true,
        }
        this.loadMore = this.loadMore.bind(this);
    }

    async loadMore() {
        await this.setState({
            pageNumber: this.state.pageNumber + 1
        })
        getShareByList(this.state.accessingUserId, this.state.byOrWith, this.state.pageNumber, this.state.pageSize)
            .then(response => {
                this.setState({
                    shareByList: [...this.state.shareByList, ...response.content],
                    loading: false
                });
            }).catch(error => {
                this.setState({
                    loading: false
                });
                Alert.error((error && error.message) || 'Something went wrong, please try again later!');
            });
    }

    loadShareByList = () => {

        getShareByList(this.state.accessingUserId, this.state.byOrWith, this.state.pageNumber, this.state.pageSize)
            .then(response => {
                this.setState({
                    shareByList: response.content,
                    sharedWithType: response.content.sharedWithType,
                    totalElements: response.totalElements,
                    loading: false
                })
            })
            .catch(error => {
                this.setState({
                    loading: false
                })
                Alert.error((error && error.message) || 'Something went wrong, please try again later!');
            })
    }
    unShareRecord = (recordId) => {
        unShareRecord(recordId)
            .then(response => {
                Alert.success("medical record unshared successfully")
                this.loadShareByList();
            })
            .catch(error => {
                this.setState({
                    loading: false
                })
                Alert.error((error && error.message) || 'Something went wrong, please try again later!');
            })
    }

    componentDidMount() {
        this.loadShareByList()
        window.scrollTo(0, 0)
    }

    returnShareWithDetails = (sharedWithType, sharedWith) => {
        return (
            <>

                {sharedWithType === 'USER' ? <td style={{ paddingLeft: '80px' }}><i class="fa fa-user fa-2x" aria-hidden="true"></i>{" "} {sharedWith.name}</td>
                    : <td className="text-md-center" data-label="Shared With"><i class="fa fa-user-md fa-2x" aria-hidden="true"></i>{" "}{sharedWith.name}</td>}
            </>
        )

    }
    render() {
        if (this.state.loading) {
            return <LoadingIndicator />
        }
        return (
            <Container>
                <div className="share-by-container">
                    <Row className="welcome text-center mb-5">
                        <Col sm={12}>
                            <h3>Shared By Me</h3>
                        </Col>
                        <hr />
                    </Row>
                    <Link to="/health-records" className="custom-button float-left btn btn-primary"><ArrowBackIcon /></Link><br /><br />
                    <Table hover responsive className="shadow" id="shareByListTable">
                        {this.state.shareByList.length === 0 ? null :
                            <thead className="thead-color">
                                <tr id="shareByListTableHead">
                                    <th className="text-center">#</th>
                                    <th className="text-center">Shared With</th>
                                    <th className="text-center">Shared On</th>
                                    <th className="text-center">Sharing Ends On</th>
                                    <th className="text-center">Stop Sharing</th>
                                </tr>
                            </thead>
                        }
                        <tbody>
                            {this.state.shareByList.map((value) => {
                                return (
                                    <tr>
                                        <td className="text-md-center" data-label="#"><Link to={{ pathname: "record-detail/" + value.id, state: { recordId: value.id } }}>{value.id}</Link></td>

                                        {value.sharedWithType === 'USER' ?
                                            this.returnShareWithDetails(value.sharedWithType, value.sharedWithUser)
                                            : value.sharedWithType === 'DOCTOR' ?
                                                this.returnShareWithDetails(value.sharedWithType, value.sharedWithDoctor)
                                                : value.sharedWithType === 'EMAIL' ?
                                                    <td style={{ paddingLeft: "80px" }} ><i class="fa fa-envelope fa-2x" aria-hidden="true"></i>{" "}{value.sharedWithEmail}</td>
                                                    : null
                                        }
                                        <td className="text-md-center" data-label="Shared On">{(getDateSafariHack(value.createdAt)).toLocaleDateString([], {
                                            year: 'numeric', month: 'long', day: 'numeric'
                                            // , hour: "numeric", minute: "numeric", hour12: "true" 
                                        })}</td>
                                        <td className="text-md-center" data-label="Sharing Ends On">{(getDateSafariHack(value.sharingEndsOn)).toLocaleDateString([], {
                                            year: 'numeric', month: 'long', day: 'numeric'
                                            // , hour: "numeric", minute: "numeric", hour12: "true" 
                                        })}</td>
                                        <td className="text-md-center" data-label="Stop Sharing"><Button data-toggle="modal" data-target="#staticBackdrop"><i class="fa fa-trash-o" aria-hidden="true"></i></Button>
                                            <div className="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                <div className="modal-dialog">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title textColor" id="staticBackdropLabel">Are you sure you want to stop sharing?</h5>
                                                            <Button type="Button" className="close" data-dismiss="modal" aria-label="Close">
                                                                <span aria-hidden="true">&times;</span>
                                                            </Button>
                                                        </div>
                                                        <div className="modal-footer">
                                                            <Button type="Button" className="btn btn-secondary" data-dismiss="modal">Cancel</Button>
                                                            <Button type="Button" className="btn btn-primary" data-dismiss="modal" onClick={() => this.unShareRecord(value.id)}>Delete</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                    {this.state.shareByList.length >= 20 ?
                        this.state.shareByList.length < this.state.totalElements ?
                            <Button color="primary" className=" loadMore" size="sm" onClick={this.loadMore}>Load more...</Button> :
                            <Button color="primary" className=" loadMore" size="sm" href="#top">Back to top</Button> : null
                    }
                </div>
                <br />
                <br />
                {this.state.shareByList.length === 0 ? <h4 className="text-center mb-5">No health record has been shared by You !</h4> : null}

            </Container>
        )
    }
}
