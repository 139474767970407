import React, { Component } from 'react';
import StarRatings from 'react-star-ratings';
import { Link, Redirect } from 'react-router-dom';
import './DoctorProfile.css';
import { Container, Row, Col, Button, Media, Table } from 'react-bootstrap';
import EmptyJumbotron from '../common/EmptyJumbotron';
import { fetchDoctorDetails, getPresignedUrl, updateDoctorStatus } from '../util/APIUtils';
import { getRatingByProviderId } from '../util/feedbackAndRatingUtils';
import { validateImageType, validateImageSize, getRole, uploadFile, currentLoggedInUser, getDateSafariHack } from '../util/functionUtils';
import Alert from 'react-s-alert';
import LoadingIndicator from '../common/LoadingIndicator';
import { getAddressInfo } from '../util/ReferentialAPIUtils'


export default class DoctorProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            did: this.props.match.params.id,
            dd: null,
            loading: true,
            photoFile: null,
            status: "",
            role: getRole(),
            show: false,
            notValidImageType: false,
            notValidImageSize: false,
            countryName: '',
            cityName: '',
            stateName: '',
            fromProfile: this.props.history.location.state ? this.props.history.location.state.fromProfile : false,
            ratings: '',
            doctorUserId: ''
        };

    }

    handleInputChange = (event) => {
        event.preventDefault();
        const file = event.target.files[0]
        this.setState({
            photoFile: file,
            notValidImageSize: false,
            notValidImageType: false
        })


    };
    handleUploadPhotoBack = (event) => {
        event.preventDefault();
        this.loadDc();
    }

    handleShow = () => {
        this.setState({
            show: true
        })
    }
    handleClose = () => {
        this.setState({
            show: false
        })
    }

    handleUploadPhoto = (event) => {
        this.setState({
            loading: true
        })
        event.preventDefault();
        const ImageType = this.state.photoFile.name.split('.').pop().toLowerCase()
        const ImageSize = this.state.photoFile.size;
        if (validateImageType(ImageType)) {
            if (validateImageSize(ImageSize)) {
                getPresignedUrl(this.state.photoFile.name, this.state.did, this.state.photoFile.type)
                    .then(response => {
                        Alert.success('Doctor profile photo upload is in progress');
                        // this.handleUploadPhotoBack();
                        this.handleClose()
                        uploadFile(response, this.state.photoFile)
                            .then(response => {
                                this.setState({
                                    loading: false
                                })
                                Alert.success("Doctor Profile Photo uploaded successfully")
                                window.location.reload()
                            })
                            .catch(error => {
                                this.setState({
                                    loading: false
                                })
                                Alert.error((error && error.message) || 'Error uploading profile photo. Please try again later');
                            })
                    }).catch(error => {
                        this.setState({
                            loading: false
                        })
                        Alert.error(error && error.message);
                    })
            } else {
                this.setState({
                    loading: false,
                    notValidImageSize: true
                })
                Alert.error('File Size is bigger than 250 KB! Please upload smaller size photo.');
            }
        } else {
            this.setState({
                loading: false,
                notValidImageType: true
            })
            Alert.error('File Type is not supported. Please try JPG, JPEG, PNG.');
        }
    }

    loadDc = () => {
        fetchDoctorDetails(this.state.did)
            .then(response => {
                if (response.address && response.address[0]) {
                    getAddressInfo(response.address[0].countryCode, response.address[0].stateCode, response.address[0].cityCode)
                        .then((response) => {
                            this.setState({
                                countryName: response.country,
                                stateName: response.state,
                                cityName: response.city
                            })
                        })
                }
                this.setState({
                    dd: response,
                    status: response.status,
                });
                this.getRatings(response.id)
                this.setState({
                    doctorUserId: response.userId
                })
            }).catch(error => {
                this.setState({
                    loading: false
                });
                Alert.error((error && error.message) || 'Something went wrong, please try again later!');
            });
    }

    getRatings = (spIds) => {
        const ids = []
        ids.push(spIds)
        getRatingByProviderId(ids)
            .then(response => {
                this.setState({
                    ratings: response,
                    loading: false
                })
            }).catch(error => {
                this.setState({
                    loading: false
                });
                Alert.error((error && error.message) || 'Something went wrong, please try again later!');
            })
    }

    componentDidMount() {
        if (this.state.did) {
            this.loadDc();
        } else {
            this.setState({
                loading: false
            });
        }
        window.scrollTo(0, 0)
    }

    personalDetails = (label, value) => {
        if (label.toLowerCase() === 'address' && value !== null && value.length !== 0) {

            return (
                <Row className="mt-4">
                    <Col md={2} className="ml-1 mb-2">
                        {label}
                    </Col>
                    <Col md={4} className="ml-1 mb-2">
                        {value[0].house}, {value[0].street1}, {value[0].street2}<br />
                        {value[0].street3}, {this.state.cityName}, {this.state.stateName}<br />
                        {this.state.countryName}-{value[0].pincode}
                    </Col>
                </Row>
            )

        }

        return (
            <Row className="mt-4">
                <Col md={2} className="ml-1 mb-2">
                    {label}
                </Col>
                <Col md={4} className="ml-1 mb-2">
                    {value}
                </Col>
            </Row>
        )
    }

    updateDoctor = (status) => {
        updateDoctorStatus(this.state.did, status)
            .then(response => {
                if (status === 'APPROVED')
                    Alert.success("Doctor Approved Successfully!!!")
                else if (status === 'REJECTED')
                    Alert.success("Doctor Rejected Successfully!!!")
                else if (status === 'DEACTIVATED')
                    Alert.success("Doctor Deactivated Successfully!!!")
                this.setState({
                    status: status
                })
            })
            .catch(error => {
                Alert.error((error && error.message) || 'Something went wrong, please try again later!');
            })
    }

    popup = (msg, status, btnName) => {
        return (
            <div className="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title textColor" id="staticBackdropLabel">{msg}</h5>
                            <Button type="Button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </Button>
                        </div>
                        <div className="modal-footer">
                            <Button type="Button" className="btn btn-secondary" data-dismiss="modal">Back</Button>
                            <Button type="Button" className="btn btn-primary" data-dismiss="modal" onClick={() => this.updateDoctor(status)}>{btnName}</Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {


        if (!this.state.did) {
            return <Redirect
                to={{
                    pathname: "/dc",
                    state: { from: this.props.location }
                }} />;
        }

        if (this.state.loading) {
            return <LoadingIndicator />
        }

        return (
            <Container>
                {this.state.dd ?
                    <div className="doctor-profile-container container-fluid">
                        {currentLoggedInUser(this.state.dd.userId) || this.state.role.roleAdmin ?
                            <>
                                <i className="fa fa-camera fa-2x" aria-hidden="true" data-toggle="modal" data-target="#staticBackdrop4"> </i>
                                <div className="modal fade" id="staticBackdrop4" data-backdrop="static" data-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                    <div className="modal-dialog">
                                        <div className="modal-content">
                                            <div className="modal-body" show={this.state.show} onHide={this.handleClose}>
                                                <h5 className="modal-title" id="staticBackdropLabel">Upload Photo</h5><br />
                                                <div className="upload-photo ">
                                                    <i className="fa fa-camera" aria-hidden="true"></i>
                                                </div>
                                                <input type="file" name="file" onChange={this.handleInputChange} />
                                                <p>Allowed image type(JPG, JPEG, PNG) Max allowed size is 250 KB</p>
                                                {this.state.notValidImageType ? <p className="text-danger">Image type not supported</p> : null}
                                                {this.state.notValidImageSize ? <p className="text-danger">Image should be smaller than 250 KB</p> : null}
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-outline-danger" data-dismiss="modal" onClick={this.handleUploadPhotoBack}>Cancel</button>
                                                <button type="button" className="btn btn-outline-success" onClick={this.handleUploadPhoto}>Upload</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                            : null
                        }
                        {/* <Table bordered className="shadow mt-3">
                        <div className="container-fluid padding">
						<div className="row mt-2">
							<div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mb-4">
                            <img
                                    width={220}
                                    height={220}
                                    className="mr-3 media-img"
                                    src={this.state.dd.profileLink ? this.state.dd.profileLink : "../img/mac.png"}
                                    alt="doctor photo">
                                    </img>
                            </div> 
                            <div className="col-xl-8 col-lg-8 col-md-6 col-sm-6 col-12 mb-4">
                            <h4 className="mb-3">Dr. {this.state.dd.name}</h4>
                                    <br />
                                    <p><h6>
                                        <i class="fa fa-user-md fa-lg" aria-hidden="true"></i>
                                        {this.state.dd.specialisations ? this.state.dd.specialisations.map((value, index) => {
                                            if (index === 0)
                                                return " " + value.dislayStr
                                            else
                                                return "," + value.dislayStr
                                        }) : ""}
                                    </h6></p>
                                    <p><h6>
                                        <i class="fa fa-graduation-cap fa-lg" aria-hidden="true"></i>
                                        {this.state.dd.degrees.map((value, index) => {
                                            if (index === 0)
                                                return " " + value.dislayStr
                                            else
                                                return "," + value.dislayStr
                                        })}
                                    </h6></p>
                                    <p><h6><i class="fa fa-black-tie fa-lg" aria-hidden="true"></i><strong>{this.state.dd.experienceInYears}+</strong> years experience</h6></p>
                                    <p><h6>Follow Me:
                                        {this.state.dd.socialProfiles ?
                                            this.state.dd.socialProfiles.map(value => {
                                                if (value.type === 'GOOGLE')
                                                    return <a target="_blank" href={value.profileUrl}> <i class="fa fa-google fa-1x" aria-hidden="true"></i></a>
                                                else if (value.type === 'FACEBOOK')
                                                    return <a target="_blank" href={value.profileUrl}> <i class="fa fa-facebook-official fa-1x" aria-hidden="true"></i></a>
                                                else if (value.type === 'INSTAGRAM')
                                                    return <a target="_blank" href={value.profileUrl}> <i class="fa fa-instagram fa-1x" aria-hidden="true"></i></a>
                                                else if (value.type === "TWITTER")
                                                    return <a target="_blank" href={value.profileUrl}> <i class="fa fa-twitter fa-1x" aria-hidden="true"></i></a>
                                                else if (value.type === "LINKEDIN")
                                                    return <a target="_blank" href={value.profileUrl}> <i class="fa fa-linkedin-square fa-1x" aria-hidden="true"></i></a>
                                                else
                                                    return null
                                            })
                                            : null}
                                    </h6></p>
                            </div>
                        </div>
                    </div>
                    </Table> */}
                        <Media>
                            <img
                                width={220}
                                height={220}
                                className="mr-3 media-img"
                                src={this.state.dd.profileLink ? this.state.dd.profileLink : "../img/mac.png"}
                                alt="doctor photo">
                            </img>

                            <Media.Body>
                                <h4 className="mb-3">Dr. {this.state.dd.name}</h4>
                                <br />
                                <p><h6>
                                    <i class="fa fa-user-md fa-lg" aria-hidden="true"></i>
                                    {this.state.dd.specialisations ? this.state.dd.specialisations.map((value, index) => {
                                        if (index === 0)
                                            return " " + value.dislayStr
                                        else
                                            return "," + value.dislayStr
                                    }) : ""}
                                </h6></p>
                                <p><h6>
                                    <i class="fa fa-graduation-cap fa-lg" aria-hidden="true"></i>
                                    {this.state.dd.degrees.map((value, index) => {
                                        if (index === 0)
                                            return " " + value.dislayStr
                                        else
                                            return "," + value.dislayStr
                                    })}
                                </h6></p>
                                <p><h6><i class="fa fa-black-tie fa-lg" aria-hidden="true"></i><strong>{this.state.dd.experienceInYears}+</strong> years experience</h6></p>
                                <p><h6>Follow Me:
                                    {this.state.dd.socialProfiles ?
                                        this.state.dd.socialProfiles.map(value => {
                                            if (value.type === 'GOOGLE')
                                                return <a target="_blank" href={value.profileUrl}> <i class="fa fa-google fa-1x" aria-hidden="true"></i></a>
                                            else if (value.type === 'FACEBOOK')
                                                return <a target="_blank" href={value.profileUrl}> <i class="fa fa-facebook-official fa-1x" aria-hidden="true"></i></a>
                                            else if (value.type === 'INSTAGRAM')
                                                return <a target="_blank" href={value.profileUrl}> <i class="fa fa-instagram fa-1x" aria-hidden="true"></i></a>
                                            else if (value.type === "TWITTER")
                                                return <a target="_blank" href={value.profileUrl}> <i class="fa fa-twitter fa-1x" aria-hidden="true"></i></a>
                                            else if (value.type === "LINKEDIN")
                                                return <a target="_blank" href={value.profileUrl}> <i class="fa fa-linkedin-square fa-1x" aria-hidden="true"></i></a>
                                            else
                                                return null
                                        })
                                        : null}
                                </h6>
                                </p>
                                <p><h6>{Object.keys(this.state.ratings).length != 0 ? "Ratings: " : null}
                                    {Object.keys(this.state.ratings).length != 0 ?
                                        <>
                                            <StarRatings
                                                rating={this.state.ratings[this.state.did].avgRating}
                                                starRatedColor="grey"
                                                starHoverColor="grey"
                                                numberOfStars={5}
                                                starDimension="15px"
                                                name='rating'
                                            /><span>({this.state.ratings[this.state.did].totalRatings})</span>
                                        </>
                                        : null}
                                </h6>
                                </p>
                            </Media.Body>
                        </Media>
                        <Col md={12}>
                            {this.state.dd.scheduleavailable ? currentLoggedInUser(this.state.dd.userId) ? null : <><hr /><Link to={{ pathname: "/appointment-form", state: { doctorId: this.state.did, dd: this.state.dd } }} className="btn btn-success">Consult Now</Link></> : null}
                        </Col>
                        <Table bordered className="shadow mt-3">
                            <thead className="thead-color">
                                <th><h5>About Dr. {this.state.dd.name} </h5></th>
                            </thead>
                            <tbody>
                                <td>
                                    <h6 className="space">{this.state.dd.about}
                                    </h6>
                                </td>
                            </tbody>
                        </Table>
                        {this.state.dd.profExps && this.state.dd.profExps.length !== 0 ?
                            <Table responsive bordered className="shadow mt-3">
                                <thead className="thead-color">
                                    <th>Professional Experience</th>

                                </thead>
                                <tbody>
                                    <td>
                                        <Table id="doctorProfile">
                                            <thead>
                                                <tr id="doctorProfileHead">
                                                <th>Organisation</th>
                                                <th>Designation</th>
                                                <th>Start Date</th>
                                                <th>End Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.dd.profExps.map(value => {
                                                    return (
                                                        <tr>
                                                            <td data-label="Organisation">{value.organisation}</td>
                                                            <td data-label="Designation">{value.designation}</td>
                                                            <td data-label="Start Date">{value.fromDate ? getDateSafariHack(value.fromDate).toLocaleDateString([], { year: 'numeric', month: 'long', day: 'numeric' }) : ""}</td>
                                                            <td data-label="End Date">{value.endDate ? getDateSafariHack(value.endDate).toLocaleDateString([], { year: 'numeric', month: 'long', day: 'numeric' }) : ""}</td>
                                                        </tr>
                                                    )

                                                })}
                                            </tbody>
                                        </Table>
                                    </td>
                                </tbody>
                            </Table>
                            : null
                        }


                        {getRole().roleAdmin || currentLoggedInUser(this.state.dd.userId) ?
                            <Table bordered className="shadow mt-3 mb-5" >
                                <thead className="thead-color">
                                    <th>Personal Details</th>
                                </thead>
                                <tbody>
                                    <td>
                                        {this.personalDetails("Email", this.state.dd.email)}
                                        {this.personalDetails("Phone", this.state.dd.phone)}
                                        {this.state.dd.address && this.state.dd.address.length !== 0 ? this.personalDetails("Address", this.state.dd.address) : null}
                                    </td>
                                </tbody>
                            </Table>
                            : null
                        }
                        {this.state.fromProfile ?
                            <Link to="/profile"><Button>Back</Button></Link>
                            : <Link to="/dc"><Button>Back</Button></Link>
                        }
                        {this.state.role.roleAdmin ?
                            this.state.status === 'PENDING' ?
                                <>
                                    <Button className="btn btn-primary float-right mx-4" data-toggle="modal" data-target="#staticBackdrop" >Approve</Button>
                                    {this.popup("Make sure you verified all details of doctor !!!", "APPROVED", "Approve")}
                                    <Button className="btn btn-danger float-right mx-4" data-toggle="modal" data-target="#reject" >Reject</Button>
                                    <div className="modal fade" id="reject" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                        <div className="modal-dialog">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title textColor" id="staticBackdropLabel">Are you sure you want to reject this doctor?</h5>
                                                    <Button type="Button" className="close" data-dismiss="modal" aria-label="Close">
                                                        <span aria-hidden="true">&times;</span>
                                                    </Button>
                                                </div>
                                                <div className="modal-footer">
                                                    <Button type="Button" className="btn btn-secondary" data-dismiss="modal">Back</Button>
                                                    <Button type="Button" className="btn btn-danger" data-dismiss="modal" onClick={() => this.updateDoctor("REJECTED")}>Reject</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                : this.state.status === 'APPROVED' ?
                                    <>
                                        <Button className="btn btn-danger btn-font float-right mx-2" data-toggle="modal" data-target="#staticBackdrop" >DeActivate</Button>
                                        {this.popup("Are you sure you want to DeActivate this doctor?", "DEACTIVATED", "DeActivate")}
                                    </>
                                    : this.state.status === 'DEACTIVATED' ?
                                        <>
                                            <Button className="btn btn-primary btn-font float-right mx-2" data-toggle="modal" data-target="#staticBackdrop" >Activate</Button>
                                            {this.popup("Are you sure you want to Activate this doctor?", "APPROVED", "Activate")}
                                        </>
                                        : this.state.status === 'REJECTED' ?
                                            <>
                                                <Button className="btn btn-primary btn-font float-right mx-2" data-toggle="modal" data-target="#staticBackdrop" >Approve</Button>
                                                {this.popup("Are you sure you want to Approve this doctor?", "APPROVED", "Approve")}
                                            </>
                                            : null
                            : null
                        }

                        {currentLoggedInUser(this.state.dd.userId) || this.state.role.roleAdmin ?
                            <Row>
                            <Col sm={12} className="mt-5">
                                {this.state.status === 'APPROVED' ?
                                    <Link to={{ pathname: "/dconfigdetails", state: { doctorId: this.state.did, dd: this.state.dd } }} ><Button className="btn btn-primary btn-font float-left">Consultation Config</Button></Link>
                                    : null
                                }
                                </Col>
                                <Col sm={12}>
                                <Link to={{ pathname: '/add-doctor', state: { doctorId: this.state.did, edit: true, loading: true, back: "doctorProfile" } }} className="btn btn-primary btn-font float-right">Edit Profile </Link>
                                </Col>
                            </Row>
                            : null
                        }
                        <hr />
                    </div>
                    : null}
                <EmptyJumbotron />
            </Container>
        )
    }
}