import React, { Component } from 'react';
import { Row, Col, Button, Container, Table } from 'react-bootstrap';
import './EntityAppointmentList.css'
import Alert from 'react-s-alert';
import { Link } from 'react-router-dom';
import { changeTime } from '../util/functionUtils';
import LoadingIndicator from '../common/LoadingIndicator';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { getEntityAppointmentsList } from '../util/EntityUtils'

class EntityAppointmentsList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            EntityAppointmentsList: [],
            entityId: this.props.history.location.state ? this.props.history.location.state.entityId : null,
            entityName: this.props.history.location.state ? this.props.history.location.state.entityName : null,
            pageNumber: 0,
            pageSize: 20,
            totalElements: 0,
            loading: true,
        }
        this.handleBack = this.handleBack.bind(this)
    }

    loadEntityAppointmnet() {
        getEntityAppointmentsList(this.state.entityId, this.state.pageNumber, this.state.pageSize)
            .then(response => {
                this.setState({
                    EntityAppointmentsList: response.content,
                    totalElements: response.totalElements,
                    loading: false
                })
            }).catch(error => {
                Alert.error((error && error.message) || 'Something went wrong, please try again later!');
            })
    }


    handleBack = () => {
        this.props.history.goBack()
    }

    componentDidMount() {
        this.loadEntityAppointmnet()
    }

    async loadMore() {
        await this.setState({
            pageNumber: this.state.pageNumber + 1
        })
        getEntityAppointmentsList(this.state.entityId, this.state.pageNumber, this.state.pageSize)
            .then(response => {
                this.setState({
                    EntityAppointmentsList: [...this.state.EntityAppointmentsList, ...response.content],
                });
            }).catch(error => {
                Alert.error((error && error.message) || 'Something went wrong, please try again later!');
            });
    }

    render() {
        if (this.state.loading)
            return <LoadingIndicator />

        return (
            <Container>
                <div className="entityAppointment-container">
                    <div className="feature-title container-fluid">
                        <h3> Appointments associated with {this.state.entityName} entity</h3><hr />
                    </div>
                    <Link className="mb-4" onClick={this.handleBack}><Button varient="primary"> <ArrowBackIcon /> </Button></Link>
                    <Table responsive hover size="md" className="shadow mb-5" id="entityAppointmentListTable">
                        {this.state.EntityAppointmentsList.length === 0 ? null :
                            <thead className="thead-color">
                                <tr id="entityAppointmentListTableHead">
                                    <th>Id</th>
                                    <th>Doctor</th>
                                    <th>Speciality</th>
                                    <th>Patient</th>
                                    <th>Time</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                        }
                        <tbody>
                            {this.state.EntityAppointmentsList.map(value => {
                                return (
                                    <tr>
                                        <td data-label="Id">{value.id} </td>
                                        <td data-label="Doctor">{value.provider.imageUrl ? <img style={{ clipPath: 'circle()' }} src={value.provider.imageUrl} width="48px" alt="" /> : <span className="profile">{value.provider.name[0]}</span>} {" "}{value.provider.name}</td>
                                        <td data-label="Speciality">{value.provider.spec}</td>
                                        {value.memberId ?
                                            <td data-label="Patient">
                                                <span className="profile">{value.member.name[0]}</span>
                                                {" "} {value.member.name}
                                            </td>
                                            : <td data-label="Patient">{value.consumer.imageUrl ? <img style={{ clipPath: 'circle()' }} src={value.consumer.imageUrl} width="48px" alt="" /> : <span className="profile">{value.consumer.name[0]}</span>}{" "} {value.consumer.name}</td>}
                                        <td data-label="Time">
                                            {(new Date(value.startTimeMs)).toLocaleDateString([], { year: 'numeric', month: 'long', day: 'numeric' })}<br />
                                            {changeTime(value.startTimeMs)} - {changeTime(value.endTimeMs)}
                                        </td>
                                        <td data-label="Amount">{value.order.currency}{" "}{value.order.value}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                    <h3 className="text-center">No appointments associated with {this.state.entityName}</h3>
                    <Row className="mb-5">
                        <Col xs={6} sm={7} md={8} lg={10}>
                            <Link onClick={this.handleBack}><Button varient="primary"> Back </Button></Link>
                        </Col>
                        <Col xs={6} sm={5} md={4} lg={2}>
                            {this.state.EntityAppointmentsList.length >= 20 ?
                                this.state.EntityAppointmentsList.length < this.state.totalElements ?
                                    <Button variant="primary" className=" float-right" size="sm" onClick={this.loadMore}>Load more...</Button> :
                                    <Button variant="primary" className=" loadMore" size="sm" href="#top">Back to top</Button> : null
                            }
                        </Col>
                    </Row>

                </div>
            </Container>
        );
    }
}

export default EntityAppointmentsList;