import React, { Component } from 'react';
import { Carousel, Container } from 'react-bootstrap';

import './CustomCarousel.css';

export default class CustomCarousel extends Component {
	render() {
		return (
			<Container>
				<Carousel className="paddinghere">
					<Carousel.Item>
						<img
							className="d-block w-100"
							src="../img/bck-doctors-consult1.jpg"
							alt="Consult Doctors"
						/>
						<Carousel.Caption>
							<h1 className="health-text-color">Consult Doctors</h1>
						</Carousel.Caption>
					</Carousel.Item>
					<Carousel.Item>
						<img
							className="d-block w-100"
							src="../img/motto_small_v1.jpg"
							alt="Motto & Vision"
						/>
						<Carousel.Caption className="carousel-caption-mv">
							<h3>Our Motto</h3>
							<h4 className="blue-text-color">Create a Healthy Nation: Physically, Intellectually & Mentally</h4>
							<h3>Our Vision</h3>
							<h4 className="blue-text-color">To bring together like minded & health care experts in every field to operate as one body towards a healthier India</h4>
						</Carousel.Caption>
					</Carousel.Item>
					{/* <Carousel.Item>
					<img
						className="d-block w-100"
						src="img/background0.jpg"
						alt="Run & Track"
					/>
					<Carousel.Caption>
			      <h1 className="display-2">Run & Track</h1> 	
			      <h3>Run Alone, In Group, Compete & Track</h3>
			    </Carousel.Caption>
			  </Carousel.Item> */}
					<Carousel.Item>
						<img
							className="d-block w-100"
							src="../img/bck-health-records2.jpg"
							alt="Health Records"
						/>
						<Carousel.Caption>
							<h1 className="health-text-color">Health Records</h1>
							<h3 className="health-text-color">Manage your Prescription online</h3>
						</Carousel.Caption>
					</Carousel.Item>
				</Carousel>
			</Container>
		)
	}
}