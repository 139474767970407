import React, { Component } from 'react';
import Alert from 'react-s-alert';
import "./PaymentForm.css";
import { getAppointmentById, getAppointmentByOrderId } from '../util/OrdersAPIUtils';
import { fetchDoctorDetails } from '../util/APIUtils';
import { paymentResponse } from '../util/paymentUtils';
import { Container, Row, Col, Button, Form, Image, Table } from 'react-bootstrap'
import { changeTime, getRole, currentLoggedInUser, getCurrentUserId } from '../util/functionUtils';
import { Link } from 'react-router-dom';
import LoadingIndicator from '../common/LoadingIndicator';

class PaymentSuccess extends Component {
    constructor(props) {
        super(props)
        let url_string = window.location.href;
        let url = new URL(url_string);
        this.state = {
            orderId: this.props.history.location.state ? this.props.history.location.state.paymentSuccessResponse.orderId : null,
            appointmentId: this.props.history.location.state ? this.props.history.location.state.paymentSuccessResponse.appointmentId : null,
            providerId: '',
            appointmentDetails: '',
            providerDetails: '',
            status: '',
            loading: true,
            // paymentStatus: 'DONE',
            trackingId: this.props.history.location.state ? this.props.history.location.state.paymentSuccessResponse.trackingId : null,
            paymentStatus: this.props.history.location.state ? this.props.history.location.state.paymentSuccessResponse.paymentStatus : null,
            failureMessage: this.props.history.location.state ? this.props.history.location.state.paymentSuccessResponse.failureMessage : null,
            statusMessage: this.props.history.location.state ? this.props.history.location.state.paymentSuccessResponse.statusMessage : null,
            paymentResponse: '',

        }
    }

    loadAppointMentDetails() {
        getAppointmentByOrderId(this.state.orderId)
            .then(response => {
                console.log("Apppintment Response " + response);
                this.setState({
                    appointmentDetails: response,
                    status: response.status,
                });
                this.loadProviderDetails(response.providerId)
            }).catch(error => {
                Alert.error((error && error.message) || 'Something went wrong, please try again later!');
            });

    }

    loadProviderDetails(providerId) {
        fetchDoctorDetails(providerId, false)
            .then(response => {
                this.setState({
                    providerDetails: response,
                    loading: false
                });

            }).catch(error => {
                Alert.error((error && error.message) || 'Something went wrong, please try again later!');
            });
    }

    paymentResponse = () => {
        const paymentResponseRequest = ""
        paymentResponse(paymentResponseRequest)
            .then(response => {
                this.setState({
                    paymentResponse: response
                })
            }).catch(error => {
                Alert.error((error && error.message) || 'Something went wrong, please try again later!');
            })
    }


    componentDidMount() {
        this.loadAppointMentDetails()
        window.scrollTo(0, 0)
    }

    render() {

        if (this.state.loading)
            return <LoadingIndicator />
        return (
            <Container>
                <div className=" payment-form-container">
                    <Row className="welcome text-center mb-5">
                        <Col sm={12}>
                            <h4> Appointment Details</h4>
                        </Col>
                        <hr />
                    </Row>

                    <Table bordered className="shadow mt-2">
                        <thead className="thead-color">
                            <th>
                                <h5>Patient's Information </h5>
                            </th>
                        </thead>
                        <tbody>
                            <td>
                                <Row className="mb-5">
                                    <Col md={4} className="mb-3 mt-2">
                                        Patient's Name :<strong> {this.state.appointmentDetails.member ? this.state.appointmentDetails.member.name : this.state.appointmentDetails.consumer.name}</strong>
                                    </Col>
                                    <Col md={4} className="mb-3 mt-2">
                                        Age : <strong>{this.state.appointmentDetails.member ? this.state.appointmentDetails.member.age : this.state.appointmentDetails.consumer.age} yrs</strong>
                                    </Col>
                                    <Col md={4} className="mb-3 mt-2">
                                        Gender : <strong>{this.state.appointmentDetails.member ? this.state.appointmentDetails.member.gender : this.state.appointmentDetails.consumer.gender}</strong>
                                    </Col>
                                    <Col md={4} className="mb-3">
                                        Status : <strong>{this.state.status}</strong>
                                    </Col>
                                    <Col md={4} className="mb-2">
                                        Date : <strong>{(new Date(this.state.appointmentDetails.startTimeMs)).toLocaleDateString([], { year: 'numeric', month: 'long', day: 'numeric' })}</strong>
                                    </Col>
                                    <Col md={4} className="mb-2">
                                        Time : <strong>{changeTime(this.state.appointmentDetails.startTimeMs)} - {changeTime(this.state.appointmentDetails.endTimeMs)}</strong>
                                    </Col>
                                    <Col md={4} className="mb-2">
                                        Service Type : {this.state.appointmentDetails.serviceType === "VIDEO_CONS" ? <strong>Video Consultation </strong> : <strong>Physical Consultation </strong>}
                                    </Col>
                                    <Col md={4} className="mb-2">
                                        Payment Status: <strong>{this.state.paymentStatus}</strong>
                                    </Col>
                                    <Col md={4} className="mb-2">
                                        TrackingId: <strong>{this.state.trackingId}</strong>
                                    </Col>
                                    <Col md={4} className="mb-3 mt-2">
                                        Payment Statue Message : {this.state.statusMessage ? <strong> {this.state.statusMessage} </strong> : <strong> {this.state.failureMessage}</strong>}
                                    </Col>

                                </Row>
                            </td>
                        </tbody>
                    </Table>


                    <Table bordered className="shadow">
                        <thead className="thead-color">
                            <th><h5 className="5">Doctor's Information</h5></th>
                        </thead>

                        <tbody>
                            <td>
                                <Row>
                                    <Col md={3} lg={3}>
                                        <Row>
                                            <Col md={12}>
                                                <Image
                                                    width={100}
                                                    height={100}
                                                    className="mr-3 media-img"
                                                    style={{ clipPath: 'circle()' }}
                                                    src={this.state.providerDetails.profileLink ? this.state.providerDetails.profileLink : "../img/mac.png"}
                                                    alt="doctor photo"
                                                />
                                            </Col>
                                            <Col md={12} className="m-3 ml-2">
                                                {this.state.providerDetails.socialProfiles ?
                                                    this.state.providerDetails.socialProfiles.map(value => {
                                                        if (value.type === 'GOOGLE')
                                                            return <a target="_blank" href={value.profileUrl}> <i class="fa fa-google fa-1x" aria-hidden="true"></i></a>
                                                        else if (value.type === 'FACEBOOK')
                                                            return <a target="_blank" href={value.profileUrl}> <i class="fa fa-facebook-official fa-1x" aria-hidden="true"></i></a>
                                                        else if (value.type === 'INSTAGRAM')
                                                            return <a target="_blank" href={value.profileUrl}> <i class="fa fa-instagram fa-1x" aria-hidden="true"></i></a>
                                                        else if (value.type === "TWITTER")
                                                            return <a target="_blank" href={value.profileUrl}> <i class="fa fa-twitter fa-1x" aria-hidden="true"></i></a>
                                                        else if (value.type === "LINKEDIN")
                                                            return <a target="_blank" href={value.profileUrl}> <i class="fa fa-linkedin-square fa-1x" aria-hidden="true"></i></a>
                                                        else
                                                            return null

                                                    })
                                                    : null}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={9} lg={9}>
                                        <h5 className="mb-3">Dr. {this.state.providerDetails.name}{" "}({this.state.providerDetails.registration})</h5>
                                        <i class="fa fa-user-md fa-1x" aria-hidden="true"></i>{" "}
                                        {this.state.providerDetails.specialisations.map((value, index) => {
                                            if (index === 0)
                                                return " " + value.dislayStr
                                            else
                                                return "," + value.dislayStr
                                        })}<br />
                                        <i class="fa fa-graduation-cap fa-1x" aria-hidden="true"></i>{" "}
                                        {this.state.providerDetails.degrees.map((value, index) => {
                                            if (index === 0)
                                                return " " + value.dislayStr
                                            else
                                                return "," + value.dislayStr
                                        })}<br />
                                        <i class="fa fa-black-tie fa-1x" aria-hidden="true"></i>{" "}<strong>{this.state.providerDetails.experienceInYears}+</strong> years experience

                                    </Col>
                                </Row>
                            </td>
                        </tbody>

                    </Table>

                    <Table bordered responsive className="shadow" id="firstTable">
                        <thead >
                            <th colSpan="7" className="thead-color"><h5>Consultation Charge Details</h5></th>
                            <tr id="tableHead">
                                <td><strong>Consultation Charge</strong></td>
                                <td><strong>Discount %</strong></td>
                                <td><strong>Discount</strong></td>
                                <td><strong>Tax %</strong></td>
                                <td><strong>Tax</strong></td>
                                <td><strong>Final Amount</strong></td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td data-label="Consultation Charge">{this.state.appointmentDetails.order.currency}{" "}{this.state.appointmentDetails.order.value}</td>
                                <td data-label="Discount %">{this.state.appointmentDetails.order.discountPercent} %</td>
                                <td data-label="Discount">{this.state.appointmentDetails.order.currency}{" "}{this.state.appointmentDetails.order.discount}</td>
                                <td data-label="Tax %">{this.state.appointmentDetails.order.taxPercent} %</td>
                                <td data-label="Tax">{this.state.appointmentDetails.order.currency}{" "}{this.state.appointmentDetails.order.tax}</td>
                                <td data-label="Final Amount">{this.state.appointmentDetails.order.currency}{" "}{this.state.appointmentDetails.order.finalValue}</td>
                            </tr>
                        </tbody>
                    </Table>
                    <Row>
                        <Col>
                            <Link to={{pathname:"/appointment-list", state:{consumerId:this.state.appointmentDetails.consumerId,providerId:this.state.appointmentDetails.providerId,superUser:false}}}><Button varient="primary" className="float-right pb-2">Next</Button></Link>
                        </Col>
                    </Row>
                    <br></br>
                </div>
            </Container>
        );
    }
}

export default PaymentSuccess;