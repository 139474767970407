import React, { Component, Fragment } from 'react';
import { Form,Col,Button } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import RemoveSharpIcon from '@material-ui/icons/RemoveSharp';



class ProfExps extends Component {
    constructor(props) {
        super(props)
        this.state = {
            designation: this.props.data.designation ? this.props.data.designation : '',
            fromDate: this.props.data.fromDateMs ? new Date(this.props.data.fromDateMs) : null,
            endDate: this.props.data.endDateMs ? new Date(this.props.data.endDateMs) : null,
            organisation: this.props.data.organisation ? this.props.data.organisation : ''
        }
        // console.log("this.props.data.fromDate " + this.props.data.fromDate);
        // console.log("this.props.data.endDate " + this.props.data.endDate);
    }
    handleInputChange = (event, index) => {
        const inputName = event.target.name;
        const inputValue = event.target.value;
        this.setState({
            [inputName]: inputValue
        })

        this.props.inputChange(inputName, inputValue, index)
    }

    handleDateChange = (date, inputName,index) => {
        this.setState({
            [inputName]: date
        })
        this.props.inputChange(inputName, date, index)

    }


    render() {
        return (
            <Fragment>
                <Form.Row>
                    <Col className="mt-4" md={6}>
                        <Form.Label>Organisation</Form.Label>
                        <Form.Control value={this.state.organisation} name="organisation" placeholder="Organisation" onChange={(event) => this.handleInputChange(event, this.props.index)} required/>
                    </Col>
                    <Col className="mt-4" md={6}>
                        <Form.Label>Designation</Form.Label>
                        <Form.Control value={this.state.designation} name="designation" placeholder="Designation" onChange={(event) => this.handleInputChange(event, this.props.index)} />
                    </Col>
                </Form.Row>

                <Form.Row >
                    <Col className="mt-4" md={6}>
                        <Form.Label>Start Date</Form.Label><br />
                        <div className="customDatePickerWidth">
                            <DatePicker
                                className="myDatePicker"
                                selected={this.state.fromDate}
                                isClearable
                                placeholderText="Select start Date"
                                showMonthDropdown
                                showYearDropdown
                                onChange={(date) => this.handleDateChange(date, 'fromDate',this.props.index)}
                            />
                        </div>
                    </Col>
                    <Col className="mt-4" md={6}>
                        <Form.Label>End Date</Form.Label><br />
                        <div className="customDatePickerWidth">
                            <DatePicker
                                className="myDatePicker"
                                selected={this.state.endDate}
                                isClearable
                                placeholderText="Select End Date"
                                showMonthDropdown
                                showYearDropdown
                                onChange={(date) => this.handleDateChange(date, 'endDate',this.props.index)}
                            />
                        </div>
                    </Col>
                </Form.Row><br/>
                

                <Button style={{float:"right"}} onClick={() => this.props.deleteProfExps(this.props.index)} className="btn-danger"><RemoveSharpIcon /></Button>
                <br/><hr/>
            </Fragment>
        )
    }
}

export default ProfExps;