import React, { Component } from 'react';
import Alert from 'react-s-alert';
import "./PaymentForm.css";
import { getAppointmentById, getAppointmentByOrderId } from '../util/OrdersAPIUtils';
import { fetchDoctorDetails } from '../util/APIUtils';
import { paymentResponse } from '../util/paymentUtils';
import { Container, Row, Col, Button, Form, Image, Table } from 'react-bootstrap'
import { changeTime, getRole, currentLoggedInUser, getCurrentUserId } from '../util/functionUtils';
import { Link } from 'react-router-dom';
import LoadingIndicator from '../common/LoadingIndicator';



class PaymentResponse extends Component {

    constructor(props) {
        super(props)
        let url_string = window.location.href;
        let url = new URL(url_string);
        this.state = {
            orderId: url.searchParams.get("order_id") ? url.searchParams.get("order_id") : null,
            appointmentId: url.searchParams.get("aid") ? url.searchParams.get("aid") : null,
            providerId: '',
            appointmentDetails: '',
            providerDetails: '',
            status: "CLOSED",
            loading: true,
            // paymentStatus: 'DONE',
            trackingId: url.searchParams.get("tracking_id") ? url.searchParams.get("tracking_id") : null,
            paymentStatus: url.searchParams.get("order_status") ? url.searchParams.get("order_status") : null,
            failureMessage: url.searchParams.get("failure_message") ? url.searchParams.get("failure_message") : null,
            statusMessage: url.searchParams.get("status_message") ? url.searchParams.get("status_message") : null,

            paymentResponse: '',

        }

    }

    componentDidMount() {
        if ("Success".toLowerCase() === this.state.paymentStatus.toLowerCase()) {
            this.props.history.push(
                {
                    pathname: "/ps",
                    state: { paymentSuccessResponse: this.state }
                })
        } else {
            this.props.history.push(
                {
                    pathname: "/appointment-details",
                    state: { paymentSuccessResponse: this.state }
                })
        }
    }

    render() {
        return (
            <div>
                
            </div>
        )
    }

}
export default PaymentResponse;