import React, { Component } from 'react';
import { Container, Row, Col, Table, Button } from 'react-bootstrap';
import './MyPrescriptionList.css';
import { getPrescriptionList, downloadPrescriptionRecord } from '../util/healthAPIUtils';
import { getCurrentUserId, getDateSafariHack ,getSps } from '../util/functionUtils';
import Alert from 'react-s-alert';
import { Link } from 'react-router-dom';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import LoadingIndicator from '../common/LoadingIndicator';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';


class MyPrescriptionList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            prescriptionList: [],
            pageNumber: 0,
            pageSize: 20,
            totalElements: 0,
            consumerId: getCurrentUserId(),
            loading:true
        }
        this.loadMore = this.loadMore.bind(this)
    }

    loadPrescription = () => {
        getPrescriptionList(this.state.consumerId, this.state.pageNumber, this.state.pageSize)
            .then(response => {
                this.setState({
                    prescriptionList: response.content,
                    totalElements: response.totalElements,
                    loading:false
                })
            }).catch(error => {
                Alert.error((error && error.message) || 'Something went wrong, please try again later!');
            })
    }

    componentDidMount() {
        this.loadPrescription()
        window.scrollTo(0, 0)
    }

    async loadMore() {
        await this.setState({
            pageNumber: this.state.pageNumber + 1
        })
        getPrescriptionList(this.state.consumerId, this.state.pageNumber, this.state.pageSize)
            .then(response => {
                this.setState({
                    prescriptionList: [...this.state.prescriptionList, ...response.content],
                });
            }).catch(error => {
                Alert.error((error && error.message) || 'Something went wrong, please try again later!');
            });
    }

    downloadPrescription = (prescriptionId) => {
        downloadPrescriptionRecord(prescriptionId,true)
            .then(url => {
                window.location.href = url;
                Alert.success("Prescription download in progress!")
            })
            .catch(error => {
                Alert.error((error && error.message) || 'Something went wrong, please try again later!');
            })
    }

    render() {
        if (this.state.loading)
            return <LoadingIndicator />

        return (
            <Container>
                <div className="health-record-list-container container-fluid" id="top">
                    <Row className="welcome text-center mb-5">
                        <Col sm={12}>
                            <h3>My Prescriptions</h3>
                        </Col>
                        <hr />
                    </Row>
                    <Link to="/health-records" className="mb-2"><Button varient="primary"> <ArrowBackIcon /></Button></Link>

                    {this.state.prescriptionList.length === 0 ? null : <h6>Total Prescription(s) : {this.state.totalElements}</h6>}
                    <Table hover responsive className="shadow"  id="priscritionListTable">
                        {this.state.prescriptionList.length === 0 ? null :
                            < thead className="thead-color" >
                                <tr id="priscritionListTableHead">
                                    <th>Id</th>
                                    <th>Doctor</th>
                                    <th>Speciality</th>
                                    <th>For</th>
                                    <th>Prescription Time</th>
                                    <th>Download</th>
                                </tr>
                            </thead>
                        }
                        <tbody>
                            {this.state.prescriptionList.map(value => {
                                if(value.status === 'COMPLETED')
                                    return (
                                        <tr>
                                            <td  data-label="Id" ><Link to={{ pathname: '/pd', state: { prescriptionId: value.id, presListBack:true} }}>{value.id}</Link></td>
                                            <td  data-label="Doctor">{value.doctor.profileLink ? <img style={{ clipPath: 'circle()' }} src={value.doctor.profileLink} width="48px" /> : null} {" "}{value.doctor.name}</td>
                                            <td  data-label="Speciality">{getSps(value.doctor.specialisations)}</td>
                                            <td  data-label="For">{value.memberId ? value.member.name : 'self'}</td>
                                            <td  data-label="Prescription Time">{(getDateSafariHack(value.createdAt)).toLocaleDateString([], { year: 'numeric', month: 'long', day: 'numeric', hour: "numeric", minute: "numeric", hour12: "true" })}</td>
                                            <td  data-label="Download"><CloudDownloadIcon color="primary" style={{ cursor: 'pointer' }} onClick={() => this.downloadPrescription(value.id)} /></td>
                                        </tr>
                                    )
                            })}
                        </tbody>
                    </Table>
                {this.state.prescriptionList.length === 0 ? <h4 className="text-center mb-5">No prescription is generated till now !</h4>:null}

                    <Row className="mb-5">
                        <Col xs={6} sm={7} md={8} lg={10}>
                            <Link to="/health-records"><Button varient="primary"> Back</Button></Link>
                        </Col>
                        <Col xs={6} sm={5} md={4} lg={2}>
                            {this.state.prescriptionList.length >= 20 ? 
                                this.state.prescriptionList.length < this.state.totalElements ?
                                    <Button color="primary" className=" float-right" size="sm" onClick={this.loadMore}>Load more...</Button> :
                                    <Button color="primary" className=" loadMore" size="sm" href="#top">Back to top</Button>:null
                            }
                        </Col>
                    </Row>
                </div>
            </Container >
        )
    }
}

export default MyPrescriptionList;