import React, { Component } from 'react'
import { Container, Row, Col, Button, Image, Table } from 'react-bootstrap'
import './SharedRecordDetail.css'
import Alert from 'react-s-alert';
import { getSharedDetails, unShareSingleRecord, downloadHealthRecord } from '../util/healthAPIUtils'
import { getDateSafariHack } from '../util/functionUtils';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link } from 'react-router-dom';
import Comment from './Comment';
import Iframe from 'react-iframe'
import VisibilityIcon from '@material-ui/icons/Visibility';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

export default class SharedRecordDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            recordId: this.props.location.state.recordId,
            recordDetails: '',
            isFound: false,
            hrurl: '',
            hrloaded: false
        }
    }


    loadSharedDetail = () => {
        getSharedDetails(this.state.recordId)
            .then(response => {
                this.setState({
                    recordDetails: response,
                    isFound: true
                })
            })
            .catch(error => {

            })
    }

    componentDidMount() {
        this.loadSharedDetail()
        window.scrollTo(0, 0)
    }

    unShareRecord = (recordId) => {
        unShareSingleRecord(recordId)
            .then(response => {
                Alert.success("medical record unshared successfully")
            })
            .catch(error => {
                Alert.error((error && error.message) || 'Something went wrong, please try again later!');
            })
    }

    viewRecord = (recordId) => {
        downloadHealthRecord(recordId, false)
            .then(url => {
                // window.location.href = url;
                // Alert.success("Health record ready to view!")
                this.setState({
                    hrurl: url,//('https://docs.google.com/viewer?url=' + url+'&embedded=true'),
                    hrloaded: true
                })
            })
            .catch(error => {
                Alert.error((error && error.message) || 'Something went wrong, please try again later!');
            })
    }

    downloadRecord = (recordId) => {
        downloadHealthRecord(recordId, true)
            .then(url => {
                window.location.href = url;
                Alert.success("Health record downloaded successfully!")
            })
            .catch(error => {
                Alert.error((error && error.message) || 'Something went wrong, please try again later!');
            })
    }

    render() {
        return (
            <Container>
                {this.state.recordDetails ?
                    <div className="share-record-container">
                        <Row className="welcome text-center mb-5">
                            <Col sm={12}>
                                <h4> Health Record details</h4>
                            </Col>
                            <hr />
                        </Row>
                        <div className="heading">
                            <Link to="/share-by" className="float-right btn btn-primary"><ArrowBackIcon /></Link>
                        </div>
                        <hr />
                        <Row>
                           
                                {/* <Image
                                    width={100}
                                    height={100}
                                    className="mr-3 media-img"
                                    style={{ clipPath: 'circle()' }}
                                    src="../img/mac.png"
                                    alt="doctor photo"
                                /> */}
                               
                         
                            <Col md={10} className="mt-2">
                                <Row>
                                    <Col md={4} className="mb-2">
                                        Shared With : <img style={{ clipPath: 'circle()' }} src={this.state.recordDetails.sharedWithDoctor.profileLink} alt ={this.state.recordDetails.sharedWithDoctor.name[0].toUpperCase()} width ="48px"/> {" "}
                                        <strong>{this.state.recordDetails.sharedWithType === 'DOCTOR' ? this.state.recordDetails.sharedWithDoctor.name : this.state.recordDetails.sharedWithType === 'USER' ? this.state.recordDetails.sharedWithUser.name : this.state.recordDetails.sharedWithType === "EMAIL" ? this.state.recordDetails.sharedWithEmail : null}</strong>
                                    </Col>
                                    <Col md={6}>
                                        Shared On : <strong>{(getDateSafariHack(this.state.recordDetails.createdAt)).toLocaleDateString([], { year: 'numeric', month: 'long', day: 'numeric'
                                        // , hour: "numeric", minute: "numeric", hour12: "true" 
                                        })}</strong>
                                    </Col>
                                    <Col md={6}>
                                        Sharing Ends On : <strong>{(getDateSafariHack(this.state.recordDetails.sharingEndsOn)).toLocaleDateString([], { year: 'numeric', month: 'long', day: 'numeric'
                                        // , hour: "numeric", minute: "numeric", hour12: "true" 
                                        })}</strong>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <hr className="mb-5" />
                        <Table responsive hover className="shadow" id="shareRecordDetails">
                            <thead className="thead-color">
                                <tr id="shareRecordDetailsHead">
                                <th className="text-center">FileName</th>
                                <th className="text-center">Shared On</th>
                                <th className="text-center">Download</th>
                                <th className="text-center">View</th>
                                <th className="text-center">Stop Sharing</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.recordDetails.sharedRecords.map((value, index) => {
                                    return (
                                        <tr>
                                            <td className="text-md-center" data-label="FileName" ><b>{value.name}</b></td>
                                            <td className="text-md-center" data-label="Shared On"><b>{value.createdAt.slice(0, 10)}</b></td>
                                            <td className="text-md-center" data-label="Download"><CloudDownloadIcon color="primary" style={{ cursor: 'pointer' }} onClick={() => this.downloadRecord(value.recordId)} /></td>
                                            <td className="text-md-center" data-label="View"><VisibilityIcon color="primary" style={{ cursor: 'pointer' }} onClick={() => this.viewRecord(value.recordId)} /></td>
                                            <td className="text-md-center" data-label="Stop Sharing"><Button data-toggle="modal" data-target="#staticBackdrop" ><i class="fa fa-trash-o" aria-hidden="true"></i></Button>
                                                <div className="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                    <div className="modal-dialog">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title textColor" id="staticBackdropLabel">Are you sure you want to delete ?</h5>
                                                                <Button type="Button" className="close" data-dismiss="modal" aria-label="Close">
                                                                    <span aria-hidden="true">&times;</span>
                                                                </Button>
                                                            </div>
                                                            <div className="modal-footer">
                                                                <Button type="Button" className="btn btn-secondary" data-dismiss="modal">Cancel</Button>
                                                                <Button type="Button" className="btn btn-primary" data-dismiss="modal" onClick={() => this.unShareRecord(value.id)}>Delete</Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </div> : null}

                {this.state.isFound === false ? <h3>No Records Found !</h3> : null}
                <br />
                <br />
                <Comment entityId={this.props.match.params.id} heading='Ask your queries'/>
                <div className="container-iframe">
                    {this.state.hrloaded === true ?
                        <iframe className="responsive-iframe" src={this.state.hrurl + "#toolbar=0"}
                            width="650px"
                            height="450px"
                            onContextMenu={(e) => e.preventDefault()} />
                        // <DocViewer documents={[{ uri: this.state.hrurl }]}/> 
                        : null

                    }
                </div>    
                {/* <h3> Ask your queries</h3><br/><hr/> */}
                
            </Container>
        )
    }
}
