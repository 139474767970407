import React, { Component } from 'react'
import { Container,Row,Col,Card } from 'react-bootstrap';
import "./PharmacyDetails.css";
import { Link } from "react-router-dom";


export default class MyPharmacyOrder extends Component {

    render() {
        return (
            <Container>
                <div className="appointment-container container-fluid">
                    <div className="feature-title container-fluid">
                        <h3>My Order</h3><hr />
                    </div>
                    <Row className="justify-content-center">

                    <Col sm={6} md={6} xl={3} className="mb-3">
                            <Link to={{ pathname: "/ph-or" }} style={{ textDecoration: "none" }} className="text-center">
                                <Card
                                    className="m-2"
                                    bg="primary"
                                    text="light"
                                    style={{ width: '15rem', minHeight: '10rem' }}
                                >
                                    <Card.Body>
                                        <Card.Title>
                                            <h3 className="pt-4">My Order</h3>
                                        </Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        <Col sm={6} md={6} xl={3} className="mb-3">
                            <Link to={{ pathname: "/or-list" }} style={{ textDecoration: "none" }} className="text-center">
                                <Card
                                    className="m-2"
                                    bg="primary"
                                    text="light"
                                    style={{ width: '15rem', minHeight: '10rem' }}
                                >
                                    <Card.Body>
                                        <Card.Title>
                                            <h3 className="pt-4">My Pharmacy Order</h3>
                                        </Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        </Row>
                        </div>
            </Container>
        )
    }
}