import React, { Component } from 'react'
import './Category.css'
import AddSharpIcon from '@material-ui/icons/AddSharp';
import { Button, Container, Table } from 'react-bootstrap';
import { loadCategoryList, DeleteCategoryById } from '../util/healthAPIUtils'
import CancelIcon from '@material-ui/icons/Cancel';
import { Link } from 'react-router-dom';
import Alert from 'react-s-alert';
import { getCurrentUserId, getDateSafariHack } from '../util/functionUtils'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import LoadingIndicator from '../common/LoadingIndicator';


export default class AddHealthRecord extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ownerId: getCurrentUserId(),
            categoryLists: [],
            loading: true
        }
    }


    loadCategory = () => {
        loadCategoryList(this.state.ownerId)
            .then(response => {
                this.setState({
                    categoryLists: response.content,
                    loading: false
                })
            })
            .catch(error => {
                Alert.error((error && error.message) || 'Oops! Something went wrong. Please try again!');
            })
    }

    handleDeleteRecord = (categoryId) => {
        DeleteCategoryById(categoryId)
            .then(response => {
                Alert.success("record deleted successfully")
               this.loadCategory()
            })
            .catch(error => {
                Alert.error((error && error.message) || 'Oops! Something went wrong. Please try again!');
            })
    }

    componentDidMount() {
        this.loadCategory()
        window.scrollTo(0, 0)
    }
    render() {
        
        if (this.state.loading) {
            return <LoadingIndicator />
        }
        return (
            <Container>
                <div className="add-health-record-container">
                    <div className="feature-title container-fluid">
                        <h3>Health Record Categories</h3><hr /><br />
                        <Link to="/health-records" className="custom-button float-left btn btn-primary"><ArrowBackIcon /></Link>
                        <Link className="float-right btn btn-primary" to="/add-category"><AddSharpIcon /></Link>
                        <br />
                        <Table className="shadow" id="categoryTable">
                            <thead className="thead-color">
                                {this.state.categoryLists.length === 0 ? null :
                                    <tr id="categoryTableHead">
                                        <th>Name</th>
                                        <th>Type</th>
                                        <th>Created On</th>
                                        <th>Delete</th>
                                    </tr>
                                }
                            </thead>
                            <tbody>
                                {this.state.categoryLists.map((value, index) => {
                                    return (
                                        <tr>
                                            <Link to={{ pathname: "/health-report", state: { id: value.id, type: value.type, name: value.name } }}> <td data-label="Name">{value.name}</td></Link>
                                            <td data-label="Type">{value.type}</td>
                                            <td data-label="Created On">{(getDateSafariHack(value.createdAt)).toLocaleDateString([], { year: 'numeric', month: 'long', day: 'numeric', hour: "numeric", minute: "numeric", hour12: "true" })}</td>
                                            <td data-label="Delete"><CancelIcon data-toggle="modal" data-target="#staticBackdrop" color="secondary" />
                                                <div className="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                    <div className="modal-dialog">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title textColor" id="staticBackdropLabel">Are you sure you want to delete ?</h5>
                                                                <Button type="Button" className="close" data-dismiss="modal" aria-label="Close">
                                                                    <span aria-hidden="true">&times;</span>
                                                                </Button>
                                                            </div>
                                                            <div className="modal-footer">
                                                                <Button type="Button" className="btn btn-secondary" data-dismiss="modal">Cancel</Button>
                                                                <Button type="Button" className="btn btn-primary"  data-dismiss="modal" onClick={() => this.handleDeleteRecord(value.id)}>Delete</Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                        {this.state.categoryLists.length === 0 ? <h5 className="text-center">No health record found. Please click add to create new category and add health record.</h5> : null}
                    </div>
                </div>
            </Container>
        )
    }
}
