import React, { Component } from 'react';

export default class LoginSignUp extends Component{
	render(){
		return(
			<div>
				LoginSignup
			</div>
		)
	}
}