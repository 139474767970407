import React, { Component } from 'react'
import { Row, Col, Button, Container, Form } from 'react-bootstrap';
import { getCurrentUserId } from '../util/functionUtils';
import Alert from 'react-s-alert';
import { postComment, getCommentList } from '../util/healthAPIUtils'

export default class Comment extends Component {
    constructor(props) {
        super(props)
        this.state = {
            comment: '',
            commentList: [],
            entity: "SHARED_REC_CONFIG",
            entityId: this.props.entityId,
            commenterId: getCurrentUserId(),
            intervalId:0
        }
    }

    changeHandler = (event) => {
        this.setState({
            comment: event.target.value
        })
    }

    submitHandler = () => {
        const { comment, entity, entityId, commenterId } = this.state
        const commentRequest = { comment, entity, entityId, commenterId };
        postComment(commentRequest)
            .then(response => {
                this.setState(prevState => ({
                    commentList: [...prevState.commentList, response],
                    comment: ''
                }))
            })
            .catch(error => {
                Alert.error((error && error.message) || 'Oops! Something went wrong. Please try again!');
            })

    }

    loadCommnet = () => {
        getCommentList(this.state.entityId, this.state.entity)
            .then(response => {
                this.setState({
                    commentList: response
                })
            })
            .catch(error => {
                Alert.error((error && error.message) || 'Oops! Something went wrong. Please try again!');
            })
    }
    componentDidMount = () => {
        let intervalId = setInterval(() => this.loadCommnet(), 5000)
        this.setState({
            intervalId : intervalId
        })
    }
    componentWillUnmount() {
        clearInterval(this.state.intervalId);
      }
    render() {
        return (
            <Container>
                <div>
                    <h3 className="float-right">{this.props.heading}</h3><br/><br/>
                    {this.state.commentList.map((value) => {
                        return (
                            <Row>
                                <Col md={4}>
                                </Col>
                                <Col md={8}>
                                    {value.commenter.imageUrl ? <img style={{ clipPath: 'circle()' }} src={value.commenter.imageUrl} width="43px" alt="" /> : null} {" "}{value.commenter.name}
                                    <Form.Control className="mt-2" value={value.comment} type="text" readOnly /><br />
                                </Col>
                            </Row>
                        )
                    })}
                    <Row>
                        <Col md={4}>
                        </Col>
                        <Col md={8}>
                            <Form.Control as="textarea" rows="3" maxLength="1000" type="text" value={this.state.comment} onChange={this.changeHandler} />
                        </Col>
                    </Row>
                    <br />
                    <Button variant="primary" className="float-right" onClick={this.submitHandler}>Submit</Button>
                </div>
                <br />
                <br />
            </Container>
        )
    }
}

