import React, { Component } from 'react';
import { withRouter } from 'react-router';
import Alert from 'react-s-alert';
import { getCurrentUser } from '../../util/APIUtils';
import './Login.css';
import {ACCESS_TOKEN, AUTHENTICATED, CURRENT_USER } from '../../constants';
import { login } from '../../util/APIUtils';
import LoadingIndicator from '../../common/LoadingIndicator';

class LoginForm extends Component {

    constructor(props) {
        super();
        this.state = {
            email: '',
            password: '',
            loading:false
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.loadCurrentlyLoggedInUser = this.loadCurrentlyLoggedInUser.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;
        const inputValue = target.value;

        this.setState({
            [inputName]: inputValue
        });
    }

    loadCurrentlyLoggedInUser() {
      if (!localStorage.getItem(CURRENT_USER)) {
        const response = getCurrentUser();

        if (response.ok) {
            localStorage.setItem(CURRENT_USER, JSON.stringify(response.JSON));
        }        
     }
   }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({
            loading:true
        })

        const loginRequest = Object.assign({}, this.state);

        const { match: { params }, history } = this.props;

        login(loginRequest)
            .then(response => {
                localStorage.setItem(ACCESS_TOKEN, response.accessToken);
                localStorage.setItem(AUTHENTICATED, "true");
                // Alert.success("You're successfully logged in!");
                this.props.handleAuth();
                history.push('/');
                
            }).catch(error => {
                this.setState({
                    loading:false
                })
                Alert.error((error && error.message) || 'Oops! Something went wrong. Please try again!');
            });
    }

    componentDidMount(){
        window.scrollTo(0, 0)
    }

    render() {

        if(this.state.loading){
            return <LoadingIndicator/>
        }
        return (
            <form onSubmit={this.handleSubmit}>
                <div className="form-item">
                    <input type="email" name="email"
                        className="form-control" placeholder="Email"
                        value={this.state.email} onChange={this.handleInputChange} required />
                </div>
                <br />
                <div className="form-item">
                    <input type="password" name="password"
                        className="form-control" placeholder="Password"
                        value={this.state.password} onChange={this.handleInputChange} required />
                </div>
                <br />
                <div className="form-item">
                    <button type="submit" className="btn btn-block btn-primary">Login</button>
                </div>
            </form>
        );
    }
}

export default withRouter(LoginForm)