import React, { Component } from 'react';
import { Container, Table, Button, Row, Col } from 'react-bootstrap';
import EmptyJumbotron from '../common/EmptyJumbotron';
import './Qualification.css';
import { getQualificationList, deleteQualification } from '../util/APIUtils';
import Alert from 'react-s-alert';
import { Link } from 'react-router-dom';
import Edit from '@material-ui/icons/Edit';
import DeleteOutlineSharpIcon from '@material-ui/icons/DeleteOutlineSharp';
import AddSharpIcon from '@material-ui/icons/AddSharp';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import LoadingIndicator from '../common/LoadingIndicator';

export default class Qualification extends Component {
	constructor(props) {
		super(props)
		this.state = {
			qualificationList: [],
			loading: true,
			pageNumber: 0,
			totalElements: 0,
			pageSize: 20,
			colorArray: ['light', 'info'],
			loading:true
		}
		this.loadql = this.loadql.bind(this);
		this.loadMore = this.loadMore.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
	}

	handleDelete(event, value) {
		this.setState({
			loading:true
		})
		const deleteQualificationRequest = Object.assign({}, value);
		deleteQualification(deleteQualificationRequest)
			.then(response => {
				Alert.success("Qualification deleted successfully.");
				this.loadql();
			}).catch(error => {
				Alert.error((error && error.message) || 'Something went wrong, please try again later!');
			})
	}

	async loadMore() {
		await this.setState({
			pageNumber: this.state.pageNumber + 1
		})
		getQualificationList(this.state.pageNumber, this.state.pageSize)
			.then(response => {
				this.setState({
					qualificationList: [...this.state.qualificationList, ...response.content],
					loading: false
				});
			}).catch(error => {
				this.setState({
					loading: false
				})
				Alert.error((error && error.message) || 'Something went wrong, please try again later!');
			})
	}

	loadql() {
		getQualificationList(this.state.pageNumber, this.state.pageSize)
			.then(response => {
				this.setState({
					qualificationList: response.content,
					totalElements: response.totalElements,
					loading: false,
				});
			}).catch(error => {
				this.setState({
					loading: false
				})
				Alert.error((error && error.message) || 'Something went wrong, please try again later!');
			})
	}

	componentDidMount() {
		this.loadql();
		window.scrollTo(0, 0)
	}

	render() {
		if(this.state.loading){
			return<LoadingIndicator> loading ...</LoadingIndicator>
		}
		const qList = this.state.qualificationList
		return (
			<Container id="top">
				<div className="admin-container container-fluid">
					<Row className="welcome text-center mb-5">
						<Col sm={12}>
							<h3>Qualification</h3>
						</Col>
						<hr />
					</Row>
					<div className="heading">
						<Link to="/admin" className="float-right btn btn-primary"><ArrowBackIcon /></Link>
						<h2></h2>
						<Link className="btn btn-primary" to="/qlform"><AddSharpIcon /></Link>
					</div>
					<Table id="qualificationList">
						{this.state.qualificationList.length === 0 ? null :
							<thead>
								<tr id="qualificationListHead">
									<th className="text-center">Code</th>
									<th className="text-center">Description</th>
									<th className="text-center"> Edit</th>
									<th className="text-center">Delete</th>
								</tr>
							</thead>
						}
						<tbody>
							{qList.map((qualification) => {
								if (qualification.isActive)
									return (
										<tr>
											<td className="text-center" data-label="Code"><Link to={{ pathname: "/dc", state:{qlCode: qualification.code ,selectedQl:{label:qualification.dislayStr , value:qualification}}}}>{qualification.code}</Link></td>
											<td className="text-center" data-label="Description"> {qualification.dislayStr}</td>
											<td className="text-center" data-label="Edit"><Button variant="outlined"><Link to={{ pathname: "/qlform", editqualification: qualification }} style={{ textDecoration: 'none' }}><Edit color="action" /></Link></Button></td>
											<td className="text-center" data-label="Delete"> <Button data-toggle="modal" data-target="#staticBackdrop" variant="outlined"><DeleteOutlineSharpIcon color="secondary" /></Button>
												<div className="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
													<div className="modal-dialog">
														<div className="modal-content">
															<div className="modal-header">
																<h5 className="modal-title textColor" id="staticBackdropLabel">Are you sure you want to delete ?</h5>
																<Button type="Button" className="close" data-dismiss="modal" aria-label="Close">
																	<span aria-hidden="true">&times;</span>
																</Button>
															</div>
															<div className="modal-body textColor">
																There may be doctors associated with this Qualification !
                                                         </div>
															<div className="modal-footer">
																<Button type="Button" className="btn btn-secondary" data-dismiss="modal">Cancel</Button>
																<Button type="Button" className="btn btn-primary" data-dismiss="modal" onClick={(event) => this.handleDelete(event, qualification)}>Delete</Button>
															</div>
														</div>
													</div>
												</div></td>
										</tr>
									)
							})}
						</tbody>
					</Table>
					{this.state.qualificationList.length >= 20 ?  this.state.qualificationList.length < this.state.totalElements ?
						<Button color="primary" className="loadMore" onClick={this.loadMore}>Load more...</Button> :
						<Button color="primary" className="loadMore" href="#top">Back to top</Button>:null
					}
					{this.state.qualificationList.length === 0 ? <h4 className="text-center mb -5"> No qualification is added</h4> : null}
					<EmptyJumbotron />
				</div>
			</Container>
		)
	}
}

