import React, { Component } from 'react';
import CacheBuster from '../CacheBuster';
import Container from 'react-bootstrap/Container';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from '../home/Home';
import RunTrack from '../runtrack/RunTrack';
import HealthRecords from '../healthrecords/HealthRecords';
import MyPrescriptionList from '../healthrecords/MyPrescriptionList';
import LoginSignUp from '../common/LoginSignUp';
import Contact from '../common/Contact';
import About from '../common/About';
import PrivacyPolicy from '../common/PrivacyPolicy';
import TermsConditions from '../common/TermsConditions';
import NotFound from '../common/NotFound';
import Navbar from '../common/CustomNavbar';
import CustomFooter from '../common/CustomFooter';

import Login from '../user/login/Login';
import Signup from '../user/signup/Signup';
import EmailVerification from '../user/signup/EmailVerification';
import Profile from '../user/profile/Profile';
import ProfileForm from '../user/profile/ProfileForm';
import PasswordForm from '../user/profile/PasswordForm';
import EmailFormToResetPassword from '../user/login/EmailFormToResetPassword';
import ResetPassword from '../user/login/ResetPassword';
import OAuth2RedirectHandler from '../user/oauth2/OAuth2RedirectHandler';
import LoadingIndicator from '../common/LoadingIndicator';
import DoctorProfile from '../doctors/DoctorProfile'

import Specialization from '../specialization/Specialization';
import SpecializationForm from '../specialization/SpecializationForm';
import Qualification from '../qualification/Qualification';
import QualificationForm from '../qualification/QualificationForm';
import Doctors from '../doctors/Doctors';
import DoctorForm from '../doctors/DoctorForm';

import EventDetails from '../event/EventDetails';
import ImageGallery from '../event/ImageGallery';
import Event from '../event/Event';
import EventForm from '../event/EventForm';
import EventRegistration from '../event/EventRegistration';
import EventCustomize from '../event/EventCustomize';
import Admin from '../admin/Admin';
import { getCurrentUser } from '../util/APIUtils';
import { ACCESS_TOKEN, AUTHENTICATED, CURRENT_USER } from '../constants';
import PrivateRoute from '../common/PrivateRoute';
import Alert from 'react-s-alert';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import './App.css';
import Schedule from '../schedule/doctorconfig/Schedule';
import PrescriptionForm from '../doctors/prescription/PrescriptionForm';
import ScheduleDetails from '../schedule/doctorconfig/ScheduleDetails'
import PrescriptionDetails from '../doctors/prescription/PrescriptionDetails';
import Users from '../user/Users';
import AppointmentForm from '../appointments/AppointmentForm';
import AppointmentList from '../appointments/AppointmentList'
import AppointmentDetail from '../appointments/AppointmentDetails';
import Appointment from '../appointments/Appointment';

import AddCategory from '../healthrecords/AddCategory'
import UploadMedical from '../healthrecords/UploadMedical';
import PatientList from '../Patients/PatientList'
import Category from '../healthrecords/Category';
import HabitTracker from '../healthrecords/HabitTracker';
import TrackHabit from '../healthrecords/TrackHabit'
import ShareRecord from '../healthrecords/ShareRecord'
import ShareWithList from '../healthrecords/ShareWithList';
import ShareByList from '../healthrecords/ShareByList';
import SharedRecordDetail from '../healthrecords/SharedRecordDetail'
import FeedbackForm from '../healthrecords/FeedbackForm';
import ShareWithRecordDetails from '../healthrecords/SharedWithRecordDetail';
import { getRole } from '../util/functionUtils';
import Consent from '../appointments/Consent';
import EntityForm from '../entity/EntityForm';
import EntityList from '../entity/EntityList';
import EntityAssociation from '../entity/EntityAssociation';
import Entity from '../entity/Entity'
import EntityDoctorList from '../entity/EntityDoctorList';
import EntityPatientList from '../entity/EntityPatientList';
import EntityAppointmentsList from '../entity/EntityAppointmentsList';
import Feedback from '../appointments/Feedback'
import PaymentSuccess from '../payment/PaymentSuccess';
import PaymentResponse from '../payment/PaymentResponse';

import Address from '../payment/Address';
import OrderSummery from '../payment/OrderSummery';
import PharmacyList from '../pharmacy/PharmacyList';
import PharmacyDetails from '../pharmacy/PharmacyDetails';
import MyPharmacyOrder from '../pharmacy/MyPharmacyOrder';
import MyOrder from '../pharmacy/MyOrder';
import PharmacyOrderList from '../pharmacy/PharmacyOrderList';
import PharmacyScheduleConfig from '../pharmacy/PharmacyScheduleConfig';
import PatientMedicineSelect from '../pharmacy/PatientMedicineSelect'
import HealthBlog from '../healthblogs/BlogList';
import BlogDetails from '../healthblogs/BlogDetails';
import UserEventRegistrationList from '../event/UserEventRegistrationList';
import {Helmet} from 'react-helmet';
import ReactGA from 'react-ga';
const TRACKING_ID = "G-9H90RQ4D4G"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

class App extends Component {

  constructor(props) {
    super();
    this.state = {
      authenticated: localStorage.getItem(AUTHENTICATED) ? localStorage.getItem(AUTHENTICATED) : false,
      currentUser: null,
      loaded: false,
    }
    this.loadCurrentlyLoggedInUser = this.loadCurrentlyLoggedInUser.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.refreshPage = this.refreshPage.bind(this);
  }

  refreshPage() {
    window.location.reload(true);
  }

  loadCurrentlyLoggedInUser() {
    if (!localStorage.getItem(CURRENT_USER)) {
      getCurrentUser()
        .then(response => {
          localStorage.setItem(CURRENT_USER, JSON.stringify(response));
          this.setState({
            currentUser: response,
            authenticated: true,
            loaded: true
          });
        }).catch(error => {
          this.setState({
            loaded: true
          });
        });
    } else {
      this.setState({
        currentUser: JSON.parse(localStorage.getItem(CURRENT_USER)),
        authenticated: true,
        loaded: true
      });
    }
  }

  handleLogout() {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(AUTHENTICATED);
    localStorage.removeItem(CURRENT_USER);
    this.setState({
      authenticated: false,
      currentUser: null
    });
    // this.refreshPage();
    Alert.success("You're safely logged out!");
  }

  componentDidMount() {
    this.loadCurrentlyLoggedInUser();
  }


  render() {

    if (!this.state.loaded) {
      return <LoadingIndicator />
    }

return(
  <CacheBuster>
    {({ loading, isLatestVersion, refreshCacheAndReload }) => {
      if (loading) return null;
      if (!loading && !isLatestVersion) {
        refreshCacheAndReload();
      }

      return (
        <div className="app">
          <Router>
            <Helmet>
                <title>Binary Intuition Health</title>
                <meta name="description" content="BI Health: A community health platform to help the community"/>
                <meta name="keywords" content="online doctor appointment, online doctor and prescription, 
                online doctor and pharmacy, online doctor consultation, online doctor opinion, online doctor query, 
                online doctor and prescription, best online doctor, get a doctor online, free doctor online, 
                online doctor chat, online doctor consultation app, online doctor india, online doctor job,
                online doctor se baat, online doctor service, online doctor se baat, online doctor talk, 
                online doctor treatment, online doctor video consultation, online doctor video consultation india,
                virtual doctor, homeopathic doctors online, homeopathy doctors online, homeopathic medicine online,
                homeopathy online doctor appointment, online habit tracking, share prescription to docotrs, 
                prescription digi locker, online health records management, online doctors portal for clinic, 
                online doctors portal for hospital, online portals for phamacy, online portals for phamacies, 
                online medicine"/>
            </Helmet>
            <Navbar authenticated={this.state.authenticated} currentUser={this.state.currentUser}
              onLogout={this.handleLogout} />
            <Container className="mncontainer">
              <Switch >
                <Route exact path="/" component={Home} />
                <Route exact path="/dc" authenticated={this.state.authenticated} currentUser={this.state.currentUser}
                  component={Doctors}></Route>
                <PrivateRoute exact path="/profile" authenticated={this.state.authenticated} currentUser={this.state.currentUser}
                  component={Profile}></PrivateRoute>
                <PrivateRoute exact path="/profileedit" authenticated={this.state.authenticated} currentUser={this.state.currentUser}
                  component={ProfileForm}></PrivateRoute>
                <PrivateRoute exact path="/changepassword" authenticated={this.state.authenticated} currentUser={this.state.currentUser}
                  component={PasswordForm}></PrivateRoute>
                <Route exact path="/login" render={(props) => <Login authenticated={this.state.authenticated} {...props} handleAuth={this.refreshPage} />}></Route>
                <Route exact path="/signup" render={(props) => <Signup authenticated={this.state.authenticated} {...props} />}></Route>
                <Route exact path="/oauth2/redirect" component={OAuth2RedirectHandler}></Route>
                <Route exact path="/verify" component={EmailVerification}></Route>
                <Route exact path="/eg" component={ImageGallery} />
                <Route exact path="/dp/:id" component={DoctorProfile} />
                <Route exact path="/run-track" component={RunTrack} />
                <PrivateRoute exact path="/health-records" authenticated={this.state.authenticated} currentUser={this.state.currentUser} component={HealthRecords} />
                <PrivateRoute exact path="/my-prescription-list" authenticated={this.state.authenticated} currentUser={this.state.currentUser} component={MyPrescriptionList} />
                <Route exact path="/login-singup" component={LoginSignUp} />
                <Route exact path="/contact" component={Contact} />
                <Route exact path="/about" component={About} />
                <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                <Route exact path="/terms" component={TermsConditions} />
                <Route exact path="/terms" component={TermsConditions} />
                <Route exact path="/forgotpassword" component={EmailFormToResetPassword} />
                <Route exact path="/reset" component={ResetPassword} />
                <Route exact path="/ed/:id" component={EventDetails} />
                <Route exact path="/ec" component={EventCustomize} />
                <Route exact path="/events" component={Event} />
                <Route exact path="/eventform" component={EventForm}></Route>
                <Route exact path="/rwith-record/:id" component={ShareWithRecordDetails} />
                <Route exact path="/er" component={EventRegistration} />
                <PrivateRoute exact path="/add-doctor" authenticated={this.state.authenticated} currentUser={this.state.currentUser} component={DoctorForm} />
                <PrivateRoute exact path="/dconfig" authenticated={this.state.authenticated} currentUser={this.state.currentUser} component={Schedule} />
                <PrivateRoute exact path="/dconfigdetails" authenticated={this.state.authenticated} currentUser={this.state.currentUser} component={ScheduleDetails} />
                <PrivateRoute exact path="/pf" authenticated={this.state.authenticated} currentUser={this.state.currentUser} component={PrescriptionForm} />
                <PrivateRoute exact path="/pd" authenticated={this.state.authenticated} currentUser={this.state.currentUser} component={PrescriptionDetails} />
                <PrivateRoute exact path="/appointment-form" authenticated={this.state.authenticated} currentUser={this.state.currentUser} component={AppointmentForm} />
                <PrivateRoute exact path="/ap" authenticated={this.state.authenticated} currentUser={this.state.currentUser} component={Appointment} />
                <PrivateRoute exact path="/add-category" authenticated={this.state.authenticated} currentUser={this.state.currentUser} component={AddCategory} />
                <PrivateRoute exact path="/track-habit" authenticated={this.state.authenticated} currentUser={this.state.currentUser} component={TrackHabit} />
                <PrivateRoute exact path="/health-report" authenticated={this.state.authenticated} currentUser={this.state.currentUser} component={UploadMedical} />
                <PrivateRoute exact path="/category" authenticated={this.state.authenticated} currentUser={this.state.currentUser} component={Category} />
                <PrivateRoute exact path="/habit-tracker" authenticated={this.state.authenticated} currentUser={this.state.currentUser} component={HabitTracker} />
                <PrivateRoute exact path='/share-record' authenticated={this.state.authenticated} currentUser={this.state.currentUser} component={ShareRecord} />
                <PrivateRoute exact path='/share-with' authenticated={this.state.authenticated} currentUser={this.state.currentUser} component={ShareWithList} />
                <PrivateRoute exact path='/share-by' authenticated={this.state.authenticated} currentUser={this.state.currentUser} component={ShareByList} />
                <PrivateRoute exact path='/record-detail/:id' authenticated={this.state.authenticated} currentUser={this.state.currentUser} component={SharedRecordDetail} />
                <PrivateRoute exact path='/feedback' authenticated={this.state.authenticated} currentUser={this.state.currentUser} component={FeedbackForm} />
                <PrivateRoute exact path="/ap" authenticated={this.state.authenticated} currentUser={this.state.currentUser} component={Appointment} />
                <PrivateRoute exact path="/appointment-list" authenticated={this.state.authenticated} currentUser={this.state.currentUser} component={AppointmentList} />
                <PrivateRoute exact path="/appointment-details" authenticated={this.state.authenticated} currentUser={this.state.currentUser} component={AppointmentDetail} />
                <PrivateRoute exact path="/patientlist" authenticated={this.state.authenticated} currentUser={this.state.currentUser} component={PatientList} />
  
                <Route exact path="/appt-consent" authenticated={this.state.authenticated} currentUser={this.state.currentUser} component={Consent} />
                <Route exact path="/en" authenticated={this.state.authenticated} currentUser={this.state.currentUser} component={EntityForm} />
                <Route exact path="/en-list" authenticated={this.state.authenticated} currentUser={this.state.currentUser} component={EntityList} />
                <Route exact path="/entity-association" authenticated={this.state.authenticated} currentUser={this.state.currentUser} component={EntityAssociation} />
                <Route exact path="/ent" authenticated={this.state.authenticated} currentUser={this.state.currentUser} component={Entity} />
                <Route exact path="/en-dl" authenticated={this.state.authenticated} currentUser={this.state.currentUser} component={EntityDoctorList} />
                <Route exact path="/en-pl" authenticated={this.state.authenticated} currentUser={this.state.currentUser} component={EntityPatientList} />
                <Route exact path="/en-ap" authenticated={this.state.authenticated} currentUser={this.state.currentUser} component={EntityAppointmentsList} />
                <Route exact path="/fd" authenticated={this.state.authenticated} currentUser={this.state.currentUser} component={Feedback} />
                <Route exact path="/ps" component={PaymentSuccess} />
                <Route exact path="/pc" component={AppointmentDetail} />
                <Route exact path="/ad" authenticated={this.state.authenticated} currentUser={this.state.currentUser} component={Address} />
                <Route exact path="/od-sm" authenticated={this.state.authenticated} currentUser={this.state.currentUser} component={OrderSummery} />
                <Route exact path="/pr" component={PaymentResponse} />
                <Route exact path="/hb" component={HealthBlog}/>
                <Route exact path="/bd/:type" component={BlogDetails}/>
                <Route exact path="/pmacy" component={PharmacyList} />
              <Route exact path="/pd/:id" component={PharmacyDetails} />
              <Route exact path="/or" component={MyPharmacyOrder} />
              <Route exact path="/ph-or" component={MyOrder} />
              <Route exact path="/or-list" component={PharmacyOrderList} />
              <Route exact path="/ph-sc" component={PharmacyScheduleConfig} />
              <Route exact path="/ph-mc-sl" component={PatientMedicineSelect} />
  
                {this.state.currentUser && getRole().roleAdmin ?
                  <>
                    <PrivateRoute exact path="/spform" authenticated={this.state.authenticated} currentUser={this.state.currentUser}
                      component={SpecializationForm}></PrivateRoute>
                    <Route exact path="/sp" authenticated={this.state.authenticated} currentUser={this.state.currentUser}
                      component={Specialization}></Route>
                    <PrivateRoute exact path="/admin" authenticated={this.state.authenticated} currentUser={this.state.currentUser} component={Admin}></PrivateRoute>
                    <PrivateRoute exact path="/qlform" authenticated={this.state.authenticated} currentUser={this.state.currentUser}
                      component={QualificationForm}></PrivateRoute>
                    <Route exact path="/qlf" authenticated={this.state.authenticated} currentUser={this.state.currentUser}
                      component={Qualification}></Route>
                    <PrivateRoute exact path="/users" authenticated={this.state.authenticated} currentUser={this.state.currentUser} component={Users} />
                    <PrivateRoute exact path="/ep/:eventId" authenticated={this.state.authenticated} currentUser={this.state.currentUser} component={UserEventRegistrationList} />
                  </>
                  : null}
                <Route exact path="/not-found" component={NotFound} />
                <Route component={NotFound} />
              </Switch>
            </Container>
            <CustomFooter />
          </Router>
          <Alert stack={{ limit: 3 }}
            timeout={4000}
            position='top-right' effect='slide' offset={65} />
        </div>
      );
    }
  }
  </CacheBuster>
);
    
  }
}
export default App;
