import React, { Component } from 'react'
import { Container } from 'react-bootstrap';
import './PatientList.css';

export default class PatientList extends Component {

    render() {
        return (
            <Container>
                <div className="patients-details-container">
                <h2>List of Patients</h2>
                </div>
            </Container>
        )
    }
}
