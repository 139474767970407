import React, { Component } from 'react';
import { Container, Form, Row, Col, Button } from 'react-bootstrap';
import Alert from 'react-s-alert';
import LoadingIndicator from '../common/LoadingIndicator';
import { Link } from 'react-router-dom';
import EmptyJumbotron from '../common/EmptyJumbotron';
import './EntityForm.css';
import { createEntity, updateEntity } from '../util/EntityUtils'



class EntityForm extends Component {

    constructor(props) {
        super(props)

        if (this.props.location.editEntity) {
            this.state = {
                name: this.props.location.editEntity.name,
                description: this.props.location.editEntity.description,
                id: this.props.location.editEntity.id
            }
        } else {
            this.state = {
                name: "",
                description: "",
                entity: ''
            }
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;
        const inputValue = target.value;

        this.setState({
            [inputName]: inputValue
        });
    }

    handleSubmit(event) {
        this.setState({ loading: true })
        event.preventDefault();
        if (this.props.location.editEntity) {

            const updateEntityRequest = Object.assign({}, this.state)
            updateEntity(updateEntityRequest)
                .then(response => {
                    this.setState({ loading: false })
                    Alert.success("Entity updated successully!");
                    this.props.history.push({
                        pathname: '/en-list',
                        state: {}
                    })
                }).catch(error => {
                    this.setState({ loading: false })
                    Alert.error((error && error.message) || 'Oops! Something went wrong. Please try again!');
                })
        } else {
            const { match: { params }, history } = this.props
            const createEntityRequest = Object.assign({}, this.state)
            createEntity(createEntityRequest)
                .then(response => {
                    this.setState({
                        entity: response.body,
                        loading: false
                    })
                    Alert.success("Entity created successully!");
                    this.props.history.push({
                        pathname: '/entity-association',
                        state: { entityId: this.state.entity.id, entityName: this.state.entity.name }       //to do send entityId and Name (not coming in response)
                    })
                }).catch(error => {
                    this.setState({ loading: false })
                    Alert.error((error && error.message) || 'Oops! Something went wrong. Please try again!');
                })
        }
    }


    render() {

        return (
            <Container>
                <div className="entity-container">
                    <div className="feature-title container-fluid">
                        <h3>{this.state.id ? "Update Entity" : "Create Entity"}</h3>
                        <hr />
                    </div>
                    <Form onSubmit={this.handleSubmit}>
                        <Form.Group as={Row} controlId="code">
                            <Form.Label column sm={2}>
                                Name
                            </Form.Label>
                            <Col sm={3}>
                                <Form.Control type="text" value={this.state.name} name="name" placeholder="Name" maxlength="20" onChange={this.handleInputChange} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="description">
                            <Form.Label column sm={2}>
                                Description
                            </Form.Label>
                            <Col sm={3}>
                                <Form.Control as="textarea" rows={3} value={this.state.description} name="description" placeholder="Description" maxlength="100" onChange={this.handleInputChange} />

                            </Col>
                        </Form.Group>
                        <Col sm={3}>
                            <Link to="/en-list" className="float-left btn btn-primary">Back</Link>
                        </Col>
                        <Col sm={5}>
                            <Button type="submit" className="float-right">Submit</Button>
                        </Col>
                    </Form>
                </div>
                <EmptyJumbotron />
            </Container>
        );
    }
}

export default EntityForm;