import React, { Component } from 'react';
import { Row, Col, Card, Container, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './EntityForm.css'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { getRole } from '../util/functionUtils';



class Entity extends Component {

    constructor(props) {
        super(props)
        this.state = {
            role: getRole(),
            entityId: this.props.history.location.state ? this.props.history.location.state.entityId : null,
            entityName: this.props.history.location.state ? this.props.history.location.state.entityName : null,
        }
    }


    render() {
        return (
            <Container>
                {this.state.role.roleEntityAdmin ?
                    <div className="entity-container">
                        <div className="feature-title container-fluid">
                            <h3>{this.state.entityName}</h3>
                            <hr />
                        </div>
                        <Link to="/en-list" className="mb-4"><Button varient="primary"> <ArrowBackIcon /> </Button></Link>
                        <Row className="justify-content-center">
                            <Col sm={6} md={6} xl={3} className="mb-3">
                                <Link to={{ pathname: "/en-dl", state: { entityId: this.state.entityId, entityName: this.state.entityName } }} style={{ textDecoration: "none" }} className="text-center">
                                    <Card
                                        className="m-2"
                                        bg="primary"
                                        text="light"
                                        style={{ width: '15rem', minHeight: '10rem' }}
                                    >
                                        <Card.Body>
                                            <Card.Title>
                                                <h3 className="mt-4">Doctors</h3>
                                            </Card.Title>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </Col>
                            <Col sm={6} md={6} xl={3} className="mb-3">
                                <Link to={{ pathname: "/en-pl", state: { entityId: this.state.entityId, entityName: this.state.entityName } }} style={{ textDecoration: "none" }} className="text-center">
                                    <Card
                                        className="m-2"
                                        bg="primary"
                                        text="light"
                                        style={{ width: '15rem', minHeight: '10rem' }}
                                    >
                                        <Card.Body>
                                            <Card.Title>
                                                <h3 className="mt-4">Patients</h3>
                                            </Card.Title>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </Col>
                            <Col sm={6} md={6} xl={3} className="mb-3">
                                <Link to={{ pathname: "/en-ap", state: { entityId: this.state.entityId, entityName: this.state.entityName } }} style={{ textDecoration: "none" }} className="text-center">
                                    <Card
                                        className="m-2"
                                        bg="primary"
                                        text="light"
                                        style={{ width: '15rem', minHeight: '10rem' }}
                                    >
                                        <Card.Body>
                                            <Card.Title>
                                                <h3 className="mt-4">Appointments</h3>
                                            </Card.Title>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </Col>
                        </Row>
                    </div>
                    : null}

            </Container>
        );
    }
}

export default Entity;