import { DOCTORS_API_BASE_URL } from '../constants';
import { request } from './APIUtils';




export function searchDoctorBy(name, spCode, qlCode, country, state, city, pageNumber, pageSize) {

    console.log(name, spCode, qlCode, country, state, city, pageNumber, pageSize);
    return request({
        url: DOCTORS_API_BASE_URL + "/doctor?name=" + name + "&sc=" + spCode + "&ql=" + qlCode + "&country=" + country + "&state=" + state + "&city=" + city + "&page=" + pageNumber + "&size=" + pageSize,
        method: 'GET'
    });



    // else if (country !== null && state !== null){
    //     return request({
    //         url: DOCTORS_API_BASE_URL + "/doctor?country=" + country + "&state=" + state + "&page=" + pageNumber + "&size=" + pageSize,
    //         method: 'GET'
    //     });

    // }

    // else if (country !== null)
    // {
    //     return request({
    //         url: DOCTORS_API_BASE_URL + "/doctor?country=" + country + "&page=" + pageNumber + "&size=" + pageSize,
    //         method: 'GET'
    //     });
    // }


}


export function getDoctorListByStatus(status) {
    return request({
        url: DOCTORS_API_BASE_URL + "/doctor?status=" + status,
        method: 'GET'
    });
}