import React, { Component } from 'react'
import { Button, Container, Modal, Form, Row, OverlayTrigger, Popover, Table } from 'react-bootstrap';
import './UploadMedical.css'
import AddSharpIcon from '@material-ui/icons/AddSharp';
import { uploadMedicalRecord, getMedicalReport, DeleteRecordById, downloadHealthRecord } from '../util/healthAPIUtils'
import { validateFileType, validateFileSize } from '../util/functionUtils'
import { Link, withRouter } from 'react-router-dom';
import Alert from 'react-s-alert';
import SettingsIcon from '@material-ui/icons/Settings';
import CancelIcon from '@material-ui/icons/Cancel';
import { getCurrentUserId, uploadFile, getDateSafariHack,get_url_extension } from '../util/functionUtils'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import LoadingIndicator from '../common/LoadingIndicator';
import Iframe from 'react-iframe'

class UploadMedical extends Component {
    constructor(props) {
        super(props)
        if (this.props.history.location.state) {
            this.state = {
                show: false,
                categoryType: this.props.history.location.state.type,
                categoryName: this.props.history.location.state.name,
                hrcatId: this.props.history.location.state.id,
                ownerId: getCurrentUserId(),
                memberId: null,
                fileName: '',
                medicalReportDetail: [],
                recordId: [],
                fileNames: [],
                contentType: '',
                hrurl: '',
                hrloaded: false,
                notValidFileType: false,
                notValidFileSize: false,
                loading: true,
                disabled: true
            }
        }
    }

    handleFileChange = (event) => {

        this.setState({
            fileName: event.target.files[0].name,
            contentType: event.target.files[0].type,
            notValidFileType: false,
            notValidFileSize: false
        })
        this.setState({
            fileData: event.target.files[0],
        })

    }

    handleShow = () => {
        this.setState({
            show: true
        })
    }
    handleClose = () => {
        this.setState({
            show: false
        })
    }

    showLoader = () => {
        this.setState({
            loading: true
        })
    }

    hideLoader = () => {
        this.setState({
            loading: false
        })
    }

    handleSubmit = () => {
        this.showLoader();

        const uploadRecordRequest = this.state;
        uploadRecordRequest.contentType = this.state.contentType
        const fileType = this.state.fileName.split('.').pop().toLowerCase();
        const fileSize = this.state.fileData.size
        if (validateFileType(fileType)) {
            if (validateFileSize(fileSize)) {
                uploadMedicalRecord(uploadRecordRequest)
                    .then(response => {
                        Alert.success(" Health record upload in progress")
                        this.handleClose();
                        uploadFile(response.url, this.state.fileData)
                            .then(response => {
                                Alert.success("Health record uploaded Successfully")
                                this.loadMedicalReport()
                            })
                            .catch(error => {
                                Alert.error((error && error.message) || 'Error uploading file');
                            })
                    })
                    .catch(error => {
                        Alert.error((error && error.message) || 'Oops! Something went wrong. Please try again!');
                        this.hideLoader();
                    })
            } else {
                this.setState({
                    notValidFileSize: true
                })
                this.hideLoader();
            }

        } else {
            this.setState({
                notValidFileType: true
            })
            this.hideLoader();
        }
    }

    loadMedicalReport() {
        getMedicalReport(this.state.ownerId, this.state.hrcatId)
            .then(response => {
                this.setState({
                    medicalReportDetail: response.content,
                    loading: false
                })
            })
            .catch(error => {
                Alert.error((error && error.message) || 'Oops! Something went wrong. Please try again!');
            })
    }

    handleDeleteRecord = (recordId) => {
        DeleteRecordById(recordId)
            .then(response => {
                Alert.success("record deleted successfully")
                window.location.reload()
            })
            .catch(error => {
                Alert.error((error && error.message) || 'Oops! Something went wrong. Please try again!');
            })
    }
    popover = () => {
        return (
            <Popover id="popover-basic">
                <Popover.Content>
                    <Row>
                        <Link to={{ pathname: "/share-record", state: { recordId: this.state.recordId, categoryId: this.state.hrcatId, categoryName: this.state.categoryName, categoryType: this.state.categoryType, fileName: this.state.fileNames, backpath: "/health-report" } }} > <Button className="m-2 btn-outline">Share</Button></Link>
                    </Row>
                </Popover.Content>
            </Popover>
        );
    }

    checkInputHandler = (event, recordId, fileName) => {
        if (event.target.checked) {
            this.setState({
                recordId: [...this.state.recordId, recordId],
                fileNames: [...this.state.fileNames, fileName],
            })
        } else {
            this.setState({
                recordId: [...this.state.recordId.filter((index) => index !== recordId)],
                fileNames: [...this.state.fileNames.filter((fileName) => fileName !== fileName)]
            })
        }
    }

    viewRecord = (recordId) => {
        downloadHealthRecord(recordId, false)
            .then(url => {
                // window.location.href = url;
                // Alert.success("Health record ready to view!")
                this.setState({
                    hrurl: url,//('https://docs.google.com/viewer?url=' + url+'&embedded=true'),
                    hrloaded: true
                })
                document.getElementById("viewId").scrollIntoView();
            })
            .catch(error => {
                Alert.error((error && error.message) || 'Something went wrong, please try again later!');
            })
    }

    downloadRecord = (recordId) => {
        downloadHealthRecord(recordId, true)
            .then(url => {
                window.location.href = url;
                Alert.success("Health record downloaded successfully!")
            })
            .catch(error => {
                Alert.error((error && error.message) || 'Something went wrong, please try again later!');
            })
    }

    componentDidMount() {
        this.loadMedicalReport()
        window.scrollTo(0, 0)
    }


    render() {

        if (this.state.loading) {
            return <LoadingIndicator />
        }
        return (
            <Container>
                <div className="upload-records-container" >
                    <h3 className="text-center"> Health Records <span className="text-blue">{this.state.categoryType.charAt(0).toUpperCase() + this.state.categoryType.substr(1).toLowerCase()}:{this.state.categoryName}</span></h3>
                    <br />
                    <div>
                        <Link className="float-left btn btn-primary" to={{ pathname: "/category" }}><ArrowBackIcon /></Link>
                        <Button variant="primary" onClick={this.handleShow} className="float-right">
                            <AddSharpIcon />
                        </Button>
                        <br />
                        <br />

                        <Row className="hide-button">
                            <Link to={{ pathname: "/share-record", state: { recordId: this.state.recordId, categoryId: this.state.hrcatId, categoryName: this.state.categoryName, categoryType: this.state.categoryType, fileName: this.state.fileNames, backpath: "/health-report" } }}> <Button className="m-2 btn-outline" disabled={this.state.recordId.length == 0 ? true : false}>Share</Button></Link>
                        </Row>
                        <Modal show={this.state.show} onHide={this.handleClose} animation={false}>
                            <Modal.Header closeButton>
                                <Modal.Title>Health Record</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="mb-3">
                                    <Form.File id="formcheck-api-regular">
                                        <Form.File.Input onChange={this.handleFileChange} placeholder="file" />
                                        {this.state.notValidFileType ? <p className="text-danger">File type not supported</p> : null}
                                        {this.state.notValidFileSize ? <p className="text-danger">File should be smaller than 5 MB</p> : null}
                                    </Form.File>
                                    <Form.Label>Allowed file types(PDF, JPG, JPEG, PNG) Max allowed file size 5 MB</Form.Label>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={this.handleClose}>
                                    Cancel
                                </Button>
                                <Button variant="primary" onClick={this.handleSubmit}>
                                    Submit
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <br />
                        <Table responsive className="shadow" id="uploadmedical">
                            <thead className="thead-color">
                                {this.state.medicalReportDetail.length === 0 ? null :
                                    <tr id="uploadmedicalHead">
                                        <th className="text-center">Name</th>
                                        <th className="text-center">Created On</th>
                                        <th className="text-center">Download</th>
                                        <th className="text-center">View</th>
                                        <th className="text-center">Delete</th>
                                        <th className="text-center"><OverlayTrigger trigger="click" placement="auto" overlay={this.popover()}>
                                            <SettingsIcon />
                                        </OverlayTrigger></th>
                                    </tr>
                                }
                            </thead>
                            <tbody>
                                {
                                    this.state.medicalReportDetail.length === 0 ? null : this.state.medicalReportDetail.map((value) => {
                                        return (
                                            <tr>
                                                <td data-label="Name" className="text-md-center">{value.fileName}</td>
                                                <td data-label="Created On" className="text-md-center">{(getDateSafariHack(value.createdAt)).toLocaleDateString([], { year: 'numeric', month: 'long', day: 'numeric', hour: "numeric", minute: "numeric", hour12: "true" })}</td>
                                                <td data-label="Download" className="text-md-center"><CloudDownloadIcon color="primary" style={{ cursor: 'pointer' }} onClick={() => this.downloadRecord(value.id)} /></td>
                                                <td data-label="view" className="text-md-center"><VisibilityIcon color="primary" style={{ cursor: 'pointer' }} onClick={() => this.viewRecord(value.id)} /></td>
                                                <td data-label="Delete" className="text-md-center"> <CancelIcon data-toggle="modal" data-target="#staticBackdrop" color="secondary" />
                                                    <div className="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                        <div className="modal-dialog">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5 className="modal-title textColor" id="staticBackdropLabel">Are you sure you want to delete ?</h5>
                                                                    <Button type="Button" className="close" data-dismiss="modal" aria-label="Close">
                                                                        <span aria-hidden="true">&times;</span>
                                                                    </Button>
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <Button type="Button" className="btn btn-secondary" data-dismiss="modal">Cancel</Button>
                                                                    <Button type="Button" className="btn btn-primary" onClick={() => this.handleDeleteRecord(value.id, value.name)}>Delete</Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td data-label="Select" className="text-md-center"> <Form.Check type="checkbox" onChange={(event) => this.checkInputHandler(event, value.id, value.fileName)} /></td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    </div>
                    {this.state.medicalReportDetail.length === 0 ? <h3 className="text-center">No health record found, Please upload health record.</h3> : null}
                    <div className="container-iframe">
                             
                        {this.state.hrloaded === true ? get_url_extension(this.state.hrurl)==='pdf'?
                            <iframe className="responsive-iframe" src={this.state.hrurl + "#toolbar=0"}
                                width="650px"
                                height="450px"
                                id="viewId"
                                display="initial"
                                onContextMenu={(e) => e.preventDefault()} />:
                                <img className="responsive-iframe" src={this.state.hrurl + "#toolbar=0"}
                                width="650px"
                                height="450px"
                                id="viewId"
                                onContextMenu={(e) => e.preventDefault()} />
                            :null
                        }
                    </div>
                </div>
                <br />
            </Container>
        )
    }
}
export default withRouter(UploadMedical)