import React, { Component } from 'react'
import { Row, Col, Button, Container, Table, Popover } from 'react-bootstrap';
import './AppointmentList.css'
import { getAppointmentList } from '../util/OrdersAPIUtils';
import { changeTime, getCurrentUserId } from '../util/functionUtils';
import Alert from 'react-s-alert';
import { Link } from 'react-router-dom';
import { status } from '../constants/index';
import LoadingIndicator from '../common/LoadingIndicator';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';


export default class AppointmentList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            consumerId: this.props.history.location.state.consumerId ? this.props.history.location.state.consumerId : '',
            providerType: 'ONLINE_DOC,PHYSICAL_DOC',
            providerId: this.props.history.location.state.providerId ? this.props.history.location.state.providerId : '',
            appointmentList: [],
            pageNumber: 0,
            pageSize: 20,
            totalElements: 0,
            loading: true,
            superUser: this.props.history.location.state ? this.props.history.location.state.superUser : false
        }
        this.loadMore = this.loadMore.bind(this)
    }

    loadAppointments = () => {
        getAppointmentList(this.state.consumerId, this.state.providerType, (this.state.providerId ? getCurrentUserId() : ''), this.state.superUser, this.state.pageNumber, this.state.pageSize)
            .then(response => {
                this.setState({
                    appointmentList: response.content,
                    totalElements: response.totalElements,
                    loading: false
                })
            }).catch(error => {
                Alert.error((error && error.message) || 'Something went wrong, please try again later!');
            })
    }
    async loadMore() {
        await this.setState({
            pageNumber: this.state.pageNumber + 1,
        })
        getAppointmentList(this.state.consumerId, this.state.providerType, (this.state.providerId ? getCurrentUserId() : ''), this.state.superUser, this.state.pageNumber, this.state.pageSize)
            .then(response => {
                this.setState({
                    appointmentList: [...this.state.appointmentList, ...response.content],
                });
            }).catch(error => {
                Alert.error((error && error.message) || 'Something went wrong, please try again later!');
            });
    }

    componentDidMount() {
        this.loadAppointments()
        window.scrollTo(0, 0)
    }

    popover = () => {
        return (

            <Popover id="popover-basic">
                <Popover.Title as="h3">Action</Popover.Title>
                <Popover.Content>
                    <Row>
                        <Button className="m-2 btn-danger" onClick={() => this.updateStatusHandler("CANCELLED")}>Cancel</Button>
                    </Row>
                </Popover.Content>
            </Popover>
        );
    }

    render() {
        if (this.state.loading)
            return <LoadingIndicator />

        return (
            <Container className="mb-5">
                <div className="appointment-details-container" id="top">
                    <Row className="welcome text-center mb-5">
                        <Col sm={12}>
                            <h3>Appointments</h3>
                        </Col>
                        <hr />
                    </Row>
                    <Link to="/ap" className="mb-4"><Button varient="primary"> <ArrowBackIcon /> </Button></Link>
                    <Table responsive hover size="md" className="shadow mb-5" id="appointmentListTable">
                        {this.state.appointmentList.length === 0 ? null :
                            <thead className="thead-color">
                                <tr id="appointmentListTableHead">
                                    <th>Id</th>
                                    {this.state.providerId ? '' :
                                        <>
                                            <th>Doctor</th>
                                            <th>Speciality</th>
                                        </>
                                    }
                                    <th>Patient</th>
                                    <th>Time</th>
                                    <th>Status</th>
                                    <th>Amount</th>
                                    <th>Consultation</th>
                                </tr>
                            </thead>
                        }
                        <tbody>
                            {this.state.appointmentList.map(value => {
                                return (
                                    <tr>
                                        <td data-label="Id"><Link to={{ pathname: "/appointment-details", state: { appointmentId: value.id, providerId: this.state.providerId, consumerId: this.state.consumerId } }}>{value.id} </Link></td>
                                        {this.state.providerId ? '' :
                                            <>
                                                <td data-label="Doctor">{value.provider.imageUrl ? <img style={{ clipPath: 'circle()' }} src={value.provider.imageUrl} width="48px" alt="" /> : <span className="profile">{value.provider.name[0]}</span>} {" "}{value.provider.name}</td>
                                                <td data-label="Speciality">{value.provider.spec}</td>
                                            </>
                                        }
                                        {value.memberId ?
                                            <td data-label="Patient">
                                                <span className="profile">{value.member.name[0]}</span>
                                                {" "} {value.member.name}
                                            </td>

                                            : <td data-label="Patient">{value.consumer.imageUrl ? <img style={{ clipPath: 'circle()' }} src={value.consumer.imageUrl} width="48px" alt="" /> : <span className="profile">{value.consumer.name[0]}</span>}{" "} {value.consumer.name}</td>}
                                        <td data-label="Time">
                                            {(new Date(value.startTimeMs)).toLocaleDateString([], { year: 'numeric', month: 'long', day: 'numeric' })}<br />
                                            {changeTime(value.startTimeMs)} - {changeTime(value.endTimeMs)}

                                        </td>
                                        <td data-label="Status">{status[value.status]}</td>
                                        <td data-label="Amount">{value.order.currency}{" "}{value.order.value}</td>
                                        <td data-label="ConsSultation">
                                            {value.status === 'CONFIRMED' && this.state.providerId ?
                                                <Link to={{ pathname: "/pf", state: { patientInformation: { member: value.member, memberId: value.memberId, consumer: value.consumer, consumerId: value.consumerId, reason: value.reason }, doctorInformation: { ...value.provider }, link: value.serviceLink, appointmentId: value.id, providerId: this.state.providerId } }}><Button variant="primary" className="mt-2" >Start Now</Button></Link>
                                                : value.status === 'CONFIRMED' && this.state.consumerId ?
                                                    <a href={value.serviceLink} target="_blank"> <Button variant="primary" className="mt-2" >Start Now</Button> </a>
                                                    : <Button variant="primary" className="mt-2" disabled>Start Now</Button>
                                            }
                                        </td>
                                        {/* <td><Form.Check type="checkbox"  /></td> */}
                                    </tr>
                                )
                            })}

                        </tbody>
                    </Table>
                    {this.state.appointmentList.length === 0 ? this.state.providerId ? <h4 className="text-center mb-5"> Looks like no patient has scheduled any appointment with you till now !</h4> : <h4 className="text-center mb-5"> Looks like no appointment has been scheduled yet!</h4> : null}
                    <Row className="mb-5">
                        <Col xs={6} sm={7} md={8} lg={10}>
                            <Link to="/ap"><Button varient="primary"> Back </Button></Link>
                        </Col>
                        <Col xs={6} sm={5} md={4} lg={2}>
                            {this.state.appointmentList.length >= 20 ?
                                this.state.appointmentList.length < this.state.totalElements ?
                                    <Button variant="primary" className=" float-right" size="sm" onClick={this.loadMore}>Load more...</Button> :
                                    <Button variant="primary" className=" loadMore" size="sm" href="#top">Back to top</Button> : null
                            }
                        </Col>
                    </Row>
                </div>
            </Container>
        )
    }
}
