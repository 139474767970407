import React, { Component } from "react";
import { Container, Row, Col, Button, Media, Table } from "react-bootstrap";
import Alert from "react-s-alert";
import { loadPharmacyById, loadPharmacySchedule } from "../util/pharmacyUtil";
import "./PharmacyDetails.css";
import { Link } from "react-router-dom";
import LoadingIndicator from '../common/LoadingIndicator';
import { getRole } from '../util/functionUtils';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { getAddressInfo } from '../util/ReferentialAPIUtils'
import {convertIntoCamelCase } from '../util/functionUtils'

import { isSunday } from "date-fns";



export default class PharmacyDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pharmacyId: this.props.match.params.id,
      type: '',
      PharmacyDetails: "",
      pharmacySchedule: [],
      countryName: '',
      cityName: '',
      stateName: '',
      loading: true,
      role: getRole(),
    };
  }

  componentDidMount() {
    this.loadPharmacyDetail();
    window.scrollTo(0, 0)
  }

  loadPharmacyDetail() {
    loadPharmacyById(this.state.pharmacyId)
      .then((response) => {
        loadPharmacySchedule(this.state.pharmacyId, this.state.type)
          .then(response => {
            this.setState({
              pharmacySchedule: response,
            })
          })
        if (response.address) {
          getAddressInfo(response.address.countryCode, response.address.stateCode, response.address.cityCode)
            .then((response) => {
              this.setState({
                countryName: response.country,
                stateName: response.state,
                cityName: response.city
              })

            })
        }
        this.setState({
          PharmacyDetails: response,
          loading: false
        });
      })
      .catch((error) => {
        this.setState({
          loading: false
        });
        Alert.error((error && error.message) || "Something went wrong, please try again later!");
      });
  }

  returnDay(index) {
    if (index == 1)
      return "Sunday";
    if (index == 2)
      return "Monday";
    if (index == 3)
      return "TuesDay";
    if (index == 4)
      return "Wednesday";
    if (index == 5)
      return "Thursday";
    if (index == 6)
      return "Friday";
    if (index == 7)
      return "Saturday";
  }

  handleBack = () => {
    this.props.history.goBack();
  }

  render() {
    if (this.state.loading) {
      return <LoadingIndicator />
    }
    return (
      <Container>
        <div className="pharmacy-container">
          <Media>
            <img
              width={220}
              height={220}
              className="mr-3 media-image"
              src={
                this.state.PharmacyDetails.photoLink
                  ? this.state.PharmacyDetails.photoLink
                  : "../img/mac.png"
              }
              alt="pharmacy photo"
            ></img>

            <Media.Body>
              <h4 className="mb-3">{this.state.PharmacyDetails.name}</h4>
              <br />
              <p>
                <h6>Type:
                  {this.state.PharmacyDetails.types
                    ? this.state.PharmacyDetails.types.map((value, index) => {
                      if (index === 0) return " " + value.pharmacyType;
                      else return "," + value.pharmacyType;
                    })
                    : ""}
                </h6>
              </p>
              <p><h6>Address: {this.state.PharmacyDetails.address.house} {this.state.PharmacyDetails.address.street1 ? (", " + convertIntoCamelCase(this.state.PharmacyDetails.address.street1)) : null}, {this.state.PharmacyDetails.street2 ? (", " + convertIntoCamelCase(this.state.PharmacyDetails.street2)) : null}
                {this.state.PharmacyDetails.address.street3 ? (", " + convertIntoCamelCase(this.state.PharmacyDetails.address.street3)) : null} {convertIntoCamelCase(this.state.cityName)}, {convertIntoCamelCase(this.state.stateName)},
                {convertIntoCamelCase(this.state.countryName)}-{this.state.PharmacyDetails.address.pincode} </h6></p>

            </Media.Body>
          </Media>
          <hr />
          <Button  className="float-left btn btn-primary" onClick={this.handleBack}><ArrowBackIcon /></Button>
          {/* {this.state.PharmacyDetails.serviceAvailable ? <Link to={{ pathname: "/", state: {} }} className="btn btn-success">
            Order Medicine
          </Link> : null} */}

          <Table bordered className="shadow mt-3">
            <thead className="thead-color">
              <th>
                <h5>About  {this.state.PharmacyDetails.name} </h5>
              </th>
            </thead>
            <tbody>
              <td>
                <h6 className="space">
                  {this.state.PharmacyDetails.about}
                </h6>
              </td>
            </tbody>
          </Table>

          {this.state.pharmacySchedule.length != 0 ? <Table bordered className="shadow mt-3">
          <thead className="thead-color">
              <th>
                <h5>Service Schedule</h5>
              </th>
            </thead>
            </Table>:null}
          <Table responsive hover className="shadow mb-5" id="schedule-detail">
            <thead className="thead-color">
              {this.state.pharmacySchedule.length != 0 ? <tr id="schedule-detailHead">
                <th> Day</th>
                <th>Start Time</th>
                <th>End Time</th>
                <th>Type</th>
              </tr> : null}
            </thead>
            <tbody>
              {this.state.pharmacySchedule.map(s => {
                return (
                  <tr>
                    <td data-label="Day">{this.returnDay(s.dayOfTheweek)}</td>
                    <td data-label="Start Time">{s.startTimeStr}</td>
                    <td data-label="End Time">{s.endTimeStr}</td>
                    <td data-label="Type">{s.type}</td>
                  </tr>
                )

              })}
            </tbody>
          </Table>
          <Row>
            <Col>
              <Button onClick={this.handleBack}>Back</Button>
      
            </Col>
            {/* <Col >
              {this.state.role.roleAdmin ? <Button className="float-right">Edit</Button> : null}
            </Col> */}
          </Row>
          {/* <Row className="mt-2">
            <Col>
              {this.state.role.roleAdmin ? <Button>Activate</Button> : null}
            </Col>
            <Col>
              {this.state.role.roleAdmin ? <Button className="float-right" variant="danger">DeActivate</Button> : null}
            </Col>
          </Row> */}
          <br />
        </div>
      </Container>
    );
  }
}
