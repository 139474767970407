import { API_BASE_URL,PAYMENT_API_BASE_URL} from '../constants';
import { request } from './APIUtils';

export function saveBilligAddress(billingRequest){
    return request({
        url: API_BASE_URL + "/address",
        method: 'POST',
        body: JSON.stringify(billingRequest)
    });
}

export function getUserAddress(userId){
    return request({
        url: API_BASE_URL + "/address?uid=" + userId,
        method: 'GET',
    })
}

export function deleteAddressById(addressId){
    return request({
        url: API_BASE_URL + "/address?aid=" + addressId,
        method: 'DELETE',
    })
}

export function makepaymentRequest(paymentRequest){
    return request({
        url: PAYMENT_API_BASE_URL + "/payment/req",
        method: 'POST',
        body: JSON.stringify(paymentRequest)
    });
    // return true;
}


export function paymentResponse(paymentResponseRequest){
    return request({
        url: PAYMENT_API_BASE_URL + "/payment/res",
        method: 'POST',
        body: JSON.stringify(paymentResponseRequest)
    });
}