import React, { Component } from 'react';
import { Container, Card } from 'react-bootstrap'
import EmptyJumbotron from '../common/EmptyJumbotron';
import './HealthRecords.css';
import { Link } from 'react-router-dom';



export default class HealthRecords extends Component {
	constructor() {
		super()
		this.state = {
			healthRecordsIndex: [["My Prescription", "/my-prescription-list"], ["Health Records", "/category"], ["Shared By Me", "/share-by"], ["Shared With Me", "/share-with"], ["Habit Tracker", "/habit-tracker"]],
			colorArray: ['primary']
		}
	}

	componentDidMount() {
		window.scrollTo(0, 0)
	}

	render() {
		return (
			<Container>
				<div className="health-records-container container-fluid">
					<div className="feature-title container-fluid">
						<h3>Health Records</h3><hr />
					</div>
					<div className="row div-body">
						{this.state.healthRecordsIndex.map((value, index) => {
							return (
								<div className="col-xl-3 col-lg-4 col-sm-6 col-12 mb-4">
									<Link to={{ pathname: value[1] }} style={{ textDecoration: "none" }}>
										<Card
											bg={this.state.colorArray[(index) % (this.state.colorArray.length)]}
											text={this.state.colorArray[(index) % (this.state.colorArray.length)] === 'light' ? 'dark' : 'white'}
											style={{ width: '15rem', minHeight: '10rem' }}
										>
											<Card.Body>
												<Card.Title
													style={{ fontSize: "25px" }} className="text-center mt-5"
												>
													{value[0]}
												</Card.Title>
											</Card.Body>
										</Card>
									</Link>
								</div>
							)

						})
						}
					</div>
					<EmptyJumbotron />
				</div>
			</Container>

		);
	}
}