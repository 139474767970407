import React, { Component, Fragment } from 'react';
import CustomFooter from '../common/CustomFooter';
import './EventForm.css'
import Alert from 'react-s-alert';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {createEvent} from '../util/APIUtils';
import { getCountryList, getStateList} from '../util/ReferentialAPIUtils';

import { Form, Col, Button, Row, Table } from 'react-bootstrap'
// import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import AddSharpIcon from '@material-ui/icons/AddSharp';

import AddRow from './AddRow';
import Select from 'react-select';
import { Link } from 'react-router-dom';

class EventForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            paneList: [],
            moderator: [],
            sponsors: [],
            partners: [],
            countryList: [],
            states: [],
            cities: [],
            pincodes: [],
            displayAddress: { display: 'none' },
            displayLink: false,

            title: "",
            subTitle: "",
            type: "",
            dateStart: new Date(),
            dateEnd: new Date(),
            link: "",
            actors: [],
            address: [
                {
                    cityCode: "",
                    countryCode: "",
                    house: "",
                    pincode: "",
                    stateCode: "",
                    street1: "",
                    street2: "",
                    street3: ""
                }
            ],
            about: "",
            tnc: ""

        }

    }


    loadCountry = () => {
        getCountryList()
            .then(response => {
                this.setState({
                    countryList: response.map((data) => {
                        return { label: data.dislayStr, value: data }
                    })
                })
            }).catch(error => {
                console.log('No Data available');
            });

    }

    componentDidMount() {
        // this.loadCountry()
    }

    handleCountryChange = (selectedOption) => {
        const countryCode = selectedOption.value.code
        this.state.address[0].countryCode = countryCode
        getStateList(countryCode)
            .then(response => {
                const states = response.states
                this.setState({
                    states: states.map(data => {
                        return { label: data.dislayStr, value: data }
                    })
                })
            }).catch(error => {
                console.log('Something went wrong, please try again later!');
            })
    }

    dropdownhandleChange = (selectedOption, setValue, setName) => {

        const list = selectedOption.value[setValue]

        if (setValue == 'cities') {
            this.setState({
                [setValue]: list.map(data => {
                    return { label: data.dislayStr, value: data }
                })
            })

        }
        else if (setValue == 'pincodes') {
            this.setState({
                [setValue]: list.map(data => {
                    return { label: data.code, value: data }
                })
            })

        }
        this.state.address[0][setName] = selectedOption.value.code

    }

    addNewRow = (setValue) => {
        this.setState((prevState) => ({
            [setValue]: [...prevState[setValue],
            {
                index: Math.random(), name: '', email: '', isExternal: true,
                designation: '', organisation: '', type: setValue.toLocaleUpperCase(),
                google: '', facebook: '', linkedin: '', instagram: '', socialProfile: []
            }
            ]
        }));
    }

    deleteRow = (index, setValue) => {
        this.setState({
            [setValue]: this.state[setValue].filter((value, currIndex) => currIndex != index)
        });
    }

    eventType = (event) => {
        const inputValue = event.target.value;
        if (inputValue === 'PHYSICAL' || inputValue === 'default') {
            this.setState({
                displayAddress: { display: 'initial' },
                displayLink: false,
                type: inputValue
            })
        }
        else {
            this.setState({
                displayAddress: { display: 'none' },
                displayLink: true,
                type: inputValue
            })

        }
    }

    handleInputChange = (event) => {
        const target = event.target
        const inputName = target.name
        const inputValue = target.value

        this.setState({
            [inputName]: inputValue
        });
        console.log(this.state.link);
    }

    handleAddressChange = (event) => {
        const target = event.target
        const inputName = target.name
        const inputValue = target.value
        console.log(inputName, inputValue);
        this.state.address[0][inputName] = inputValue


    }
    handleDateChange = (date, inputName) => {
        this.setState({
            [inputName]: date
        })

    }

    actorInputChange = (inputName, inputValue, index, setValue) => {
        console.log(inputName, inputValue, index, setValue);


        this.state[setValue][index][inputName] = inputValue
    }
    setSocialProfile = (value) => {
        if (value.google !== '') {
            value.socialProfile.push({ profileUrl: value.google, type: "GOOGLE" })
        }
        if (value.facebook !== '') {
            value.socialProfile.push({ profileUrl: value.facebook, type: "FACEBOOK" })
        }
        if (value.linkedin !== '') {
            value.socialProfile.push({ profileUrl: value.linkedin, type: "LINKEDIN" })
        }
        if (value.instagram !== '') {
            value.socialProfile.push({ profileUrl: value.instagram, type: "INSTAGRAM" })
        }
    }

    handleSubmit = (event) => {
        event.preventDefault()
        this.state.paneList = this.state.paneList.map((value) => {
            this.setSocialProfile(value)
            return value
        })



        this.state.moderator = this.state.moderator.map((value) => {
            this.setSocialProfile(value)
            return value
        })

        this.state.sponsors = this.state.sponsors.map((value) => {
            this.setSocialProfile(value)
            return value
        })

        this.state.partners = this.state.partners.map((value) => {
            this.setSocialProfile(value)
            return value
        })




        this.state.actors = [...this.state.paneList, ...this.state.moderator,...this.state.partners,...this.state.sponsors]
        const eventFormRequest = Object.assign({}, this.state);
        console.log(eventFormRequest);

        createEvent(eventFormRequest)
            .then(response => {
                Alert.success("Event Created successfully !");
            }).catch(error => {
                Alert.error('Something went wrong, please try again later!');

            });
    }


    // TODO : validation
    render() {
        return (
            <Fragment>
                <div className="event-form-container container-fluid">
                    <Row>
                        <Col sm={2}></Col>
                        <Col md={8}>
                            <h2>Add Event</h2>
                            <Form onSubmit={this.handleSubmit}>
                                <Form.Row>
                                    <Col className="mt-4" md={6}>
                                        <Form.Label>Title</Form.Label>
                                        <Form.Control value={this.state.title} name="title" onChange={this.handleInputChange} placeholder="Title" />
                                    </Col>
                                    <Col className="mt-4" md={6}>
                                        <Form.Label>SubTitle</Form.Label>
                                        <Form.Control value={this.state.subTitle} name="subTitle" onChange={this.handleInputChange} placeholder="SubTitle" />
                                    </Col>
                                </Form.Row>

                                <Form.Row >
                                    <Col className="mt-4" md={6}>
                                        <Form.Label>Event Type</Form.Label>
                                        <Form.Control as="select" name="type" onChange={this.eventType}>
                                            <option value="default" selected>Open this select menu</option>
                                            <option value="PHYSICAL">PHYSICAL</option>
                                            <option value="VIDEO_RECORDING">VIDEO RECORDING</option>
                                            <option value="LIVE_STREAM">LIVE STREAM</option>
                                        </Form.Control>
                                    </Col>

                                    <Col className="mt-4" md={6}>
                                        <Form.Label>Link</Form.Label>
                                        {this.state.displayLink ? <Form.Control value={this.state.link} name="link" onChange={this.handleInputChange} placeholder="Event link" /> : <Form.Control placeholder="Event link" readOnly />}

                                    </Col>

                                </Form.Row>
                                <Form.Row >
                                    <Col className="mt-4" md={6}>
                                        <Form.Label>Start Date</Form.Label><br />
                                        <div className="customDatePickerWidth">
                                            <DatePicker
                                                className="myDatePicker"
                                                selected={this.state.dateStart}
                                                isClearable
                                                placeholderText="Select start Date and Time!"
                                                dateFormat="Pp"
                                                showMonthDropdown
                                                showYearDropdown
                                                showTimeSelect
                                                onChange={(date) => this.handleDateChange(date, 'dateStart')}
                                            />
                                        </div>
                                    </Col>
                                    <Col className="mt-4" md={6}>
                                        <Form.Label>End Date</Form.Label><br />
                                        <div className="customDatePickerWidth">
                                            <DatePicker
                                                className="myDatePicker"
                                                selected={this.state.dateEnd}
                                                isClearable
                                                placeholderText="Select End Date and Time!"
                                                dateFormat="Pp"
                                                showMonthDropdown
                                                showYearDropdown
                                                showTimeSelect
                                                onChange={(date) => this.handleDateChange(date, 'dateEnd')}
                                            />
                                        </div>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col className="mt-4" md={12}>
                                        <Form.Label>About</Form.Label>
                                        <Form.Control value={this.state.about} name="about" onChange={this.handleInputChange} as="textarea" rows="3" placeholder="maximum 1000 characters allowed..." maxLength="1000" />
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col className="mt-4" md={12}>
                                        <Form.Label>Terms & Conditions</Form.Label>
                                        <Form.Control value={this.state.tnc} name="tnc" onChange={this.handleInputChange} as="textarea" rows="3" placeholder="maximum 1000 characters allowed..." maxLength="1000" />
                                    </Col>
                                </Form.Row>
                                <br /><br />
                                <div>Panelist <Button style={{ float: "right" }} onClick={() => this.addNewRow("paneList")}><AddSharpIcon /></Button></div><br /><hr />

                                {this.state.paneList.map((value, index) => {
                                    return (
                                        <AddRow
                                            key={value.index}
                                            index={index}
                                            name={value.name}
                                            emaill={value.email}
                                            designation={value.designation}
                                            organisation={value.organisation}
                                            google={value.google}
                                            facebook={value.facebook}
                                            linkedin={value.linkedin}
                                            instagram={value.instagram}
                                            deleteRow={(returnIndex) => this.deleteRow(returnIndex, "paneList")}
                                            addNewRow={() => this.addNewRow("paneList")}
                                            inputChange={(inputName, inputValue, index) => this.actorInputChange(inputName, inputValue, index, "paneList",)}
                                        />
                                    );
                                })}


                                <br /><br />
                                <div>Moderators <Button style={{ float: "right" }} onClick={() => this.addNewRow("moderator")}><AddSharpIcon /></Button></div><br /><hr />

                                {this.state.moderator.map((value, index) => {
                                    return (
                                        <AddRow
                                            key={value.index}
                                            index={index}
                                            name={value.name}
                                            emaill={value.email}
                                            designation={value.designation}
                                            organisation={value.organisation}
                                            google={value.google}
                                            facebook={value.facebook}
                                            linkedin={value.linkedin}
                                            instagram={value.instagram}
                                            deleteRow={(returnIndex) => this.deleteRow(returnIndex, "moderator")}
                                            addNewRow={() => this.addNewRow("moderator")}
                                            inputChange={(inputName, inputValue, index) => this.actorInputChange(inputName, inputValue, index, "moderator",)}

                                        />
                                    );
                                })}

                                <br /><br />
                                <div>Sponsorers <Button style={{ float: "right" }} onClick={() => this.addNewRow("sponsors")}><AddSharpIcon /></Button></div><br /><hr />

                                {this.state.sponsors.map((value, index) => {
                                    return (
                                        <AddRow
                                            key={value.index}
                                            index={index}
                                            name={value.name}
                                            emaill={value.email}
                                            designation={value.designation}
                                            organisation={value.organisation}
                                            google={value.google}
                                            facebook={value.facebook}
                                            linkedin={value.linkedin}
                                            instagram={value.instagram}
                                            deleteRow={(returnIndex) => this.deleteRow(returnIndex, "sponsors")}
                                            addNewRow={() => this.addNewRow("sponsors")}
                                            inputChange={(inputName, inputValue, index) => this.actorInputChange(inputName, inputValue, index, "sponsors",)}

                                        />
                                    );
                                })}

                                <br /><br />
                                <div>Partners <Button style={{ float: "right" }} onClick={() => this.addNewRow("partners")}><AddSharpIcon /></Button></div><br /><hr />

                                {this.state.partners.map((value, index) => {
                                    return (
                                        <AddRow
                                            key={value.index}
                                            index={index}
                                            name={value.name}
                                            emaill={value.email}
                                            designation={value.designation}
                                            organisation={value.organisation}
                                            google={value.google}
                                            facebook={value.facebook}
                                            linkedin={value.linkedin}
                                            instagram={value.instagram}
                                            deleteRow={(returnIndex) => this.deleteRow(returnIndex, "partners")}
                                            addNewRow={() => this.addNewRow("partners")}
                                            inputChange={(inputName, inputValue, index) => this.actorInputChange(inputName, inputValue, index, "partners",)}

                                        />
                                    );
                                })}



                                <Form.Group style={this.state.displayAddress}>
                                    <Form.Row >
                                        <Col className="mt-4" md={6}>
                                            <Form.Label>House</Form.Label>
                                            <Form.Control value={this.state.hous} name="house" onChange={this.handleAddressChange} placeholder="House" />
                                        </Col>
                                        <Col className="mt-4" md={6}>
                                            <Form.Label>Street1</Form.Label>
                                            <Form.Control value={this.state.street1} name="street1" onChange={this.handleAddressChange} placeholder="1234 Main St, Apartment, studio, or floor" />
                                        </Col>
                                    </Form.Row>

                                    <Form.Row>
                                        <Col className="mt-4" md={6}>
                                            <Form.Label>Street2</Form.Label>
                                            <Form.Control value={this.state.street2} name="street2" onChange={this.handleAddressChange} placeholder="1234 Main St, Apartment, studio, or floor" />
                                        </Col>
                                        <Col className="mt-4" md={6}>
                                            <Form.Label>Street3</Form.Label>
                                            <Form.Control value={this.state.street3} name="street3" onChange={this.handleAddressChange} placeholder="1234 Main St, Apartment, studio, or floor" />
                                        </Col>
                                    </Form.Row>

                                    <Form.Row>
                                        <Col className="mt-4" md={3}>
                                            <Form.Label>Country</Form.Label>
                                            <Select
                                                options={this.state.countryList}
                                                onChange={this.handleCountryChange}
                                            />
                                        </Col>
                                        <Col className="mt-4" md={3}>
                                            <Form.Label>State</Form.Label>
                                            <Select
                                                options={this.state.states}
                                                onChange={value => this.dropdownhandleChange(value, "cities", "stateCode")}
                                            />
                                        </Col>
                                        <Col className="mt-4" md={3}>
                                            <Form.Label>City</Form.Label>
                                            <Select
                                                options={this.state.cities}
                                                onChange={value => this.dropdownhandleChange(value, "pincodes", "cityCode")}
                                            />
                                        </Col>
                                        <Col className="mt-4" md={3}>
                                            <Form.Label>Pincode</Form.Label>
                                            <Select
                                                options={this.state.pincodes}
                                                onChange={value => this.dropdownhandleChange(value, "", "pincode")}
                                            />
                                        </Col>
                                    </Form.Row>
                                </Form.Group>
                                <br /><br />

                                <Button type="submit" className="btn btn-primary float-right">Submit</Button>
                                <Link to="/events">  <Button type="button" className="btn btn-primary" href="./events">Back</Button></Link>
                            </Form>
                        </Col>
                    </Row>
                </div>
                <br /><br />
            </Fragment>
        );
    }

}

export default EventForm;