import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

export default class LoadingIndicator extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <div className="loading-indicator" style={{ display: 'block', textAlign: 'center', marginTop: '150px' }}>
                <CircularProgress color="secondary" />
            </div>
        );
    }
}