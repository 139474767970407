
import { API_BASE_URL, ORDERS_API_BASE_URL, DOCTORS_API_BASE_URL } from '../constants';
import { request } from './APIUtils';

export function createEntity(createEntityFormRequest) {
    return request({
        url: API_BASE_URL + "/spentity",
        method: 'POST',
        body: JSON.stringify(createEntityFormRequest)
    })
}

export function getEntityList(pageNumber, pageSize) {
    return request({
        url: API_BASE_URL + "/spentity?page=" + pageNumber + "&size=" + pageSize,
        method: 'GET'
    });
}

export function deleteEntity(entityId) {
    return request({
        url: API_BASE_URL + "/spentity?id=" + entityId,
        method: 'DELETE'
    });
}

export function updateEntity(updateEntityFormRequest) {
    return request({
        url: API_BASE_URL + "/spentity",
        method: 'PUT',
        body: JSON.stringify(updateEntityFormRequest)
    })
}

export function createAssociation(EntityAssociationRequest) {
    console.log("in api util" + EntityAssociationRequest)
    return request({
        url: API_BASE_URL + "/spentity/sp",
        method: 'POST',
        body: JSON.stringify(EntityAssociationRequest)
    })
}

export function getEntityDoctorList(entityId, spType, pageNumber, pageSize) {
    return request({
        url: API_BASE_URL + "/spentity/sp" + "?eid=" + entityId + "&st=" + spType + "&page=" + pageNumber + "&size=" + pageSize,
        method: 'GET'
    });
}

export function getUserForentity(EntityUserRequest) {
    return request({
        url: API_BASE_URL + "/user/info",
        method: 'POST',
        body: JSON.stringify(EntityUserRequest)
    })
}

export function getEntityPatientsList(entityId, pageNumber, pageSize) {
    return request({
        url: ORDERS_API_BASE_URL + "/appointment/e/p" + "?eid=" + entityId + "&page=" + pageNumber + "&size=" + pageSize,
        method: 'GET'
    })
}

export function getEntityAppointmentsList(entityId, pageNumber, pageSize) {
    return request({
        url: ORDERS_API_BASE_URL + "/appointment/e" + "?eid=" + entityId + "&page=" + pageNumber + "&size=" + pageSize,
        method: 'GET'
    })
}

export function getDoctorInfo(userIds, by) {
    return request({
        url: DOCTORS_API_BASE_URL + "/doctor/info" + "?by=" + by,
        method: 'POST',
        body: JSON.stringify(userIds)
    })
}