
export const vitals = {
    TEMP: 'Temp(F)',
    PULSE: 'Pulse',
    HEIGHT_CM: 'Height(cm)',
    WEIGHTS_KG: 'Weight(Kg)',
    SYSTOLIC_BP_MM_HG: 'SYSTOLIC BP(mmHg)',
    DIASTOLIC_BP_MM_HG: 'DIASTOLIC BP(mmHg)',
    SpO2_PCENT: 'Oxygen Saturation Level',
    BMI_KG_PER_M2: 'BMI(kg/m2)'
}
