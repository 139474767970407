import React, { Component } from 'react';
import Alert from 'react-s-alert';
import "./PaymentForm.css";
import "./OrderSummary.css"
import { getAppointmentById } from '../util/OrdersAPIUtils';
import { fetchDoctorDetails } from '../util/APIUtils';
import { makepaymentRequest } from '../util/paymentUtils';
import { Container, Row, Col, Button, Form, Image, Table, Card, CardGroup } from 'react-bootstrap'
import { changeTime, getRole, getCurrentUserId, getCurrentUser } from '../util/functionUtils';
import { Link } from 'react-router-dom';
import LoadingIndicator from '../common/LoadingIndicator';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { ACCESS_TOKEN } from '../constants';

import { PAYMENT_API_BASE_URL } from '../constants/index'

class OrderSummery extends Component {
    constructor(props) {
        super(props)
        this.state = {
            appointmentId: this.props.history.location.state ? this.props.history.location.state.appointmentId : null,
            providerId: '',
            appointmentDetails: '',
            providerDetails: '',
            billingAddress: this.props.history.location.state ? this.props.history.location.state.billingAddress : '',
            shippingAddress: this.props.history.location.state ? this.props.history.location.state.shippingAddress : '',
            currentUser: getCurrentUser(),
            status: '',
            loading: true,
            merchant_id: '',
            order_id: '',
            currency: 'INR',
            amount: '',
            redirect_url: '',
            cancel_url: '',
            language: 'EN',
            billing_name: '',
            billing_address: '',
            billing_city: '',
            billing_state: '',
            billing_zip: '',
            billing_country: '',
            billing_tel: '',
            billing_email: '',
            delivery_name: '',
            delivery_address: '',
            delivery_city: '',
            delivery_state: '',
            delivery_zip: '',
            delivery_country: '',
            delivery_tel: '',
            merchant_param1: '',
            merchant_param2: '',
            merchant_param3: '',
            merchant_param4: '',
            merchant_param5: '',
            promo_code: '',
            customer_identifier: getCurrentUserId(),
            pr: ''
        }

    }

    loadAppointMentDetails() {
        getAppointmentById(this.state.appointmentId)
            .then(response => {
                this.setState({
                    appointmentDetails: response,
                    status: response.status,
                    providerId: response.providerId

                });
                this.loadProviderDetails(response.providerId)
            }).catch(error => {
                Alert.error((error && error.message) || 'Something went wrong, please try again later!');
            });

    }

    loadProviderDetails(providerId) {
        fetchDoctorDetails(providerId, false)
            .then(response => {
                this.setState({
                    providerDetails: response,
                    loading: false
                });

            }).catch(error => {
                Alert.error((error && error.message) || 'Something went wrong, please try again later!');
            });
    }

    componentDidMount() {
        this.loadAppointMentDetails()
        window.scrollTo(0, 0)

    }
    makePaymentHandler = () => {
        document.getElementById("paymentform").submit();
    }

    handleBack = () => {
        this.props.history.goBack()
    }

    render() {
        if (this.state.loading)
            return <LoadingIndicator />
        return (
            <Container>
                <div className=" payment-form-container">
                    <Row className="welcome text-center mb-5">
                        <Col sm={12}>
                            <h4> Appointment Details</h4>
                        </Col>
                        <hr />
                    </Row>
                    <Link className="mb-4"><Button varient="primary" onClick={this.handleBack}> <ArrowBackIcon /> </Button></Link>
                    <Table bordered className="shadow mt-2">
                        <thead className="thead-color">
                            <th>
                                <h5>Patient's Information </h5>
                            </th>
                        </thead>
                        <tbody>
                            <td>
                                <Row className="mb-5">
                                    <Col md={4} className="mb-3 mt-2">
                                        Patient's Name :<strong> {this.state.appointmentDetails.memberId ? this.state.appointmentDetails.member.name : this.state.appointmentDetails.consumer.name}</strong>
                                    </Col>
                                    <Col md={4} className="mb-3 mt-2">
                                        Age : <strong>{this.state.appointmentDetails.memberId ? this.state.appointmentDetails.member.age : this.state.appointmentDetails.consumer.age} yrs</strong>
                                    </Col>
                                    <Col md={4} className="mb-3 mt-2">
                                        Gender : <strong>{this.state.appointmentDetails.memberId ? this.state.appointmentDetails.member.gender : this.state.appointmentDetails.consumer.gender}</strong>
                                    </Col>
                                    <Col md={4} className="mb-3">
                                        Status : <strong>{this.state.status}</strong>
                                    </Col>
                                    <Col md={4} className="mb-2">
                                        Date : <strong>{(new Date(this.state.appointmentDetails.startTimeMs)).toLocaleDateString([], { year: 'numeric', month: 'long', day: 'numeric' })}</strong>
                                    </Col>
                                    <Col md={4} className="mb-2">
                                        Time : <strong>{changeTime(this.state.appointmentDetails.startTimeMs)} - {changeTime(this.state.appointmentDetails.endTimeMs)}</strong>
                                    </Col>
                                    <Col md={4} className="mb-2">
                                        Service Type : {this.state.appointmentDetails.serviceType === "VIDEO_CONS" ? <strong>Video Consultation </strong> : <strong>Physical Consultation </strong>}
                                    </Col>
                                </Row>
                            </td>
                        </tbody>
                    </Table>


                    <Table bordered className="shadow">
                        <thead className="thead-color">
                            <th><h5 className="5">Doctor's Information</h5></th>
                        </thead>
                        <tbody>
                            <td>
                                <Row>
                                    <Col md={3} lg={3}>
                                        <Row>
                                            <Col md={12}>
                                                <Image
                                                    width={100}
                                                    height={100}
                                                    className="mr-3 media-img"
                                                    style={{ clipPath: 'circle()' }}
                                                    src={this.state.providerDetails.profileLink ? this.state.providerDetails.profileLink : "../img/mac.png"}
                                                    alt="doctor photo"
                                                />
                                            </Col>
                                            <Col md={12} className="m-3 ml-2">
                                                {this.state.providerDetails.socialProfiles ?
                                                    this.state.providerDetails.socialProfiles.map(value => {
                                                        if (value.type === 'GOOGLE')
                                                            return <a target="_blank" href={value.profileUrl}> <i class="fa fa-google fa-1x" aria-hidden="true"></i></a>
                                                        else if (value.type === 'FACEBOOK')
                                                            return <a target="_blank" href={value.profileUrl}> <i class="fa fa-facebook-official fa-1x" aria-hidden="true"></i></a>
                                                        else if (value.type === 'INSTAGRAM')
                                                            return <a target="_blank" href={value.profileUrl}> <i class="fa fa-instagram fa-1x" aria-hidden="true"></i></a>
                                                        else if (value.type === "TWITTER")
                                                            return <a target="_blank" href={value.profileUrl}> <i class="fa fa-twitter fa-1x" aria-hidden="true"></i></a>
                                                        else if (value.type === "LINKEDIN")
                                                            return <a target="_blank" href={value.profileUrl}> <i class="fa fa-linkedin-square fa-1x" aria-hidden="true"></i></a>
                                                        else
                                                            return null

                                                    })
                                                    : null}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={9} lg={9}>
                                        <h5 className="mb-3">Dr. {this.state.providerDetails.name}{" "}({this.state.providerDetails.registration})</h5>
                                        <i class="fa fa-user-md fa-1x" aria-hidden="true"></i>{" "}
                                        {this.state.providerDetails.specialisations.map((value, index) => {
                                            if (index === 0)
                                                return " " + value.dislayStr
                                            else
                                                return "," + value.dislayStr
                                        })}<br />
                                        <i class="fa fa-graduation-cap fa-1x" aria-hidden="true"></i>{" "}
                                        {this.state.providerDetails.degrees.map((value, index) => {
                                            if (index === 0)
                                                return " " + value.dislayStr
                                            else
                                                return "," + value.dislayStr
                                        })}<br />
                                        <i class="fa fa-black-tie fa-1x" aria-hidden="true"></i>{" "}<strong>{this.state.providerDetails.experienceInYears}+</strong> years experience

                                    </Col>
                                </Row>
                            </td>
                        </tbody>

                    </Table>

                    <Table bordered className="shadow" id="orderSummary">
                        <thead >
                            <th className="thead-color" colSpan="7"><h5>Consultation Charge Details</h5></th>
                            <tr id="orderSummaryHead">
                                <td><strong>Consultation Charge</strong></td>
                                <td><strong>Discount %</strong></td>
                                <td><strong>Discount</strong></td>
                                <td><strong>Tax %</strong></td>
                                <td><strong>Tax</strong></td>
                                <td><strong>Final Amount</strong></td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td data-label="Consultation Charge">{this.state.appointmentDetails.order.currency}{" "}{this.state.appointmentDetails.order.value}</td>
                                <td data-label="Discount %">{this.state.appointmentDetails.order.discountPercent} %</td>
                                <td data-label="Discount">{this.state.appointmentDetails.order.currency}{" "}{this.state.appointmentDetails.order.discount}</td>
                                <td data-label="Tax %">{this.state.appointmentDetails.order.taxPercent} %</td>
                                <td data-label="Tax">{this.state.appointmentDetails.order.currency}{" "}{this.state.appointmentDetails.order.tax}</td>
                                <td data-label="Final Amount">{this.state.appointmentDetails.order.currency}{" "}{this.state.appointmentDetails.order.finalValue}</td>
                            </tr>
                        </tbody>
                    </Table>
                    {this.state.appointmentDetails.order.couponCode ?
                        <div className="border mt-2 mb-2">
                            <Row className="mb-2  md={12}">
                                <Col className="mt-2"><strong>Coupon Code Applied :</strong></Col>
                                <Col className="mt-2"><Form.Control placeholder="XXXXXX" value={this.state.appointmentDetails.order.couponCode} maxlength="6"  disabled /></Col>
                                <Col></Col>
                            </Row>
                        </div> : null}
                    {/* to put check when billing addres is not there */}
                    <Table bordered responsive className="shadow">
                        <thead>
                            <tr className="thead-color">
                                <th colSpan="7"><h5>Addresses</h5></th>
                            </tr>
                        </thead>
                        <tbody>
                            <CardGroup>
                                <Card style={{ width: '19rem', minHeight: '120px' }}>
                                    <Card.Body>
                                        <Card.Title>Billing Address</Card.Title>
                                        <Card.Text>
                                            {this.state.billingAddress.house + "," + this.state.billingAddress.street1 + (this.state.billingAddress.street2 ? ("," + this.state.billingAddress.street2) : '') + (this.state.billingAddress.street3 ? ("," + this.state.billingAddress.street3) : '') + "\n" + this.state.billingAddress.city + "," + this.state.billingAddress.state + "," + this.state.billingAddress.country + "," + this.state.billingAddress.pincode}
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                                <Card style={{ width: '19rem', minHeight: '120px' }}>
                                    <Card.Body>
                                        <Card.Title>Shipping Address</Card.Title>
                                        <Card.Text>
                                            {this.state.shippingAddress.house + "," + this.state.shippingAddress.street1 + (this.state.shippingAddress.street2 ? ("," + this.state.shippingAddress.street2) : '') + (this.state.shippingAddress.street3 ? ("," + this.state.shippingAddress.street3) : '') + "\n" + this.state.shippingAddress.city + "," + this.state.shippingAddress.state + "," + this.state.shippingAddress.country + "," + this.state.shippingAddress.pincode}
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </CardGroup>
                        </tbody>
                    </Table>
                    <Button className="float-right" onClick={this.makePaymentHandler}>Make Payment</Button>
                    <Link><Button className="btn btn-primary" onClick={this.handleBack}>Back</Button></Link>
                    <br />
                    <br />
                </div>

                <form id="paymentform" method="post" name="redirect" action={PAYMENT_API_BASE_URL + "/payment-form/req"} >
                    <input type="hidden" id="orderId" name="order_id" value={this.state.appointmentDetails.orderId} />
                    <input type="hidden" id="currency" name="currency" value={this.state.appointmentDetails.order.currency} />
                    <input type="hidden" id="amount" name="amount" value={this.state.appointmentDetails.order.finalValue} />
                    <input type="hidden" id="redirectUrl" name="redirect_url" value={PAYMENT_API_BASE_URL + "/payment-form/res"} />
                    <input type="hidden" id="cancelUrl" name="cancel_url" value={PAYMENT_API_BASE_URL + "/payment-form/res"} />
                    <input type="hidden" id="language" name="language" value={this.state.language} />
                    <input type="hidden" id="billingName" name="billing_name" value={this.state.currentUser.name} />
                    <input type="hidden" id="billingAddress" name="billing_address" value={this.state.billingAddress.house + "," + this.state.billingAddress.street1 + (this.state.billingAddress.street2 ? ("," + this.state.billingAddress.street2) : '') + (this.state.billingAddress.street3 ? ("," + this.state.billingAddress.street3) : '')} />
                    <input type="hidden" id="billingCity" name="billing_city" value={this.state.billingAddress.city} />
                    <input type="hidden" id="billingZip" name="billing_zip" value={this.state.billingAddress.pincode} />
                    <input type="hidden" id="billingState" name="billing_state" value={this.state.billingAddress.state} />
                    <input type="hidden" id="billingCountry" name="billing_country" value={this.state.billingAddress.country} />
                    <input type="hidden" id="billingTel" name="billing_tel" value={this.state.currentUser.phone ? this.state.currentUser.phone : ''} />
                    <input type="hidden" id="billingEmail" name="billing_email" value={this.state.currentUser.email} />
                    <input type="hidden" id="deliveryName" name="delivery_name" value={((this.state.shippingAddress && this.state.shippingAddress.name) ? (this.state.shippingAddress.name) : this.state.currentUser.name)} />
                    <input type="hidden" id="deliveryAddress" name="delivery_address" value={this.state.shippingAddress.house + "," + this.state.shippingAddress.street1 + (this.state.shippingAddress.street2 ? ("," + this.state.shippingAddress.street2) : '') + (this.state.shippingAddress.street3 ? ("," + this.state.shippingAddress.street3) : '')} />
                    <input type="hidden" id="deliverCity" name="delivery_city" value={this.state.shippingAddress.city} />
                    <input type="hidden" id="deliveryZip" name="delivery_zip" value={this.state.shippingAddress.pincode} />
                    <input type="hidden" id="deliveryState" name="delivery_state" value={this.state.shippingAddress.state} />
                    <input type="hidden" id="deliveryCountry" name="delivery_country" value={this.state.shippingAddress.country} />
                    <input type="hidden" id="deliveryTel" name="delivery_tel" value={this.state.shippingAddress.phone ? this.state.shippingAddress.phone : (this.state.currentUser.phone ? this.state.currentUser.phone : '')} />
                    <input type="hidden" id="merchatParam1" name="merchant_param1" value="" />
                    <input type="hidden" id="merchatParam2" name="merchant_param2" value="" />
                    <input type="hidden" id="merchatParam3" name="merchant_param3" value="" />
                    <input type="hidden" id="merchatParam4" name="merchant_param4" value="" />
                    <input type="hidden" id="merchatParam5" name="merchant_param5" value="" />
                    <input type="hidden" id="promoCode" name="promo_code" value="" />
                    <input type="hidden" id="customerIdentifier" name="customer_identifier" value={this.state.customer_identifier} />
                </form>
            </Container>
        );
    }
}
export default OrderSummery;
