import React, { Component } from 'react'
import { Container } from 'react-bootstrap';

export default class PatientList extends Component {

    render() {
        return (
            <Container>
                <div className="patients-details-container">
                <h2>List of my pharmacy list</h2>
                </div>
            </Container>
        )
    }
}
