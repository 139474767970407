import React, { Component } from 'react'
import { Row, Col, Card, Button, Container, Table, Media } from 'react-bootstrap';
import { getRole, changeTime, eventsTypeMapping } from '../util/functionUtils';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { getEventDetails } from '../util/APIUtils';
import LoadingIndicator from '../common/LoadingIndicator';
import {getRegisteredUserToEvent,getUserInfo} from '../util/eventsUtils';
import Alert from 'react-s-alert';


export default class UserEventRegistrationList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            eventId: this.props.match.params.eventId,
            eventDetails: [],
            loading: true,
            userIds:[1],
            eventUsersDetails:[]
        }
    }

    componentDidMount() {
        this.loadEventDetails();
    }

    loadEventDetails = () => {
        getEventDetails(this.state.eventId)
            .then(response => {
                this.RegisteredUserToEvent(response.id);
                this.setState({
                    eventDetails: response,
                })
            })
            .catch(error => {
                this.setState({
                    loading: false
                })
            })
    }

    RegisteredUserToEvent=(eventId) =>{
        getRegisteredUserToEvent(eventId)
        .then(response=>{
           this.getUserInfos(response.content)
        },error=>{
            this.setState({
                loading: false
            })
        })
    }

    getUserInfos=(userIds)=>{
        getUserInfo(userIds)
        .then(response=>{
          this.setState({
            eventUsersDetails:response,
            loading:false
          })
        },error=>{
               Alert.error(error.message)
            this.setState({
                loading: false
            })

        })
    }


    handleBack = () => {
        this.props.history.goBack()
    }


    render() {
        if (this.state.loading)
            return <LoadingIndicator />
        return (
            <Container>
                <div className="patients-details-container">
                <Row className="welcome text-center mb-5">
                        <Col sm={12}>
                            <h1 className="display-4">Users</h1>
                        </Col>
                        <hr />
                    </Row>
                </div>
                <Button className="btn btn-primary" onClick={this.handleBack}><ArrowBackIcon /></Button>
                <Media>
                    <img
                        width={220}
                        height={220}
                        className="mr-3 media-img"
                        src={this.state.eventDetails.eventImgLink ? this.state.eventDetails.eventImgLink : "../img/mac.png"}
                        alt="Event Image">
                    </img>
                    <Media.Body>
                        <h4 className="mb-3">{this.state.eventDetails.title}</h4>
                        <h6 className="mb-3">{this.state.eventDetails.subTitle}</h6>
                        <p><h6>
                            <b>Start Date & Time</b>: {new Date(this.state.eventDetails.dateStart).toLocaleDateString([], { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })} at {changeTime(this.state.eventDetails.dateStart)}
                        </h6></p>
                        <p><h6>
                            <b>End Date & Time</b>: {new Date(this.state.eventDetails.dateEnd).toLocaleDateString([], { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })} at {changeTime(this.state.eventDetails.dateEnd)}
                        </h6></p>
                        <p><h6>
                            Type:{eventsTypeMapping(this.state.eventDetails.type)}
                        </h6></p>
                    </Media.Body>
                </Media>
                <br></br>
                <Table hover responsive className="shadow">
                    <thead className="thead-color">
                        <tr>
                            <th className="text-center">#</th>
                            <th className="text-center">Photo</th>
                            <th className="text-center">Name</th>
                            <th className="text-center">Email</th>
                            <th className="text-center">Phone</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.eventUsersDetails.map((user)=>{
                            return(
                                <tr>
                                    <td className="text-center">{user.id}</td>
                                    <td data-label="Photo">

                                            {
                                                user.imageUrl ? (
                                                    <div className="profile-avatar">
                                                        <img src={user.imageUrl} alt={user.name} />
                                                    </div>
                                                ) : (
                                                    <div className="text-avatar">
                                                        <span>{user.name && user.name[0]}</span>
                                                    </div>
                                                )
                                            }
                                        </td>
                                    <td className="text-center"> {user.name}</td>
                                    <td className="text-center">{user.email}</td>
                                    <td className="text-center">{user.phone}</td>
                                    </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </Container>
        )
    }
}