import React, { Component } from 'react'
import './ShareWithList.css'
import { Row, Col, Button, Container, Table } from 'react-bootstrap';
import Alert from 'react-s-alert';
import { getShareWithList, unShareRecord } from '../util/healthAPIUtils'
import { getCurrentUserId, getDateSafariHack } from '../util/functionUtils';
import { Link } from 'react-router-dom'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

export default class ShareWithList extends Component {
    constructor() {
        super()
        this.state = {
            accessingUserId: getCurrentUserId(),
            byOrWith: 'with',
            shareWithList: [],
            pageNumber: 0,
            pageSize: 20,
            totalElements: 0
        }

        this.loadMore = this.loadMore.bind(this);
    }


    async loadMore() {
        await this.setState({
            pageNumber: this.state.pageNumber + 1
        })
        getShareWithList(this.state.accessingUserId, this.state.byOrWith, this.state.pageNumber, this.state.pageSize)
            .then(response => {
                this.setState({
                    shareWithList: [...this.state.shareWithList, ...response.content],
                });
            }).catch(error => {
                Alert.error((error && error.message) || 'Something went wrong, please try again later!');
            });
    }

    loadShareWithList = () => {

        getShareWithList(this.state.accessingUserId, this.state.byOrWith, this.state.pageNumber, this.state.pageSize)
            .then(response => {
                this.setState({
                    shareWithList: response.content,
                    totalElements: response.totalElements
                })
            })
            .catch(error => {
                Alert.error((error && error.message) || 'Something went wrong, please try again later!');
            })
    }

    unShareRecord = (recordId) => {
        unShareRecord(recordId)
            .then(response => {
                Alert.success("medical record unshared successfully")
                this.setState({
                    shareByList: this.state.ShareByList.filter(index => recordId !== index)
                })
            })
            .catch(error => {
                Alert.error((error && error.message) || 'Something went wrong, please try again later!');
            })
    }

    componentDidMount() {
        this.loadShareWithList()
        window.scrollTo(0, 0)
    }

    returnShareWithDetails = (sharedWithType, sharedWith) => {
        return (
            <>
                {/* {sharedWithType === 'USER' ? <td>{sharedWith.imageUrl ? <div className="profile-avatar">
                    <img src={sharedWith.imageUrl} alt={sharedWith.name} />
                </div> : <div className="text-avatar">{sharedWith.name[0]}</div>
                }{" "}<i class="fa fa-user fa-2x" aria-hidden="true"></i>{" "} {sharedWith.name} </td>: 
                <td>{sharedWith.profileLink ? <div className="profile-avatar">
                    <img src={sharedWith.profileLink} alt={sharedWith.name} /></div>
                    : <div className="text-avatar">{sharedWith.name[0]}</div>}
                    {" "}<i class="fa fa-user-md fa-2x" aria-hidden="true"></i>{" "}{sharedWith.name}</td>} */}
                {sharedWithType === 'USER' ? <td className="text-center" data-label="Shared By"><i class="fa fa-user fa-2x" aria-hidden="true"></i>{" "} {sharedWith.name}</td>
                    : <td className="text-md-center" data-label="Shared By"><i class="fa fa-user-md fa-2x" aria-hidden="true"></i>{" "}{sharedWith.name}</td>}
            </>
        )

    }
    render() {
        return (
            <Container>
                <div className="share-by-container">
                    <Row className="welcome text-center mb-5">
                        <Col sm={12}>
                            <h3>Shared With Me</h3>
                        </Col>
                        <hr />
                    </Row>
                    <Link to="/health-records" className="custom-button float-left btn btn-primary"><ArrowBackIcon /></Link><br /><br />
                    <Table id="shareWithListTable">
                        {this.state.shareWithList.length === 0 ? null :
                            <thead className="thead-color">
                                <tr id="shareWithListTableHead">
                                    <th className="text-md-center">#</th>
                                    <th className="text-center">Shared By</th>
                                    <th className="text-center">Category</th>
                                    <th className="text-center">Shared On</th>
                                    <th className="text-center">Sharing Ends On</th>
                                    <th className="text-center">Stop Sharing</th>
                                </tr>
                            </thead>
                        }
                        <tbody>
                            {this.state.shareWithList.map((value) => {
                                return (
                                    <tr>
                                        <td className="text-md-center" data-label="#"><Link to={{ pathname: "/rwith-record/" + value.id, state: { recordId: value.id } }}>{value.id}</Link></td>

                                        {value.sharedWithType === 'USER' ?
                                            this.returnShareWithDetails(value.sharedWithType, value.sharedByUser)
                                            : value.sharedWithType === 'DOCTOR' ?
                                                this.returnShareWithDetails(value.sharedWithType, value.sharedByUser)
                                                : value.sharedWithType === 'EMAIL' ?
                                                    <td className="text-center"><i class="fa fa-envelope fa-2x" aria-hidden="true"></i>{" "}{value.sharedWithEmail}</td>
                                                    : null
                                        }
                                        <td className="text-md-center" data-label="Category">{value.catInfo.name}</td>
                                        <td className="text-md-center" data-label="Shared On">{(getDateSafariHack(value.createdAt)).toLocaleDateString([], {
                                            year: 'numeric', month: 'long', day: 'numeric'
                                            // , hour: "numeric", minute: "numeric", hour12: "true" 
                                        })}</td>
                                        <td className="text-md-center" data-label="Sharing Ends On">{(getDateSafariHack(value.sharingEndsOn)).toLocaleDateString([], {
                                            year: 'numeric', month: 'long', day: 'numeric'
                                            // , hour: "numeric", minute: "numeric", hour12: "true" 
                                        })}</td>
                                        <td className="text-md-center" data-label="Stop Sharing"><Button data-toggle="modal" data-target="#staticBackdrop"><i class="fa fa-trash-o" aria-hidden="true"></i></Button>
                                            <div className="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                <div className="modal-dialog">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title textColor" id="staticBackdropLabel">Are you sure you want to stop sharing ?</h5>
                                                            <Button type="Button" className="close" data-dismiss="modal" aria-label="Close">
                                                                <span aria-hidden="true">&times;</span>
                                                            </Button>
                                                        </div>
                                                        <div className="modal-footer">
                                                            <Button type="Button" className="btn btn-secondary" data-dismiss="modal">Cancel</Button>
                                                            <Button type="Button" className="btn btn-primary" data-dismiss="modal" onClick={() => this.unShareRecord(value.id)}>Delete</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                    {this.state.shareWithList.length >= 20 ?
                        this.state.shareWithList.length < this.state.totalElements ?
                            <Button color="primary" className=" loadMore" size="sm" onClick={this.loadMore}>Load more...</Button> :
                            <Button color="primary" className=" loadMore" size="sm" href="#top">Back to top</Button> : null
                    }
                </div>
                <br />
                <br />
                {this.state.shareWithList.length === 0 ? <h4 className="text-center mb-5">No health record has been shared with you !</h4> : null}
            </Container>
        )
    }
}
