import { PHARMACY_API_BASE_URL } from '../constants';
import { request } from './APIUtils';

export function loadPharmacyById(pharmacyId) {
    return request({
        url: PHARMACY_API_BASE_URL + "/pharmacy?id="+ pharmacyId,
        method: 'GET'
    })
}


export function searchPharmacyBy(name,type,country,state,city,pageNumber,pageSize) {
    return request({
        url: PHARMACY_API_BASE_URL + "/pharmacy?n="+name +"&t="+type+"&country="+country+"&state="+state+"&city="+city+"&page="+pageNumber+"&size="+pageSize,
        method: 'GET'
    })
}

export function getPharmacyListByStatus(status) {
    return request({
        url: PHARMACY_API_BASE_URL + "/pharmacy?status="+ status,
        method: 'GET'
    })
}


export function pharmacySchedule(pharmacyScheduleRequest) {
    return request({
        url: PHARMACY_API_BASE_URL + "/pharmacy/servicesched",
        method: 'POST',
        body:JSON.stringify(pharmacyScheduleRequest)
    })
}

// yet to implement
export function loadPharmacySchedule(pharmacyId,type) {
    return request({
        url: PHARMACY_API_BASE_URL + "/pharmacy/servicesched?id="+ pharmacyId + "&t=" + type,
        method: 'Get',
    })
}