import React, { Component } from 'react';
import { Container, Card, Form, Row, Col, Image, Button } from 'react-bootstrap';
import { createSpecialization, updateSpecialization } from '../util/APIUtils';
import EmptyJumbotron from '../common/EmptyJumbotron';
import Alert from 'react-s-alert';
import {Link} from 'react-router-dom';
import LoadingIndicator from '../common/LoadingIndicator';

class SpecializationForm extends Component {
    constructor(props) {
        super(props)
        console.log("edit sp " + this.props);
        if (this.props.location.editspecialization) {
            this.state = {
                code: this.props.location.editspecialization.code,
                dislayStr: this.props.location.editspecialization.dislayStr,
                loading:false
            }
        } else {
            this.state = {
                code: "",
                dislayStr: "",
                loading:false
            }
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;
        const inputValue = target.value;

        this.setState({
            [inputName]: inputValue
        });
    }

    componentDidMount(){
        window.scrollTo(0, 0)
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({
            loading:true
        })
        const { match: { params }, history } = this.props;
        if (this.props.location.editspecialization) {
            const specializationUpdateRequest = Object.assign({}, this.state);
            updateSpecialization(specializationUpdateRequest)
                .then(response => {
                    this.setState({
                        loading:false
                    })
                    Alert.success("Specialization updated successully!");
                    
                }).catch(error => {
                    this.setState({
                        loading:false
                    })
                    Alert.error((error && error.message) || 'Oops! Something went wrong. Please try again!');
                });
        } else {
            const specializationFormRequest = Object.assign({}, this.state);
            createSpecialization(specializationFormRequest)
                .then(response => {
                    this.setState({
                        loading:false
                    })
                    Alert.success("Specialization created successully!");
                }).catch(error => {
                    this.setState({
                        loading:false
                    })
                    Alert.error((error && error.message) || 'Oops! Something went wrong. Please try again!');
                });
        }



    }

    render() {
        if(this.state.loading){
            return <LoadingIndicator/>
        }

        return (
            <Container>
                <div className="change-password-container">
                    {this.props.location.editspecialization ? <h2>Update Specialisation</h2> : <h2>Create Specialisation</h2>}
                    <Form onSubmit={this.handleSubmit}>
                        <Form.Group as={Row} controlId="code">
                            <Form.Label column sm={2}>
                                Code
							    </Form.Label>
                            <Col sm={3}>{this.props.location.editspecialization ? <Form.Control type="text" value={this.state.code} name="code" placeholder="Code" pattern="[A-Za-z0-9]{5}" title="Three letter code" onChange={this.handleInputChange} disabled />
                                : <Form.Control type="text" value={this.state.code} name="code" placeholder="Code" pattern="[0-9a-zA-Z]{1,5}" title="At most five letter code" onChange={this.handleInputChange} />}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="description">
                            <Form.Label column sm={2}>
                                Description
							    </Form.Label>
                            <Col sm={3}>
                                <Form.Control type="text" value={this.state.dislayStr} name="dislayStr" placeholder="Description" onChange={this.handleInputChange} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col sm={2}>
                               <Link to="/sp" className="float-left btn btn-primary">  Back</Link>
                            </Col>
                            <Col sm={3}>
                                <Button type="submit" className="float-right">Submit</Button>
                            </Col>
                        </Form.Group>
                    </Form>
                </div>
                <EmptyJumbotron />
            </Container>
        );
    }
}

export default SpecializationForm;
