import React, { Component } from 'react';
import './PrescriptionDetails.css';
import Alert from 'react-s-alert';
import { Row, Col, Button, Container, Table } from 'react-bootstrap';
import { getPrescriptionById } from '../../util/healthAPIUtils';
import { Redirect, Link } from 'react-router-dom';
import { getDateSafariHack } from '../../util/functionUtils';
import LoadingIndicator from '../../common/LoadingIndicator';
import { getSps } from '../../util/functionUtils';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';


export default class PrescriptionDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            prescriptionId: this.props.history.location.state ? this.props.history.location.state.prescriptionId ? this.props.history.location.state.prescriptionId : '' : '',
            appointmentId: this.props.history.location.state ? this.props.history.location.state.appointmentId ? this.props.history.location.state.appointmentId : '' : '',
            prescription: '',
            providerId: '',
            allergy: [],
            socialHabits: [],
            vitals: [],
            medicineList: [],
            complaint: '',
            diagnosis: '',
            doctorNotes: '',
            BMI_KG_PER_M2: '',
            DIASTOLIC_BP_MM_HG: '',
            PULSE: '',
            SYSTOLIC_BP_MM_HG: '',
            SpO2_PCENT: '',
            TEMP: '',
            WEIGHTS_KG: '',
            HEIGHT_CM: '',
            loading: true,
            adBack: this.props.history.location.state ? this.props.history.location.state.adBack ? this.props.history.location.state.adBack : '' : '',
            presListBack: this.props.history.location.state ? this.props.history.location.state.presListBack ? this.props.history.location.state.presListBack : '' : '',
        }
        this.loadPrescription = this.loadPrescription.bind(this);
    }

    loadPrescription() {
        getPrescriptionById(this.state.prescriptionId, this.state.appointmentId)
            .then(response => {
                this.setState({
                    prescription: response,
                    prescriptionId: response.id,
                    appointmentId: response.appointmentId,
                    providerId: response.doctor.userId,
                    loading: false
                })

                response.healthFindings.map((value) => {
                    if (value.type === "ALLERGY") {
                        this.setState(prevState => ({
                            allergy: [...prevState.allergy, value]
                        }))
                    } else if (value.type === "SOCIAL_HABBITS") {
                        this.setState(prevState => ({
                            socialHabits: [...prevState.socialHabits, value]
                        }))
                    } else if (value.type === "VITALS") {
                        this.setState(prevState => ({
                            vitals: [...prevState.vitals, value]
                        }))
                        this.setState({
                            [value.subtype]: value.details
                        })
                    }
                    else if (value.type === "COMPLAINT") {
                        this.setState({
                            complaint: value
                        })
                    } else if (value.type === "DIAGNOSIS") {
                        this.setState({
                            diagnosis: value
                        })
                    } else if (value.type === 'NOTES') {
                        this.setState({
                            doctorNotes: value
                        })
                    }
                })

                response.prescribedItems.map(value => {
                    if (value.type === 'MEDICINE') {
                        this.setState(prevState => ({
                            medicineList: [...prevState.medicineList, value]
                        }))
                    }
                })
            })
            .catch(error => {
                Alert.error('Something went wrong, please try again later!')
            })
    }
    componentDidMount() {
        if (this.state.prescriptionId || this.state.appointmentId)
            this.loadPrescription()
        window.scrollTo(0, 0)
    }

    render() {

        if (this.state.prescriptionId === '' && this.state.appointmentId === '') {
            return (<Redirect
                to={{
                    pathname: '/not-found',
                }}
            />)
        }
        if (this.state.loading) {
            return <LoadingIndicator />
        }
        const prescriptionDetails = this.state.prescription
        return (
            <Container id="divToPrint">
                <div className="prescribe-details-container">
                    <Row className="welcome text-center mb-5">
                        <Col sm={12}>
                            <h4>Prescription Details</h4>
                        </Col>
                        <hr />
                    </Row>
                    {prescriptionDetails.status === "COMPLETED" ?
                        this.state.adBack ?
                            <Link to={{ pathname: "/appointment-details", state: { appointmentId: this.state.appointmentId, consumerId: this.props.history.location.state.consumerId, providerId: this.props.history.location.state.providerId } }} className="custom-button"><Button varient="primary"> <ArrowBackIcon /> </Button></Link>
                            : this.state.presListBack ?
                                <Link to="/my-prescription-list" className="custom-button"><Button varient="primary"> <ArrowBackIcon /> </Button></Link>
                                : <Link to={{ pathname: "/appointment-details", state: { appointmentId: this.state.appointmentId, providerId: this.state.providerId } }} className="custom-button"><Button varient="primary"> <ArrowBackIcon /> </Button></Link>
                        : <Link to={{ pathname: "/appointment-details", state: { appointmentId: this.state.appointmentId, providerId: this.state.providerId } }} className="custom-button"><Button varient="primary"> <ArrowBackIcon /> </Button></Link>
                    }

                    <Table className="shadow">
                        <thead className="thead-color">
                            <th>
                                Prescription Time : <strong>{(getDateSafariHack(prescriptionDetails.createdAt)).toLocaleDateString([], { year: 'numeric', month: 'long', day: 'numeric', hour: "numeric", minute: "numeric", hour12: "true" })}</strong>
                                {/* <Link to={{pathname: '/ph-mc-sl', state : {prescriptionId: this.state.prescriptionId, appointmentId:this.state.appointmentId}}} className="custom-button"><Button className="btn btn-success float-right">Order Medicine</Button></Link> */}
                            </th>
                        </thead>
                        <tbody>
                            <td>
                                <Row >
                                    <Col md={4}> Patient : <strong>{prescriptionDetails.name}</strong></Col>
                                    <Col md={4}> Age : <strong>{prescriptionDetails.age} yr</strong></Col>
                                    <Col md={4}> Gender : <strong>{prescriptionDetails.gender}</strong></Col>
                                    <Col md={4} className="mt-4">Doctor : <strong>{prescriptionDetails.doctor.name}</strong></Col>
                                    <Col md={4} className="mt-4">Mrno : <strong>{prescriptionDetails.mrno}</strong></Col>
                                    <Col md={4} className="mt-4">Speciality : <strong>{getSps(prescriptionDetails.doctor.specialisations)}</strong></Col>
                                </Row>
                            </td>
                        </tbody>
                    </Table>

                    <Table className="shadow">
                        <thead className="thead-color">
                            <th>VITALS</th>
                        </thead>
                        <tbody>
                            <td>
                                <Row>
                                    <Col md={3}>Temp : <strong>{this.state.TEMP} <sup>0</sup>F</strong></Col>
                                    <Col md={3}>Weight : <strong>{this.state.WEIGHTS_KG} Kg</strong></Col>
                                    <Col md={3}>Height : <strong>{this.state.HEIGHT_CM} cm</strong></Col>
                                    <Col md={3}>Pulse : <strong>{this.state.PULSE}</strong></Col>
                                    <Col md={3} className="mt-2">Systolic BP : <strong>{this.state.SYSTOLIC_BP_MM_HG} mmHg</strong></Col>
                                    <Col md={3} className="mt-2">Diastolic BP : <strong>{this.state.DIASTOLIC_BP_MM_HG} mmHg</strong></Col>
                                    <Col md={3} className="mt-2">Oxygen Saturation Level: : <strong>{this.state.SpO2_PCENT}</strong></Col>
                                    <Col md={3} className="mt-2">BMI : <strong>{this.state.BMI_KG_PER_M2} Kg/m<sup>2</sup></strong></Col>
                                </Row>
                            </td>
                        </tbody>
                    </Table>
                    <Row>
                        <Col md={12}>
                            <Table responsive bordered className="shadow">
                                <thead className="thead-color">
                                    <th>{this.state.complaint.type}</th>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><li>{this.state.complaint.details}</li></td>
                                    </tr>
                                </tbody>

                            </Table>
                        </Col>

                    </Row>

                    <Row>
                        <Col md={6}>
                            <Table responsive striped bordered className="shadow">
                                <thead className="thead-color">
                                    <tr>
                                        <th>ALLERGY</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <td>
                                        <ul>
                                            {this.state.allergy ? this.state.allergy.map((value, index) => {
                                                return (
                                                    <div>

                                                        {
                                                            <li><span className="subtype-heading"> {value.subtype.charAt(0).toUpperCase() + value.subtype.slice(1).toLowerCase()}</span>
                                                                <ul>
                                                                    <li>{value.details}</li>
                                                                </ul>
                                                            </li>
                                                        }
                                                    </div>
                                                )
                                            }) : null}
                                        </ul>
                                    </td>
                                </tbody>
                            </Table>
                        </Col>
                        <Col md={6}>
                            <Table responsive bordered className="shadow">
                                <thead className="thead-color">
                                    <tr>
                                        <th>SOCIAL HABITS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <td>
                                        <ul>
                                            {this.state.socialHabits ? this.state.socialHabits.map((value, index) => {
                                                return (
                                                    <div>

                                                        {
                                                            <li> <span className="subtype-heading">{value.subtype.charAt(0).toUpperCase() + value.subtype.slice(1).toLowerCase()}</span>
                                                                <ul>
                                                                    <li>{value.details}</li>
                                                                </ul>
                                                            </li>
                                                        }
                                                    </div>
                                                )
                                            }) : null}
                                        </ul>
                                    </td>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row>

                        <Col md={6}>
                            <Table responsive bordered className="shadow">
                                <thead className="thead-color">
                                    <th>DIAGNOSIS</th>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><li>{this.state.diagnosis.details}</li></td>
                                    </tr>
                                </tbody>

                            </Table>
                        </Col>
                        <Col md={6}>
                            <Table responsive bordered className="shadow">
                                <thead className="thead-color">
                                    <th>DOCTOR'S NOTES</th>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><li>{this.state.doctorNotes.details}</li></td>
                                    </tr>
                                </tbody>

                            </Table>
                        </Col>
                    </Row>
                    {this.state.medicineList.length !== 0 ?
                        <Row>
                            <Col md={12}>
                                <Table responsive bordered className="shadow" id="prescriptionDetails">
                                    <thead className="thead-color" >
                                        <tr id="prescriptionDetailsHead">
                                            <th className="text-center">Medicine Name</th>
                                            <th className="text-center">Dosage</th>
                                            <th className="text-center">Frequency</th>
                                            <th className="text-center">Duration</th>
                                            <th className="text-center">Instruction</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.medicineList.map(value => {
                                            if (value.type === 'MEDICINE')
                                                return (
                                                    <tr>
                                                        <td className="text-center" data-label="Medicine Name">{value.name}</td>
                                                        <td className="text-center" data-label="Dosage">{value.dosage}</td>
                                                        <td className="text-center" data-label="Frequency">{value.frequency}</td>
                                                        <td className="text-center" data-label="Duration">{value.duration}</td>
                                                        <td className="text-center" data-label="Instruction">{value.instruction}</td>
                                                    </tr>
                                                )
                                        })}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        : null
                    }

                    <br />
                    <Row className="mb-3">
                        {
                            prescriptionDetails ? prescriptionDetails.prescribedItems.map((value, index) => {
                                if (value.type === "ADVICE")
                                    return (
                                        <Col md={12}>
                                            <Table bordered className="shadow">
                                                <thead className="thead-color">
                                                    <th>General Advice</th>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td><li>{value.instruction}</li></td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Col>
                                    )
                            }) : null
                        }
                    </Row>
                    {prescriptionDetails.status === "COMPLETED" ?
                        this.state.adBack ?
                            <Link to={{ pathname: "/appointment-details", state: { appointmentId: this.state.appointmentId, consumerId: this.props.history.location.state.consumerId, providerId: this.props.history.location.state.providerId } }} className="custom-button"><Button varient="primary"> Back </Button></Link>
                            : this.state.presListBack ?
                                <Link to="/my-prescription-list" className="custom-button"><Button varient="primary"> Back </Button></Link>
                                : <Link to={{ pathname: "/appointment-details", state: { appointmentId: this.state.appointmentId, providerId: this.state.providerId } }} className="custom-button"><Button varient="primary"> Back </Button></Link>
                        : <Link to={{ pathname: "/appointment-details", state: { appointmentId: this.state.appointmentId, providerId: this.state.providerId } }} className="custom-button"><Button varient="primary"> Back </Button></Link>
                    }
                    {prescriptionDetails.status === "DRAFT" ?
                        <Link to={{ pathname: "/pf", state: { prescriptionId: this.state.prescriptionId, edit: true, appointmentId: '', providerId: this.state.providerId } }}><Button varient="primary" className="float-right">Edit</Button></Link>

                        : null
                    }
                    <br /><br />
                </div>
            </Container>
        )
    }
}
