import React, { Component } from 'react'
import './BlogList.css';
import { Link } from 'react-router-dom';
import { Container, Row, Media, Col,Image, Button, CardDeck } from 'react-bootstrap';
import { fetchDoctorDetails} from '../util/APIUtils';
import { getRatingByProviderId } from '../util/feedbackAndRatingUtils';
import Alert from 'react-s-alert';
import LoadingIndicator from '../common/LoadingIndicator';




export default class BlogList extends Component {

	constructor(props) {
		super(props)
		this.state = {
			did:9,
           dd:'',
		   ratings:'',
		   loading:true
		}
	}


	componentDidMount(){
		this.loadDc()
		window.scrollTo(0,0)
	}

	getSps(spArr) {
		let spStr = '';
		if (spArr) {
			spArr.map(function (sp) {
				spStr = spStr + sp.dislayStr + ", "
			})
			//let toSlc = 10;
			//spStr = spStr.substring(0, 15) + "...";
			spStr = spStr.slice(0, -2);
			return spStr;
		} else {
			return 'NA';
		}
	}

	loadDc = () => {
        fetchDoctorDetails(this.state.did)
            .then(response => {
                this.setState({
                    dd: response,
                });
                this.getRatings(response.id)
                this.setState({
                    doctorUserId: response.userId
                })
            }).catch(error => {
                this.setState({
                    loading: false
                });
                Alert.error((error && error.message) || 'Something went wrong, please try again later!');
            });
    }


    getRatings = (spIds) => {
        const ids = []
        ids.push(spIds)
        getRatingByProviderId(ids)
            .then(response => {
                this.setState({
                    ratings: response,
                    loading: false
                })
            }).catch(error => {
                this.setState({
                    loading: false
                });
                Alert.error((error && error.message) || 'Something went wrong, please try again later!');
            })
    }



	render() {

		if (this.state.loading) {
			return <LoadingIndicator />
		}
		return (
			<Container>
				<div className="blog-container">
					<Row className="welcome text-center mb-5">
						<Col sm={12}>
							<h3>Health Blogs</h3>
						</Col>
						<hr />
					</Row>


					<CardDeck>
						<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-4">
							<Media >
								<Image
									width={150}
									height={150}
									className="mr-3"
									src={this.state.dd.profileLink ? this.state.dd.profileLink : "../img/mac.png"}
									alt="Generic placeholder"
									roundedCircle
								/>
								<Media.Body>
								<h6>{"Dr. " + this.state.dd.name}</h6>
								<p className="crdfont mb-2">{this.state.dd.experienceInYears + " years | " + this.getSps(this.state.dd.specialisations)}</p>
									<h6> Mental Health and Homeopathy</h6>
									<p className="crdfont mb-2">Mental illnesses are broad spectrum disorder that affect every individual from a child, teenage to an adult...</p>
									<Link to={{ pathname: "/bd/mental-health-and-homeopathy" }} style={{ color: 'white', textDecoration: 'none' }}><Button className="align-center float-right" variant="primary" size="sm"> Read more...</Button></Link>
								</Media.Body>
							</Media>
						</div>

						<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-4">
							<Media >
								<Image
									width={150}
									height={150}
									className="mr-3"
									src={this.state.dd.profileLink ? this.state.dd.profileLink : "../img/mac.png"}
									alt="Generic placeholder"
									roundedCircle
								/>
								<Media.Body>
								<h6>{"Dr. " + this.state.dd.name}</h6>
								<p className="crdfont mb-2">{this.state.dd.experienceInYears + " years | " + this.getSps(this.state.dd.specialisations)}</p>
									
									<h6>Depression And Homeopathy</h6>
									<p className="crdfont mb-2">I haven't seen the light yet, My journey is painful to get up and walk the earth...</p>
									<Link to={{ pathname: "/bd/depression-and-homeopathy" }} style={{ color: 'white', textDecoration: 'none' }}><Button className="align-center float-right" variant="primary" size="sm"> Read more...</Button></Link>
								</Media.Body>
							</Media>
						</div>

						<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-4">
							<Media >
								<Image
									width={150}
									height={150}
									className="mr-3"
									src={this.state.dd.profileLink ? this.state.dd.profileLink : "../img/mac.png"}
									alt="Generic placeholder"
									roundedCircle
								/>
								<Media.Body>
								<h6>{"Dr. " + this.state.dd.name}</h6>
								<p className="crdfont mb-2">{this.state.dd.experienceInYears + " years | " + this.getSps(this.state.dd.specialisations)}</p>
									<h6>Homeopathy for Anxiety</h6>
									<p className="crdfont mb-2">The inner turmoil, moving back and forth, the restless body,a dreaded feeling before some event are signs of Anxiety.</p>
									<Link to={{ pathname: "/bd/homeopathy-for-anxiety" }} style={{ color: 'white', textDecoration: 'none' }}><Button className="align-center float-right" variant="primary" size="sm"> Read more...</Button></Link>
								</Media.Body>
							</Media>
						</div>
					</CardDeck>
				</div>
			</Container>
		)
	}
}
