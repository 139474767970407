import React, { Component } from 'react';
import { Container, Table, Form, Row, Col, Button } from 'react-bootstrap';
import './PasswordForm.css';
import EmptyJumbotron from '../../common/EmptyJumbotron';
import { passwordUpdate } from '../../util/APIUtils';
import Alert from 'react-s-alert';

class PasswordForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: this.props.currentUser.id,
            oldPassword: "",
            newPassword: "",
            confirmedPassword: ""
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;
        const inputValue = target.value;

        this.setState({
            [inputName]: inputValue
        });
    }

    validatePassword() {
        const newPassword = this.state.newPassword;
        const confirmedPassword = this.state.confirmedPassword;
        var reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        if (!reg.test(newPassword)) {
            Alert.error("password should contain at least 8 characters, one uppercase,one lowercase,one digit and one special character");
            return false;
        }

        if (newPassword !== confirmedPassword) {
            Alert.error('new password did not matched confirm password');
            return false;

        }
        return true;
    }

    handleSubmit(event) {
        event.preventDefault();
        const passwordFormRequest = Object.assign({}, this.state);

        const { match: { params }, history } = this.props;
        if (this.validatePassword()) {
            passwordUpdate(passwordFormRequest)
                .then(response => {
                    Alert.success("Your password updated successully!");

                }).catch(error => {
                    Alert.error((error && error.message) || 'Oops! Something went wrong. Please try again!');
                });
        }
    }


    render() {
        return (
            <Container>
                <div className="change-password-container">
                    <h2>Update Your Password</h2>
                    <Form onSubmit={this.handleSubmit}>
                        <Form.Group as={Row} controlId="currentPassword">
                            <Form.Label column sm={2}>
                                Current Password
							    </Form.Label>
                            <Col sm={3}>
                                <Form.Control name="pas" type="password" name="oldPassword" placeholder="Current Password" onChange={this.handleInputChange} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="newPassword">
                            <Form.Label column sm={2}>
                                New Password
							    </Form.Label>
                            <Col sm={3}>
                                <Form.Control type="password" name="newPassword" placeholder="New Password" onChange={this.handleInputChange} />
                                <p className="login-link"> Password should contain at least 8 characters, 1 Uppercase,1 lowercase,1 digit and 1 special character</p>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="confirmPassword">
                            <Form.Label column sm={2}>
                                Confirm Password
							    </Form.Label>
                            <Col sm={3}>
                                <Form.Control type="password" name="confirmedPassword" placeholder="Confirm Password" onChange={this.handleInputChange} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col sm={{ span: 2, offset: 2 }}>
                                <Button type="submit">Update</Button>

                            </Col>
                            <Col >
                                <Button type="button" href="/profile">Back</Button>
                            </Col>
                        </Form.Group>
                    </Form>
                </div>
                <EmptyJumbotron />
            </Container>
        );
    }
}

export default PasswordForm;