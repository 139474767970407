import React, { Component } from 'react'
import './ShareRecord.css'
import { Row, Col, Button, Container, Table, Form } from 'react-bootstrap';
import { getDoctorList, loadUsers } from '../util/APIUtils';
import { sharedByRequest } from '../util/healthAPIUtils'
import { searchDoctorBy } from '../util/searchAPIUtils'
import { searchUserBy } from '../util/APIUtils'
import Alert from 'react-s-alert';
import SearchIcon from '@material-ui/icons/Search';
import { getCurrentUserId } from '../util/functionUtils'
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
import RefreshTwoToneIcon from '@material-ui/icons/RefreshTwoTone';
import LoadingIndicator from '../common/LoadingIndicator';

export default class ShareRecord extends Component {
    constructor(props) {
        super(props)
        this.state = {
            doctorList: [],
            usersList: [],
            search: '',
            searchingUsers: true,
            searchingDoctors: true,
            categoryName: this.props.history.location.state.categoryName ? this.props.history.location.state.categoryName : null,
            categoryType: this.props.history.location.state.categoryType ? this.props.history.location.state.categoryType : null,
            fileName: this.props.history.location.state.fileName ? this.props.history.location.state.fileName : null,
            pageNumber: 0,
            pageSize: 20,
            totalElements: 0,
            sharedWithEmail: '',
            sharingEndsOn: new Date(),
            categoryId: this.props.history.location.state.categoryId,
            sharedBy: getCurrentUserId(),
            sharedWithType: '',
            sharedWithIds: [],
            loading: true,
            sharedRecords: this.props.history.location.state.recordId ? this.props.history.location.state.recordId.map(value => {
                return ({ recordId: value })
            }) : null
        }
        this.loadMoreForDoctors = this.loadMoreForDoctors.bind(this);
        this.loadMoreForUsers = this.loadMoreForUsers.bind(this);
        this.handleSearchForUsers = this.handleSearchForUsers.bind(this);
        this.handleSearchForDoctors = this.handleSearchForDoctors.bind(this);
        this.submitRequestHandler = this.submitRequestHandler.bind(this);
    }

    loadDc() {
        getDoctorList(this.state.pageNumber, this.state.pageSize)
            .then(response => {
                this.setState({
                    doctorList: response.content,
                    sharedWithType: 'DOCTOR',
                    totalElements: response.totalElements,
                    loading: false
                });
            }).catch(error => {
                this.setState({
                    loading: false
                });
                Alert.error((error && error.message) || 'Something went wrong, please try again later!');
            });

    }

    componentDidMount() {
        this.loadDc()
        window.scrollTo(0, 0)
    }

    usersList() {
        loadUsers(this.state.pageNumber, this.state.pageSize)
            .then(response => {
                this.setState({
                    usersList: response.content,
                    totalElements: response.totalElements,
                })
            })
    }

    handleChange = (event) => {
        const inputValue = event.target.value
        if (inputValue === 'DOCTOR') {
            this.setState({
                sharedWithType: inputValue
            })
        }

        if (inputValue === 'USER') {
            if (this.state.usersList.length === 0)
                this.usersList()
            this.setState({
                sharedWithType: inputValue
            })
        }
        if (inputValue === 'EMAIL') {
            this.setState({
                sharedWithType: inputValue
            })
        }
    }

    handleDateChange = (date) => {
        this.setState({
            sharingEndsOn: date
        })
    }
    handleSearchChange = (event) => {
        const inputValue = event.target.value;
        const inputName = event.target.name
        this.setState({
            [inputName]: inputValue
        })
    }
    emailHandleChange = (event) => {
        const inputValue = event.target.value
        const inputName = event.target.name
        this.setState({
            [inputName]: inputValue
        })
    }

    checkInputHandler = (event, shareId) => {

        if (event.target.checked) {
            this.setState({
                sharedWithIds: [...this.state.sharedWithIds, shareId]
            })
        }
        else {
            this.setState({
                sharedWithIds: this.state.sharedWithIds.filter((currIndex) => currIndex !== shareId)
            })
        }
    }

    async loadMoreForDoctors() {
        await this.setState({
            pageNumber: this.state.pageNumber + 1
        })
        getDoctorList(this.state.pageNumber, this.state.pageSize)
            .then(response => {
                this.setState({
                    doctorList: [...this.state.doctorList, ...response.content],
                    totalElements: response.totalElements,
                });
            }).catch(error => {
                Alert.error((error && error.message) || 'Something went wrong, please try again later!');
            });
    }

    async loadMoreForUsers() {
        await this.setState({
            pageNumber: this.state.pageNumber + 1
        })
        loadUsers(this.state.pageNumber, this.state.pageSize)
            .then(response => {
                this.setState({
                    usersList: [...this.state.usersList, ...response.content],
                    totalElements: response.totalElements,
                });
            }).catch(error => {
                Alert.error((error && error.message) || 'Something went wrong, please try again later!');
            });
    }

    async handleSearchForDoctors() {
        await this.setState({
            pageNumber: 0,
            searchingDoctors: false,
        })
        searchDoctorBy(this.state.search, "", "", "", "", "", "", "")
            .then(response => {
                this.setState({
                    doctorList: response.content,
                });
            }).catch(error => {
                Alert.error((error && error.message) || 'Something went wrong, please try again later!');
            });
    }
    async handleSearchForUsers() {
        await this.setState({
            pageNumber: 0,
            searchingUsers: false
        })
        searchUserBy(this.state.search, "", "", "", "", "", "", "")
            .then(response => {
                this.setState({
                    usersList: response.content,
                });
            }).catch(error => {
                Alert.error((error && error.message) || 'Something went wrong, please try again later!');
            });
    }
    async submitRequestHandler() {
        await this.setState({
            loading: true
        })
        console.log("inside handler", this.state.loading);
        const shareḍRequest = {
            "hrcatId": this.state.categoryId,
            "sharedBy": this.state.sharedBy,
            "sharedWithIds": this.state.sharedWithIds,
            "sharingEndsOn": this.state.sharingEndsOn,
            "sharedRecords": this.state.sharedRecords,
            "sharedWithEmail": this.state.sharedWithEmail,
            "sharedWithType": this.state.sharedWithType
        }
        sharedByRequest(shareḍRequest)
            .then(response => {
                this.setState({
                    loading: false
                })
                Alert.success("Health record shared successfully. Doctors/Users will be able to view health record and provide opinion.")
            })
            .catch(error => {
                this.setState({
                    loading: false
                })
                Alert.error((error && error.message) || 'Something went wrong, please try again later!');
            })
    }

    onChange = (event) => {
        this.setState({
            search: event.target.value
        })
    }

    clearSearch = () => {
        this.setState({
            search: ''
        })
    }

    render() {
        console.log("value of loading ", this.state.loading);
        if (this.state.loading) {
            return <LoadingIndicator />
        }

        return (
            <Container>
                <div id="top" className="shared-records-container">
                    <h3 className="text-center">Health Record Sharing</h3><hr /><br />
                    <p>You are sharing following health records of<b className="text-blue"> {this.state.categoryType.charAt(0).toUpperCase() + this.state.categoryType.substr(1).toLowerCase()} : {this.state.categoryName}</b></p>
                    {this.state.fileName === undefined ? null : this.state.fileName.map((value, index) => {
                        return (
                            <div>
                                <td>({index + 1}) {value}</td>
                            </div>
                        )
                    })}
                    <br />
                    <Row>
                        <Col md={2}>
                            Sharing Records With
                        </Col>
                        <Col md={3}>
                            <Row>
                                <Form.Check type="radio" label="Doctor's on this platform" value="DOCTOR" name="sharedWithType" onChange={this.handleChange} defaultChecked />
                            </Row>
                        </Col>
                        <Col md={3}>
                            <Form.Check type="radio" label="User's on this platform" value="USER" name="sharedWithType" onChange={this.handleChange} className="radio" />
                        </Col>
                        <Col md={3}>
                            <Form.Check type="radio" label="Others" value="EMAIL" name="sharedWithType" onChange={this.handleChange} className="radio" />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col md={2}>
                            Sharing Ends On
                        </Col>
                        <Col md={4}>
                            <DatePicker
                                name="sharingEndsOn"
                                selected={this.state.sharingEndsOn}
                                value={this.state.sharingEndsOn}
                                onChange={this.handleDateChange}
                                minDate={new Date()}
                                showMonthDropdown
                                showYearDropdown />
                        </Col>
                    </Row>
                    <br />
                    {this.state.sharedWithType === 'DOCTOR' ?
                        <Row>
                            <Col md={2}>
                            </Col>
                            <Col md={7}>
                                <Form.Control data-browse="Button text" type="text" name="search" value={this.state.search} placeholder="Search doctor by name" onChange={(event) => this.handleSearchChange(event)} />
                            </Col>
                            <Col className="mt-2">
                                <Button onClick={this.handleSearchForDoctors}> <SearchIcon /> </Button>
                            </Col>
                            <Col className="mt-2">
                                <Button onClick={this.clearSearch}><RefreshTwoToneIcon /></Button>
                            </Col>
                        </Row>
                        : null}
                    {this.state.sharedWithType === 'USER' ?
                        <Row>
                            <Col md={2}>
                            </Col>
                            <Col md={7}>
                                <Form.Control data-browse="Button text" type="text" name="search" value={this.state.search} placeholder="Search user by name" onChange={(event) => this.handleSearchChange(event)} />
                            </Col>
                            <Col md={2}>
                                <Button onClick={this.handleSearchForUsers}> <SearchIcon /></Button>
                            </Col>
                            <Col md={1}>
                                <Button onClick={this.clearSearch}><RefreshTwoToneIcon /></Button>
                            </Col>
                        </Row>
                        : null}
                    <br />
                    <Table className="shadow">
                        {this.state.sharedWithType === 'DOCTOR' || this.state.sharedWithType === 'USER' ?
                            <thead className="thead-color">
                                <th>#</th>
                                <th className="text-left">Name</th>
                                <th className="text-center">Select</th>
                            </thead>
                            : null}
                        <tbody>
                            {this.state.sharedWithType === 'DOCTOR' ?
                                (this.state.doctorList.map((value) => {
                                    return (
                                        <tr>
                                            <td>{value.id}</td>
                                            <td className="text-left">{value.profileLink ? (
                                                <span className="profile-avatar">
                                                    <img src={value.profileLink} alt={value.name} width="25px" height="25px" />
                                                </span>
                                            ) : (
                                                <span className="profile">{value.name[0]}</span>
                                            )
                                            }
                                                {" "}  {value.name}</td>
                                            <td className="text-center"><Form.Check type="checkbox" onChange={(event) => this.checkInputHandler(event, value.userId)}></Form.Check></td>
                                        </tr>
                                    )
                                })) : this.state.sharedWithType === 'USER' ? (
                                    this.state.usersList.map((value) => {
                                        return (
                                            <tr>
                                                <td>{value.id}</td>
                                                <td className="text-left">{value.imageUrl ? (
                                                    <div className="profile-avatar">
                                                        <img src={value.imageUrl} alt={value.name} width="25px" height="25px" />
                                                    </div>
                                                ) : (
                                                    <span className="profile">{value.name[0]}</span>
                                                )
                                                }
                                                    {value.name}</td>
                                                <td className="text-center"><Form.Check type="checkbox" onChange={(event) => this.checkInputHandler(event, value.id)}></Form.Check></td>
                                            </tr>
                                        )

                                    })
                                ) : this.state.sharedWithType === 'EMAIL' ? (
                                    <tr>
                                        <Form.Group as={Row} controlId="email">
                                            <Form.Label column sm="2">
                                                Share With Email :
                                            </Form.Label>
                                            <Col sm="4">
                                                <Form.Control type="email" placeholder="Email" value={this.state.sharedWithEmail} name="sharedWithEmail" onChange={this.emailHandleChange} />
                                            </Col>
                                        </Form.Group>
                                    </tr>
                                ) : null
                            }
                        </tbody>
                    </Table>
                    <div>
                        {this.state.doctorList.length >= 20 ?
                            this.state.sharedWithType === "DOCTOR" && this.state.searchingDoctors ? this.state.doctorList.length < this.state.totalElements ?
                                <Button color="primary" className=" loadMore ml-3" size="sm" onClick={this.loadMoreForDoctors}>Load more...</Button> :
                                <Button color="primary" className=" loadMore ml-3" size="sm" href="#top">Back to top</Button> : null : null
                        }
                    </div>
                    <div>
                        {this.state.usersList.length >= 20 ?
                            this.state.sharedWithType === "USER" && this.state.searchingUsers ? this.state.usersList.length < this.state.totalElements ?
                                <Button color="primary" className=" loadMore ml-3" size="sm" onClick={this.loadMoreForUsers}>Load more...</Button> :
                                <Button color="primary" className=" loadMore ml-3" size="sm" href="#top">Back to top</Button> : null : null
                        }
                    </div>{this.state.sharedWithIds.length === 0 ?
                        <Button type="button" className="btn btn-primary float-right" data-toggle="modal" data-target="#staticBackdrop" disabled> Submit</Button> :
                        <Button type="button" className="btn btn-primary float-right" data-toggle="modal" data-target="#staticBackdrop" > Submit</Button>
                    }
                    <div className="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title textColor" id="staticBackdropLabel"> Based on your consent we will share health record with selected users. Please make sure you have selected correct users and sharing end date. Please confirm to proceed or cancel to not proceed.</h5>
                                    <Button type="Button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </Button>
                                </div>
                                <div className="modal-footer">
                                    <Button type="Button" className="btn btn-secondary" data-dismiss="modal">Cancel</Button>
                                    <Button type="Button" className="btn btn-primary" data-dismiss="modal" onClick={this.submitRequestHandler}>Confirm</Button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Link to={{ pathname: "/category" }}> <Button type="button" className="btn btn-primary">Back</Button></Link>
                </div>
                <br />
            </Container>
        )
    }
}


