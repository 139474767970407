import React, { Component } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link, NavLink } from 'react-router-dom';
import {getRole} from '../util/functionUtils';

import './CustomNavbar.css';

export default class CustomNavbar extends Component {
    constructor(props){
        super()
        this.state={
            role : getRole()
        }
    }

    getDropdownTitle = () => {
        return (
            <div className="profile-container">
                <div className="container">
                    <div className="profile-info">
                        <div className="profile-avatar">
                            {
                                this.props.currentUser.photoByte ? (
                                    <img src={"data:image/jpg;base64," + this.props.currentUser.photoByte} alt={this.props.currentUser.name[0]} />
                                ) : (
                                    this.props.currentUser.imageUrl ? (
                                        <img src={this.props.currentUser.imageUrl} alt={this.props.currentUser.name} />
                                    ) : (
                                        <div className="text-avatar">
                                            <span>{this.props.currentUser.name && this.props.currentUser.name[0]}</span>
                                        </div>
                                    )
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    render() {
        return (
            <Navbar collapseOnSelect expand="md" bg="light" variant="light" fixed="top" className="nopadding">
                <Navbar.Brand href="/">
                    <img
                        src="../img/logo4.png"
                        className="d-inline-block align-top"
                        alt="BI Health"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto"></Nav>
                    <Nav>
                    <Nav.Item>
                    <Nav.Link eventKey="events" as={Link} to="/events">
                                Events
                            </Nav.Link>
                        </Nav.Item>
                    <Nav.Item>
                            <Nav.Link eventKey="doctors" as={Link} to="/hb">
                            Health Blogs
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="doctors" as={Link} to="/dc">
                                Doctors
                            </Nav.Link>
                        </Nav.Item>

						<Nav.Item>
                            <Nav.Link eventKey="pharmacies" as={Link} to="/pmacy">
                                Pharmacies
                            </Nav.Link>
                        </Nav.Item>
						
                        {
                            this.props.authenticated ?
                                (
                                    <>
                                        {this.state.role.roleAdmin
                                            ?   <Nav.Item>
                                                    <Nav.Link eventKey="admin" as={Link} to="/admin">
                                                        Admin
                                                    </Nav.Link>
                                                </Nav.Item>
                                            : null
                                        }
                                        {/* <Nav.Link href="/patientlist">Patients</Nav.Link> */}
                                        {/* {this.state.role.roleAdmin || this.state.role.roleDoctor ? <NavLink className="nav-link" to="/ap">Appointments</NavLink> : <NavLink className="nav-link" to={{ pathname: "/appointment-list", state: { consumerId: getCurrentUserId(),superUser:false } }}>Appointments</NavLink>} */}
                                        <Nav.Item>
                                            <Nav.Link eventKey="appointments" as={Link} to="/ap">
                                                Appointments
                                            </Nav.Link>
                                        </Nav.Item>
                                        {/* <NavLink className="nav-link" to="/run-track" active="true">Run & Track</NavLink> */}
                                        <Nav.Item>
                                            <Nav.Link eventKey="health-records" as={Link} to="/health-records">
                                                Health Records
                                            </Nav.Link>
                                        </Nav.Item>
                                        <NavDropdown alignRight title={this.getDropdownTitle()} id="collasible-nav-dropdown">
                                            <NavDropdown.Item href="#">
                                                <div>
                                                    {this.props.currentUser.name}
                                                    {/* <p>{this.props.currentUser.email}</p> */}
                                                </div>
                                            </NavDropdown.Item>
                                            <NavDropdown.Item eventKey="profile"><NavLink className="nav-link active" to={{pathname:"/profile", state:{userProfile:true}}}>Profile</NavLink></NavDropdown.Item>
                                            <NavDropdown.Divider />
                                            <NavDropdown.Item eventKey="sign-out" href="/" onClick={this.props.onLogout}>Sign Out</NavDropdown.Item>
                                        </NavDropdown>
                                    </>
                                )
                                :
                                (
                                    <>
                                        <NavDropdown alignRight title="Login" id="collasible-nav-dropdown">
                                            <NavDropdown.Item eventKey="login"><NavLink className="nav-link" to="/login">Login</NavLink></NavDropdown.Item>
                                            <NavDropdown.Divider />
                                            <NavDropdown.Item eventKey="sign-up"><NavLink className="nav-link" to="/signup">SignUp</NavLink></NavDropdown.Item>
                                        </NavDropdown>
                                    </>
                                )
                        }
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        )
    }
}