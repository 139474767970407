import React, { Component } from 'react';
import { Container, Table, Form, Row, Col, Button } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './ProfileForm.css';
import { getCurrentUser, profileUpdate } from '../../util/APIUtils';
import { CURRENT_USER } from '../../constants';
import Alert from 'react-s-alert';
import EmptyJumbotron from '../../common/EmptyJumbotron';
import { Link } from 'react-router-dom';
import { getDateStrFromUIObj, getDateSafariHack } from '../../util/functionUtils';
import LoadingIndicator from '../../common/LoadingIndicator';


class ProfileForm extends Component {

	constructor(props) {
		super(props);
		const currentUser = JSON.parse(localStorage.getItem(CURRENT_USER));
		this.state = {
			id: currentUser.id,
			name: currentUser.name,
			dob: getDateSafariHack(currentUser.dob),
			gender: currentUser.gender,
			phone: currentUser.phone,
			loading: false
		};
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleDateChange = this.handleDateChange.bind(this);
	}

	handleInputChange(event) {
		const target = event.target;
		const inputName = target.name;
		const inputValue = target.value;

		this.setState({
			[inputName]: inputValue
		});
	}

	handleDateChange(event) {
		this.setState({
			dob: new Date(getDateStrFromUIObj(event))
		})
	}

	componentDidMount() {
		window.scrollTo(0, 0)
	}

	handleSubmit(event) {
		event.preventDefault();
		this.setState({
			loading: true
		})
		const profileFormRequest = Object.assign({
			id: this.state.id,
			name: this.state.name,
			dob: this.state.dob,
			gender: this.state.gender,
			phone: this.state.phone
		});

		profileUpdate(profileFormRequest)
			.then(response => {
				Alert.success("Your profile is updated successully!");
				getCurrentUser().then(function (res) {
					localStorage.removeItem(CURRENT_USER);
					localStorage.setItem(CURRENT_USER, JSON.stringify(res));
				}).catch(function (error) {
					Alert.error((error && error.message) || 'Oops! Your session is not refreshed with updated profile. Please sign out and sign in once!');
				});
				this.setState({
					loading: false
				})
			}).catch(function (error) {
				Alert.error((error && error.message) || 'Oops! Something went wrong. Please try again!');
			});
	}

	render() {
		if (this.state.loading) {
			return <LoadingIndicator />
		}
		return (

			<Container>
				<div className="acct-profile-container">
					<div className="container">
						<div className="acct-profile-info">
							<h2>Update Your Profile</h2>
							<Form onSubmit={this.handleSubmit}>
								<Form.Group as={Row} controlId="formName">
									<Form.Label column sm={2}>
										Name
									</Form.Label>
									<Col sm={3}>
										<Form.Control type="text" placeholder="Name" name="name" value={this.state.name} onChange={this.handleInputChange} required />
									</Col>
								</Form.Group>
								<Form.Group as={Row} controlId="formDob">
									<Form.Label column sm={2}>
										Date of Birth
									</Form.Label>
									<Col sm={3}>
										<DatePicker name="dob" selected={this.state.dob} value={this.state.dob} onChange={this.handleDateChange} showMonthDropdown showYearDropdown required />
									</Col>
								</Form.Group>
								<Form.Group as={Row} controlId="formGender">
									<Form.Label column sm={2}>
										Gender
									</Form.Label>
									<Col sm={3}>
										<Form.Check inline label="Female" type="radio" name="gender" value="Female" checked={this.state.gender === 'Female'} onChange={this.handleInputChange} required />
										<Form.Check inline label="Male" type="radio" name="gender" value="Male" checked={this.state.gender === 'Male'} onChange={this.handleInputChange} required />
										<Form.Check inline label="Others" type="radio" name="gender" value="Others" checked={this.state.gender === 'Others'} onChange={this.handleInputChange} required />
									</Col>
								</Form.Group>
								<Form.Group as={Row} controlId="formPhone">
									<Form.Label column sm={2}>
										Phone
									</Form.Label>
									<Col sm={3}>
										<Form.Control type="text" placeholder="xxx-xxx-xxxx" name="phone" value={this.state.phone} onChange={this.handleInputChange} maxLength="10" required />
									</Col>
								</Form.Group>
								<Row>
									<Link className="custom-button btn btn-primary" to={{ pathname: "/profile", state: { reload: true } }}> Back </Link>
									<Button type="submit" className="left-margin" >Update</Button>
								</Row>
							</Form>
						</div>
					</div>
				</div>
				<EmptyJumbotron />
			</Container >
		);
	}

}

export default ProfileForm