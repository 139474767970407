import React, { Component } from 'react';
import { Jumbotron, Table } from 'react-bootstrap';
import './About.css';

export default class About extends Component {
	componentDidMount(){
		window.scrollTo(0, 0)
	}
	render() {
		return (
			<div className="container-fluid">

				<Jumbotron className="row abtjumbotron justify-content-center">
					<div>
						<div>
							<h1 className="justify-content-center text-white">About Us</h1>
							<a href="https://www.binaryintuition.com" target="_blank"></a>
						</div>
					</div>
				</Jumbotron>

				<div className="container mb-2">
				<Table className="shadow" striped bordered hover variant="light">
						<div className="justify-content-center para">
							<p>Health systems are swimming in data. The challenge will be to make all data more useful and actionable to make better decisions for patients. We have recognized Digital technology to make pretty much anything available to patients at their fingertips. It is important for healthcare to cater to their on-demand care, anywhere and anytime.</p>
							<p>We want to make it easy for patients to connect, engage and utilize best healthcare support through our platform, may it be talking to doctors, or filling their prescriptions to stay on their meds, or may be getting their physical health examined at the comfort of their home. Engaging patients through our BI Health platform to monitor adherence and communication is in the top of our mind.</p>
							<p>We enable engagement through intelligently designed solution using range of different communication channels allowing information flow between the stakeholders in a smooth manner within highly secure digital infrastructure.</p>
						</div>
					</Table>
				</div>
			</div >
		)
	}
}