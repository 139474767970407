import React, { Component, Fragment } from 'react'
import { Form, Col, Button, Row, Table, Container } from 'react-bootstrap'
import EventCarousel from './EventCarousel'
import {Link} from 'react-router-dom'
import './EventRegistration.css'
import Alert from 'react-s-alert';
import Select from 'react-select';
export default class EventRegistration extends Component {

    constructor(props) {
        super(props)
        this.state = {
            banners: this.props.location.banners
        }
    }

    render() {
        return (
            <Container>
            <Fragment>
                <div className="event-container container-fluid">
                 {this.state.banners ? <EventCarousel list={this.state.banners} />:null}
                    <Row>
                        <Col md={3}></Col>
                        <Col md={6}>
                            <Form>
                                <Form.Row>
                                    <Col className="mt-4" md={12}>
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control name="name" placeholder="Name" size="3"></Form.Control>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col className="mt-4" md={12}>
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control name="email" placeholder="Email"></Form.Control>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col className="mt-4" md={12}>
                                        <Form.Label>Phone Number</Form.Label>
                                        <Form.Control type="tel" name="phone" placeholder="Phone Number"></Form.Control>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col className="mt-4" md={12}>
                                        <Form.Label>Country</Form.Label>
                                        <Select />
                                    </Col>
                                </Form.Row>
                                <Form.Group id="formGridCheckbox">
                                    <Form.Check type="checkbox" label="I agree to the Terms and Conditions" />
                                </Form.Group>
                                <Row>
                                    <Col>
                                        <Link to={{ pathname: "/ed",id:this.props.location.id }}><Button variant="primary" className="float-left" >Back</Button></Link>
                                    </Col>
                                    <Col>
                                        <Button type="submit" className="float-right">Submit</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                        <Col md={3}></Col>
                    </Row>
                </div>
            </Fragment>
            </Container>
        )
    }
}
