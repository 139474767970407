import { LocalConvenienceStoreTwoTone } from '@material-ui/icons';
import React, { Component } from 'react';
import { Container, Form, Row, Col, Button, Table } from 'react-bootstrap';
import Alert from 'react-s-alert';
import './EntityPatientList.css'
import { getEntityPatientsList, getUserForentity, getDoctorInfo } from '../util/EntityUtils'
import LoadingIndicator from '../common/LoadingIndicator';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link } from 'react-router-dom';

class EntityPatientList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            entityPatientList: [],
            entityName: this.props.history.location.state ? this.props.history.location.state.entityName : null,
            entityId: this.props.history.location.state ? this.props.history.location.state.entityId : null,
            pageNumber: 0,
            pageSize: 20,
            totalElements: 0,
            loading: true
        }
        this.handleBack = this.handleBack.bind(this)
    }

    loadEntityPatients() {
        getEntityPatientsList(this.state.entityId, this.state.pageNumber, this.state.pageSize)
            .then(response => {
                let entityPatientsIds = []
                response.content.map(value => {
                    entityPatientsIds.push(value.consumerId)
                })
                getUserForentity(entityPatientsIds)
                    .then(response => {
                        this.setState({
                            entityPatientList: response,
                            totalElements: response.totalElements,
                            loading: false,
                        })
                    }).catch(error => {
                        Alert.error((error && error.message) || 'Something went wrong, please try again later!');
                    })
            }).catch(error => {
                Alert.error((error && error.message) || 'Something went wrong, please try again later!');
            })
    }

    componentDidMount() {
        this.loadEntityPatients()
    }

    handleBack = () => {
        this.props.history.goBack()
    }
    render() {
        if (this.state.loading)
            return <LoadingIndicator />
        return (
            <Container>
                <div className="entityPatientList-container">
                    <div className="feature-title container-fluid">
                        <h3> Patients associated with {this.state.entityName} entity</h3><hr />
                    </div>
                    <Link className="mb-4" onClick={this.handleBack}><Button varient="primary"> <ArrowBackIcon /> </Button></Link>
                    <Table hover responsive className="text-xsmall table-font-size shadow" id="entitypatientListTable">

                        {this.state.entityPatientList.length == 0 ? null :
                            <thead className="thead-color" >
                                <tr id="entitypatientListTableHead">
                                    <td className="text-left">#</td>
                                    <td className="text-left">Name</td>
                                    <td className="text-left">Gender</td>
                                </tr>
                            </thead>
                        }

                        <tbody>
                            {this.state.entityPatientList.map(user => {
                                return (<tr>
                                    <td className="text-left" data-label="#">{user.id}</td>

                                    <td className="text-left" data-label="Name">{user.imageUrl ? (
                                        <span className="profile-avatar">
                                            <img src={user.imageUrl} width="25px" height="25px" />
                                        </span>
                                    ) : (
                                        <span className="profile">{user.name[0]}</span>
                                    )
                                    }
                                        {" "}  {user.name}</td>
                                    <td data-label="Gender"> {user.gender}</td>
                                </tr>)
                            })}
                        </tbody>
                    </Table>

                    {this.state.entityPatientList.length == 0 ? <h3 className="text-center">No Patients associated with {this.state.entityName}</h3> : null}
                </div>
            </Container>
        );
    }
}

export default EntityPatientList;