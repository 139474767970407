import { ADD, ADD_ENTITY, SUBTRACT, GET_ENTITY_LIST } from "../actions/actionType";

const initialState = 50;
export const changeNumber = (state = initialState, action) => {

    switch (action.type) {
        case ADD: return state + 1;
        case SUBTRACT: return state - 1;
        default: return state;
    }
}

const initialEntity = {
    id: '',
    entityName: ''
}
export const addEntity = (state = initialEntity, action) => {
    switch (action.type) {

        case ADD_ENTITY: return {
            ...state,
            id: action.payload.id,
            entityName: action.payload.name
        }
        default: return state

    }
}

const initialEntityIds = {
    entityList:[]
}
export const getEntityList = (state = initialEntityIds, action) => {
    switch (action.type) {
       
        case GET_ENTITY_LIST: return {
            ...state,
            entityList: action.payload
        }
        default: return state
    }
}