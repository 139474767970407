import { ORDERS_API_BASE_URL, API_BASE_URL } from '../constants';
import { request } from './APIUtils';

export function getAvailableAppointment(providerId, providerType, serviceType) {
    return request({
        url: ORDERS_API_BASE_URL + "/appointment/availability" + "?pid=" + providerId + "&pt=" + providerType + "&t=" + serviceType,
        method: 'GET'
    });
}

export function createAppointment(appointmentFormRequest) {
    return request({
        url: ORDERS_API_BASE_URL + "/appointment",
        method: 'POST',
        body: JSON.stringify(appointmentFormRequest)
    })
}

export function getAppointmentById(appointmentId) {
    return request({
        url: ORDERS_API_BASE_URL + "/appointment?aid=" + appointmentId,
        method: 'GET'
    })
}

export function getAppointmentByOrderId(orderId){
    return request({
        url: ORDERS_API_BASE_URL + "/appointment/order?oid="+ orderId,
        method: 'GET'
    })
}


export function getAppointmentList(consumerId, providerType, providerId, superUser, pageNumber, pageSize) {
    return request({
        url: ORDERS_API_BASE_URL + "/appointment" + "?cid=" + consumerId + "&pt=" + providerType + "&pid=" + providerId + "&su=" + superUser + "&page=" + pageNumber + "&size=" + pageSize,
        method: 'GET'
    })
}

export function getMenberOfUser(consumerId) {
    return request({
        url: API_BASE_URL + "/user/member?uid=" + consumerId,
        method: 'GET'
    })
}

export function updateAppointmentStatus(appointmentId, status, serviceLink) {
    return request({
        url: ORDERS_API_BASE_URL + "/appointment/status" + "?aid=" + appointmentId + "&s=" + status + "&url=" + serviceLink,
        method: 'PUT'
    })
}


export function addConsent(appointmentId, consent) {
    return request({
        url: ORDERS_API_BASE_URL + "/appointment/concent" + "?aid=" + appointmentId + "&c=" + consent,
        method: 'PUT'
    })
}

export function applyCoupon(orderId, CouponCode) {
    return request({
        url: ORDERS_API_BASE_URL + "/order/coupon?oid=" + orderId + "&cc=" + CouponCode,
        method: 'POST'
    })
}