import { API_BASE_URL, ACCESS_TOKEN, DOCTORS_API_BASE_URL, EVENTS_API_BASE_URL, GALLERY_API_BASE_URL, EMAIL_API_API_BASE_URL } from '../constants';
import { getRole } from './functionUtils';

export const request = (options) => {
    const headers = new Headers({
        'Content-Type': 'application/json',
    })

    if (localStorage.getItem(ACCESS_TOKEN)) {
        headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
    }
    const defaults = { headers: headers };
    options = Object.assign({}, defaults, options);
    return fetch(options.url, options)
        .then(response =>
            response.json().then(json => {
                if (!response.ok) {
                    return Promise.reject(json);
                }
                return json;
            })
        );
};

export const requestWithWaitOfResponse = (options) => {
    const headers = new Headers({
        'Content-Type': 'application/json',
    })

    if (localStorage.getItem(ACCESS_TOKEN)) {
        headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
    }
    const defaults = { headers: headers };
    options = Object.assign({}, defaults, options);

    return fetch(options.url, options)
    .then(function(response){
        const json = response.json();
        if (!response.ok) {
            return Promise.reject(json);
        }
        return json;
    })
    .then(function(json){
        if(json.length !== 0){
            return json;
        }
    })
    .catch(function(err){
        return null;
    })
};

export const requestWithEmptyResponse = (options) => {
    const headers = new Headers({
        'Content-Type': 'application/json',
    })

    if (localStorage.getItem(ACCESS_TOKEN)) {
        headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
    }
    const defaults = { headers: headers };
    options = Object.assign({}, defaults, options);

    return fetch(options.url, options)
        .then(function(response){
            return response.json();
        })
        .then(function(json){
            if(json.length !== 0){
                return json;
            }
        })
        .catch(function(err){
            return null;
        })

}




const requestWithWait = async (options) => {

    const headers = new Headers({
        'Content-Type': 'application/json',
    })

    if (localStorage.getItem(ACCESS_TOKEN)) {
        headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
    }
    const defaults = { headers: headers };
    options = Object.assign({}, defaults, options);
    const response = await fetch(options.url, options);
    const json = await response.json();
    return json;
    // .then(response =>
    //         response.json().then(json => {
    //             if (!response.ok) {
    //                 return Promise.reject(json);
    //             }
    //             return json;
    //         })
    //     );
}


const uploadPhoto = (options) => {
    const headers = new Headers()

    if (localStorage.getItem(ACCESS_TOKEN)) {
        headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
    }

    const defaults = { headers: headers };
    options = Object.assign({}, defaults, options);

    return fetch(options.url, options)
        .then(response =>
            response.json().then(json => {
                if (!response.ok) {
                    return Promise.reject(json);
                }
                return json;
            })
        );
};

export function getCurrentUser() {
    if (!localStorage.getItem(ACCESS_TOKEN)) {
        return Promise.reject("No access token set.");
    }

    return requestWithWaitOfResponse({
        url: API_BASE_URL + "/user/me",
        method: 'GET'
    });
}

export function getCurrentUserDoctorId(userId){
    return requestWithEmptyResponse({
        url : DOCTORS_API_BASE_URL + "/doctor/info?uid=" + userId,
        method: 'GET',
    })
}

export function login(loginRequest) {
    return request({
        url: API_BASE_URL + "/auth/login",
        method: 'POST',
        body: JSON.stringify(loginRequest)
    });
}


export function emailVerification(id) {
    return request({
        url: API_BASE_URL + "/auth/verify?id=" + id,
        method: 'GET',
    });
}



export function profileUpdate(profileFormRequest) {
    return request({
        url: API_BASE_URL + "/user/profile",
        method: 'POST',
        body: JSON.stringify(profileFormRequest)
    });
}

export function passwordUpdate(passwordFormRequest) {
    return request({
        url: API_BASE_URL + "/user/password",
        method: 'POST',
        body: JSON.stringify(passwordFormRequest)
    });
}

export function getUserPresignedUrl(fileName, id, contentType) {
    // const formData = new FormData();
    // formData.append('file', fileData);
    return request({
        url: API_BASE_URL + "/user/profile/photo/url?id=" + id + "&p=" + fileName + "&ct=" + contentType + "&t=PUT",
        method: 'GET'
    });
}

export function getPresignedUrl(fileName, id, contentType) {
    // const formData = new FormData();
    // formData.append('file', fileData);
    return request({
        url: DOCTORS_API_BASE_URL + "/doctor/photo/url?did=" + id + "&p=" + fileName + "&ct=" + contentType + "&t=PUT",
        method: 'GET'
    });
}

export function updateGalleryPhoto(fileData, id, type) {
    const formData = new FormData();
    formData.append('file', fileData);
    return uploadPhoto({
        url: GALLERY_API_BASE_URL + "/gallery/parts?id=" + id + "&type=" + type,
        method: 'POST',
        body: formData
    });
}

export function updateActorPhoto(fileData, eid, aid) {
    const formData = new FormData();
    formData.append('file', fileData);
    return uploadPhoto({
        url: EVENTS_API_BASE_URL + "/event/actor/photo?eid=" + eid + "&aid=" + aid,
        method: 'POST',
        body: formData
    });
}

export function signup(signupRequest) {
    return request({
        url: API_BASE_URL + "/auth/signup",
        method: 'POST',
        body: JSON.stringify(signupRequest)
    });
}

export function forgotPasswordSendMail(forgotPasswordRequest) {
    return request({
        url: API_BASE_URL + "/user/reset/request",
        method: 'POST',
        body: JSON.stringify(forgotPasswordRequest)

    });
}

export function passwordReset(passwordFormResetRequest) {
    return request({
        url: API_BASE_URL + "/user/reset/password",
        method: 'POST',
        body: JSON.stringify(passwordFormResetRequest)
    });
}

export function getSpecializationList(pageNumber, pageSize) {
    return request({
        url: DOCTORS_API_BASE_URL + "/sp?page=" + pageNumber + "&size=" + pageSize,
        method: 'GET'
    });
}

export function getDoctorList(pageNumber, pageSize) {
    return request({
        url: DOCTORS_API_BASE_URL + "/doctor?page=" + pageNumber + "&size=" + pageSize,
        method: 'GET'
    });
}


export function fetchDoctorDetails(id) {
    // if (!localStorage.getItem(ACCESS_TOKEN)) {
    //     return Promise.reject("No access token set.");
    // }

    return request({
        url: DOCTORS_API_BASE_URL + "/doctor?id=" + id ,
        method: 'GET'
    });
}

export function fetchGalleryParts(eId, type) {
    if (!localStorage.getItem(ACCESS_TOKEN)) {
        return Promise.reject("No access token set.");
    }

    return request({
        url: GALLERY_API_BASE_URL + "/gallery/parts?eid=" + eId + "&type=" + type,
        method: 'GET'
    });
}

export function getGalleryPhotos(pageNumber, pageSize, eid, type) {
    return request({
        url: GALLERY_API_BASE_URL + "/gallery/parts?page=" + pageNumber + "&size=" + pageSize + "&eid=" + eid + "&type=" + type,
        method: 'GET'
    });
}
export function getQualificationList(pageNumber, pageSize) {

    return request({
        url: DOCTORS_API_BASE_URL + "/ql?page=" + pageNumber + "&size=" + pageSize,
        method: 'GET'
    });
}

export function createQualification(qualicationFormRequest) {
    return request({
        url: DOCTORS_API_BASE_URL + "/ql",
        method: 'POST',
        body: JSON.stringify(qualicationFormRequest)
    });
}

export function updateQualification(qualicationUpdateRequest) {
    return request({
        url: DOCTORS_API_BASE_URL + "/ql",
        method: 'PUT',
        body: JSON.stringify(qualicationUpdateRequest)
    });
}

export function deleteQualification(deleteQualificationRequest) {
    return request({
        url: DOCTORS_API_BASE_URL + "/ql",
        method: 'DELETE',
        body: JSON.stringify(deleteQualificationRequest)
    });
}
export function createSpecialization(specializationFormRequest) {
    return request({
        url: DOCTORS_API_BASE_URL + "/sp",
        method: 'POST',
        body: JSON.stringify(specializationFormRequest)
    });
}

export function updateSpecialization(updateSpecializationRequest) {
    return request({
        url: DOCTORS_API_BASE_URL + "/sp",
        method: 'PUT',
        body: JSON.stringify(updateSpecializationRequest)
    });
}
export function deleteSpecialization(deleteSpecializationRequest) {
    return request({
        url: DOCTORS_API_BASE_URL + "/sp",
        method: 'DELETE',
        body: JSON.stringify(deleteSpecializationRequest)
    });
}
export function createDoctor(doctorFormRequest , method,userId) {
    if (getRole().roleAdmin) {
        return request({
            url: DOCTORS_API_BASE_URL + "/doctor",
            method: method,
            body: JSON.stringify(doctorFormRequest)

        });
    } else if (getRole().roleUser) {
        return request({
            url: DOCTORS_API_BASE_URL + "/doctor?id=" + userId,
            method: method,
            body: JSON.stringify(doctorFormRequest)

        });
    }
}

export function createEvent(eventFormRequest) {
    return request({
        url: EVENTS_API_BASE_URL + "/event",
        method: 'POST',
        body: JSON.stringify(eventFormRequest)
    });
}

export function getEventDetails(id) {
    return request({
        url: EVENTS_API_BASE_URL + "/event?id=" + id,
        method: 'GET'
    });
}
export function getEventsList(pageNumber, pageSize,passedEvents) {
    return request({
        url: EVENTS_API_BASE_URL + "/event?page=" + pageNumber + "&size=" + pageSize + "&past=" + passedEvents,
        method: 'GET',
    });
}
export function getEventsListByDate(pageNumber, pageSize, past) {
    return request({
        url: EVENTS_API_BASE_URL + "/event?page=" + pageNumber + "&size=" + pageSize + "&past=" + past,
        method: 'GET',
    });
}

export function doctorSchedule(scheduleRequest, doctorId) {
    return request({
        url: DOCTORS_API_BASE_URL + "/cnscfg?docid=" + doctorId,
        method: 'POST',
        body: JSON.stringify(scheduleRequest)
    });

}

export function doctorScheduleUpdate(scheduleRequest, doctorId) {
    return request({
        url: DOCTORS_API_BASE_URL + "/cnscfg?docid=" + doctorId,
        method: 'PUT',
        body: JSON.stringify(scheduleRequest)
    });

}

export function loadDoctorConfigDetailsById(doctorId) {

    return request({
        url: DOCTORS_API_BASE_URL + "/cnscfg?docid=" + doctorId,
        method: 'GET'

    });
}


export function loadUsers(pageNumber, pageSize) {
    return request({
        url: API_BASE_URL + "/user/search?page=" + pageNumber + "&size=" + pageSize,
        method: 'GET'
    });
}

export function searchUserBy(name, email, phone, status, isLocked, emailVerified, pageNumber, pageSize) {
    return request({
        url: API_BASE_URL + "/user/search?n=" + name + "&e=" + email + "&p=" + phone + "&st=" + status + "&il=" + isLocked + "&ev=" + emailVerified + "&page=" + pageNumber + "&size" + pageSize,
        method: 'GET'
    });
}

export function addAppointmentMember(appointmentMemberRequest) {
    return request({
        url: API_BASE_URL + "/user/member",
        method: 'POST',
        body: JSON.stringify(appointmentMemberRequest)
    });
}

export function updateDoctorStatus(id, status) {
    return request({
        url: DOCTORS_API_BASE_URL + "/doctor/status?id=" + id + "&status=" + status,
        method: 'PUT'
    });
}

export function updateUserStatus(updateStatus) {
    return request({
        url: API_BASE_URL + "/user/bulk",
        method: 'POST',
        body: JSON.stringify(updateStatus)
    });
}

export function contactFormRequest(formRequest){
    return request({
        url: EMAIL_API_API_BASE_URL + "/email-service",
        method: 'POST',
        body: JSON.stringify(formRequest)
    });
}