import React, { Component } from 'react'
import './AddCategory.css'
import { Row, Col, Button, Container, Form } from 'react-bootstrap';
import { addCategory } from '../util/healthAPIUtils'
import Select from 'react-select'
import Alert from 'react-s-alert';
import { Link } from 'react-router-dom'
import { getCurrentUserId } from '../util/functionUtils'

export default class AddCategory extends Component {

    constructor() {
        super()
        this.state = {
            categories: [{ label: "Prescription", value: "PRESCRIPTION" }, { label: "LabReports", value: "LAB_REPORTS" }],
            ownerId: getCurrentUserId(),
            type: '',
            subType: 'EXT',
            name: '',
            addedCategory: ''


        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    changeDropdownHandler = (setValue, setName) => {
        this.setState({
            [setName]: setValue
        })
    }
    handleNameChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    submitHandler = () => {
        const addCategoryRequest = this.state
        addCategory(addCategoryRequest)
            .then(response => {
                Alert.success("category added successfully")
                this.setState({
                    addedCategory: response
                })
                this.props.history.push({
                    pathname: 'health-report',
                    state: { id: this.state.addedCategory.id, type: this.state.addedCategory.type, name: this.state.addedCategory.name }
                })
            }).catch(error => {
                Alert.error((error && error.message) || 'Oops! Something went wrong. Please try again!');
            })
    }

    render() {
        return (
            <Container>
                <div className="addcategory-records-container">
                    <h2>Add Medical Record Category</h2>
                    <br />
                    <Form onSubmit={this.handleSubmit}>
                        <Form.Group as={Row} controlId="type">
                            <Form.Label column sm={3}>
                                Health Record Category :
                            </Form.Label>
                            <Col sm={3}>
                                <Select
                                    options={this.state.categories}
                                    onChange={(selectedOption) => this.changeDropdownHandler(selectedOption.value, "type")}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="name">
                            <Form.Label column sm={3}>
                                Category Name :
                            </Form.Label>
                            <Col sm={3}>
                                <Form.Control type="text" value={this.state.name} name="name" placeholder="Category Name" onChange={this.handleNameChange} maxLength="40" required />
                            </Col>
                        </Form.Group>
                        <Row>
                            <Link className=" btn btn-primary" to="/category">Back</Link>
                            <Button variant="primary" className="left-margin-adcat" onClick={this.submitHandler}>Submit</Button>
                        </Row>
                    </Form>
                    <br />
                </div>
            </Container>
        )
    }
}
