import { isValid } from 'date-fns';
import { CURRENT_USER, ACCESS_TOKEN } from '../constants';
import { getAddress } from '../util/ReferentialAPIUtils'
import jwt_decode from "jwt-decode";
import Alert from 'react-s-alert';

export function getRole(currentUser) {
    if (!currentUser) {
        currentUser = localStorage.getItem(CURRENT_USER);
    }

    let roleUser = false
    let roleAdmin = false
    let roleDoctor = false
    let roleSuperAdmin = false
    let roleSupportAdmin = false
    let roleEntityAdmin = false

    if (currentUser) {
        const user = JSON.parse(currentUser)

        const role = user.mappedRoles

        if (role) {
            role.map(value => {
                if (value === "ROLE_USER")
                    roleUser = true
                else if (value === "ROLE_DOCTOR")
                    roleDoctor = true
                else if (value === "ROLE_ADMIN")
                    roleAdmin = true
                else if (value === "ROLE_SUPER_ADMIN")
                    roleSuperAdmin = true
                else if (value === "ROLE_SUPPORT_ADMIN")
                    roleSupportAdmin = true
                else if (value === "ROLE_ENTITY_ADMIN")
                    roleEntityAdmin = true
            })
        }
    }

    return {
        "roleUser": roleUser,
        "roleDoctor": roleDoctor,
        "roleAdmin": roleAdmin,
        "roleSuperAdmin": roleSuperAdmin,
        "roleSupportAdmin": roleSupportAdmin,
        "roleEntityAdmin": roleEntityAdmin
    }

}

export function changeTime(timeStr) {

    let date = new Date(timeStr)
    let hour = date.getHours()
    let min = date.getMinutes()
    let format = 'AM'
    if (hour > 12) {
        hour -= 12
        format = 'PM'
    }
    else if (hour === 0)
        hour = 12
    else if (hour === 12)
        format = 'PM'

    if (hour < 10)
        hour = "0" + hour
    if (min < 10)
        min = "0" + min

    const time = hour + ":" + min + " " + format
    return time

}

export function getDateStrFromUIObj(date) {
    let day = date.getDate()
    let monthList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    // console.log("getDateStrFromObj " + date.getMonth() + " for " + date + " day = " + day);
    let month = monthList[date.getMonth()]
    let year = date.getFullYear()
    return day + " " + month + " " + year;
}

export function getDateStrFromObj(date) {
    let day = date.getDate()
    let monthList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    // console.log("getDateStrFromObj " + date.getMonth() + " for " + date + " day = " + day);
    let month = monthList[date.getMonth() - 1]
    let year = date.getFullYear()
    return day + " " + month + " " + year;
}

export function getDate(dateStr) {
    if (dateStr) {
        let dateArray = dateStr.split('-');
        let monthList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        let date = new Date(dateArray[0], dateArray[1], dateArray[2].substring(0, dateArray[2].indexOf('T')))
        let day = date.getDate()
        console.log("getDate " + date.getMonth() + " for " + dateStr + " day = " + day);
        let month = monthList[date.getMonth() - 1]
        let year = date.getFullYear()
        return day + " " + month + " " + year
    }
    return dateStr;

}

export function getDateSafariHack(dateStr) {
    if (dateStr) {
        let a = dateStr.split(/[^0-9]/);
        let d = new Date(Date.UTC(a[0], a[1] - 1, a[2], a[3], a[4], a[5]));
        return d;
    }
    return null;
}


export function getCurrentUserId() {
    const currentUser = localStorage.getItem(CURRENT_USER);
    if (currentUser) {
        const user = JSON.parse(localStorage.currentUser)
        const id = user.id
        return id;
    }
    return null;

}

export function getCurrentUser() {
    const currentUser = localStorage.getItem(CURRENT_USER);
    if (currentUser) {
        return JSON.parse(currentUser)
    }
    return null;
}

export function uploadFile(signedUrl, fileData) {
    return uploadFileUsingSignedUrl({
        url: signedUrl,
        method: 'PUT',
        body: fileData
    }, fileData.type)
}

export function uploadCustomFile(signedUrl, fileData, contentType) {
    return uploadFileUsingSignedUrl({
        url: signedUrl,
        method: 'PUT',
        body: fileData
    }, contentType)
}

const uploadFileUsingSignedUrl = (options, contentType) => {
    const headers = new Headers({
        'Content-Type': contentType,
    })
    const defaults = { headers: headers };
    options = Object.assign({}, defaults, options);
    return fetch(options.url, options)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response);
            }
            return response
        })
}


export function currentLoggedInUser(id) {
    const currentUserId = getCurrentUserId()
    return currentUserId === id
}


export function getSps(spArr) {
    let spStr = '';
    if (spArr) {
        spArr.map(function (sp) {
            spStr = spStr + sp.dislayStr + ", "
        })
        //let toSlc = 10;
        //spStr = spStr.substring(0, 15) + "...";
        spStr = spStr.slice(0, -2);
        return spStr;
    } else {
        return 'NA';
    }
}


export function validateImageType(fileExtension) {
    var allowedExtension = ['jpeg', 'jpg', 'png'];
    var isValidFile = false;
    for (var index in allowedExtension) {
        if (fileExtension === allowedExtension[index]) {
            isValidFile = true;
            break;
        }
    }
    return isValidFile;
}

export function validateImageSize(ImageSize) {
    var size = parseFloat(ImageSize / (1024));
    if (size < 250) {
        return true;
    }
    return false;
}

export function validateFileType(fileExtension) {
    var allowedExtension = ['pdf', 'jpeg', 'jpg', 'png'];
    var isValidFile = false;
    for (var index in allowedExtension) {
        if (fileExtension === allowedExtension[index]) {
            isValidFile = true;
            break;
        }
    }
    return isValidFile;
}

export function validateFileSize(fileSize) {
    var size = parseFloat(fileSize / (1024 * 1024)).toFixed(2);
    if (size < 5) {
        return true;
    }
    return false;
}

export function addFeedBackItems(feedbackList) {
    const list = {}
    feedbackList.map(item => {
        list.fd = item;
    })
    const obj = list.fd
    obj.items.map(it => {
    })
    return obj;
}

export function addressCountryStateCityMap(response) {
    //converting country,state,and city
    let countryList = {}
    response.map(address => {
        if (address.countryCode in countryList) {
            if (address.stateCode in countryList[address.countryCode]) {
                countryList[address.countryCode][address.stateCode].push(address.cityCode)
            }
            else {
                let stateCode = address.stateCode
                let cityList = [address.cityCode]
                countryList[address.countryCode][stateCode] = cityList
            }
        }
        else {
            let stateCode = address.stateCode
            let cityList = [address.cityCode]
            countryList[address.countryCode] = { [stateCode]: cityList }
        }
    })
    return countryList
}


export function get_url_extension(url) {
    return url.split(/[#?]/)[0].split('.').pop().trim();
}

export function convertIntoCamelCase(string) {
    return string.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
}

export function decodeToken() {
    const token = localStorage.getItem(ACCESS_TOKEN);
    var decoded = jwt_decode(token);
    console.log("token decode", decoded);
    return decoded;


}

export function eventsTypeMapping(eventType) {

    if (eventType == "VIDEO_RECORDING") {
        return "Video Recording";
    } else if (eventType == "LIVE_STREAM") {
        return "Live Stream";
    } else if (eventType == "PHYSICAL") {
        return "Physical";
    } else {
        return "Podcast";
    }
}

export function timeDifferenceInMinutes(time1,time2){
    var diff = Math.abs(new Date(time1) - new Date(time2));
    var minutes = Math.floor((diff/1000)/60);
    return minutes;
}