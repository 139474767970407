import React, { Component } from 'react';

import { Container, Form, Row, Col, Button } from 'react-bootstrap';
import EmptyJumbotron from '../common/EmptyJumbotron';
import { createQualification, updateQualification } from '../util/APIUtils'
import Alert from 'react-s-alert';
import {Link} from 'react-router-dom';
import LoadingIndicator from '../common/LoadingIndicator';

class QualificationForm extends Component {
    constructor(props) {
        super(props)
        if (this.props.location.editqualification) {
            this.state = {
                code: this.props.location.editqualification.code,
                dislayStr: this.props.location.editqualification.dislayStr,
                loading:false
            }
        }
        else {
            this.state = {
                code: '',
                dislayStr: '',
                loading:false
            }
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;
        const inputValue = target.value;

        this.setState({
            [inputName]: inputValue
        });
    }

    handleSubmit(event) {
        this.setState({loading:true})
        event.preventDefault();
        const { match: { params }, history } = this.props;

        if (this.props.location.editqualification) {
            const updateQualificationObject = Object.assign({}, this.state)
            updateQualification(updateQualificationObject)
                .then(response => {
                    this.setState({loading:false})
                    Alert.success("Qualification updated successully!");
                }).catch(error => {
                    this.setState({loading:false})
                    Alert.error((error && error.message) || 'Oops! Something went wrong. Please try again!');
                });
        } else {
            const qualicationFormRequest = Object.assign({}, this.state);
            createQualification(qualicationFormRequest)
                .then(response => {
                    this.setState({loading:false})
                    Alert.success("Qualification created successully!");
                }).catch(error => {
                    this.setState({loading:false})
                    Alert.error((error && error.message) || 'Oops! Something went wrong. Please try again!');
                });
        }
    }

    componentDidMount(){
        window.scrollTo(0, 0)
    }

    render() {
        if(this.state.loading){
            return <LoadingIndicator/>
        }
        return (
            <Container>
                <div className="change-password-container">
                    {this.props.location.editqualification ? <h2>Update Qualification</h2> : <h2>Create Qualification</h2>}
                    <Form onSubmit={this.handleSubmit}>
                        <Form.Group as={Row} controlId="code">
                            <Form.Label column sm={2}>
                                Code
							    </Form.Label>
                            <Col sm={3}>
                                {this.props.location.editqualification ? <Form.Control type="text" value={this.state.code} name="code" placeholder="Code" onChange={this.handleInputChange} readOnly /> :
                                    <Form.Control type="text" value={this.state.code} name="code" placeholder="Code" pattern="[0-9a-zA-Z]{1,5}" title="At most five letter code" onChange={this.handleInputChange} />}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="description">
                            <Form.Label column sm={2}>
                                Description
							    </Form.Label>
                            <Col sm={3}>
                                <Form.Control type="text" value={this.state.dislayStr} name="dislayStr" placeholder="Description" onChange={this.handleInputChange} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col sm={2}>
                                <Link to="/qlf" className="float-left btn btn-primary">  Back</Link>
                            </Col>
                            <Col sm={3}>
                                <Button type="submit" className="float-right">Submit</Button>
                            </Col>
                        </Form.Group>

                    </Form>
                </div>
                <EmptyJumbotron />
            </Container>
        );
    }
}

export default QualificationForm;