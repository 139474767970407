import React, { Component, Fragment } from 'react';
import RemoveSharpIcon from '@material-ui/icons/RemoveSharp';
import { Form, Col, Button} from 'react-bootstrap'
import AddSharpIcon from '@material-ui/icons/AddSharp';






class AddRow extends Component {
    constructor(props) {
        super(props)
        console.log(this.props);
        this.state = {
            designation: this.props.designation,
            id: 0,
            isExternal: true,
            name: this.props.name,
            email:this.props.email,
            organisation: this.props.organisation,
            google:this.props.google,
            facebook:this.props.facebook,
            linkedin:this.props.linkedin,
            instagram:this.props.instagram,
            type: ""
        }
    }

    handleInputChange = (event, index) => {
        const inputName = event.target.name;
        const inputValue = event.target.value;
        this.setState({
            [inputName]: inputValue
        })

        this.props.inputChange(inputName, inputValue, index)
    }

    

    render() {
        return (
            <Fragment>
                {/* <tr key={this.props.key}>
                    <td><Form.Control name="name" value={this.state.name} placeholder="Name" onChange={(event) => this.handleInputChange(event,this.props.index)} /></td>
                    <td><Form.Control name="designation" value={this.state.designation} placeholder="Designation" onChange={(event) => this.handleInputChange(event,this.props.index)} /></td>
                    <td><Form.Control name="organisation" value={this.state.organisation} placeholder="organisation" onChange={(event) => this.handleInputChange(event,this.props.index)} /></td>
                    <td><Form.Control name="socialProfile" value={this.state.socialProfile} placeholder="Social Profile" onChange={(event) => this.handleInputChange(event,this.props.index)} /></td>
                    <td><Button onClick={() => this.props.deleteRow(this.props.index)} className="btn-danger"><RemoveSharpIcon /></Button></td>
                </tr> */}

                <Form.Row>
                    <Col className="mt-4" md={6}>
                        <Form.Label>Name</Form.Label>
                        <Form.Control value={this.state.name} name="name" placeholder="name" onChange={(event) => this.handleInputChange(event,this.props.index)}/>
                    </Col>
                    <Col className="mt-4" md={6}>
                        <Form.Label>Email</Form.Label>
                        <Form.Control value={this.state.email} name="email" placeholder="email" onChange={(event) => this.handleInputChange(event,this.props.index)} />
                    </Col>
                </Form.Row>

                <Form.Row>
                    <Col className="mt-4" md={6}>
                        <Form.Label>Designation</Form.Label>
                        <Form.Control value={this.state.designation} name="designation" placeholder="Designation" onChange={(event) => this.handleInputChange(event,this.props.index)} />
                    </Col>
                    <Col className="mt-4" md={6}>
                        <Form.Label>Organisation</Form.Label>
                        <Form.Control value={this.state.organisation} name="organisation" placeholder="Organisation" onChange={(event) => this.handleInputChange(event,this.props.index)} />
                    </Col>
                </Form.Row>

                <Form.Row>
                
                    <Col className="mt-4" md={6}>
                        <Form.Label>Google Profile</Form.Label>
                        <Form.Control value={this.state.google} name="google" placeholder="Google Profile" onChange={(event) => this.handleInputChange(event,this.props.index)}/>
                    </Col>
                    <Col className="mt-4" md={6}>
                        <Form.Label>Facebook Profile</Form.Label>
                        <Form.Control value={this.state.facebook} name="facebook" placeholder="Facebook Profile" onChange={(event) => this.handleInputChange(event,this.props.index)} />
                    </Col>
                </Form.Row>

                <Form.Row>
                    <Col className="mt-4" md={6}>
                        <Form.Label>Linkedin Profile</Form.Label>
                        <Form.Control value={this.state.linkedin} name="linkedin" placeholder="Linkedin Profile" onChange={(event) => this.handleInputChange(event,this.props.index)} />
                    </Col>
                    <Col className="mt-4" md={6}>
                        <Form.Label>Instagram Profile</Form.Label>
                        <Form.Control value={this.state.instagram} name="instagram" placeholder="Instagram Profile" onChange={(event) => this.handleInputChange(event,this.props.index)} />
                    </Col>
                </Form.Row><br/>
                <Button style={{ float: "right",marginLeft:"10px" }} onClick={this.props.addNewRow}><AddSharpIcon /></Button>

                <Button style={{float:"right"}} onClick={() => this.props.deleteRow(this.props.index)} className="btn-danger"><RemoveSharpIcon /></Button>
                <br/><hr/>


            </Fragment>

        );
    }
}

export default AddRow;
