import React, { Component } from "react";
import { Container, Row, Col, Table, Button, Form, Card, Modal } from "react-bootstrap";
import "./PaymentForm.css";
import AddressForm from './AddressForm';
import EmptyJumbotron from '../common/EmptyJumbotron';
import AddSharpIcon from '@material-ui/icons/AddSharp';
import { saveBilligAddress, getUserAddress } from '../util/paymentUtils';
import Alert from 'react-s-alert';
import { getCurrentUserId, getCurrentUser, addressCountryStateCityMap } from '../util/functionUtils'
import AddressList from "./AddressList";
import { Add } from "@material-ui/icons";
import { getAddress, getAddressInfo } from '../util/ReferentialAPIUtils'
import LoadingIndicator from '../common/LoadingIndicator';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link } from 'react-router-dom';


export default class Address extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      billingAddressList: [],
      shippingAddressList: [],
      editAddress: {},
      isDiffernetAddressChecked: false,
      appointmentId: this.props.history.location.state ? this.props.history.location.state.appointmentId : null,
      providerId: this.props.history.location.state ? this.props.history.location.state.providerId : null,
      billingAddress: {
        type: 'BILLING',
        userId: getCurrentUserId(),
      },
      shippingAddress: {
        type: 'SHIPPING',
        userId: getCurrentUserId(),
      },
      currentUser: getCurrentUser(),
      showBillingModal: false,
      showShippingModal: false,
      phone: getCurrentUser().phone ? getCurrentUser().phone : ''
    };
  }

  handleInputChange = (event) => {
    const inputName = event.target.name
    const inputValue = event.target.checked
    this.setState({
      [inputName]: inputValue
    })
  }

  handleAddressChange = (name, value, type) => {
    if (type === 'BILLING') {
      this.setState(prevState => ({
        billingAddress: { ...prevState.billingAddress, [name]: value, }
      }))
    }
    else if (type === 'SHIPPING') {
      this.setState(prevState => ({
        shippingAddress: { ...prevState.shippingAddress, [name]: value, }
      }))
    }
  }

  handleSubmit = (event) => {
    event.preventDefault()


    if (this.state.billingAddressList.length === 0 || this.state.shippingAddressList.length === 0) {
      if (this.state.billingAddress.countryCode === undefined || this.state.billingAddress.pincode === '') {
        Alert.error("Please select Country, State, City and Pincode")
      }
      else if (this.state.isDiffernetAddressChecked && (this.state.shippingAddress.countryCode === undefined || this.state.shippingAddress.pincode === '')) {
        Alert.error("Please select Country, State, City and Pincode")
      }
      else if (this.state.phone === '') {
        Alert.error("Please enter Phone Number")
      }
      else {
        const addressRequest = []
        addressRequest.push(this.state.billingAddress)
        if (this.state.isDiffernetAddressChecked)
          addressRequest.push(this.state.shippingAddress)
        else {
          let shippingAddress = Object.assign({}, this.state.billingAddress)
          shippingAddress.type = "SHIPPING"
          shippingAddress.name = this.state.currentUser.name
          shippingAddress.phone = this.state.phone
          addressRequest.push(shippingAddress)
        }

        saveBilligAddress(addressRequest)
          .then(response => {
            Alert.success("Billing & Shipping addresses added successfully for payment!");
            var shippingAddress;
            if (this.state.shippingAddress.name !== '') {
              shippingAddress = this.state.shippingAddress
            } else {
              shippingAddress = Object.assign({}, this.state.billingAddress)
              shippingAddress.type = "SHIPPING"
              shippingAddress.name = this.state.currentUser.name
              shippingAddress.phone = this.state.phone
            }

            //converting country,state,and city
            let requestMap = addressCountryStateCityMap(response)
            getAddress(requestMap)
              .then(res => {
                response.map(address => {
                  if (res[address.countryCode]) {
                    address.country = res[address.countryCode]["ccDisplayStr"]
                    address.state = res[address.countryCode]["stateMap"][address.stateCode]["scDisplayStr"]
                    address.city = res[address.countryCode]["stateMap"][address.stateCode]["cityMap"][address.cityCode]["ccDisplayStr"]
                  }
                  this.setState({
                    loading: false
                  })
                })

                var billingAddress = ''
                var shippingAddress = ''
                response.map(address => {
                  if (address.type === "BILLING") {
                    billingAddress = address
                  }
                  if (address.type === "SHIPPING") {
                    shippingAddress = address
                  }
                })

                if (shippingAddress === '') {
                  shippingAddress = billingAddress
                  shippingAddress.name = this.state.currentUser.name
                  shippingAddress.phone = this.state.phone
                }
                this.props.history.push(
                  {
                    pathname: "/od-sm",
                    state: { appointmentId: this.state.appointmentId, billingAddress: billingAddress, shippingAddress: shippingAddress }
                  })
              }).catch(error => {
                Alert.error((error && error.message) || 'Something went wrong, please try again later!');
              })


          }).catch(error => {
            Alert.error((error && error.message) || 'Something went wrong, please try again later!');
          })
      }
    } else {
      if (this.state.billingAddress.house && this.state.billingAddress.countryCode) {
        if (this.state.phone == '') {
          Alert.error("Please enter Phone Number")
        } else {
          var shippingAddress;
          if (this.state.shippingAddress.name !== undefined && this.state.shippingAddress.name !== '') {
            shippingAddress = this.state.shippingAddress
          } else {
            shippingAddress = Object.assign({}, this.state.billingAddress)
            shippingAddress.type = "SHIPPING"
            shippingAddress.name = this.state.currentUser.name
            shippingAddress.phone = this.state.phone
          }
          this.props.history.push(
            {
              pathname: "/od-sm",
              state: { appointmentId: this.state.appointmentId, billingAddress: this.state.billingAddress, shippingAddress: shippingAddress }
            })
        }
      }
      else {
        Alert.error('Please Select billing address!');
      }
    }
  }

  addNewAddress = (type) => {
    const addressRequest = []
    if (type === 'BILLING') {
      addressRequest.push(this.state.billingAddress)
    }
    else if (type === 'SHIPPING') {
      addressRequest.push(this.state.shippingAddress)
    }

    saveBilligAddress(addressRequest)
      .then(response => {
        //method to create map to call country state city mapping
        let requestMap = addressCountryStateCityMap(response)
        getAddress(requestMap)
          .then(res => {
            response.map(address => {
              if (res[address.countryCode]) {
                address.country = res[address.countryCode]["ccDisplayStr"]
                address.state = res[address.countryCode]["stateMap"][address.stateCode]["scDisplayStr"]
                address.city = res[address.countryCode]["stateMap"][address.stateCode]["cityMap"][address.cityCode]["ccDisplayStr"]
              }
              this.setState({
                loading: false
              })
            })
          }).catch(error => {
            Alert.error((error && error.message) || 'Something went wrong, please try again later!');
          })
        if (type === 'BILLING') {
          this.setState(prevState => ({
            billingAddressList: [response[0], ...prevState.billingAddressList]
          }))
        }
        else if (type === 'SHIPPING') {
          this.setState(prevState => ({
            shippingAddressList: [response[0], ...prevState.shippingAddressList]
          }))
        }
      }).catch(error => {
        Alert.error((error && error.message) || 'Something went wrong, please try again later!');
      })
  }

  componentDidMount() {
    this.getUserAddress()
  }

  getUserAddress = async () => {
    const userId = getCurrentUserId()
    getUserAddress(userId)
      .then(response => {
        let requestMap = addressCountryStateCityMap(response)
        getAddress(requestMap)
          .then(res => {
            response.map(address => {
              if (res[address.countryCode]) {
                address.country = res[address.countryCode]["ccDisplayStr"]
                address.state = res[address.countryCode]["stateMap"][address.stateCode]["scDisplayStr"]
                address.city = res[address.countryCode]["stateMap"][address.stateCode]["cityMap"][address.cityCode]["ccDisplayStr"]
              }
              this.setState({
                loading: false
              })
            })
          }).catch(error => {
            Alert.error((error && error.message) || 'Something went wrong, please try again later!');
          })
        var billingAddressList = []
        var shippingAddressList = []
        response.map(address => {
          if (address.type === "BILLING")
            billingAddressList.push(address)
          else
            shippingAddressList.push(address)
        })
        this.setState({
          billingAddressList: billingAddressList,
          shippingAddressList: shippingAddressList,
          loading: false
        })
      })
      .catch(error => {
        Alert.error((error && error.message) || 'Something went wrong, please try again later!');
      })
  }

  editCallBack = (address) => {
    // this.setState((prevState)=>({
    //     billingAddress:{...prevState.billingAddress,
    //       house: address.hosue,
    //       street1: address.street1,
    //       street2: address.street2,
    //       street3: address.street3,
    //     }
    // }))
    this.setState({
      editAddress: address
    })
  }

  selectedAddress = (address, index, type) => {
    let addressCard
    const helper = (i) => {
      if (i === index) {
        addressCard.classList.toggle('bg-primary');
        addressCard.classList.toggle('text-light');
        if (type === 'BILLING') {
          if (addressCard.classList.value.includes("bg-primary"))
            this.setState({ billingAddress: address })
          else
            this.setState({ billingAddress: { type: 'BILLING', userId: getCurrentUserId() } })
        }
        else if (type === 'SHIPPING') {
          if (addressCard.classList.value.includes("bg-primary"))
            this.setState({ shippingAddress: address })
          else
            this.setState({ shippingAddress: { type: 'SHIPPING', userId: getCurrentUserId() } })
        }
      }
      else {
        addressCard.classList.remove('bg-primary', 'text-light')
      }
    }

    if (type === 'BILLING') {
      for (let i = 0; i < this.state.billingAddressList.length; i++) {
        addressCard = document.getElementById('billing' + i);
        helper(i)
      }
    }
    else if (type === 'SHIPPING') {
      for (let i = 0; i < this.state.shippingAddressList.length; i++) {
        addressCard = document.getElementById('shipping' + i);
        helper(i)
      }
    }

  }

  setModalShow = (show, type) => {
    this.setState({
      [type]: show
    })
  }


  handleBack = () => {
    this.props.history.goBack()
  }

  handlePhoneChange = (event) => {
    this.setState({
      phone: event.target.value
    })
  }

  render() {
    if (this.state.loading) {
      return <LoadingIndicator />
    }

    return (
      <Container>
        <div className="payment-form-container">
          <Row className="welcome text-center mb-5">
            <Col sm={12}>
              <h3>Billing and Shipping Details</h3>
            </Col>
            <hr />
          </Row>
          <Link className="mb-4"><Button varient="primary" onClick={this.handleBack}> <ArrowBackIcon /> </Button></Link>
          <Form onSubmit={this.handleSubmit}>
            <Table bordered className="shadow mt-2">
              <thead className="thead-color">
                <th>
                  <h5>Personal Details </h5>
                </th>
              </thead>
              <tbody>
                <td>
                  <Row className="mb-2 mt-2 ml-1">
                    <Col md={3} className="mb-3 mt-2">
                      Name :<strong> {this.state.currentUser.name}</strong>
                    </Col>
                    <Col md={3} className="mb-3 mt-2">
                      Email : <strong> {this.state.currentUser.email}</strong>
                    </Col>
                    <Col>
                      <Row>
                        <Col sm={2}>
                          Phone:
                        </Col>
                        <Col sm={8}>
                          {this.state.currentUser.phone === null ? <Form.Control type="number" placeholder="Phone" name="phone" value={this.state.phone} onChange={this.handlePhoneChange} maxlength="10" />:<Form.Control type="text" value={this.state.phone} placeholder="Phone" disabled />}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </td>
              </tbody>
            </Table>
            <Table className="shadow">
              <thead className="thead-color">
                <th>Billing Address  {this.state.billingAddressList.length !== 0 ?
                  <>
                    <Button className="float-right btn-success" onClick={() => this.setModalShow(true, "showBillingModal")} ><AddSharpIcon /></Button>
                    <  AddBillingAddressModal
                      show={this.state.showBillingModal}
                      onHide={() => this.setModalShow(false, "showBillingModal")}
                      handleAddressChange={this.handleAddressChange}
                      addNewAddress={this.addNewAddress}
                      type="BILLING"
                    />
                  </>
                  : null}</th>
              </thead>
              <tbody>
                <td>
                  {this.state.billingAddressList.length !== 0 ?
                    <Row>
                      {this.state.billingAddressList.map((billingAddress, index) => {
                        let fullAddress = billingAddress.house + ',' + billingAddress.street1 + (billingAddress.street2 ? ',' + billingAddress.street2 : '') + (billingAddress.street3 ? ',' + billingAddress.street3 : '') + '\n'
                          + billingAddress.city + ',' + billingAddress.state + ',' + billingAddress.country + ' - ' + billingAddress.pincode
                        return (
                          <Col lg={4} sm={6} className="mb-3">
                            <Card style={{ width: '20rem', minHeight: '120px' }} id={`billing${index}`} onClick={() => this.selectedAddress(billingAddress, index, 'BILLING')}>
                              <Card.Body>
                                <Card.Text>
                                  {fullAddress}
                                </Card.Text>
                              </Card.Body>
                            </Card>
                          </Col>
                        )
                      })}
                    </Row>
                    :
                    <Row>
                      <AddressForm parentCallback={this.handleAddressChange} type="BILLING" editAddress={this.state.editAddress} />
                    </Row>
                  }
                </td>
              </tbody>
            </Table>

            <Row>
              <Col>
                <Form.Check type="checkbox" name="isDiffernetAddressChecked" value={this.state.isDiffernetAddressChecked} label="Is shipping address different than billing address" onChange={this.handleInputChange} />
              </Col>
            </Row>

            {this.state.isDiffernetAddressChecked && this.state.shippingAddressList.length === 0 ?
              <Table className="shadow">
                <thead className="thead-color">
                  <th>Shipping Address  </th>
                </thead>
                <tbody>
                  <td>
                    <Row>
                      <Col lg={6} className="mb-2">
                        <Form.Control placeholder="Name" name="name" onChange={(event) => this.handleAddressChange(event.target.name, event.target.value, "SHIPPING")} required />
                      </Col>
                      <Col lg={6} className="mb-2">
                        <Form.Control placeholder="Phone number" name="phone" onChange={(event) => this.handleAddressChange(event.target.name, event.target.value, "SHIPPING")} required />
                      </Col>
                      <AddressForm parentCallback={this.handleAddressChange} type="SHIPPING" editAddress={this.state.editAddress} />
                    </Row>
                  </td>
                </tbody>
              </Table>
              : this.state.isDiffernetAddressChecked && this.state.shippingAddressList.length !== 0 ?
                <Table className="shadow">
                  <thead className="thead-color">
                    <th>Shipping Address
                      {this.state.billingAddressList.length !== 0 ?
                        <>
                          <Button className="float-right btn-success" onClick={() => this.setModalShow(true, "showShippingModal")} ><AddSharpIcon /></Button>
                          <  AddShippingAddressModal
                            show={this.state.showShippingModal}
                            onHide={() => this.setModalShow(false, "showShippingModal")}
                            handleAddressChange={this.handleAddressChange}
                            addNewAddress={this.addNewAddress}
                            type="SHIPPING"
                          />
                        </>
                        : null}
                    </th>
                  </thead>
                  <tbody>
                    <td>
                      <Row>
                        {this.state.shippingAddressList.map((shippingAddress, index) => {
                          let fullAddress = shippingAddress.name + '\n' + shippingAddress.house + ',' + shippingAddress.street1 + (shippingAddress.street2 ? ',' + shippingAddress.street2 : '') + (shippingAddress.street3 ? ',' + shippingAddress.street3 : '') + '\n'
                            + shippingAddress.city + ',' + shippingAddress.state + ',' + shippingAddress.country + ' - ' + shippingAddress.pincode + "," + shippingAddress.phone
                          return (
                            <Col lg={4} sm={6} className="mb-3">
                              <Card style={{ width: '20rem', minHeight: '120px' }} id={`shipping${index}`} onClick={() => this.selectedAddress(shippingAddress, index, 'SHIPPING')}>
                                <Card.Body>
                                  <Card.Text>
                                    {fullAddress}
                                  </Card.Text>
                                </Card.Body>
                              </Card>
                            </Col>
                          )
                        })}
                      </Row>
                    </td>
                  </tbody>
                </Table>
                : null}

            {/* commented for later use case */}
            {/* <Table className="shadow mt-2">
              <thead className="thead-color">
                <th>Additional Information</th>
              </thead>
              <tbody>
                <td>
                  <Row>
                    <Col className="mb-2">
                      <Form.Control as="textarea" rows="3" maxLength="100" name="name" placeholder="Notes" />
                    </Col>
                  </Row>
                </td>
              </tbody>
            </Table> */}
            <Button type="submit" className="btn btn-primary float-right " >Checkout</Button>
            <Link><Button className="btn btn-primary float-left mr-2" onClick={this.handleBack}>Back</Button></Link>
          </Form>
        </div >
        <EmptyJumbotron />
      </Container >
    );
  }
}

class AddBillingAddressModal extends Component {
  constructor(props) {
    super(props)
  }

  handleSubmit = () => {
    this.props.onHide()
    this.props.addNewAddress(this.props.type)
  }
  render() {
    return (
      <Modal
        show={this.props.show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="thead-color">
          <Modal.Title id="contained-modal-title-vcenter">
            Billing Address
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <AddressForm parentCallback={this.props.handleAddressChange} type={this.props.type} />
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-danger" onClick={this.props.onHide}>Close</Button>
          <Button onClick={this.handleSubmit}>Save</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}



class AddShippingAddressModal extends Component {
  constructor(props) {
    super(props)
  }

  handleSubmit = () => {
    this.props.onHide()
    this.props.addNewAddress(this.props.type)
  }
  render() {
    return (
      <Modal
        show={this.props.show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="thead-color">
          <Modal.Title id="contained-modal-title-vcenter">
            Shipping Address
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg={6} className="mb-2">
              <Form.Control placeholder="Name" name="name" onChange={(event) => this.props.handleAddressChange(event.target.name, event.target.value, "SHIPPING")} required />
            </Col>
            <Col lg={6} className="mb-2">
              <Form.Control placeholder="Phone number" name="phone" onChange={(event) => this.props.handleAddressChange(event.target.name, event.target.value, "SHIPPING")} required />
            </Col>
            <AddressForm parentCallback={this.props.handleAddressChange} type={this.props.type} />
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-danger" onClick={this.props.onHide}>Close</Button>
          <Button onClick={this.handleSubmit}>Save</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}