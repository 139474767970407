import React, { Component } from 'react';
import { Container, Row, Col, Button, Form, Image, Table } from 'react-bootstrap';
import './AppointmentDetails.css';
import Alert from 'react-s-alert';
import { getAppointmentById, updateAppointmentStatus, applyCoupon, getAppointmentByOrderId } from '../util/OrdersAPIUtils';
import { getfeedbackById } from '../util/feedbackAndRatingUtils'
import { getfeedback, } from '../util/feedbackAndRatingUtils';
import { changeTime, getRole, currentLoggedInUser, getCurrentUserId } from '../util/functionUtils';
import { getPrescriptionById } from '../util/healthAPIUtils';
import { Link } from 'react-router-dom';
import StarRatings from 'react-star-ratings';
import { fetchDoctorDetails } from '../util/APIUtils';
import { status } from '../constants/index';
import LoadingIndicator from '../common/LoadingIndicator';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { getRatingByProviderId } from '../util/feedbackAndRatingUtils';
import Feedback from './Feedback';


export default class AppointmentDetails extends Component {
    constructor(props) {
        super(props)
        let url_string = window.location.href;
        let url = new URL(url_string);

        this.state = {
            orderId: this.props.history.location.state.paymentSuccessResponse ? this.props.history.location.state.paymentSuccessResponse.orderId : null,
            appointmentId: this.props.history.location.state ? this.props.history.location.state.appointmentId : null,
            appointmentDetails: '',
            serviceLink: '',
            providerId: this.props.history.location.state ? this.props.history.location.state.providerId : null,
            consumerId: this.props.history.location.state ? this.props.history.location.state.consumerId : null,
            providerDetails: null,
            role: getRole(),
            status: '',
            message: 'BIHEALTH support representative will call you regarding payment.',
            loading: true,
            prescriptionGenerated: false,
            feedBackList: [],
            paymentMode: 'online',
            online: '',
            offline: '',
            couponCode: '',
            ratings: ''
        }
        this.handleStatus = this.handleStatus.bind(this)
    }

    //load appointmnetDetails by appointmentId
    loadAppointMentDetails() {
        getAppointmentById(this.state.appointmentId)
            .then(response => {
                this.setState({
                    appointmentDetails: response,
                    //  providerId: response.providerId,
                    status: response.status,
                    paymentMode: response.status === "AWAITING_PAYMENT" ? "offline" : "online",
                    providerId: response.providerId
                });
                this.loadProviderDetails(response.providerId)

                if ((this.state.role.roleAdmin && response.status == "CLOSED") || (getCurrentUserId() === response.consumerId && response.status == "CLOSED")) {
                    this.loadFeedBack(response.consumerId)
                }
            }).catch(error => {
                Alert.error((error && error.message) || 'Something went wrong, please try again later!');
            });

    }
    //load appointmnetDetails by orderId
    loadAppointMentDetailsByOrdrId() {
        getAppointmentByOrderId(this.state.orderId)
            .then(response => {
                this.setState({
                    appointmentDetails: response,
                    appointmentId: response.id,
                    providerId: response.providerId,
                    consumerId: response.consumerId,
                    status: response.status

                });
                this.loadProviderDetails(response.providerId)

                if ((this.state.role.roleAdmin && response.status == "CLOSED") || (getCurrentUserId() === response.consumerId && response.status == "CLOSED")) {
                    this.loadFeedBack(response.consumerId)
                }
            }).catch(error => {
                Alert.error((error && error.message) || 'Something went wrong, please try again later!');
            });

    }

    //load feedback
    loadFeedBack(feedbackProviderId) {
        const by = this.state.role.roleAdmin ? 'e' : 'p';
        getfeedback("APPOINTMENT", this.state.appointmentId, feedbackProviderId, by)
            .then(response => {
                this.setState({
                    feedBackList: response.content
                })
            }).catch(error => {
                Alert.error((error && error.message) || 'Something went wrong, please try again later!');
            })
    }


    getRatings = (spIds) => {
        const ids = []
        ids.push(spIds)
        getRatingByProviderId(ids)
            .then(response => {
                this.setState({
                    ratings: response,
                    loading: false
                })
            }).catch(error => {
                this.setState({
                    loading: false
                });
                Alert.error((error && error.message) || 'Something went wrong, please try again later!');
            })
    }

    isPrescriptionGenerated = () => {
        getPrescriptionById("", this.state.appointmentId)
            .then(response => {
                this.setState({
                    prescriptionGenerated: true
                })
            })
    }

    loadProviderDetails(providerId) {
        fetchDoctorDetails(providerId, false)
            .then(response => {
                this.setState({
                    providerDetails: response,
                });
                this.getRatings(providerId)

            }).catch(error => {
                Alert.error((error && error.message) || 'Something went wrong, please try again later!');
            });
    }

    componentDidMount() {
        //to check  getCurrentUserId() === this.state.appointmentDetails.consumerId
        // to call for entity admin
        if (this.state.orderId) {
            this.loadAppointMentDetailsByOrdrId()
        } else {
            this.loadAppointMentDetails()
        }

        window.scrollTo(0, 0)
    }

    handleInputChange = (event) => {
        const inputName = event.target.name
        const inputValue = event.target.value
        this.setState({
            [inputName]: inputValue,
        })
    }

    handlePaymentMode = (event) => {
        const inputName = event.target.name
        const inputValue = event.target.value
        this.setState({
            [inputName]: inputValue
        })
    }
    popup = (msg, status, btnName) => {
        return (
            <div className="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title textColor" id="staticBackdropLabel">{msg}</h5>
                            <Button type="Button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </Button>
                        </div>
                        <div className="modal-footer">
                            <Button type="Button" className="btn btn-secondary" data-dismiss="modal">Back</Button>
                            <Button type="Button" className="btn btn-primary" data-dismiss="modal" onClick={() => this.handleStatus(status, this.state.paymentMode)}>{btnName}</Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    returnButtonBasedOnStatus = (status) => {
        if (status === 'PENDING')
            if (getCurrentUserId() === this.state.appointmentDetails.consumerId) {
                return (
                    // <Col md={4}>
                    <>
                        <Button className="float-right mr-2" variant="primary" onClick={() => this.handleStatus('AWAITING_PAYMENT', this.state.paymentMode)}>{this.state.paymentMode == 'online' ? "Proceed" : "Confirm Charge"}</Button>
                    </>
                    // {/* </Col> */}

                )
            }
        if ((this.state.role.roleDoctor && this.state.consumerId !== this.state.appointmentDetails.consumerId) || this.state.role.roleAdmin) {
            if (status === 'AWAITING_PAYMENT')
                return (
                    // <Col md={4}>
                    <>
                        <Button className="float-right mr-2" variant="primary" data-toggle="modal" data-target="#staticBackdrop">Confirm Payment</Button>
                        {this.popup("Please make sure consultation charge is collected, before you confirm the appointment!", "PAYMENT_DONE", "Confirm Payment")}
                    </>
                    // </Col>
                )
            else if (status === 'PAYMENT_DONE')
                return (
                    // <Col md={4}>
                    <>
                        {this.state.serviceLink.length !== 0 ?
                            <Button className="float-right mr-2" variant="primary" onClick={() => this.handleStatus('CONFIRMED', this.state.paymentMode)}> Confirm </Button>
                            : <Button className="float-right mr-2" variant="primary" disabled> Confirm </Button>}
                    </>
                    // </Col>
                )
        }
        if (status === 'CONFIRMED')
            return (
                // <Col md={4}>
                <>
                    {this.state.providerId ?
                        <Link to={{ pathname: "/pf", state: { patientInformation: { member: this.state.appointmentDetails.member, memberId: this.state.appointmentDetails.memberId, consumer: this.state.appointmentDetails.consumer, consumerId: this.state.appointmentDetails.consumerId, reason: this.state.appointmentDetails.reason }, doctorInformation: { ...this.state.appointmentDetails.provider }, link: this.state.appointmentDetails.serviceLink ? this.state.appointmentDetails.serviceLink : this.state.serviceLink, appointmentId: this.state.appointmentId, providerId: this.state.providerId } }}><Button variant="primary" className="mb-2 float-right mr-2" >Start Now</Button></Link>
                        : this.state.appointmentDetails.serviceLink ? <a href={this.state.appointmentDetails.serviceLink} target="_blank"> <Button variant="primary" className="mb-2 float-right mr-2" >Start Now</Button> </a>
                            : <a href={this.state.serviceLink} target="_blank"> <Button variant="primary" className="mb-2 float-right mr-2" >Start Now</Button> </a>
                    }
                </>
                // </Col>
            )
        else if (status === 'CANCELLED')
            return null
        return null
    }


    async handleStatus(status, paymentMode) {
        if (paymentMode == "offline") {
            await this.setState({
                loading: true
            })
            updateAppointmentStatus(this.state.appointmentId, status, this.state.serviceLink)
                .then(response => {
                    if (status === 'AWAITING_PAYMENT') {
                        this.setState({
                            status: 'AWAITING_PAYMENT',
                            loading: false
                        })
                        Alert.success('Appointment Confirmed Successfully , BIHEALTH support representative will call you regarding payment.!')
                    }
                    else if (status === 'PAYMENT_DONE') {
                        this.setState({
                            status: 'PAYMENT_DONE',
                            loading: false
                        })
                        Alert.success('Payment Confirmed Successfully, Please provide video consultation link to confirm the apppintment.')
                    }
                    else if (status === 'CONFIRMED') {
                        this.setState({
                            status: 'CONFIRMED',
                            loading: false
                        })
                        Alert.success('Appointment Confirmed Successfully!')
                    }
                    else if (status === 'CANCELLED') {
                        this.setState({
                            status: 'CANCELLED',
                            loading: false
                        })
                        Alert.success('Appointment Cancelled Successfully!')
                    }
                }).catch(error => {
                    this.setState({
                        loading: false
                    })
                    Alert.error((error && error.message) || 'Something went wrong, please try again later!');
                })
        } else {
            await this.setState({
                loading: true
            })

            if (status === 'CONFIRMED') {
                updateAppointmentStatus(this.state.appointmentId, status, this.state.serviceLink)
                    .then(response => {
                        if (status === 'CONFIRMED') {
                            this.setState({
                                status: 'CONFIRMED',
                                loading: false
                            })
                            Alert.success('Appointment Confirmed Successfully!')
                        }
                    }).catch(error => {
                        this.setState({
                            loading: false
                        })
                        Alert.error((error && error.message) || 'Something went wrong, please try again later!');
                    })
            } else if(status === 'CANCELLED'){
                updateAppointmentStatus(this.state.appointmentId, status, this.state.serviceLink)
                .then(response => {
                    this.setState({
                        status: 'CANCELLED',
                        loading: false
                    })
                    Alert.success('Appointment Cancelled Successfully!')
                }).catch(error => {
                    this.setState({
                        loading: false
                    })
                    Alert.error((error && error.message) || 'Something went wrong, please try again later!');
                })
            }else {
                this.props.history.push(
                    {
                        pathname: "/ad",
                        state: { appointmentId: this.state.appointmentId, providerId: this.state.providerId }
                    })
            }
        }
    }
    couponInputHandler = (event) => {
        this.setState({
            couponCode: event.target.value
        })

    }

    couponHandler = () => {
        const orderId = this.state.appointmentDetails.orderId
        const couponCode = this.state.couponCode
        applyCoupon(orderId, couponCode)
            .then(response => {
                this.setState((prevState => ({
                    appointmentDetails: { ...prevState.appointmentDetails, order: response }
                })
                ))
                Alert.success("Coupon applied Successfully !");
            }).catch(error => {
                this.setState({
                    loading: false
                })
                Alert.error((error && error.message) || 'Something went wrong, please try again later!');
            })
    }

    render() {
        if (this.state.loading)
            return <LoadingIndicator />
        return (

            <Container>
                <div className='appointment-details-container'>

                    <Row className="welcome text-center mb-5">
                        <Col sm={12}>
                            <h4> Appointment Details</h4>
                        </Col>
                        <hr />
                    </Row>
                    {this.state.consumerId ?
                        <Link className="mb-2" to={{ pathname: "/appointment-list", state: { consumerId: this.state.consumerId, superUser: false } }}><Button variant="primary"><ArrowBackIcon /></Button></Link>
                        : getRole().roleAdmin ? <Link className="mb-2" to={{ pathname: "/appointment-list", state: { superUser: true } }}><Button variant="primary"><ArrowBackIcon /></Button></Link> : this.state.providerId ?
                            <Link className="mb-2" to={{ pathname: "/appointment-list", state: { providerId: this.state.providerId, superUser: false } }}><Button variant="primary"><ArrowBackIcon /></Button></Link>
                            : <Link className="mb-2" to={{ pathname: "/appointment-list", state: { superUser: false } }}><Button variant="primary"><ArrowBackIcon /></Button></Link>
                    }
                    <Table bordered className="shadow mt-2">
                        <thead className="thead-color">
                            <th>
                                <h5>Patient's Information  {this.state.status === 'CLOSED' ? <Link to={{ pathname: "/pd", state: { adBack: true, appointmentId: this.state.appointmentId, consumerId: this.state.consumerId, providerId: this.state.providerId } }}><Button className="float-right" variant="success">View Prescription</Button></Link> : null}</h5>
                            </th>
                        </thead>
                        <tbody>
                            <td>
                                <Row className="mb-5">
                                    <Col md={4} className="mb-3 mt-2">
                                        Patient's Name :<strong> {this.state.appointmentDetails.memberId ? this.state.appointmentDetails.member.name : this.state.appointmentDetails.consumer.name}</strong>
                                    </Col>
                                    <Col md={4} className="mb-3 mt-2">
                                        Age : <strong>{this.state.appointmentDetails.memberId ? this.state.appointmentDetails.member.age : this.state.appointmentDetails.consumer.age} yrs</strong>
                                    </Col>
                                    <Col md={4} className="mb-3 mt-2">
                                        Gender : <strong>{this.state.appointmentDetails.memberId ? this.state.appointmentDetails.member.gender : this.state.appointmentDetails.consumer.gender}</strong>
                                    </Col>
                                    <Col md={4} className="mb-3">
                                        Status : <strong>{status[this.state.status]}</strong>
                                    </Col>
                                    <Col md={4} className="mb-2">
                                        Date : <strong>{(new Date(this.state.appointmentDetails.startTimeMs)).toLocaleDateString([], { year: 'numeric', month: 'long', day: 'numeric' })}</strong>
                                    </Col>
                                    <Col md={4} className="mb-2">
                                        Time : <strong>{changeTime(this.state.appointmentDetails.startTimeMs)} - {changeTime(this.state.appointmentDetails.endTimeMs)}</strong>
                                    </Col>
                                    <Col md={4} className="mb-2">
                                        Service Type : {this.state.appointmentDetails.serviceType === "VIDEO_CONS" ? <strong>Video Consultation </strong> : <strong>Physical Consultation </strong>}
                                    </Col>
                                </Row>
                            </td>
                        </tbody>
                    </Table>
                    {this.state.status === 'AWAITING_PAYMENT' ?
                        <Table bordered className="shadow">
                            <thead className="thead-color">
                                <th><h5 className="5">NOTE</h5></th>
                            </thead>
                            <tbody>
                                <td>
                                    <p><strong>{this.state.message}</strong></p>
                                </td>
                            </tbody>
                        </Table>
                        : null}

                    <Table bordered className="shadow">
                        <thead className="thead-color">
                            <th><h5 className="5">Doctor's Information</h5></th>
                        </thead>
                        <tbody>
                            <td>
                                <Row>
                                    <Col md={3} lg={3}>
                                        <Row>
                                            <Col md={12}>
                                                <Image
                                                    width={100}
                                                    height={100}
                                                    className="mr-3 media-img"
                                                    style={{ clipPath: 'circle()' }}
                                                    src={this.state.providerDetails.profileLink ? this.state.providerDetails.profileLink : "../img/mac.png"}
                                                    alt="doctor photo"
                                                />
                                            </Col>
                                            <Col md={12} className="m-3 ml-2">
                                                {this.state.providerDetails.socialProfiles ?
                                                    this.state.providerDetails.socialProfiles.map(value => {
                                                        if (value.type === 'GOOGLE')
                                                            return <a target="_blank" href={value.profileUrl}> <i class="fa fa-google fa-1x" aria-hidden="true"></i></a>
                                                        else if (value.type === 'FACEBOOK')
                                                            return <a target="_blank" href={value.profileUrl}> <i class="fa fa-facebook-official fa-1x" aria-hidden="true"></i></a>
                                                        else if (value.type === 'INSTAGRAM')
                                                            return <a target="_blank" href={value.profileUrl}> <i class="fa fa-instagram fa-1x" aria-hidden="true"></i></a>
                                                        else if (value.type === "TWITTER")
                                                            return <a target="_blank" href={value.profileUrl}> <i class="fa fa-twitter fa-1x" aria-hidden="true"></i></a>
                                                        else if (value.type === "LINKEDIN")
                                                            return <a target="_blank" href={value.profileUrl}> <i class="fa fa-linkedin-square fa-1x" aria-hidden="true"></i></a>
                                                        else
                                                            return null

                                                    })
                                                    : null}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={9} lg={9}>
                                        <h5 className="mb-3">Dr. {this.state.providerDetails.name}{" "}({this.state.providerDetails.registration})</h5>
                                        <i class="fa fa-user-md fa-1x" aria-hidden="true"></i>{" "}
                                        {this.state.providerDetails.specialisations.map((value, index) => {
                                            if (index === 0)
                                                return " " + value.dislayStr
                                            else
                                                return "," + value.dislayStr
                                        })}<br />
                                        <i class="fa fa-graduation-cap fa-1x" aria-hidden="true"></i>{" "}
                                        {this.state.providerDetails.degrees.map((value, index) => {
                                            if (index === 0)
                                                return " " + value.dislayStr
                                            else
                                                return "," + value.dislayStr
                                        })}<br />
                                        <i class="fa fa-black-tie fa-1x" aria-hidden="true"></i>{" "}<strong>{this.state.providerDetails.experienceInYears}+</strong> years experience
                                        <br /> {Object.keys(this.state.ratings).length != 0 ? "Ratings: " : null}
                                        {Object.keys(this.state.ratings).length != 0 ?
                                            <>
                                                <StarRatings
                                                    rating={this.state.ratings[this.state.providerId].avgRating}
                                                    starRatedColor="grey"
                                                    starHoverColor="grey"
                                                    numberOfStars={5}
                                                    starDimension="15px"
                                                    name='rating'
                                                />
                                            </>
                                            : null}
                                    </Col>
                                </Row>
                            </td>
                        </tbody>
                    </Table>
                    <Table bordered className="shadow">
                        <thead className="thead-color">
                            <th><h5 className="5">Reason To Visit</h5></th>
                        </thead>
                        <tbody>
                            <td>
                                <p>{this.state.appointmentDetails.reason}</p>
                            </td>
                        </tbody>
                    </Table>

                    {this.state.status === 'PAYMENT_DONE' && (this.state.role.roleAdmin || currentLoggedInUser(this.state.providerDetails.userId)) ?
                        <Table bordered className="shadow">
                            <thead className="thead-color">
                                <th><h5 className="5">Provide Video Consultation Link</h5></th>
                            </thead>
                            <tbody>
                                <td>
                                    <Row>
                                        <Col md={10} className="mt-2">
                                            <Form.Control type="text" name="serviceLink" value={this.state.serviceLink} placeholder="http://abc.com" onChange={this.handleInputChange} />
                                        </Col>
                                        <Col md={2} className="mt-2">
                                            {this.returnButtonBasedOnStatus(this.state.status)}
                                        </Col>
                                    </Row>
                                </td>
                            </tbody>
                        </Table>
                        : null}



                    <Table bordered className="shadow" id="firstTable">

                        <thead className="thead-color">
                            <th colSpan="7"><h5>Consultation Charge Details</h5></th>
                            <tr id="tableHead">
                                <td><strong>Consultation Charge</strong></td>
                                <td><strong>Discount %</strong></td>
                                <td><strong>Discount</strong></td>
                                <td><strong>Tax %</strong></td>
                                <td><strong>Tax</strong></td>
                                <td><strong>Final Amount</strong></td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td data-label="Consultation Charge">{this.state.appointmentDetails.order.currency}{" "}{this.state.appointmentDetails.order.value}</td>
                                <td data-label="Discount %">{this.state.appointmentDetails.order.discountPercent} %</td>
                                <td data-label="Discount">{this.state.appointmentDetails.order.currency}{" "}{this.state.appointmentDetails.order.discount}</td>
                                <td data-label="Tax %">{this.state.appointmentDetails.order.taxPercent} %</td>
                                <td data-label="Tax">{this.state.appointmentDetails.order.currency}{" "}{this.state.appointmentDetails.order.tax}</td>
                                <td data-label="Final Amount">{this.state.appointmentDetails.order.currency}{" "}{this.state.appointmentDetails.order.finalValue}</td>
                            </tr>
                            <tr>
                            </tr>
                        </tbody>
                    </Table>
                    {this.state.status === 'PENDING' && getCurrentUserId() === this.state.appointmentDetails.consumerId ?
                        <div className="border mt-2 mb-2">
                            <Row className="mb-2  md={12}">
                                <Col className="mt-2"><strong>Coupon Code :</strong></Col>
                              {this.state.appointmentDetails.couponCode? <Col className="mt-2"><Form.Control placeholder="XXXXXX" value={this.state.appointmentDetails.couponCode} maxlength="6" size="10" disabled/></Col>: <Col className="mt-2"><Form.Control placeholder="XXXXXX" maxlength="6" size="10" onChange={this.couponInputHandler} /></Col>}
                              {this.state.appointmentDetails.couponCode? <Col className="mt-2"><Button className="float-right"  disabled>Apply</Button></Col> :<Col className="mt-2"><Button className="float-right" onClick={this.couponHandler}>Apply</Button></Col>}
                            </Row>
                        </div> : null}

                    {this.state.status === 'PENDING' && getCurrentUserId() === this.state.appointmentDetails.consumerId ? 
                        <Table bordered className="shadow">
                            <thead className="thead-color">
                                <tr>
                                    <th colSpan="7"><h5>Payment Mode</h5></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td >
                                        <input type="radio" className="mr-2" value="online" name="paymentMode" onChange={this.handlePaymentMode} defaultChecked />
                                        <strong>Online Payment</strong>
                                    </td>
                                    <td>
                                        <input type="radio" className="mr-2" value="offline" name="paymentMode" onChange={this.handlePaymentMode} />
                                        <strong>Offline Payment</strong>
                                    </td>
                                </tr>
                            </tbody>
                        </Table> : null}
                    {this.state.feedBackList.length == 0 && this.state.role.roleAdmin ? null :
                        this.state.feedBackList.length == 0 && this.state.status == "CLOSED" ? <Feedback appointmentId={this.state.appointmentId} providerId={this.state.appointmentDetails.providerId} readMode={false} /> : null}
                    {this.state.feedBackList.length != 0 && this.state.status == "CLOSED" ? <Feedback appointmentId={this.state.appointmentId} providerId={this.state.appointmentDetails.providerId} feedbackList={this.state.feedBackList} readMode={true} /> : null}
                    <div className="pb-2 mb-5 mt-2">
                        <Col>
                            {this.state.consumerId ?
                                <Link to={{ pathname: "/appointment-list", state: { consumerId: this.state.consumerId, superUser: false } }}><Button variant="primary" className="mb-2 float-left">Back</Button></Link>
                                : getRole().roleAdmin ? <Link className="mb-2" to={{ pathname: "/appointment-list", state: { superUser: true } }}><Button variant="primary">Back</Button></Link>
                                : this.state.providerId ?
                                    <Link to={{ pathname: "/appointment-list", state: { providerId: this.state.providerId, superUser: false } }}><Button variant="primary" className="mb-2 float-left">Back</Button></Link>
                                    : <Link to={{ pathname: "/appointment-list", state: { superUser: true } }}><Button variant="primary" className="mb-2 float-left">Back</Button></Link>
                            }
                        </Col>
                        {this.state.status === 'PAYMENT_DONE' ? null : this.returnButtonBasedOnStatus(this.state.status)}

                        {this.state.status !== 'CANCELLED' && this.state.status !== 'CLOSED' ?
                            // <Col>
                            <>
                                <Button variant="danger" data-toggle="modal" data-target="#staticBackdrop" className="mb-2 mr-2 float-right">Cancel</Button>
                                {this.popup("Are you sure, you want to cancel?", "CANCELLED", "Cancel")}
                            </>
                            : null
                        }
                    </div>
                </div>
            </Container>
        )
    }
}