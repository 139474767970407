import { API_BASE_URL, ORDERS_API_BASE_URL, DOCTORS_API_BASE_URL,GALLERY_API_BASE_URL,EVENTS_API_BASE_URL } from '../constants';
import { request } from './APIUtils';

export function registerEvents(eventId) {
    return request({
        url: EVENTS_API_BASE_URL + "/event/participant?id=" + eventId,
        method: 'POST',
        body: ""
    })
}

export function userRegisteredToEvents(eventId) {
    return request({
        url: EVENTS_API_BASE_URL + "/event/participant/validate",
        method: 'POST',
        body:JSON.stringify(eventId)
    })
}

export function getRegisteredUserToEvent(eventId) {
    return request({
        url: EVENTS_API_BASE_URL + "/event/participant?id=" + eventId,
        method: 'GET',
    });
}

export function getUserInfo(userIds) {
    return request({
        url: API_BASE_URL + "/user/info/contact",
        method:'POST',
        body:JSON.stringify(userIds)
    });
}