import React, { Component } from 'react';
import Alert from 'react-s-alert';
import StarRatings from 'react-star-ratings';
import './FeedBack.css';
import { Table, Container, Col, Row, Form, Button } from 'react-bootstrap';
import { loadFeedBackKeys, createFeedBack, createRating } from '../util/feedbackAndRatingUtils'
import { getCurrentUserId, addFeedBackItems } from '../util/functionUtils'
import { RateReviewOutlined } from '@material-ui/icons';


class Feedback extends Component {

    constructor(props) {
        super(props)
        this.state = {
            feedbackList: this.props ? this.props.feedbackList : null,
            feedBackItem: this.props ? this.props.readMode ? addFeedBackItems(this.props.feedbackList) : null : null,
            readMode: this.props ? this.props.readMode : null,
            questionList: [],
            entity: "APPOINTMENT",
            entityId: this.props ? this.props.appointmentId : null,     // appointmnet id(to do)
            fpId: getCurrentUserId(),
            items: {},
            // this is for providing rating
            spType: 'DOC',
            spId: this.props ? this.props.providerId : null,          // doctor Id(to do)
            rpId: getCurrentUserId(),
            rating: 0,
        }
    }

    componentDidMount() {
        this.loadQuestion()
    }


    // method to load the feddback questions
    loadQuestion() {
        loadFeedBackKeys()
            .then(response => {
                let itemObject = {}
                response.map(q => {
                    let item = { id: q.id }
                    let obj = { "key": item, rating: "", comment: "" }
                    itemObject[q.id] = obj
                })
                //setting the value 
                this.setState({
                    questionList: response,
                    items: itemObject
                })
            }).catch(error => {
                Alert.error((error && error.message) || 'Something went wrong, please try again later!')
            })
    }

    // methos to handle back
    handleBack = () => {
        this.props.history.goBack()
    }

    // handler for setting feedback request
    handleChange = (event, id, objective) => {
        const target = event.target;
        const inputName = target.name;
        const inputValue = target.value;
        let obj = this.state.items[id]
        if (objective) {
            obj.rating = inputValue
            this.setState(prevState => ({
                items: { ...prevState.items, [inputName]: obj }
            }));
        } else {
            obj.comment = inputValue
            this.setState(prevState => ({
                items: { ...prevState.items, [inputName]: obj }
            }));
        }

    }

    // rating handler
    changeRatingHandler = (newRating) => {
        this.setState({
            rating: newRating
        })
    }

    // handler for submitting the feedback
    handleSubmit = (event) => {
        event.preventDefault()
        const objects = this.state.items
        const postFeedBackrequest = {
            entity: this.state.entity,
            entityId: this.state.entityId,
            fpId: this.state.fpId,
            items: Object.values(objects)
        }
        //  const { spType, spId, rpId, rating } = this.state
        let ratingRequest = {
            spType: this.state.spType,
            spId: this.state.spId,
            rpId: this.state.rpId,
            rating: this.state.rating
        }
        createRating(ratingRequest)
            .then(response => {
                createFeedBack(postFeedBackrequest)
                    .then(response => {
                        Alert.success("Thanks for your valuable Feedback !")
                        window.location.reload()
                    }).catch(error => {
                        Alert.error((error && error.message) || 'Something went wrong, please try again later!')
                    })
            }).catch(error => {
                Alert.error((error && error.message) || 'Something went wrong, please try again later!')
            })

    }

    render() {
        return (
            <>
                {this.state.readMode ? <div>
                    <div className="feature-title container-fluid">
                        <h3> Appointment Feedback </h3><hr />
                    </div>
                    <div>
                        <Table responsive bordered className="shadow mt-3" id="feedback">
                            <thead className="thead-color" >
                                <tr id="feedbackHead">
                                    <th>#</th>
                                    <th className="text-center"> Question</th>
                                    <th className="text-center">Excellent</th>
                                    <th className="text-center">Satisfactory</th>
                                    <th className="text-center">Meets Expectation</th>
                                    <th className="text-center">Needs Improvement</th>
                                    <th className="text-center">Poor</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.feedBackItem.items.map((item, index) => {
                                    if (item.key.objective) {
                                        const rating = item.rating
                                        let checked1 = rating == 1 ? true : false
                                        let checked2 = rating == 2 ? true : false
                                        let checked3 = rating == 3 ? true : false
                                        let checked4 = rating == 4 ? true : false
                                        let checked5 = rating == 5 ? true : false
                                        return (
                                            <tr>
                                                <td data-label="#">{index + 1}</td>
                                                <td data-label="Question">{item.key.key}</td>
                                                <td className="text-center" data-label="Excellent">
                                                    <input id="1" value="1" name={item.key.id} type="radio" disabled={!checked1} checked={checked1} />
                                                </td>
                                                <td className="text-center" data-label="Satisfactory">
                                                    <input id="2" value="2" name={item.key.id} type="radio" disabled={!checked2} checked={checked2} />
                                                </td>
                                                <td className="text-center" data-label="Meets Expectation">
                                                    <input id="3" value="3" name={item.key.id} type="radio" disabled={!checked3} checked={checked3} />
                                                </td>
                                                <td className="text-center" data-label="Needs Improvement">
                                                    <input id="4" value="4" name={item.key.id} type="radio" disabled={!checked4} checked={checked4} />
                                                </td>
                                                <td className="text-center" data-label="Poor">
                                                    <input id="5" value="5" name={item.key.id} type="radio" disabled={!checked5} checked={checked5} />
                                                </td>

                                            </tr>
                                        )
                                    }
                                })}
                            </tbody>
                        </Table>
                        {this.state.feedBackItem.items.map(item => {
                            if (!item.key.objective) {
                                return (
                                    <Table className="shadow">
                                        <thead className="thead-color">
                                            <th>{item.key.key}</th>
                                        </thead>
                                        <tbody>
                                            <td>
                                                <Form.Group controlId="formBasicEmail">
                                                    <Form.Control as="textarea" rows="3" maxLength="1000" value={item.comment} name="" placeholder="Comment" readOnly />
                                                </Form.Group>
                                            </td>
                                        </tbody>
                                    </Table>
                                )
                            }
                        })}

                        {/* <Row>
                            <Col>
                                <strong> Doctor's Ratings :</strong>
                            </Col>
                            <Col>
                                <StarRatings
                                    rating={4}       // remove hard coded value
                                    starRatedColor="grey"
                                    starHoverColor="grey"
                                    numberOfStars={5}
                                    starDimension="15px"
                                    name='rating'
                                />
                            </Col>
                        </Row> */}
                    </div>
                </div> :
                    <div>
                        <div className="feature-title container-fluid">
                            <h3> Appointment Feedback</h3><hr />
                        </div>
                        <div>
                            <Table responsive bordered className="shadow mt-3" id="feedback">
                                <thead className="thead-color">
                                    <tr id="feedbackHead">
                                        <th>#</th>
                                        <th className="text-center"> Question</th>
                                        <th className="text-center">Excellent</th>
                                        <th className="text-center">Satisfactory</th>
                                        <th className="text-center">Meets Expectation</th>
                                        <th className="text-center">Needs Improvement</th>
                                        <th className="text-center">Poor</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.questionList.map((question, index) => {
                                        if (question.objective) {
                                            return (
                                                <tr>
                                                    <td data-label="#">{index + 1}</td>
                                                    <td data-label="Question">{question.key}</td>
                                                    <td className="text-center" data-label="Excellent">
                                                        <input id="mac" value="1" name={question.id} type="radio" onChange={(event) => this.handleChange(event, question.id, question.objective)} />
                                                    </td>
                                                    <td className="text-center" data-label="Satisfactory">
                                                        <input id="mac" value="2" name={question.id} type="radio" onChange={(event) => this.handleChange(event, question.id, question.objective)} />
                                                    </td>
                                                    <td className="text-center" data-label="Meets Expectation">
                                                        <input id="mac" value="3" name={question.id} type="radio" onChange={(event) => this.handleChange(event, question.id, question.objective)} />
                                                    </td>
                                                    <td className="text-center" data-label="Needs Improvement">
                                                        <input id="mac" value="4" name={question.id} type="radio" onChange={(event) => this.handleChange(event, question.id, question.objective)} />
                                                    </td>
                                                    <td className="text-center" data-label="Poor">
                                                        <input id="mac" value="5" name="rating" type="radio" onChange={(event) => this.handleChange(event, question.id, question.objective)} />
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    })}
                                </tbody>
                            </Table>
                            {this.state.questionList.map(question => {
                                if (!question.objective) {
                                    return (
                                        <Table className="shadow">
                                            <thead className="thead-color">
                                                <th>{question.key}</th>
                                            </thead>
                                            <tbody>
                                                <td>
                                                    <Form.Group controlId="formBasicEmail">
                                                        <Form.Control as="textarea" rows="3" maxLength="1000" value={this.state.items[question.id].comment} name={question.id} placeholder="Comment" onChange={(event) => this.handleChange(event, question.id, question.objective)} />
                                                    </Form.Group>
                                                </td>
                                            </tbody>
                                        </Table>
                                    )
                                }
                            })}
                            <Table>
                                <tbody>
                                    <td> Doctor's Ratings</td>
                                    <td>
                                        <StarRatings
                                            rating={this.state.rating}
                                            starRatedColor="green"
                                            starHoverColor="grey"
                                            changeRating={this.changeRatingHandler}
                                            numberOfStars={5}
                                            starDimension="30px"
                                            name='rating'
                                        />
                                    </td>
                                </tbody>
                            </Table>
                        </div>
                        <div>
                            <Button type="Button" className="btn btn-primary float-right" onClick={this.handleSubmit}>Submit</Button>
                        </div>
                    </div>}

            </>
        );
    }
}

export default Feedback;