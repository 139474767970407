import React, {Component } from 'react';
import Container from 'react-bootstrap/Container';
import CustomCarousel from '../common/CustomCarousel';
import './Home.css';
import LoadingIndicator from '../common/LoadingIndicator';

export default class Home extends Component {
	constructor(props) {
		super(props)
		this.state = {
			//Events
			list: [1, 2, 3, 4, 5, 6, 7],
			eventsListUp: [],
			eventsListPast: [],
			pageNumber: 0,
			pageSize: 6,
			//Doctors
			dcList: [],
			loading: true,
			totalElements: 0,
			colorArray: ['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'dark', 'light']
		}
		// this.loadDc = this.loadDc.bind(this);
		// this.getSps = this.getSps.bind(this);
		// this.knowMoreEvents = this.knowMoreEvents.bind(this);
		// this.knowMoreDoctors = this.knowMoreDoctors.bind(this);
		// this.loadEvents = this.loadEvents.bind(this);
		// this.loadPastEvents = this.loadPastEvents.bind(this);

	}
	// Events page dependencies
	// loadEvents() {
	// 	getEventsListByDate(this.state.pageNumber, this.state.pageSize, false)
	// 		.then(response => {
	// 			this.setState({
	// 				eventsListUp: response.content
	// 			})
	// 		}).catch(error => {
	// 			Alert.error((error && error.message) || 'Something went wrong, please try again later!');
	// 		})
	// }

	// loadPastEvents() {
	// 	getEventsListByDate(this.state.pageNumber, this.state.pageSize, true)
	// 		.then(response => {
	// 			this.setState({
	// 				eventsListPast: response.content
	// 			})
	// 		}).catch(error => {
	// 			Alert.error((error && error.message) || 'Something went wrong, please try again later!');
	// 		})
	// }

	// knowMoreEvents(eid) {
	// 	this.props.history.push({ pathname: "/ed", id: eid });
	// }
	// //Ends

	// loadDc() {
	// 	getDoctorList(this.state.pageNumber, this.state.pageSize)
	// 		.then(response => {
	// 			this.setState({
	// 				dcList: response.content,
	// 				totalElements: response.totalElements,
	// 				loading: false
	// 			});
	// 		}).catch(error => {
	// 			this.setState({
	// 				loading: false
	// 			});
	// 			Alert.error((error && error.message) || 'Doctor Something went wrong, please try again later!');
	// 		});

	// }

	// getSps(spArr) {
	// 	let spStr = '';
	// 	if (spArr) {
	// 		spArr.map(function (sp) {
	// 			spStr = spStr + sp.dislayStr + ", "
	// 		})
	// 		let toSlc = 10;
	// 		spStr = spStr.substring(0, 15) + "...";
	// 		// spStr = spStr.slice(0, -2);
	// 		return spStr;
	// 	} else {
	// 		return 'NA';
	// 	}
	// }

	// knowMoreDoctors(did) {
	// 	this.props.history.push({ pathname: "/dp", did: did });
	// }


	// componentDidMount() {
	// 	// this.loadEvents();
	// 	// this.loadPastEvents();
	// 	this.loadDc();
	// }
	render() {

		// if (this.state.loading) {
		// 	return <LoadingIndicator />
		// }

		return (
			<Container>
				<CustomCarousel />
				{/* <CustomJumbotron /> */}
				<div className="container-fluid">
					<div className="row mt-2">
						<div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mb-4">
							<div className="mycontainer">
								<img className="imgsize" src="img/comm.jpg" alt="Snow" />
								<div className="bottom-left border textformat">Connecting Patients with Doctors & Creating Digital Health Community</div>
							</div>
						</div>
						<div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mb-4">
							<div className="mycontainer">
								<img className="imgsize" src="img/pres2.jpg" alt="Snow" />
								<div className="bottom-left border textformat">Get rid of Physical Prescriptions & Health Records, Manage them Digitally!</div>
							</div>
						</div>
						<div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mb-4">
							<div className="mycontainer">
								<img className="imgsize" src="img/hor.jpg" alt="Snow" />
								<div className="bottom-left border textformat">Widen your Horizon for Expert Opinion!</div>
							</div>
						</div>
					</div>
				</div>
				{/* Start */}

				<div className="container-fluid">
					<hr />
					<h1 className="text-center">What We Offer?</h1>
					<hr />
					<div className="container-fluid padding">
						<div className="row mt-2">
							<div className="col-12 mb-4">
								<h2 className="text-left">Consult Doctors</h2>
								<img className="img-fluid rounded border" src="img/conDoc3.png" alt="Generic placeholder image" />
							</div>
							<div className="col-12 mb-4">
								<h2 className="text-left">Get Second Opinion from Expert</h2>
								<img className="img-fluid rounded border" src="img/secOpn.png" alt="Generic placeholder image" />
							</div>
							<div className="col-12 mb-4">
								<h2 className="text-left">Doctors On-boarding</h2>
								<img className="img-fluid rounde border" src="img/DoctorOnboarding1.png" alt="Generic placeholder image" />
							</div>
						</div>
					</div>
				</div>
				{/* End */}
			</Container >
		)
	}
}