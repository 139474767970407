import React, { Component } from 'react';
import { Jumbotron} from 'react-bootstrap';

import './CustomJumbotron.css';

export default class CustomJumbotron extends Component {
	render() {
		return (
			<Jumbotron fluid className="cstjumbotron">
				<div className="divalign bottom">
					<p className="lead text-center">
						OUR VISION - Create a Healthy Nation: Physically, Intellectually & Mentally
					            <br /> OUR MOTTO - To bring together like minded & health care experts in every field to operate as
					                one body towards a healthier India
				</p></div>
				{/* <div className="row">
						<div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 padding">
							<div className="mydiv pt-1 pb-1">


							</div>
						</div>
						<div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12 padding">
							<div className="mydiv pt-1 pb-1">


							</div>
						</div>
					</div> */}

			</Jumbotron>
		)
	}
}