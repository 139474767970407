import React, { Component } from 'react';
import { Container, Row, Media, Col, Form, Image, Button, CardDeck, Card } from 'react-bootstrap';
import './BlogList.css';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { fetchDoctorDetails} from '../util/APIUtils';
import { getRatingByProviderId } from '../util/feedbackAndRatingUtils';
import Alert from 'react-s-alert';
import { Link } from 'react-router-dom';
import BlogContent from './BlogContent';
import StarRatings from 'react-star-ratings';
import LoadingIndicator from '../common/LoadingIndicator';



class BlogDetails extends Component {

    constructor(props) {
        super(props)
        this.state = {
            blogType:this.props.match.params.type,
            dd:'',
            did:9,
            ratings: '',
            loading:true
        }
    }

    componentDidMount(){
        this.loadDc()
        window.scrollTo(0,0)
    }

    loadDc = () => {
        fetchDoctorDetails(this.state.did)
            .then(response => {
                this.setState({
                    dd: response,
                });
                this.getRatings(response.id)
                this.setState({
                    doctorUserId: response.userId
                })
            }).catch(error => {
                this.setState({
                    loading: false
                });
                Alert.error((error && error.message) || 'Something went wrong, please try again later!');
            });
    }


    getRatings = (spIds) => {
        const ids = []
        ids.push(spIds)
        getRatingByProviderId(ids)
            .then(response => {
                this.setState({
                    ratings: response,
                    loading: false
                })
            }).catch(error => {
                this.setState({
                    loading: false
                });
                Alert.error((error && error.message) || 'Something went wrong, please try again later!');
            })
    }


    handleBack = () => {
        this.props.history.goBack();
    }

    render() {
        if (this.state.loading) {
			return <LoadingIndicator />
		}

        return (
            <Container>
                <div className="blog-container">

                    <Button varient="primary" onClick={this.handleBack}> <ArrowBackIcon /> </Button><br/>
                    <Media>
                            <img
                                width={220}
                                height={220}
                                className="mr-3 media-img"
                                src={this.state.dd.profileLink ? this.state.dd.profileLink : "../img/mac.png"}
                                alt="doctor photo">
                            </img>

                            <Media.Body>
                                <h4 className="mb-3">Dr. {this.state.dd.name}</h4>
                                <br />
                                <p><h6>
                                    <i class="fa fa-user-md fa-lg" aria-hidden="true"></i>
                                    {this.state.dd.specialisations ? this.state.dd.specialisations.map((value, index) => {
                                        if (index === 0)
                                            return " " + value.dislayStr
                                        else
                                            return "," + value.dislayStr
                                    }) : ""}
                                </h6></p>
                                <p><h6>
                                    <i class="fa fa-graduation-cap fa-lg" aria-hidden="true"></i>
                                    { this.state.dd.degrees ? this.state.dd.degrees.map((value, index) => {
                                        if (index === 0)
                                            return " " + value.dislayStr
                                        else
                                            return "," + value.dislayStr
                                    }):""}
                                </h6></p>
                                <p><h6><i class="fa fa-black-tie fa-lg" aria-hidden="true"></i><strong>{this.state.dd.experienceInYears}+</strong> years experience</h6></p>
                                <p><h6>Follow Me:
                                    {this.state.dd.socialProfiles ?
                                        this.state.dd.socialProfiles.map(value => {
                                            if (value.type === 'GOOGLE')
                                                return <a target="_blank" href={value.profileUrl}> <i class="fa fa-google fa-1x" aria-hidden="true"></i></a>
                                            else if (value.type === 'FACEBOOK')
                                                return <a target="_blank" href={value.profileUrl}> <i class="fa fa-facebook-official fa-1x" aria-hidden="true"></i></a>
                                            else if (value.type === 'INSTAGRAM')
                                                return <a target="_blank" href={value.profileUrl}> <i class="fa fa-instagram fa-1x" aria-hidden="true"></i></a>
                                            else if (value.type === "TWITTER")
                                                return <a target="_blank" href={value.profileUrl}> <i class="fa fa-twitter fa-1x" aria-hidden="true"></i></a>
                                            else if (value.type === "LINKEDIN")
                                                return <a target="_blank" href={value.profileUrl}> <i class="fa fa-linkedin-square fa-1x" aria-hidden="true"></i></a>
                                            else
                                                return null
                                        })
                                        : null}
                                </h6>
                                </p>
                                <p><h6>Published on:{this.state.blogType==="homeopathy-for-anxiety"? "Apr 22, 2019":" Apr 19, 2019"}</h6></p>
                                <p><h6>{Object.keys(this.state.ratings).length != 0 ? "Ratings: " : null}
                                    {Object.keys(this.state.ratings).length != 0 ?
                                        <>
                                            <StarRatings
                                                rating={this.state.ratings[this.state.did].avgRating}
                                                starRatedColor="grey"
                                                starHoverColor="grey"
                                                numberOfStars={5}
                                                starDimension="15px"
                                                name='rating'
                                            /><span>({this.state.ratings[this.state.did].totalRatings})</span>
                                        </>
                                        : null}
                                </h6>
                                </p>
                            </Media.Body>
                        </Media>
                        <br/>
                   <BlogContent blogType={this.state.blogType}/>
                    <Button className="primary mb-2" onClick={this.handleBack}>Back</Button>
                </div>
            </Container>
        );
    }
}

export default BlogDetails;
