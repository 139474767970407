import React, { Component } from 'react';
import StarRatings from 'react-star-ratings';
// eslint-disable-next-line
import { Container, Row, Media, Col, Form, Image, Button, CardDeck, Card } from 'react-bootstrap';
import EmptyJumbotron from '../common/EmptyJumbotron';
import './Doctors.css';
import { getDoctorList, getSpecializationList, getQualificationList } from '../util/APIUtils';
import { getDoctorListByStatus, searchDoctorBy } from '../util/searchAPIUtils'
import { getRatingByProviderId } from '../util/feedbackAndRatingUtils';
import Alert from 'react-s-alert';
import LoadingIndicator from '../common/LoadingIndicator';
import AddSharpIcon from '@material-ui/icons/AddSharp';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { getRole } from '../util/functionUtils';
import Select from 'react-select';
import Referential from '../referential/Referential';
import SearchIcon from '@material-ui/icons/Search';
import { Link } from 'react-router-dom';
import RefreshTwoToneIcon from '@material-ui/icons/RefreshTwoTone';
import useAnalyticsEventTracker from '../common/useAnalyticsEventTracker';

const gaEventTracker = useAnalyticsEventTracker('Online Doctors');

export default class Doctors extends Component {

	constructor(props) {
		super(props);
		this.state = {
			dcList: [],
			spCode: this.props.history.location.state ? this.props.history.location.state.spCode ? this.props.history.location.state.spCode : '' : '',
			qlCode: this.props.history.location.state ? this.props.history.location.state.qlCode ? this.props.history.location.state.qlCode : '' : '',
			qlCodeSelected: this.props.history.location.state ? this.props.history.location.state.selectedQL ? this.props.history.location.state.selectedQl : null : null,
			spCodeSelected: this.props.history.location.state ? this.props.history.location.state.selectedSp ? this.props.history.location.state.selectedSp : null : null,
			selectedCountry: null,
			selectedState: null,
			selectedCity: null,
			countryCode: '',
			stateCode: '',
			cityCode: '',
			name: '',
			role: getRole(),
			spList: [],
			qualificationList: [],
			specialisations: null,

			loading: true,
			doctorPartReload: false,
			pageNumber: 0,
			pageSize: 20,
			totalElements: 0,
			colorArray: ['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'dark', 'light'],
			status: [{ label: "APPROVED", value: "APPROVED" }, { label: "PENDING", value: "PENDING" }, { label: "REJECTED", value: "REJECTED" }, { label: "DEACTIVATED", value: "DEACTIVATED" }],
			ratings: '',
			rating: 2
		};

		this.loadDc = this.loadDc.bind(this);
		this.getSps = this.getSps.bind(this);
		this.loadMore = this.loadMore.bind(this);
		this.searchDoctor = this.searchDoctor.bind(this);
		this.handleSearch = this.handleSearch.bind(this);
	}

	loadMore() {
		this.setState({
			pageNumber: this.state.pageNumber + 1
		})
		getDoctorList(this.state.pageNumber, this.state.pageSize)
			.then(response => {
				this.setState({
					dcList: [...this.state.dcList, ...response.content],
					loading: false
				});
			}).catch(error => {
				this.setState({
					loading: false
				});
				Alert.error((error && error.message) || 'Something went wrong, please try again later!');
			});
	}

	// load doctors list
	loadDc() {
		this.setState({
			loading: true
		})
		getDoctorList(this.state.pageNumber, this.state.pageSize)
			.then(response => {
				this.setState({
					dcList: response.content,
					totalElements: response.totalElements,

				});
				const spIds = []
				this.state.dcList.map(dc => {
					return (
						spIds.push(dc.id)
					)
				})
				this.getRatings(spIds)
			}).catch(error => {
				this.setState({
					loading: false
				});
				Alert.error((error && error.message) || 'Something went wrong, please try again later!');
			});

	}

	getSps(spArr) {
		let spStr = '';
		if (spArr) {
			spArr.map(function (sp) {
				spStr = spStr + sp.dislayStr + ", "
			})
			//let toSlc = 10;
			//spStr = spStr.substring(0, 15) + "...";
			spStr = spStr.slice(0, -2);
			return spStr;
		} else {
			return 'NA';
		}
	}


	//loading specilisation
	loadSp = () => {
		getSpecializationList(this.state.pageNumber, 100)
			.then(response => {
				this.setState({
					spList: response.content.map(data => {
						return { label: data.dislayStr, value: data }
					})
				});
			}).catch(error => {
				this.setState({
					loading: false
				});
				Alert.error((error && error.message) || 'Something went wrong, please try again later!');
			});
	}

	//loading qualification
	loadql = () => {
		getQualificationList(this.state.pageNumber, 100)
			.then(response => {
				this.setState({
					qualificationList: response.content.map(data => {
						return { label: data.dislayStr, value: data }
					})
				});
			}).catch(error => {
				this.setState({
					loading: false
				})
				Alert.error((error && error.message) || 'Something went wrong, please try again later!');
			})
	}

	async handleSearch() {
		await this.setState({
			pageNumber: 0,
			doctorPartReload: true
		})
		searchDoctorBy(this.state.name, this.state.spCode, this.state.qlCode, this.state.countryCode, this.state.stateCode, this.state.cityCode, this.state.pageNumber, this.state.pageSize)
			.then(response => {
				this.setState({
					dcList: response.content,
					totalElements: response.totalElements,
					doctorPartReload: false,
					loading:false
				});
			}).catch(error => {
				this.setState({
					doctorPartReload: false,
					loading:false
				});
				Alert.error((error && error.message) || 'Something went wrong, please try again later!');
			});
	}

	getRatings = (spIds) => {
		getRatingByProviderId(spIds)
			.then(response => {
				this.setState({
					ratings: response,
					loading: false
				})
				// const abc= this.state.ratings[userId]["avgRating"]
			}).catch(error => {
				this.setState({
					loading: false
				})
				Alert.error((error && error.message) || 'Something went wrong, please try again later!');
			})
	}

	componentDidMount() {

		this.loadql()
		this.loadSp()
		if (this.state.spCode || this.state.qlCode) {
			this.handleSearch()
		}
		else {
			this.loadDc()
		}
		window.scrollTo(0, 0)
	}



	// searching doctor
	async searchDoctor(setValue, setName, selectedOption, selected) {
		await this.setState({
			[setName]: setValue,
			[selected]: selectedOption
		})
	}

	handleNameChange = (event) => {
		this.setState({
			name: event.target.value
		})
	}

	clearSearch = () => {
		this.loadDc()
		this.setState({
			name: '',
			qlCode: '',
			qlCodeSelected: null,
			spCode: '',
			spCodeSelected: null,
			selectedCountry: null,
			selectedState: null,
			selectedCity: null,
			countryCode: '',
			stateCode: '',
			cityCode: '',
		})
	}


	// get doctor by status
	searchDoctorByStatus = (selectedOption) => {
		const status = selectedOption.value
		this.setState({
			loading: true
		})
		getDoctorListByStatus(status)
			.then(response => {
				this.setState({
					dcList: response.content,
					loading: false
				});
			}).catch(error => {
				this.setState({
					loading: false
				});
				Alert.error((error && error.message) || 'Something went wrong, please try again later!');
			});

	}

	handleBack = () => {
		this.props.history.goBack()
	}



	render() {
		if (this.state.loading) {
			return <LoadingIndicator />
		}


		return (
			<Container>
				<div className="doctor-container container-fluid">
					<Row className="welcome text-center mb-5">
						<Col sm={12}>
							<h3>Doctors</h3>
						</Col>
						<hr />
					</Row>
					<div className="heading">
						<Button className="float-right" varient="primary" onClick={this.handleBack}><ArrowBackIcon /></Button>
						{this.state.role.roleSuperAdmin || this.state.role.roleAdmin || this.state.role.roleSupportAdmin ? <Link to={{ pathname: "/add-doctor" }}><Button><AddSharpIcon /></Button> </Link> : null}
					</div>
					<Row className="justify-content-center mb-2">
						<Col md={7}>
							<Form.Control data-browse="Button text" type="text" name="name" value={this.state.name} placeholder="Search by doctor name" onChange={this.handleNameChange} />
						</Col>
					</Row>
					<Row>
						<Col md={2} className="mb-2">
							{this.state.role.roleAdmin === true ?
								<Select
									options={this.state.qualificationList}
									onChange={(selectedOption) => this.searchDoctor(selectedOption.value.code, "qlCode", selectedOption, "qlCodeSelected")}
									placeholder="Qualification"
									value={this.state.qlCodeSelected}
								/> : null}
						</Col>
						<Col md={2} className="mb-2">
							<Select
								options={this.state.spList}
								onChange={(selectedOption) => this.searchDoctor(selectedOption.value.code, "spCode", selectedOption, "spCodeSelected")}
								placeholder="Specialisation"
								value={this.state.spCodeSelected}
							/>
						</Col>
						<Referential
							doctorFilterBy={(setValue, setName, selectedOption, selected) => this.searchDoctor(setValue, setName, selectedOption, selected)}
							selectedCity={this.state.selectedCity}
							selectedState={this.state.selectedState}
							selectedCountry={this.state.selectedCountry}
						/>
						<Col md={2}>
							{
								this.state.role.roleAdmin === true ?
									<Select
										options={this.state.status}
										onChange={this.searchDoctorByStatus}
										placeholder="status"
									/> : null
							}
						</Col>
					</Row>
					<Row className="justify-content-end mt-2">
						<Col xs={3} sm={2} md={1}>
							<Button onClick={this.handleSearch}> <SearchIcon /></Button>
						</Col>
						<Col xs={3} sm={2} md={1}>
							<Button onClick={this.clearSearch}><RefreshTwoToneIcon /></Button>
						</Col>
					</Row>


					<hr />
					{this.state.doctorPartReload ? <LoadingIndicator /> :
						<>
							<CardDeck>
								{
									this.state.dcList.map(function (dc) {
										// let userId = dc.userId
										return (
											<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-4" key={dc.id}>
												<Media >
													<Image
														width={150}
														height={150}
														className="mr-3"
														src={dc.profileLink ? dc.profileLink : "../img/mac.png"}
														alt="Generic placeholder"
														roundedCircle
													/>
													<Media.Body>
														<h6>{"Dr. " + dc.name}</h6>
														<p className="crdfont mb-2">{dc.experienceInYears + " years | " + this.getSps(dc.specialisations)}</p>
														<p className="crdfont mb-2">{dc.about.substring(0, 100) + "..."}</p>
														<Link to={{ pathname: "/dp/" + dc.id }} style={{ color: 'white', textDecoration: 'none' }}><Button className="align-center" variant="secondary" size="sm" onClick={()=>gaEventTracker('Know More')}> Know more...</Button></Link>
														{dc.scheduleavailable ? <Link to={{ pathname: "/appointment-form", state: { doctorId: dc.id, dd: dc } }} style={{ color: 'white', textDecoration: 'none' }}><Button className="align-center btn btn-success float-right"  size="sm"  onClick={()=>gaEventTracker('Consult Now')}> Consult Now</Button></Link>:null}
														<br />


															{/* {this.state.ratings[userId] ? <b>Ratings:{" "}{this.state.ratings[userId].totalRatings}</b> : null} */}
														{this.state.ratings[dc.id] ?
															<>
																<StarRatings
																	rating={this.state.ratings[dc.id].avgRating}
																	starRatedColor="grey"
																	starHoverColor="grey"
																	numberOfStars={5}
																	starDimension="15px"
																	name='rating'
																/><span>({this.state.ratings[dc.id].totalRatings})</span>
															</>
															: null}

													</Media.Body>

												</Media>
											</div>
										)
									}, this)
								}
							</CardDeck>{this.state.dcList.length >= 20 ?
								this.state.dcList.length < this.state.totalElements ?
									<Button color="primary" className=" loadMore" size="sm" onClick={this.loadMore}>Load more...</Button> :
									<Button color="primary" className=" loadMore" size="sm" href="#top">Back to top</Button> : null
							}
							{this.state.dcList.length === 0 ? <h3 className="text-center"> No doctors found !</h3> : null}
						</>
					}
				</div>

				<EmptyJumbotron />

			</Container>
		)
	}
}