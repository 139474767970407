import React, { Component, Fragment } from 'react';
import { Form,Col,Button,Row} from 'react-bootstrap';
import Select from 'react-select';
import RemoveSharpIcon from '@material-ui/icons/RemoveSharp';



class SocialProfile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            displayValue:{label:this.props.data.type , value:this.props.data},
            type: "",
            profileUrl: this.props.data.profileUrl,
            typeList: [{label:"GOOGLE",value:"GOOGLE"},{label:"FACEBOOK",value:"FACEBOOK"},{label:"INSTAGRAM",value:"INSTAGRAM"},{label:"LINKEDIN",value:"LINKEDIN"},{label:"TWITTER",value:"TWITTER"}]
        }
    }
    handleInputChange = (event, index) => {
        const inputName = event.target.name;
        const inputValue = event.target.value;
        this.setState({
            [inputName]: inputValue
        })

        this.props.inputChange(inputName, inputValue, index)
    }

    handleDropdownChange = (selectedOption,inputName,index) =>{
        const inputValue = selectedOption.value
        this.setState({
            [inputName]:inputValue,
            displayValue :selectedOption 
        })
        this.props.inputChange(inputName, inputValue, index)

    }

    render() {
        return (
            <Fragment>
                <Row className="mt-3">
                    <Col md={4}>
                        {this.props.data.type ?
                            <Select
                                options = {this.state.typeList}
                                value = {this.state.displayValue}
                                onChange = {(selectedOption) => this.handleDropdownChange(selectedOption,'type',this.props.index)}
                                placeholder="Select Type"
                            />

                        :  <Select
                                options = {this.state.typeList}
                                onChange = {(selectedOption) => this.handleDropdownChange(selectedOption,'type',this.props.index)}
                                placeholder="Select Type"
                            />
                    }
                    </Col>
                    <Col md={7}>
                        <Form.Control value={this.state.profileUrl} name="profileUrl" placeholder="Profile Url" onChange={(event) => this.handleInputChange(event, this.props.index)} />
                    </Col>
                    <Col md={1}>
                        <Button style={{float:"right"}} onClick={() => this.props.deleteSocialProfile(this.props.index)} className="btn-danger"><RemoveSharpIcon /></Button>
                    </Col>
                </Row>
            </Fragment>
        )
    }
}

export default SocialProfile;