import React, { Component } from 'react'
import './ScheduleDetails.css';
import Alert from 'react-s-alert';
import { loadDoctorConfigDetailsById } from '../../util/APIUtils'
import { Table, Container, Button, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import DoctorProfileHeader from '../../doctors/DoctorProfileHeader';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import LoadingIndicator from '../../common/LoadingIndicator'


export default class ScheduleDetails extends Component {

    constructor(props) {
        super(props)
        this.state = {
            docterconfigdetails: [],
            doctorId: this.props.history.location.state.doctorId,
            Sunday: [],
            Monday: [],
            Tuesday: [],
            Wednesday: [],
            Thursday: [],
            Friday: [],
            Saturday: [],
            loading: true
        }
    }

    loadDoctorConfigDetails() {

        const doctorId = this.state.doctorId;

        loadDoctorConfigDetailsById(doctorId)
            .then(response => {
                this.setState({
                    docterconfigdetails: response,
                    loading: false
                })
                response.map((value) => {
                    let day = ''
                    if (value.dayOfTheweek === 1)
                        day = 'Sunday'
                    else if (value.dayOfTheweek === 2)
                        day = 'Monday'
                    else if (value.dayOfTheweek === 3)
                        day = 'Tuesday'
                    else if (value.dayOfTheweek === 4)
                        day = 'Wednesday'
                    else if (value.dayOfTheweek === 5)
                        day = 'Thursday'
                    else if (value.dayOfTheweek === 6)
                        day = 'Friday'
                    else if (value.dayOfTheweek === 7)
                        day = 'Saturday'

                    value.index = Math.random()

                    this.setState((prevState) => ({
                        [day]: [...prevState[day], value]
                    }))
                })
            })
            .catch(error => {
                Alert.error('Something went wrong, please try again later!')
            });
    }

    componentDidMount() {
        this.loadDoctorConfigDetails()
        window.scrollTo(0, 0)
    }
    render() {

        if (this.state.loading)
            return <LoadingIndicator />

        return (
            <Container>
                <div className="schedule-details-container container-fluid">
                    <Row className="welcome text-center mb-5">
                        <Col sm={12}>
                            <h3>Consultation Schedule</h3>
                        </Col>
                        <hr />
                    </Row>
                    <Row className="mb-5">
                        <Col md={12}>
                            <DoctorProfileHeader dd={this.props.history.location.state.dd} />
                        </Col>
                    </Row>
                    {this.state.docterconfigdetails.length === 0 ?
                        <p className="text-center m-5">No configuration details available, Please configure your consultation schedule {" "}
                            <Link to={{ pathname: "/dconfig", state: { Sunday: this.state.Sunday, Monday: this.state.Monday, Tuesday: this.state.Tuesday, Wednesday: this.state.Wednesday, Thursday: this.state.Thursday, Friday: this.state.Friday, Saturday: this.state.Saturday, doctorId: this.state.doctorId, dd: this.props.history.location.state.dd } }}>
                                <Button className="btn btn-primary">Configure</Button>
                            </Link>
                        </p>
                        :
                        <>
                            <Link to={{ pathname: "/dp/" + this.state.doctorId }} className="btn btn-primary mb-2"><ArrowBackIcon /></Link>
                            <Table responsive hover className="shadow mb-5" id="schedule-detail">
                                <thead className="thead-color">
                                    <tr id="schedule-detailHead">
                                        <th> Day</th>
                                        <th>Start Time</th>
                                        <th>End Time</th>
                                        <th>Type</th>
                                        <th>Duration</th>
                                        <th>Currency</th>
                                        <th>Charge</th>
                                    </tr>
                                </thead>
                                {this.state.docterconfigdetails.map((value, index) => {
                                    return (
                                        <tbody>
                                            <tr>
                                                <td data-label="Day">{value.dayOfTheweek === 1 ? 'Sunday' :
                                                    value.dayOfTheweek === 2 ? 'Monday' :
                                                        value.dayOfTheweek === 3 ? 'Tuesday' :
                                                            value.dayOfTheweek === 4 ? 'Wednesday' :
                                                                value.dayOfTheweek === 5 ? 'Thursday' :
                                                                    value.dayOfTheweek === 6 ? 'Friday' :
                                                                        value.dayOfTheweek === 7 ? 'Saturday' :
                                                                            null
                                                }
                                                </td>
                                                <td data-label="Start Time"> {value.startTimeStr}</td>
                                                <td data-label="End Time"> {value.endTimeStr}</td>
                                                <td data-label="Type"> {value.type}</td>
                                                <td data-label="Duration"> {value.durationInMins}</td>
                                                <td data-label="Currency"> {value.currency}</td>
                                                <td data-label="Charge"> {value.charge}</td>

                                            </tr>
                                        </tbody>

                                    )
                                })}
                            </Table>
                            <Link to={{ pathname: "/dp/" + this.state.doctorId }} className="btn btn-primary mb-5">Back</Link>
                            <Link to={{ pathname: "/dconfig", state: { Sunday: this.state.Sunday, Monday: this.state.Monday, Tuesday: this.state.Tuesday, Wednesday: this.state.Wednesday, Thursday: this.state.Thursday, Friday: this.state.Friday, Saturday: this.state.Saturday, doctorId: this.state.doctorId, dd: this.props.history.location.state.dd } }}>
                                <Button className="btn btn-primary float-right mb-5">Edit</Button>
                            </Link>
                        </>
                    }
                </div>
            </Container>
        )
    }
}
