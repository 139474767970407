import React, { Component } from 'react'
import { Container, Table, Form, Row, Col, Button } from 'react-bootstrap';
import RemoveSharpIcon from '@material-ui/icons/RemoveSharp';


export default class Medicine extends Component {

    constructor(props) {
        super(props)
        this.state = {
            name: this.props.value.name ? this.props.value.name : "",
            dosage: this.props.value.dosage ? this.props.value.dosage : '',
            frequency: this.props.value.frequency ? this.props.value.frequency : '',
            duration: this.props.value.duration ? this.props.value.duration : '',
            instruction: this.props.value.instruction ? this.props.value.instruction : ''
        }
    }

    changeInputHandler(event, index) {
        const inputName = event.target.name
        const inputValue = event.target.value
        this.setState({
            [inputName]: inputValue
        })

        this.props.medicineObject(inputName, inputValue, index)
    }

    render() {
        // return (
        //     <div>

        //         <Row>
        //             <Col md={2}>
        //                 <Form.Control placeholder="Medicine Name" name="name" value={this.state.name} onChange={(event) => this.changeInputHandler(event , this.props.index)} />
        //             </Col>
        //             <Col md={2}>
        //                 <Form.Control placeholder="Dosage" name="dosage" value={this.state.dosage} onChange={(event) => this.changeInputHandler(event , this.props.index)} />
        //             </Col>
        //             <Col md={2}>
        //                 <Form.Control placeholder="Frequency" name="frequency" value={this.state.frequency} onChange={(event) => this.changeInputHandler(event , this.props.index)} />
        //             </Col>
        //             <Col md={2}>
        //                 <Form.Control placeholder="Duration" name="duration" value={this.state.duration} onChange={(event) => this.changeInputHandler(event , this.props.index)} />
        //             </Col>
        //             <Col md={3}>
        //                 <Form.Control placeholder="Instruction" name="instruction" value={this.state.instruction} onChange={(event) => this.changeInputHandler(event , this.props.index)} />
        //             </Col>
        //             <Col md={1}>
        //                 <Button style={{float:"right"}} onClick={() => this.props.deleteMedicine(this.props.index)} className="btn-danger"><RemoveSharpIcon /></Button>
        //             </Col>
        //         </Row>
        //         <hr />

        //     </div>
        // )

        return (
            <tr>
                <td data-label="Medicine Name">
                        <Form.Control placeholder="Medicine Name" name="name" value={this.state.name} onChange={(event) => this.changeInputHandler(event, this.props.index)} />
                </td>
                <td data-label="Dosage">
                        <Form.Control placeholder="Dosage" name="dosage" value={this.state.dosage} onChange={(event) => this.changeInputHandler(event, this.props.index)} />
                </td>
                <td data-label="Frequency">
                        <Form.Control placeholder="Frequency" name="frequency" value={this.state.frequency} onChange={(event) => this.changeInputHandler(event, this.props.index)} />
                </td>
                <td data-label="Duration">
                        <Form.Control placeholder="Duration" name="duration" value={this.state.duration} onChange={(event) => this.changeInputHandler(event, this.props.index)} />
                </td>
                <td data-label="Instruction">
                        <Form.Control placeholder="Instruction" name="instruction" value={this.state.instruction} onChange={(event) => this.changeInputHandler(event, this.props.index)} />
                </td>
                <td>
                         <Button style={{ float: "right" }} onClick={() => this.props.deleteMedicine(this.props.index)} className="btn-danger"><RemoveSharpIcon /></Button>
                </td>
            </tr>
        )

    }
}
