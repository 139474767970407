import React, { Component } from 'react';
import { Container, Table, Form, Row, Col, Button } from 'react-bootstrap';
import './EmailFormToResetPassword.css'
import EmptyJumbotron from '../../common/EmptyJumbotron';
import './ResetPassword.css';
import Alert from 'react-s-alert';
import { passwordReset } from '../../util/APIUtils';

class ResetPassword extends Component {

    constructor() {
        super()
        let url_string = window.location.href
        let url = new URL(url_string)

        this.state = {
            newPassword: null,
            confirmedPassword: null,
            resetPasswordToken: url.searchParams.get("requestId")
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleInputChange(event) {
        const target = event.target
        const inputName = target.name
        const inputValue = target.value
        this.setState({
            [inputName]: inputValue
        });
    }

    validatePassword() {
        const newPassword = this.state.newPassword;
        const confirmedPassword = this.state.confirmedPassword;
        var reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        if (!reg.test(newPassword)) {
            Alert.error("password should contain at least 8 characters, one uppercase,one lowercase,one digit and one special character");
            return false;
        }
        if (newPassword !== confirmedPassword) {
            Alert.error('new password did not matched confirm password');
            return false;
        }
        return true;
    }

    componentDidMount(){
        window.scrollTo(0, 0)
    }


    handleSubmit(event) {
        event.preventDefault()
        if (this.validatePassword()) {
            const passwordFormResetRequest = Object.assign({}, this.state);

            passwordReset(passwordFormResetRequest)
                .then(response => {
                    Alert.success("Your password updated successully!");
                    this.props.history.push("/login");
                }).catch(error => {
                    console.log("error" + error.message);
                    Alert.error((error && error.message) || 'Oops! Something went wrong. Please try again!');
                });
        }
    }

    render() {
        return (
            <Container>
                <div className="reset-password-container">
                    <h2> Reset Password</h2>

                    <Form>
                        <Form.Group as={Row} controlId="newPassword">
                            <Form.Label column sm={2}>
                                New Password
							    </Form.Label>
                            <Col sm={3}>
                                <Form.Control type="password" name="newPassword" placeholder="new Password" onChange={this.handleInputChange} />
                                <p className="login-link"> Password should contain at least 8 characters, 1 Uppercase,1 lowercase,1 digit and 1 special character</p>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="ConfirmPassword">
                            <Form.Label column sm={2}>
                                Confirm Password
							    </Form.Label>
                            <Col sm={3}>
                                <Form.Control type="password" name="confirmedPassword" placeholder="Confirm Password" onChange={this.handleInputChange} />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Col sm={{ span: 2, offset: 2 }}>
                                <Button onClick={this.handleSubmit}>Update</Button>
                            </Col>
                            <Col >
                                <Button type="button" href="/forgotpassword"> Back</Button>
                            </Col>
                        </Form.Group>
                    </Form>

                </div>
                <EmptyJumbotron />
            </Container>
        );
    }
}

export default ResetPassword;