import React, { Component } from 'react';
import './EntityDoctorList.css';
import Alert from 'react-s-alert';
import { Row, Col, Card, Container, Table, Button } from 'react-bootstrap';
import { getEntityDoctorList, getUserForentity, getDoctorInfo } from '../util/EntityUtils'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link } from 'react-router-dom';
import LoadingIndicator from '../common/LoadingIndicator';
import { getRole } from '../util/functionUtils';
import AddSharpIcon from '@material-ui/icons/AddSharp';
class EntityDoctorList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            role: getRole(),
            doctorInfo: [],
            doctorAssociationList: [],
            entityUserList: [],
            entityId: this.props.history.location ? this.props.history.location.state.entityId : null,
            entityName: this.props.history.location.state ? this.props.history.location.state.entityName : null,
            spType: "DOC",
            pageNumber: 0,
            pageSize: 20,
            totalElements: 0,
            loading: true,
            // isEdit: this.props ? this.props.isEdit : false,
            isEdit: true,
            spec: []
        }
        this.handleBack = this.handleBack.bind(this)
    }

    componentDidMount() {
        this.loadDoctorByEntity()
    }

    loadDoctorByEntity() {
        getEntityDoctorList(this.state.entityId, this.state.spType, this.state.pageNumber, this.state.pageSize)
            .then(response => {
                this.setState({
                    doctorAssociationList: response.content,
                });
                let doctorIds = []
                this.state.doctorAssociationList.map(value => {
                    doctorIds.push(value.spId)
                })
                getUserForentity(doctorIds)
                    .then(response => {
                        this.setState({
                            entityUserList: response,
                            totalElements: response.totalElements,
                            loading: false,
                        })
                    }).catch(error => {
                        Alert.error((error && error.message) || 'Something went wrong, please try again later!');
                    })
                getDoctorInfo(doctorIds, "uid")
                    .then(response => {
                        this.setState({
                            doctorInfo: response,
                            loading: false,
                        })

                        //mapping specialisation 
                        let specialisation = []
                        this.state.doctorInfo.map(val => {
                            let id = val.userId;
                            let spString = ''
                            val.specialisations.map(sp_map => {
                                spString += sp_map.dislayStr + ','
                            })
                            let dict = {}
                            dict.userId = id
                            dict.sp = spString.substring(0, spString.length - 1)
                            specialisation.push(dict)
                        })
                        this.setState({
                            spec: specialisation
                        })
                    }).catch(error => {
                        Alert.error((error && error.message) || 'Something went wrong, please try again later!');
                    })


            }).catch(error => {
                this.setState({
                    loading: false
                })
                Alert.error((error && error.message) || 'Something went wrong, please try again later!');
            })
    }

    handleBack = () => {
        this.props.history.goBack()
    }


    editHandler = () => {
        this.props.history.push({
            pathname: '/entity-association',
            state: { isEdit: this.state.isEdit, entityId: this.state.entityId, entityName: this.state.entityName }
        })
    }

    render() {

        if (this.state.loading)
            return <LoadingIndicator />

        return (

            <Container>
                <div className="entityDoctorList-container">
                    <div className="feature-title container-fluid">
                        <h3> Doctors associated with {this.state.entityName} entity</h3><hr />
                    </div>
                    <Link className="mb-4" onClick={this.handleBack}><Button varient="primary"> <ArrowBackIcon /> </Button></Link>
                    {this.state.role.roleAdmin ? <Button className="float-right" onClick={this.editHandler}><AddSharpIcon /></Button> : null}
                    <Table id="entityDoctorListTable">
                        {this.state.entityUserList.length == 0 ? null :
                            <thead className="thead-color" >
                                <tr id="entityDoctorListTableHead">
                                    <td className="text-left">#</td>
                                    <td className="text-left">Name</td>
                                    <td className="text-left"> Speciality</td>
                                </tr>
                            </thead>
                        }


                        <tbody>
                            {this.state.entityUserList.map(user => {
                                return (<tr>
                                    <td className="text-left" data-label="#">{user.id}</td>

                                    <td className="text-left" data-label="Name">{user.imageUrl ? (
                                        <span className="profile-avatar">
                                            <img src={user.imageUrl} alt={user.name} width="25px" height="25px" />
                                        </span>
                                    ) : (
                                        <span className="profile">{user.name[0]}</span>
                                    )
                                    }
                                        {" "}  {user.name}</td>

                                    {this.state.spec.map(val => {
                                        if (user.id == val.userId)
                                            return <td data-label="Speciality">{val.sp}</td>
                                    })}

                                </tr>)
                            })}
                        </tbody>
                    </Table>
                    {this.state.entityUserList.length == 0 ? <h3 className="text-center">No doctors associated with {this.state.entityName}</h3> : null}
                </div>
            </Container>
        );
    }
}

export default EntityDoctorList;