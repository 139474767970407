import React, { Component, Fragment } from 'react';
import { getEventsList } from '../util/APIUtils';
import { getRole, changeTime, eventsTypeMapping } from '../util/functionUtils';
import Alert from 'react-s-alert';
import AddSharpIcon from '@material-ui/icons/AddSharp';
import LoadingIndicator from '../common/LoadingIndicator';
import './Event.css';
import { Link, Redirect } from 'react-router-dom'
import { registerEvents } from '../util/eventsUtils'
import { Container, Row, Col, Card, Form, Button, CardDeck, Media, Image } from 'react-bootstrap'
import { AUTHENTICATED } from '../constants';



class Event extends Component {

    constructor() {
        super()
        this.state = {
            eventsList: [],
            pageNumber: 0,
            pageSize: 20,
            role: getRole(),
            loading: true,
            authenticated: localStorage.getItem(AUTHENTICATED) ? localStorage.getItem(AUTHENTICATED) : false,
        }
    }

    loadEvents = (pastEvents) => {
        // this.setState({
        //     loading:true
        // })
        getEventsList(this.state.pageNumber, this.state.pageSize, pastEvents)
            .then(response => {
                this.setState({
                    eventsList: response.content,
                    loading: false
                })
            }).catch(error => {
                Alert.error((error && error.message) || 'Something went wrong, please try again later!');
                this.setState({
                    loading: false
                })
            })
    }


    componentDidMount() {
        this.loadEvents('false')
    }

    registerToEvents = () => {
        if (this.state.authenticated) {
            registerEvents(this.state.eventId)
                .then(response => {
                    Alert.success("Successfully registered to the event.")
                })
                .catch(error => {
                    Alert.error('Oops! Something went wrong!')
                })
        } else {
            this.props.history.push("/login")
        }
    }

    handlePast = (events) => {
        this.loadEvents(events.target.checked)
    }

    render() {
        if (this.state.loading)
            return <LoadingIndicator />

        return (
            <Container>
                <div className="event-container container-fluid">
                    <Row className="welcome text-center mb-5">
                        <Col sm={12}>
                            <h1 className="display-4">Events</h1>
                        </Col>
                        <hr />
                    </Row>
                    {/* TODO, enable post Demo */}
                    <div className="heading">
                        <Form.Check type="checkbox" label="Past Events" onChange={(event) => this.handlePast(event)} />
                        {this.state.role.roleAdmin ? <Button href="/eventform"><AddSharpIcon /></Button> : ''}
                    </div>
                    <hr />
                    <CardDeck>

                        {this.state.eventsList.map((value) => {
                            return (
                                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-4" key={value.id}>
                                    <Media >
                                        <Image
                                            width={150}
                                            height={150}
                                            className="mr-3"
                                            src={value.eventImgLink ? value.eventImgLink : "../img/mac.png"}
                                            alt="Event Image"
                                            roundedCircle
                                        />
                                        <Media.Body>
                                            <h4>{value.title}</h4>
                                            <h5>{value.subTitle.substring(0, 50) + "..."}</h5>
                                            <b>Start Date : </b>
                                            {new Date(value.dateStart).toLocaleDateString([], { month: 'long', day: 'numeric' })} at {changeTime(value.dateStart)}
                                            <br></br>
                                            <b>Type:</b>{eventsTypeMapping(value.type)}<br></br>
                                            <Link to={{ pathname: "/ed/"+ value.id }} style={{ color: 'white', textDecoration: 'none' }}><Button className="align-center" variant="primary" size="sm">Know more...</Button></Link>
                                            {/* {this.state.authenticated ? <Link style={{ color: 'white', textDecoration: 'none' }}><Button className="align-center btn btn-primary float-right" size="sm" data-toggle="modal" data-target="#staticBackdrop"> Register</Button></Link> :
                                                <Link style={{ color: 'white', textDecoration: 'none' }}><Button className="align-center btn btn-primary float-right" size="sm" onClick={this.registerToEvents} > Register</Button></Link>}
                                            <div className="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                <div className="modal-dialog">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title textColor" id="staticBackdropLabel">Thank you for registering to the event. We will share the details to join/attend the event on your registered email id.</h5>
                                                            <Button type="Button" className="close" data-dismiss="modal" aria-label="Close">
                                                                <span aria-hidden="true">&times;</span>
                                                            </Button>
                                                        </div>
                                                        <div className="modal-footer">
                                                            <Button type="Button" className="btn btn-secondary" data-dismiss="modal">Cancel</Button>
                                                            <Button type="Button" className="btn btn-primary" data-dismiss="modal" onClick={this.registerToEvents} >Submit</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}

                                        </Media.Body>
                                    </Media>
                                </div>
                            )
                        })}
                    </CardDeck>
                    {this.state.eventsList.length==0? <h4 className="text-center mb-5">No Events!</h4>:null}
                   
                </div>
            </Container>
        );
    }
}

export default Event