import React, { Component } from 'react';
import { Media,Image } from 'react-bootstrap';

class DoctorProfileHeader extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dd: this.props.dd
        }
    }
    getSps(spArr) {
		let spStr = '';
		if (spArr) {
			spArr.map(function (sp) {
				spStr = spStr + sp.dislayStr + ", "
			})
			//let toSlc = 10;
			//spStr = spStr.substring(0, 15) + "...";
			spStr = spStr.slice(0, -2);
			return spStr;
		} else {
			return 'NA';
		}
	}

    render() {
        return (
            <Media>
                <Image
                    width={150}
                    height={150}
                    className="mr-3"
                    src={this.state.dd.profileLink ? this.state.dd.profileLink : "../img/mac.png"}
                    alt="Generic placeholder"
                    roundedCircle
                />
                <Media.Body>
                    <h6>{"Dr. " + this.state.dd.name}</h6>
                    <p className="crdfont mb-2">{this.state.dd.experienceInYears + " years | " + this.getSps(this.state.dd.specialisations)}</p>
                    <p className="crdfont mb-2">{this.state.dd.about.substring(0, 100) + "..."}</p>
                </Media.Body>
            </Media>
        );
    }
}

export default DoctorProfileHeader;