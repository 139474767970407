import React, { Component } from 'react';
import { Container} from 'react-bootstrap';
import Alert from 'react-s-alert';
import './Login.css';
import LoginForm from './LoginForm';
import { GOOGLE_AUTH_URL, AUTHENTICATED } from '../../constants';
import { Link, Redirect } from 'react-router-dom';
import googleLogo from '../../img/google-logo.png';
import EmptyJumbotron from '../../common/EmptyJumbotron';
import { getCurrentUser } from '../../util/APIUtils';
import { CURRENT_USER } from '../../constants';
import { getRole} from '../../util/functionUtils'



class Login extends Component {

    constructor(props){
        super();
        this.state = {
            currentUser: null,
            loaded: false
          };
          this.loadCurrentlyLoggedInUser = this.loadCurrentlyLoggedInUser.bind(this);  
    }
    componentDidMount() {
        // If the OAuth2 login encounters an error, the user is redirected to the /login page with an error.
        // Here we display the error and then remove the error query parameter from the location.
        if (this.props.location.state && this.props.location.state.error) {
            setTimeout(() => {
                Alert.error(this.props.location.state.error, {
                    timeout: 5000
                });
                this.props.history.replace({
                    pathname: this.props.location.pathname
                });
            }, 100);
        }
    }

    loadCurrentlyLoggedInUser() {
        if (!localStorage.getItem(CURRENT_USER)) {
          getCurrentUser()
            .then(response => {
              localStorage.setItem(CURRENT_USER, JSON.stringify(response));
              this.setState({
                currentUser: response,
                loaded: true
              });
            }).catch(error => {
              this.setState({
                loaded: true
              });
            });
        } else {
          this.setState({
            currentUser: JSON.parse(localStorage.getItem(CURRENT_USER)),
            loaded: true
          });
        }
      }

    render() {

        const authenticated = localStorage.getItem(AUTHENTICATED);
        if(authenticated){
            this.loadCurrentlyLoggedInUser();
            if (this.state.currentUser) {
                const role = getRole();
                if (this.state.currentUser.isProfileUpdated) {
                  if (role.roleAdmin) {
                    return <Redirect
                      to={{
                        pathname: "/admin",
                        state: { from: this.props.location }
                      }} />;
                  }
                  else if (role.roleDoctor) { 
                    return <Redirect
                      to={{
                        pathname: "/ap",
                        state: { from: this.props.location }
                      }} />;
                  }
                  else if (role.roleUser) {
                    return <Redirect
                      to={{
                        pathname: "/health-records",
                        state: { from: this.props.location }
                      }} />;
                  }
                } else{
                    return <Redirect
                    to={{
                        pathname: "/profile",
                        state: { from: this.props.location
                            // , currentUser: user 
                        }
                    }} 
                    />;
                }
              }
        }

        
        return (

            <Container>
                <div className="login-container">
                    <div className="login-content">
                        <h1 className="login-title">Login to BI Health</h1>
                        {/* <SocialLogin /> */}
                        {/* <div className="or-separator">
                            <span className="or-text">OR</span>
                        </div> */}
                        <LoginForm {...this.props} history={this.props.history} />
                        <span className="signup-link">New user? <Link to="/signup">Sign up!</Link></span>
                        <div className="forgot-password">
                            Have you forgotten your password ? <Link to="/forgotpassword">Forgot Password!</Link>
                        </div>
                    </div>
                </div>
                <EmptyJumbotron />
            </Container>
        );

    }
}

class SocialLogin extends Component {
    render() {
        return (
            <div className="social-login">
                <a className="btn btn-block social-btn google" href={GOOGLE_AUTH_URL}>
                    <img src={googleLogo} alt="Google" />Log in with Google</a>
            </div>
        );
    }
}



export default Login