import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import './CustomFooter.css';
import { Container} from 'react-bootstrap';

export default class CustomFooter extends Component {
	render() {
		return (
			<Container>
			<footer>
				<div className="container-fluid"></div>
				<div className="row text-center">
					<div className="col-md-4">
						<hr className="light" />
						<img src="../img/logo4.png" />
						<hr className="light" />
						{/* <p className="foottext">XXX-XXX-XXXX</p> */}
						<p className="foottext">contact@binaryintuition.com</p>
						<p className="foottext">Bengaluru, Karnataka, India, 560037</p>
						<p className="foottext">
							Follow Us:&nbsp;
							<a href="https://www.facebook.com/binaryintuition" target="_blank"><i className="fa fa-facebook"></i></a>
							{/* <a href="#"><i className="fa fa-twitter"></i></a>
							<a href="#"><i className="fa fa-instagram"></i></a>
							<a href="#"><i className="fa fa-youtube"></i></a> */}
						</p>
					</div>
					<div className="col-md-4">
						{/* <hr className="light" />
						<h5 className="foottext">Upcoming Features</h5>
						<hr className="light" />
						
						<p className="foottext">Online Pharmacy</p>
						<p className="foottext">Online Diagnostics</p> */}
					</div>
					<div className="col-md-4">
						<hr className="light" />
						<img src="../img/BIlogo4.png" />
						<hr className="light" />
						<p className="foottext"><Link to="/contact">Contact Us</Link></p>
						<p className="foottext"><Link to="/privacy-policy">Privacy Policy</Link></p>
						<p className="foottext"><Link to="/terms">Terms & Conditions</Link></p>
						<p className="foottext"><Link to="/about">About Us</Link></p>
					</div>
					<div className="col-12 pb-4">
						<hr className="light-100" />
						<small>&copy; 2020 BS Unprecedented Intuition Tech Services LLP. All rights reserved.</small>
					</div>
				</div>
			</footer>
			</Container>
		)
	}
}