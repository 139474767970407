import React, { Component } from 'react';
import './Appointment.css';
import { Row, Col, Card, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getCurrentUserId, getRole } from '../util/functionUtils';



class Appointment extends Component {
    constructor() {
        super()
        this.state = {
            role: getRole()
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <Container>
                <div className="appointment-container container-fluid">
                    <div className="feature-title container-fluid">
                        <h3>Appointments</h3><hr />
                    </div>
                    <Row className="justify-content-center">
                        <Col sm={6} md={6} xl={3} className="mb-3">
                            <Link to={{ pathname: "/appointment-list", state: { consumerId: getCurrentUserId(), superUser: false } }} style={{ textDecoration: "none" }} className="text-center">
                                <Card
                                    className="m-2"
                                    bg="primary"
                                    text="light"
                                    style={{ width: '15rem', minHeight: '10rem' }}
                                >
                                    <Card.Body>
                                        <Card.Title>
                                            <h3 className="pt-4">My Appointments</h3>
                                        </Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        {this.state.role.roleDoctor ?
                            <Col sm={6} md={6} xl={3} className="mb-3">
                                <Link to={{ pathname: "/appointment-list", state: { providerId: getCurrentUserId(), superUser: false } }} style={{ textDecoration: "none" }}>
                                    <Card
                                        className="m-2"
                                        bg="primary"
                                        text="light"
                                        style={{ width: '15rem', minHeight: '10rem' }}
                                    >
                                        <Card.Body>
                                            <Card.Title
                                                style={{ fontSize: "25px" }}
                                            >
                                                <h3 className="text-center pt-4">My Patients Appointments</h3>
                                            </Card.Title>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </Col>
                            : null}

                        {this.state.role.roleAdmin ?
                            <Col sm={6} md={6} xl={3} className="mb-3">
                                <Link to={{ pathname: "/appointment-list", state: { superUser: true } }} style={{ textDecoration: "none" }}>
                                    <Card
                                        className="m-2"
                                        bg="primary"
                                        text="light"
                                        style={{ width: '15rem', minHeight: '10rem' }}
                                    >
                                        <Card.Body>
                                            <Card.Title
                                                style={{ fontSize: "25px" }}
                                            >
                                                <h3 className="text-center mt-4">All Appointments </h3>
                                            </Card.Title>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </Col>
                            : null}

                        {this.state.role.roleEntityAdmin ?
                            <Col sm={6} md={6} xl={3} className="mb-3">
                                <Link to={{ pathname: "/en-list", state: { consumerId: getCurrentUserId(), superUser: false } }} style={{ textDecoration: "none" }} className="text-center">
                                    <Card
                                        className="m-2"
                                        bg="primary"
                                        text="light"
                                        style={{ width: '15rem', minHeight: '10rem' }}
                                    >
                                        <Card.Body>
                                            <Card.Title
                                                style={{ fontSize: "25px" }}>
                                                <h3 className="pt-4">My Entity </h3>
                                            </Card.Title>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </Col> : null}
                    </Row>
                </div>
            </Container>
        )
    }
}

export default Appointment;